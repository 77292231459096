import appConfig from "configs/config";

export const pt = {

    help: "Manuais de ajuda",
    dark_mode: "Alternar Dark Mode",
    account: "Utilizador",
    dark: "Escuro",
    light: "Claro",


    my_entity: "A minha entidade",
    users: "Utilizadores",
    list_users: "Listagem de utilizadores",
    create_users: "Criar novo utilizador",

    entities: "Entidades",
    list_entities: "Listagem de entidades",
    create_entity: "Criar entidade",

    home: "Entrada",
    admin: "Definições",

    roles: "Permissões",
    list_roles: "Listagem de permissões",
    create_roles: "Criar perfil de permissões",

    suppliers: "Fornecedores",
    list_supplier: "Listar fornecedores",
    create_supplier: "Criar fornecedor",



    //Menu
    sign_out: "Terminar sessão",
    personal: "Área pessoal",
    impersonate: "Impersonate",
    exit_impersonate: "Sair do modo impersonate",
    change_entity: "Mudar de entidade",
    user_historic: "Histórico de operações",

    //Alerts
    user_impersonate: "Está em modo impersonate",
    user_impersonate_msg: "<strong>Impersonate</strong> - Neste momento está a utilizar a conta do utilizador {{name}}.",

    //FILES
    files: "Documentos",
    manage_files: "Gerir documentos gerais",
    entity_files: "Documentos gerais",
    general_files: "Doc. gerais",


    //Schools
    schools: "Unidades orgânicas",
    list_school: "Listar unidades orgânicas",
    create_school: "Criar unidade orgânica",


    //Schools groups
    school_groups: "Agrupamentos",
    list_school_groups: "Listar agrupamentos",
    create_school_groups: "Criar agrupamentos",


    //beneficiary
    beneficiary: "Beneficiários",
    list_students: "Listar alunos",
    list_teachers: "Listar docentes",
    refuse_equipment: "Registar recusas de equipamentos",

    old_beneficiary: "Antigos beneficiários (com equipamento)",
    list_old_students: "Listar antigos alunos",
    list_old_teachers: "Listar antigos docentes",

    //No compliances
    no_compliance: "Não conformidades",
    no_compliance_list: "Lista de não conformidades",
    sla: "SLAs",
    manage_supplier_sla: "Gerir SLAs de fornecedores",

    //Audit
    configs: "Sistema",
    configuration: "Configuração",
    read_audit: "Ver logs",

    //TIckets
    tickets: "Suporte / Tickets",
    manage_categories: "Gerir categorias",

    //Equipment
    equipments: "Equipamentos",
    equipments_status: "Estados na plataforma",
    equipments_profiles: "Perfis de equipamentos",
    list_equipments: "Listar equipamentos",
    equipment_typology: "Tipologias de equipamentos",

    register_equipments: "Importação de equipamentos",
    validate_registration: "Validação de importações",
    load_equipments: "Carregar ficheiro excel",
    rule_template: "Modelos de regras / operações",


    //Procedures
    procedures: "Procedimentos",
    list_procedures: "Listar fases",
    list_contracts: "Listar contratos",

    //Contracts
    manage_contract_models: "Gerir modelos de contrato/acordo",

    //control
    control: "Controlo",
    validate_rules: "Validar operações",

    reports_and_operations: "Relatórios e operações",
    reports: "Relatórios",
    list_report_templates: "Listagem de relatórios",
    list_operation_templates: "Listagem de operações",
    create_report_templates: "Criar relatório",
    create_operation_templates: "Criar Operação",
    schedule_report: "Agendamento de relatórios",
    operations: "Operações",
    schedule_operation: "Agendamento de operações",

    bulk_operations: "Operações gerais",

    //Generic Error
    error: "Erro",
    error_msg: "Ocorreu um erro a carregar o componente. Por favor atualize a versão do seu browser para uma mais recente",

    go_back: "Voltar",

    bottom_message: "2024 - " + appConfig.name + " - " + appConfig.nameComplete,


    //Audit_process
    audit_process: "Auditoria",
    list_processes: "Ver processos",
    create_process: "Criar processo",

    //Assist
    assist_control_confirm_message: "Os serviços da SGEC desejam inciar uma sessão de suporte remota. Deseja continuar? ",
    assist_control_accept: "Aceitar",
    assist_control_cancel: "Cancelar",

    assist_call_confirm_message: "Os serviços da SGEC desejam inciar uma chamada de suporte. Deseja continuar? ",
    assist_call_accept: "Aceitar",
    assist_call_cancel: "Cancelar",



};

export default pt;
