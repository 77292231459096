import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";
import React, {useMemo} from "react";
import BeneficiaryType from "types/BeneficiaryType";

const BeneficiaryDropdown = props => {

    const {type, schoolId, schoolYears, ranks, schoolTypologies} = props;

    const filterExtraProps = useMemo(() => ({
        type: type,
        schoolIds: schoolId,
        schoolYears: schoolYears,
        ranks: ranks,
        schoolTypologies: schoolTypologies
    }), [type, schoolId, schoolYears, ranks, schoolTypologies]);

    const remotePath = useMemo(
        () => {
            switch (type) {
                case BeneficiaryType.STUDENT:
                    return "/students/search"
                case BeneficiaryType.TEACHER:
                    return "/teachers/search"
                default:
                    return "/beneficiaries/search"
            }
        },
        [type]
    );

    return <SimpleListDropdown
        {...props}
        key={type}
        labelHandler={beneficiaryLabelHandler}
        filterExtraProps={filterExtraProps}
        remotePath={remotePath}
    />


}

const beneficiaryLabelHandler = (o) => {

    if (!o)
        return "";

    const vatNumber = o['vatNumber'];
    const name = o["name"];

    if (!name) {
        return o['vatNumber'];
    }

    return vatNumber + " - " + name;


}

export {beneficiaryLabelHandler};
export default BeneficiaryDropdown
