import React, {useMemo, useRef} from "react";
import classnames from "classnames";
import lazyWithRetry from "system/System/lazyWithRetry";
import {themes, useThemeState} from "system/Themes/ThemeContextWrapper";
import "layout/modules/Forms/HTMLEditor/assets/htmlEditor.scss";

const JoditEditor = lazyWithRetry(() => import("jodit-react"));


const HTMLEditor = (props) => {

    const {defaultValue, value, onChange, disabled, height = 900} = props;
    const {theme} = useThemeState();

    const isDarkMode = theme === themes.dark;
    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: disabled,
            theme: isDarkMode ? 'dark' : undefined,
            imageProcessor: {
                replaceDataURIToBlobIdInView: true // This is the default value, but for examples we set it
            },
            cleanHTML: {
                fillEmptyParagraph: false
            },
            uploader: {"insertImageAsBase64URI": true},
            activeButtonsInReadOnly: ['source', 'about'],
            height: height,
            language: 'pt_br',
            placeholder: "",
            buttons: [
                'undo',
                'redo',
                '|',
                'source',
                '|',
                'bold',
                'italic',
                'underline',
                'superscript',
                'subscript',
                '|',
                'ul',
                'ol',
                '|',
                'outdent', 'indent',  '|',
                'font',
                'fontsize',
                'brush',
                'paragraph',
                '|',
                'image',
                'table',
                'link',
                '|',
                'left',
                'center',
                'right',
                'justify',
                'lineHeight',
                '|',
                'undo',
                'redo',
                '|',
                'hr',
                'eraser',
                'fullsize',
            ],
            toolbarAdaptive: false,
        }),
        [disabled, isDarkMode, height]
    );

    return <div className={classnames("html-editor-area", {"read-only": disabled})}>


        <React.Suspense fallback={<div></div>}>
            <JoditEditor
                ref={editor}
                value={defaultValue ?? value}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={onChange} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {
                }}
            />
        </React.Suspense>

    </div>

}

export default HTMLEditor;
