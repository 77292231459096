import React, {useEffect, useState} from 'react';
import {useApiState} from "system/API/APIStateContext";
import NotFound from "layout/modules/ErrorPages/NotFound";
import Unauthorized from "layout/modules/ErrorPages/Unauthorized";
import ErrorPage from "layout/modules/ErrorPages/ErrorPage";
import useCurrentURL from "system/API/Error/utils/useCurrentURL";
import tracker from "modules/OpenReplay/tracker";
import InternetError from "layout/modules/ErrorPages/InternetError";
import {ReactComponent as ErrorImage} from "layout/modules/ErrorPages/assets/images/error.svg";

const ApiErrorInterceptor = (props) => {

    const {apiState: {error}} = useApiState();
    const [internalError, setInternalError] = useState();

    const currentURL = useCurrentURL();


    useEffect(
        () => {
            setInternalError(error);
            if (error && (error.status === 403 || !error.data?.hasErrors)) {
                tracker.event('ErrorPage', error);
            }
        },
        [error, setInternalError]
    );

    useEffect(
        () => {
            if (internalError && currentURL !== internalError?.errorURL) {
                setInternalError(undefined);
            }

        },
        [currentURL, internalError, setInternalError]
    );



    if (internalError) {
        switch (internalError.status) {
            case 401:
                return props.children;
            case 404:
                return <NotFound />
            case 403:
                return <Unauthorized/>;
            case -1:
                return <InternetError/>;
            default:
                return !internalError.data?.hasErrors
                    ? <ErrorPage {...props} error={internalError.data?.message}>
                        <ErrorImage/>
                    </ErrorPage>
                    : props.children;
        }
    }
    return props.children;
}

export default ApiErrorInterceptor;
