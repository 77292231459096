import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/equipmentStatus/locales/locales";
import EquipmentStatusDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentStatusDropdown";


const FormConfiguration = props => {

    const {setConfiguration, configuration} = props;

    const {t} = useTranslations("EquipmentStatusRuleAction", locale);

    return <Row>

        <Col sm={12} lg={12} xl={12}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('new_status')}</Form.Label>
                <EquipmentStatusDropdown
                    value={getOrNull(configuration?.props?.statusId)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                statusId: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>


    </Row>

}

export default FormConfiguration;
