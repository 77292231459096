import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import EquipmentAlertsComponent from "app/equipments/equipments/components/show/EquipmentAlertsComponent";
import EquipmentListComponent
    from "app/ruleTemplates/actions/equipmentSelection/components/execution/EquipmentListComponent";
import {Button} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import {useTranslation} from "react-i18next";
import isFunction from "system/Utils/isFunction";
import equipmentResolver from "app/equipments/equipments/utils/equipmentResolver";

const EquipmentSelectionComponent = props => {


    const {config, position, onEquipmentSelect, setReplacedButtonComponent} = props;
    const {t} = useTranslation("EquipmentSelectionRuleAction");

    const randId = useMemo(
        () => generateRandomId(5 + position),
        [position]
    );

    const [selectedEquipment, setSelectedEquipment] = useState(null);

    const currentSelection = useMemo(
        () => config?.requiredEquipments[position],
        // eslint-disable-next-line
        [position]
    );

    useEffect(
        () => {
            setReplacedButtonComponent(
                <>
                    <Button
                        variant={"primary"}
                        onClick={() => onEquipmentSelect(null, position)}
                    >
                        {t('cancel')}
                    </Button>

                    <Button
                        variant={"primary"}
                        disabled={!selectedEquipment && currentSelection?.mandatory}
                        onClick={() => onEquipmentSelect(selectedEquipment, position)}
                    >
                        {t('confirm')}
                    </Button>
                </>
            );

            return () => setReplacedButtonComponent(null);
        },
        [selectedEquipment, position, onEquipmentSelect, t, currentSelection, setReplacedButtonComponent]
    );


    const handleEquipmentSelection = useCallback((fnc) => {
            const selectedItems = isFunction(fnc) ? fnc([]) : fnc;
            const selectedEquipment = selectedItems.length > 0
                ? equipmentResolver(selectedItems[0])
                : null;

            setSelectedEquipment(selectedEquipment);
        },
        [setSelectedEquipment]
    );

    return <div className={"equipment-selection-mode"}>
        <AdvanceInfoMessage
            icon={faInfo}
            type={"success"}
            iconType={"success"}
            title={t("select_equipment_title")}
            message={t(currentSelection?.mandatory ? "select_mandatory_equipment_msg" : "select_optional_equipment_msg", currentSelection)}
        />

        {selectedEquipment &&
        <EquipmentAlertsComponent equipment={selectedEquipment} selectionMode={true}/>
        }

        <EquipmentListComponent
            {...props}
            id={randId}
            currentSelection={currentSelection}
            selectedEquipment={selectedEquipment}
            handleEquipmentSelection={handleEquipmentSelection}
        />
    </div>

}

export default EquipmentSelectionComponent;
