import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback, useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import AttachmentComponent from "app/support/components/view/messages/AttachmentComponent";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContentToByteString} from "system/Files/ReadFileContentToByteString";
import Loading from "layout/modules/Loading/Loading";

import "app/support/assets/css/upload.scss"
import "app/support/assets/css/attachments.scss";

const NewMessageComponent = React.forwardRef((props, ref) => {

    const {t} = useTranslation("support");
    const {errors = {}, id, request = {}, setRequest, rows = 3, actionMode} = props;
    const [uploadingCounter, setUploadingCounter] = useState(0);


    const handleFileUpload = useCallback((nf) => {
        nf.forEach((file) => {
            setUploadingCounter((uc) => uc + 1);
            readFileContentToByteString(file, (content) => {
                setRequest(r => ({
                    ...r,
                    attachments: [
                        ...r.attachments,
                        {
                            title: file.name,
                            filename: file.name,
                            type: file.type,
                            contents: btoa(content)
                        }
                    ]
                }));
                setUploadingCounter((uc) => uc - 1);
            });
        })
    }, [setUploadingCounter, setRequest]);

    return <Row>
        <Col md={12}>
            <FormGroup error={errors[id]} mandatory={true}>
                <Form.Label>{t(id)}</Form.Label>
                <Form.Control
                    type="textarea"
                    as="textarea"
                    rows={rows}
                    value={getOrEmpty(request[id])}
                    onChange={(e) => setRequest(r => ({
                        ...r,
                        [id]: e.target.value,
                    }))}
                />

                <Form.Text className="sub-error">
                    {t(errors[id])}
                </Form.Text>
            </FormGroup>

        </Col>

        {!actionMode &&
        <>
            <Col md={12} className={"ticket-upload"}>
                <DragDropFile
                    ref={ref}
                    maxFiles={10}
                    text={t('upload_ticket_files')}
                    onFileUploaded={handleFileUpload}
                />
            </Col>

            <Col md={12} className={"ticket-file-list"}>
                <Loading visible={uploadingCounter > 0}/>
                {request.attachments.map((file, id) =>
                    <AttachmentComponent
                        key={id}
                        iconClassName={"red"}
                        attachment={{
                            ...file,
                            icon: faTimes,
                            onClick: () => {
                                setRequest(r => {
                                    const attachments = [...r.attachments];
                                    attachments.splice(id, 1);
                                    return {
                                        ...r,
                                        attachments: attachments
                                    }
                                })
                            }
                        }}/>
                )}
            </Col>
        </>
        }

    </Row>
});

export default NewMessageComponent;
