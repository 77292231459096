import React from "react";
import 'app/login/assets/css/base-login-layout.scss'
import 'app/login/assets/css/base-login-responsive.scss'
import logo from 'app/login/assets/images/logo.png';

const BasePageLayout = props => {

    return <div className="login-screen">

        <div className={"login-area"}>
            <div className={"login-logo"}>
                <img src={logo} alt={"Escola digital"}/>
                <div className={"app-name"}>
                    Escola<br/>Digital
                </div>
            </div>
            <div className={"login-dynamic-area"}>
                {props.children}
            </div>
        </div>
    </div>
}

export default BasePageLayout;
