const supportedFilesTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png"
];

const canPreviewFile = (file) => supportedFilesTypes.includes(file.type);


export default canPreviewFile;
