import * as PropTypes from "prop-types"
import {useEffect} from "react"
import {useHeaderProps} from "layout/modules/BaseLayout/ContentArea/ContentArea";

const Title = (props) => {

    const { setTitle: setHeaderParams} = useHeaderProps();
    const {title, breadcrumbs, children, disable = false } = props;

    useEffect(() => {
        //Set Title
        if (!disable) {
            setHeaderParams({
                title: title,
                linkPath: breadcrumbs,
            })
        }
    }, [breadcrumbs, children, title, setHeaderParams, disable]);

    return children?children:null;
}


export default Title

Title.propTypes = {
    title: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired
};
