import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import "app/home/assets/css/chart-filter.scss";
import ContractChartType from "app/home/utils/ContractChartType";
import {UserType} from "types/UserType";
import {useApiState} from "system/API/APIStateContext";
import ContractChartAdvancedFilter from "app/home/components/charts/contracts/ContractChartAdvancedFilter";

const ContractChartFilterComponent = props => {

    const {setFilters, filters} = props;
    const {t} = useTranslation('dashboard');

    const {authentication} = useApiState();
    const canFilterEntity = authentication.type === UserType.NORMAL;


    return <div className={"chart-data-filter"}>

        <Row>

            <Col md={6} xxl={12}>
                <div className={"label"}>
                    {t('model_type')}
                </div>
                <div className={"value"}>
                    <EnumDropdown
                        t={t}
                        value={filters.modelType}
                        isClearable={false}
                        isSearchable={false}
                        options={ContractChartType}
                        onChange={useCallback((value) => setFilters(filter => ({
                            ...filter,
                            modelType: value
                        })), [setFilters])}

                    />

                </div>

            </Col>


            {canFilterEntity &&
                <ContractChartAdvancedFilter {...props}/>
            }

        </Row>

    </div>
}

export default ContractChartFilterComponent;

