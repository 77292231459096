const TicketStatus = {
    OPEN: "OPEN",
    IN_PROGRESS: "IN_PROGRESS",
    PENDING: "PENDING",
    REPLIED: "REPLIED",
    TRANSFERRED: "TRANSFERRED",
    RESOLVED : "RESOLVED",
    REOPEN: "REOPEN",

};

export default TicketStatus;
