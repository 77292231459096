import React, {useEffect} from "react";
import "app/ruleTemplates/actions/equipmentSelection/assets/selection.scss";
import SelectedEquipmentsComponent
    from "app/ruleTemplates/actions/equipmentSelection/components/execution/SelectedEquipmentsComponent";
import EquipmentSelectionComponent
    from "app/ruleTemplates/actions/equipmentSelection/components/execution/EquipmentSelectionComponent";
import isDefined from "system/Utils/isDefined";
import useAdvanceEquipmentSelection
    from "app/ruleTemplates/actions/equipmentSelection/utils/useAdvanceEquipmentSelection";
import Loading from "layout/modules/Loading/Loading";
import HelpHeaderExecuteComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderExecuteComponent";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";

const ExecutionComponent = props => {

    const {bulkMode, setData, config, data, errors: remoteErrors} = props;
    const {t} = useTranslations("EquipmentSelectionRuleAction", locale);

    const {
        selectedEquipments,
        handleEquipmentSelection,
        setCurrentEquipmentSelectionPosition,
        currentEquipmentSelectionPosition,
        loading,
        errors,
    } = useAdvanceEquipmentSelection({
        requiredEquipments: config.requiredEquipments,
        autoSelectRelated: config.autoSelectRelated,
        selectedEquipments: data.selectedEquipments,
        errors: remoteErrors,
    })

    const isSelectingEquipment = isDefined(currentEquipmentSelectionPosition);

    useEffect(
        () => {

            if (!bulkMode) {
                setData(data => ({
                    ...data,
                    equipmentIds: selectedEquipments.map(e => e?.id ?? null),
                    selectedEquipments: selectedEquipments
                }));
            }
        },
        // eslint-disable-next-line
        [selectedEquipments, bulkMode]
    );


    return <div className={"rule-equipment-selection"}>

        <Loading visible={loading}/>

        <HelpHeaderExecuteComponent {...props}/>
        <GenericFormError t={t} errors={remoteErrors}/>

        {!bulkMode &&
            <>
                {isSelectingEquipment
                    ? <EquipmentSelectionComponent
                        {...props}
                        position={currentEquipmentSelectionPosition}
                        onEquipmentSelect={handleEquipmentSelection}

                    />
                    : <SelectedEquipmentsComponent
                        remoteErrors={remoteErrors}
                        errors={errors}
                        requiredEquipments={config?.requiredEquipments}
                        selectedEquipments={selectedEquipments}
                        onEquipmentSelect={handleEquipmentSelection}
                        selectEquipment={setCurrentEquipmentSelectionPosition}
                    />
                }
            </>
        }

    </div>

}

export default ExecutionComponent;
