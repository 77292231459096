export const en = {

    email: "Endereço de email",
    email_short: "Email",

    email_tip: "O seu endereço de email",
    password: "Password",
    password_tip: "A sua password",
    new_password_tip: "Escreva a nova password",
    login: "Entrar",
    help: "Obter ajuda",

    error_authenticating: "Erro na autenticação",

    //Password Recovery and Activation
    password_activate_title: "Ativar conta",
    password_recovery_title: "Recuperar password",

    password_confirm: "Confirmação da password",
    password_confirm_tip: "Repita a password inserida acima",

    set_new_password: "Definir nova password",

    invalid_password: "A password deverá ter pelo menos:",
    invalid_confirm_password: "A password de confirmação deverá ser igual à nova password",

    //Password Recovery tips
    uppercase: "1 letra maiúscula",
    lowercase: "1 letra minúscula",
    digits: "1 número",
    symbols: "1 caracter não alfanumérico",
    min: "6 caracteres",
    max: "25 caracteres",


    //Errors recovery
    session_expired: "Sessão expirada",
    session_expired_msg: "Por razões de segurança a sessão foi expirada. Por favor volte a fazer login",
    password_reset_success: "A password foi alterada com sucesso. Por favor faça login com a nova password.",
    password_forgot_success: "Foi enviado um email com as instruções para alterar a sua password.",
    error_recovery_token: "O email recebido já não é válido. Por favor utilize \"Recuperar password\" e tente novamente",

    //Email Recovery
    password_recovery_send: "Recuperar password",
    email_recovery_description: "Indique o endereço de e-mail associado à sua conta. Irá receber de seguida no seu e-mail as indicações para que possa definir uma nova password.",
    go_to_login: "< Voltar ao login",


    //SERVER ERRORS
    LOGIN_INVALID_CREDENTIALS: "Credenciais inválidas! Por favor verifique o endereço de email e a password e tente novamente!",

    LOGIN_CREDENTIALS_EXPIRED: "A validade da sua password expirou! Por favor use o \"Recuperar password\" para definir uma nova password.",
    LOGIN_MAX_RETRIES_EXCEEDED: "Conta bloqueada! Por favor use o \"Recuperar password\" para definir uma nova password e desbloquear a sua conta.",

    USER_NOT_FOUND: "O email indicado não está associado a nenhuma conta válida.",
    USER_INACTIVE: "A conta está inactiva. Para mais informações contacte o administrador.",


    //Legal Warning
    accept_legal_warning: "Aceitar condições",

    //help
    close: "Fechar",

    //2FA
    title_2fa: "Necessária confirmação de autenticação",
    description_2fa: "Para sua segurança é necessário validar a sua conta. " +
        "Por favor consulte a sua caixa de email e indique na caixa abaixo o código de 6 dígitos recebido.<br/><br/>Alertamos que o código enviado <strong>expira em {{minutes}} minuto(s) e {{seconds}} segundo(s).</strong>",
    submit_2fa: "Autenticar",

    LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED: "Erro ao autenticar",
    LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED_MSG: "Neste momento o código já nao é válido. Por favor volte a realizar o login para obter um novo código.",

    LOGIN_TWO_FA_INVALID_CODE: "Código inválido",
    LOGIN_TWO_FA_INVALID_CODE_MSG: "Por favor verifique o código introduzido",

    ERROR_2FA_LINK: "Erro a autenticar",
    LOGIN_INVALID_TWO_FA_CREDENTIALS_MSG: "O link utilizado já não se encontra válido. Por favor volte a realizar novamente o login.",
    LOGIN_EXPIRED_TWO_FA_CREDENTIALS_MSG: "O link utilizado já não se encontra válido.",
};

export default en;
