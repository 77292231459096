import React, {useCallback, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/validation/locales/locales";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import ValidationEntityType, {generateDestinationEntityList} from "app/ruleTemplates/actions/validation/utils/ValidationEntityType";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import EquipmentStatusDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentStatusDropdown";
import HelpHeaderConfigComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderConfigComponent";


const FormConfiguration = props => {

    const {rule, position, setConfiguration, configuration} = props;

    const {t} = useTranslations("ValidationRuleAction", locale);
    const options = useMemo( () => generateDestinationEntityList(rule, position), [rule, position]);

    return <>

        <Row className={"rule-action-validate-config-form"}>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('validation_entity_type')}</Form.Label>
                <EnumDropdown
                    t={t}
                    value={getOrNull(configuration?.props?.validationEntityType)}
                    options={options}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                validationEntityType: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

        {configuration?.props?.validationEntityType === ValidationEntityType.DEFINED_ENTITY &&
        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('validation_entity')}</Form.Label>
                <EntityDropdown
                    value={getOrNull(configuration?.props?.entityId)}
                    onChange={(value) => setConfiguration(config => ({
                        ...config,
                        props: {
                            ...config.props,
                            entityId: value,
                        }

                    }))}
                />
            </FormGroup>
        </Col>
        }

        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={false}>
                <Form.Label>{t('rejected_status')}</Form.Label>
                <EquipmentStatusDropdown
                    value={getOrNull(configuration?.props?.rejectedStatus)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                rejectedStatus: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>
    </Row>

    <HelpHeaderConfigComponent {...props}/>
    </>

}

export default FormConfiguration;
