import React, {useCallback, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/supportTicket/locales/locales";
import localeValidation from "app/ruleTemplates/actions/validation/locales/locales";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import ValidationEntityType, {generateDestinationEntityList} from "app/ruleTemplates/actions/validation/utils/ValidationEntityType";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import FormRequestSupportComponent from "app/support/components/form/FormRequestSupportComponent";

const FormConfiguration = props => {

    const {rule, position, setConfiguration, configuration} = props;

    const {t} = useTranslations("SupportTicketAction", locale);
    const {t: tValidation} = useTranslations("ValidationRuleAction", localeValidation);

    const options = useMemo(() => generateDestinationEntityList(rule, position), [rule, position]);

    return <Row className={"rule-action-support-config-form"}>

        <Col sm={12} lg={true} xl={true}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('destination_entity_type')}</Form.Label>
                <EnumDropdown
                    t={tValidation}
                    value={getOrNull(configuration?.props?.destinationEntityType)}
                    options={options}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                destinationEntityType: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

        {configuration?.props?.destinationEntityType === ValidationEntityType.DEFINED_ENTITY &&
        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('destination_entity')}</Form.Label>
                <EntityDropdown
                    value={getOrNull(configuration?.props?.entityId)}
                    onChange={(value) => setConfiguration(config => ({
                        ...config,
                        props: {
                            ...config.props,
                            entityId: value,
                        }

                    }))}
                />
            </FormGroup>
        </Col>
        }


        <FormRequestSupportComponent
            actionMode={true}
            request={configuration?.props ?? {}}
            setRequest={useCallback(
                (fnc) => setConfiguration(config => ({
                    ...config,
                    props: {
                        ...fnc(config.props)
                    }
                })),
        [setConfiguration])}
        />


    </Row>

}

export default FormConfiguration;
