const SubModules = {
    "SCHOOLS": "SCHOOLS",
    "BENEFICIARIES": "BENEFICIARIES",
    "STUDENTS": "STUDENTS",
    "TEACHERS": "TEACHERS",
    "EQUIPMENTS": "EQUIPMENTS",
    "SUPPLIERS": "SUPPLIERS",
}

export default SubModules;
