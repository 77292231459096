import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faArrowRotateBack} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/recoveryEquipmentStatus/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/recoveryEquipmentStatus/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/recoveryEquipmentStatus/components/FormEditor";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";


class RecoverEquipmentStatusRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faArrowRotateBack, t('name'), t('description'));

    static getName = () => "RecoverEquipmentStatusRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static isAllowed = (config, position) => ruleHasEquipments(config, position);

}

export default RecoverEquipmentStatusRuleAction;
