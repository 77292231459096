import ContractModelBodyType from "app/procedures/contractModel/utils/ContractModelBodyType";
import getAsArray from "system/Arrays/getArray";

const GenerateContractBody = modelType => {

    const model = {
        bodies: []
    };

    Object.keys(ContractModelBodyType.from(modelType)).forEach((type) => {
        model.bodies.push({
            type: type,
            body: "",
        });
    });

    return model;
}


const ValidateContractBody = model => {

    const bodies = getAsArray(model.bodies);

    Object.keys(ContractModelBodyType.from(model.type)).forEach((type) => {
        const body = GetBodyFromType(model, type);
        if (!body) {
            bodies.push({
                type: type,
                body: "",
            });
        }
    });

    return {
        bodies: bodies
    }
}

const SetBodyFromType = (model, type, body) => {

    const bodies = [...model.bodies];
    const index = bodies.findIndex(item => item.type === type);

    if (index >= 0) {
        bodies[index] = {
            ...body
        }
    }
    return bodies;
}


const GetBodyFromType = (model, type) => {
    return getAsArray(model?.bodies)
        .find(body => body.type === type);

}


export default GenerateContractBody;
export {ValidateContractBody, GetBodyFromType, SetBodyFromType}
