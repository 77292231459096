import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faUsersViewfinder} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/useBeneficiaryEquipments/locales/locales";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasBeneficiaries from "app/ruleTemplates/utils/config/ruleHasBeneficiaries";


class UseBeneficiaryEquipmentsRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.EQUIPMENT, RuleType.SCHOOL];
    static disableConfiguration = true;

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faUsersViewfinder, t('name'), t('description'));

    static getName = () => "UseBeneficiaryEquipmentsRuleAction";
    static getDefaultProps = () => ({});
    static renderEditor = () => null;
    static isAllowed = (config, position) => !ruleHasEquipments(config, position) && ruleHasBeneficiaries(config, position);

}

export default UseBeneficiaryEquipmentsRuleAction;
