import {useTranslation} from "react-i18next";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";

const FormWarranty = props => {

    const {equipment, setEquipment, errors, readOnly} = props;
    const {t} = useTranslation('equipments');

    return <>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['warrantyStartTimestamp']} mandatory={false}>
                <Form.Label>{t('warranty_start_date')}</Form.Label>
                <DatePicker
                    placeholder={t('date_ph')}
                    timezone={"UTC"}
                    disabled={readOnly}
                    value={getOrEmpty(equipment?.warrantyStartTimestamp)}
                    onChange={useCallback(
                        date => setEquipment(equipment => ({
                            ...equipment,
                            warrantyStartTimestamp: date,
                        })), [setEquipment]
                    )}
                />

                <Form.Text className="sub-error">
                    {t(errors['warrantyStartTimestamp'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['warrantyEndTimestamp']} mandatory={false}>
                <Form.Label>{t('warranty_end_date')}</Form.Label>
                <DatePicker
                    placeholder={t('date_ph')}
                    timezone={"UTC"}
                    disabled={readOnly}
                    value={getOrEmpty(equipment?.warrantyEndTimestamp)}
                    onChange={useCallback(
                        date => setEquipment(equipment => ({
                            ...equipment,
                            warrantyEndTimestamp: date,
                        })), [setEquipment]
                    )}
                />

                <Form.Text className="sub-error">
                    {t(errors['warrantyEndTimestamp'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </>
}

export default FormWarranty;
