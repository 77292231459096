export const pt = {

    name: "Abertura de ticket de suporte",
    description: "Abre um ticket de suporte com um conteudo dinâmico para uma entidade a definir.",

    form_description: "Abertura de ticket de suporte com o titulo <strong>{{title}}</strong> para <strong>{{destinationEntityType}}</strong>.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é a entidade que vai receber o pedido bem como a categoria, titulo e corpo do pedido de suporte.",

    //Config
    destination_entity_type: "Entidade de destino",
    destination_entity: "Entidade manual",
    title: "Titulo / Assunto",
    model: "Mensagem / Modelo",

    placeholder: "Escolha uma opção",







};

export default pt;
