export const pt = {

    name: "Input texto",
    set_label: "Defina o nome do campo",

    placeholder: "Ajuda / Descrição de preenchimento",
    placeholder_ph: "Este texto é um placeholder",


    validation_expression: "Regex de validação",
    validation_ph: "Indique uma regex para validar o campo",

};

export default pt;
