import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {shouldMove} from "layout/modules/FormBuilder/utils/editor/dragUtils";
import classnames from "classnames";
import MultiOptionEntryComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionEntryComponent";

const MultiOptionDragAndDropComponent = React.forwardRef((props, ref) => {

    const {id, position, moveEntry} = props;
    const refDrag = useRef(null);

    const [{handlerId}, drop] = useDrop({
        accept: 'CARD',
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover: (item, monitor) => {
            if (!refDrag.current || item?.id === id) {
                return;
            }

            if (shouldMove(refDrag, position, item, monitor)) {
                moveEntry(item?.position, position)
            }
        },
    });


    const [{isDragging}, drag] = useDrag({
        type: 'CARD',
        item: () => ({
            position,
            id
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(refDrag));

    return <div className={classnames("form-multi-option-item", {'in-drag': isDragging})}
                ref={refDrag}
                data-handler-id={handlerId}
    >
        <MultiOptionEntryComponent {...props} ref={ref}/>
    </div>


});

export default MultiOptionDragAndDropComponent;
