import React, {useMemo} from "react";
import locale from "app/beneficiaries/teachers/locales/locales"
import ListComponent from "app/beneficiaries/teachers/components/list/ListComponent"
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import useTranslations from "system/Translations/UseTranslations";
import {useParams} from "react-router-dom";

const List = (props) => {

    const {id, selectionMode, schoolIds, showOld} = props;
    const {t} = useTranslations('teacher', locale);
    const {universalSearch} = useParams();

    const {
        bondStatusIds,
        equipmentStatus,
        recruitmentGroupIds,
        schoolTypologies,
        teachingComponent,
        onlyCompatibleWithEquipmentIds
    } = props;

    return <GenericListContainer
        {...props}
        hideTitle={!!selectionMode}
        module="teacher"
        title={showOld ? t('title_old') : t('title')}
        id={showOld ? "old-students" : "teacher" + (id ?? schoolIds)}
        locale={locale}
        searchPath={useMemo(
            () => showOld ? "/teachers/search-old" : "/teachers/search",
            [showOld]
        )}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 10,
            schoolIds: schoolIds,
            bondStatusIds: bondStatusIds,
            equipmentStatus: equipmentStatus,
            recruitmentGroupIds: recruitmentGroupIds,
            schoolTypologies: schoolTypologies,
            onlyCompatibleWithEquipmentIds: onlyCompatibleWithEquipmentIds,
            teachingComponent: teachingComponent,
            universalSearch: universalSearch,

        }), [schoolIds, bondStatusIds, equipmentStatus, recruitmentGroupIds, schoolTypologies, teachingComponent,
            onlyCompatibleWithEquipmentIds, universalSearch])}>
        <ListComponent {...props}/>
    </GenericListContainer>

}

export default List;
