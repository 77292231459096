import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";

const EditorComponent = props => {

    const {label, placeholder, mandatory, type} = props;
    const {t} = useTranslations('FormInputModule', locale);

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <FormControl
            type={type ?? 'text'}
            autoComplete="chrome-off"
            placeholder={placeholder}
            onChange={() => {}}
            value={""}
        />
    </FormGroup>

}

export default EditorComponent;
