import React, {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {useLocation} from "react-router";
import useWindowSize from "system/Window/WindowSize";
import {useMenuState} from "layout/modules/BaseLayout/Menu/MenuStateContext";
import {maxWidthForResponsive} from "layout/modules/BaseLayout/Menu/utils/Utils";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const SingleMenuItem = (props) => {

    const {
        path, label, permissions, children, disabled = false, onClick,
        icon, className, isInMainMenu = true, orUserTypes, counter, any, ...otherProps
    } = props;

    const location = useLocation();
    const {width} = useWindowSize();
    const [, setMenuState] = useMenuState();

    const handleClick = useCallback(
        (e) => {
            if (onClick) {
                e.stopPropagation();
                setMenuState((menuState) => ({
                    ...menuState,
                    visible: false
                }));
                onClick();
            }

            if (width > maxWidthForResponsive) {
                setMenuState((menuState) => ({
                    ...menuState,
                    active: undefined
                }));
            }
        },
        [onClick, width, setMenuState]
    );

    if (disabled)
        return <></>;

    return <LinkWithPermission permissions={permissions} orUserTypes={orUserTypes} any={any}>
        <SingleMenuLink path={path} pathname={location?.pathname} isInMainMenu={isInMainMenu}
                        className={className} onClick={handleClick} {...otherProps}>
            {icon && <FontAwesomeIcon icon={icon}/>}
            <div className={"label"}>
                {children ? children : label}
            </div>

            {(counter && counter > 0) &&
            <div className={"counter"}>
                {counter}
            </div>
            }
        </SingleMenuLink>
    </LinkWithPermission>
}

const LinkWithPermission = props => {

    const {permissions, orUserTypes, children, any} = props;

    if (permissions || orUserTypes) {
        return <UserHasPermission permission={permissions} orUserTypes={orUserTypes} any={any}>
            {children}
        </UserHasPermission>
    }


    return children;

}

const SingleMenuLink = props => {

    const {isInMainMenu, path, pathname, className, children, onClick, ...otherProps} = props;

    const currentPage = (path === pathname);
    const classes = useMemo(
        () => classnames({
            "main-menu-item": isInMainMenu,
            "sub-menu-item": !isInMainMenu,
            "selected": currentPage,
            [className]: className
        }),
        [className, currentPage, isInMainMenu]
    );

    if (path)
        return <Link {...otherProps} to={path?.pathname ? path?.pathname : path} state={path?.state} className={classes} tabIndex={-1}
                     role="menuitem" aria-current={currentPage} >
            {children}
        </Link>

    else
        return <div {...otherProps} className={classes} onClick={onClick} tabIndex={-1}>
            {children}
        </div>

}

export default SingleMenuItem;
