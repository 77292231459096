import {getActionFromConfig} from "app/ruleTemplates/utils/config/getActionFromConfig";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";

const hasAnyVisibleStepBefore = (step, operation) => {

    if (step === 0)
        return false;

    let ret = false;

    for (let i = (step-1); i >= 0; i--) {
        if (getActionFromConfig(operation.actions[i])?.type === RuleActionType.SYNC) {
            ret = i;
            break;
        }
    }
    return ret;
}

const hasAnyVisibleStepAfter = (step, operation) => {

    let ret = false;

    if (operation.actions.length > step + 1) {

        for (let i = (step +1); i < operation.actions.length; i++) {
            const type = getActionFromConfig(operation.actions[i])?.type;
            if (type === RuleActionType.SYNC || type === RuleActionType.HYBRID ) {
                ret = true;
                break;
            }
        }
    }

    return ret

}

export {hasAnyVisibleStepBefore, hasAnyVisibleStepAfter};
