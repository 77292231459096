import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/calendar/locales/locales";

const EditorComponent = props => {

    const {id, setData, data, configuration, readOnly, errorMessage} = props;
    const {t} = useTranslations('CalendarModule', locale);

    return <Col lg={6} sm={6}>
        <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
            <Form.Label>{configuration.label}</Form.Label>
            <DatePicker
                placeholder={t("date_ph")}
                disabled={readOnly}
                onlyPastDates={configuration.onlyPastDates}
                onlyFutureDates={configuration.onlyFutureDates}
                fromDate={configuration.fromDate}
                toDate={configuration.toDate}
                timezone={"UTC"}
                value={getOrEmpty(data[id])}
                onChange={(value) => setData(data => ({
                    ...data,
                    [id]: value,
                }))}
            />
            <Form.Text className="sub-error">
                {errorMessage}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EditorComponent;
