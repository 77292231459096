import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/infoPage/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/infoPage/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/infoPage/components/FormEditor";
import ExecutionComponent from "app/ruleTemplates/actions/infoPage/components/ExecutionComponent";


class InfoPageRuleAction extends RuleEngineAction {

    static type = RuleActionType.SYNC
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faInfoCircle, t('name'), t('description'));

    static getName = () => "InfoPageRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static renderExecution = (props) => <ExecutionComponent {...props} />

}

export default InfoPageRuleAction;
