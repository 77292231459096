import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/help/locales/locales";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {getOrDefault} from "system/Objects/ObjectParameters";

const EditorComponent = props => {

    const {title, text} = props;
    const {t} = useTranslations('HelpModule', locale);

    return <div className={"dynamic-form-help"}>
        <AdvanceInfoMessage
            icon={faInfo}
            type={"primary"}
            iconType={"primary"}
            title={getOrDefault(title, t("title_default"))}
            message={getOrDefault(text, t('text_default'))}

        />
    </div>


}

export default EditorComponent;
