import React, {useMemo} from 'react'
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const RoleDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "roleId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        userEntityId,
        excludeUnavailableForUser = false,
        userTypes,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder,t('role_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage,t('role_no'))}
        loadingMessage={useLabelCallback(loadingMessage,t('role_loading'))}
        remotePath={userEntityId ? "/roles/search-for-user" : "/roles/search"}
        remoteClassPath={"/roles/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
            userEntityId: userEntityId,
            excludeUnavailableForUser: excludeUnavailableForUser,
            userTypes: userTypes
        }), [maxItems, userEntityId, excludeUnavailableForUser, userTypes])}
    />

}

export default RoleDropdown;
