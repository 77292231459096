import React from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import TeachingComponent from "types/TeachingComponent";

const TeachingComponentDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);

    return <EnumDropdown
        {...props}
        t={t}
        options={TeachingComponent}
        placeholder={t('teaching_component_ph')}
    />

}

export default TeachingComponentDropdown;
