import {useTranslation} from "react-i18next";
import React, {useCallback, useMemo, useState} from "react";
import Container from "react-bootstrap/Container";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import ListFilterComponent from "app/equipments/equipments/components/list/ListFilterComponent";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/beneficiaries/students/locales/locales";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {getOrDefault} from "system/Objects/ObjectParameters";
import classnames from "classnames";
import SelectionModeMainCheckBox from "app/equipments/equipments/components/list/utils/SelectionModeMainCheckBox";
import SelectionModeEntryCheckBox from "app/equipments/equipments/components/list/utils/SelectionModeEntryCheckBox";

const ListComponent = (props) => {

    const {t} = useTranslation('equipments');
    const {t: tStudent} = useTranslations('student', locale);

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        loading,
        onDelete,
        onFilterSubmit,
        pendingMode = false,
        embeddedMode = false,
        hideBeneficiary = false,
        disableSaving,
        beneficiaryIds,
        extraButtons,
        boxTitle,
        selectionMode,
        hideAttributions,
        maxSelection,
        selectedItems,
        lockedFilters,
        hideSearch
    } = props;

    const [deletedInfo, setDeletedInfo] = useState({});
    const [profiles, loadingProfiles] = useRemoteEntity(data, '/equipment-profiles/search', 'profile');

    const isSelectionDisabled = (maxSelection > 0 && selectedItems?.length >= maxSelection);

    const hideBeneficiaryColumn = useMemo(
        () => !data?.content?.find(v => !!v.beneficiary) || hideBeneficiary,
        [data?.content, hideBeneficiary]
    );

    return <Container fluid>

        <Box className={classnames("equipment-list", {"selection-disabled": isSelectionDisabled})}>

            {boxTitle &&
                <BoxTitle>{boxTitle}</BoxTitle>
            }

            <PopupConfirm
                title={t('DELETE_EQUIPMENT_CONFIRM')}
                message={t('DELETE_EQUIPMENT_CONFIRM_MSG', deletedInfo)}
                classConfirm={"danger"}
                visible={!!deletedInfo.id}
                confirmationString={deletedInfo.name}
                onCancel={() => {
                    setDeletedInfo({})
                }}
                onConfirm={useCallback(() => {
                    setDeletedInfo({})
                    onDelete(deletedInfo.id, {name: deletedInfo.name});
                }, [setDeletedInfo, deletedInfo.id, deletedInfo.name, onDelete])}
            />

            <TableHeader>
                <SearchArea onSearch={onSearch} value={searchFilter?.universalSearch}
                            onSubmit={(data) => onFilterSubmit(data)}
                            disableSaving={disableSaving} hideInput={hideSearch}
                >
                    {(!embeddedMode && !pendingMode) &&
                        <SearchFilter>
                            <ListFilterComponent t={t} searchFilter={searchFilter}
                                                 hideAttributions={!!beneficiaryIds || hideAttributions}
                                                 lockedFilters={lockedFilters}
                            />
                        </SearchFilter>
                    }
                </SearchArea>

                <TableHeaderButtonGroup>
                    {(!embeddedMode && !pendingMode && !selectionMode) &&
                        <TableIconAdd path="/equipments/create" permission={"CREATE_EQUIPMENT"}/>
                    }

                </TableHeaderButtonGroup>

            </TableHeader>


            <SearchDataPagination data={data} onPageChange={onPageChange}>
                <SelectionModeMainCheckBox {...props}/>
            </SearchDataPagination>

            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data || loadingProfiles)}>
                <thead>
                <tr>

                    {selectionMode &&
                        <th/>
                    }

                    <th id="procedure" className={"d-none d-md-table-cell"}>
                        {t('procedure')}
                    </th>

                    <th id="serialNumber" sorted>
                        {t('serial_number')}
                    </th>

                    <th id="internalCode">
                        {t('internal_code')}
                    </th>


                    <th id="profile">
                        {t('profile')}
                    </th>

                    {!pendingMode &&
                        <th id="status">
                            {t('status')}
                        </th>
                    }

                    <th id="school" className={"d-none d-md-table-cell"}>
                        {t('school')}
                    </th>


                    {(!hideBeneficiaryColumn && !pendingMode) &&
                        <UserHasPermission permission={"READ_BENEFICIARY"}>
                            <th id="procedure" className={"d-none d-md-table-cell"}>
                                {t('beneficiary')}
                            </th>
                        </UserHasPermission>
                    }

                    {!isNotDefinedNorEmpty(searchFilter?.equipmentSupplierIds) &&
                        <th>
                            {t('equipment_supplier')}
                        </th>
                    }


                    {!isNotDefinedNorEmpty(searchFilter?.beneficiaryRanks) &&
                        <th>
                            {t('rank')}
                        </th>
                    }


                    {!isNotDefinedNorEmpty(searchFilter?.maintenanceSupplierIds) &&
                        <th>
                            {t('maintenance_supplier')}
                        </th>
                    }

                    {!selectionMode &&
                        <th/>
                    }

                </tr>
                </thead>
                <tbody>


                {data?.content?.map((equipment, index) => {


                    const selectValue = getOrDefault(selectedItems?.find(e => e.id === equipment.id));
                    const disableLine = isSelectionDisabled && !selectValue;

                    return <tr key={index} className={classnames({"disabled-line": disableLine})}>

                        <SelectionModeEntryCheckBox {...props} disabled={disableLine} equipment={equipment}/>
                        <td className="fill-empty d-none d-md-table-cell">
                            {profiles[equipment.profile.id]?.procedure?.name}
                        </td>

                        <td className="fill-empty td-sorted">
                            {equipment.serialNumber}
                        </td>

                        <td className="fill-empty">
                            {equipment.internalCode}
                        </td>

                        <td className="fill-empty">
                            <div className={"over-list-entry"}>
                                {profiles[equipment.profile.id]?.typology?.name}
                            </div>
                            {equipment.profile?.name}
                        </td>

                        {!pendingMode &&
                            <td className="fill-empty">
                                {equipment.status?.name}
                            </td>
                        }

                        <td className="fill-empty d-none d-md-table-cell">
                            <div className={"over-list-entry"}>
                                {t(equipment.school?.typology)}
                            </div>

                            {equipment.school?.name}
                        </td>

                        {(!pendingMode && !hideBeneficiaryColumn) &&
                            <UserHasPermission permission={"READ_BENEFICIARY"}>
                                <td className="fill-empty d-none d-md-table-cell">
                                    {equipment.beneficiary &&
                                        <>
                                            <div className={"over-list-entry"}>
                                                {t(equipment.beneficiary?.type)}
                                            </div>
                                            {equipment?.beneficiary?.vatNumber}
                                            {equipment?.beneficiary?.name && <>&nbsp;-&nbsp;</>}
                                            {equipment.beneficiary?.name}
                                        </>
                                    }
                                </td>
                            </UserHasPermission>
                        }


                        {!isNotDefinedNorEmpty(searchFilter?.equipmentSupplierIds) &&
                            <td>
                                {profiles[equipment.profile.id]?.equipmentSupplier?.name}
                            </td>
                        }


                        {!isNotDefinedNorEmpty(searchFilter?.beneficiaryRanks) &&
                            <td>
                                {tStudent(equipment.beneficiaryRank)}
                            </td>
                        }

                        {!isNotDefinedNorEmpty(searchFilter?.maintenanceSupplierIds) &&
                            <td>
                                {equipment.maintenanceSupplier?.id
                                    ? <>{equipment.maintenanceSupplier?.name}</>
                                    : <>{profiles[equipment.profile.id]?.maintenanceSupplier?.name}</>
                                }
                            </td>
                        }


                        {!selectionMode &&
                            <td className="table-buttons">

                                <div className="buttons">
                                    {(!pendingMode) &&
                                        <>
                                            <TableIconButton
                                                path={"/equipments/" + equipment.id}
                                                icon={faSearch}
                                                className={"table-btn btn-rounded-primary small"}
                                            />
                                            {!embeddedMode &&
                                                <TableIconButton
                                                    icon={faTrash}
                                                    permission={"DELETE_EQUIPMENT"}
                                                    className={"table-btn btn-rounded-danger small"}
                                                    onClick={() => {
                                                        setDeletedInfo(equipment)
                                                    }}
                                                />
                                            }
                                        </>
                                    }

                                    {extraButtons &&
                                        React.cloneElement(extraButtons, {
                                            value: equipment,
                                        })
                                    }
                                </div>

                            </td>
                        }
                    </tr>
                })}


                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_equipment_found')}
                </div>
            }
        </Box>
    </Container>

}


export default ListComponent;
