import BeneficiaryType from "types/BeneficiaryType";
import StudentList from "app/beneficiaries/students/List";
import TeacherList from "app/beneficiaries/teachers/List";
import React, {useEffect, useMemo, useState} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import {ToastError} from "layout/modules/NotificationArea/components/ToastComponent";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/beneficiarySelection/locales/locales";
import HelpHeaderExecuteComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderExecuteComponent";

const BeneficiarySelectionComponent = props => {

    const {setData, config, operationConfig, errors = {}} = props;
    const {t} = useTranslations("BeneficiarySelectionRuleAction", locale);
    const {beneficiaryType} = config;

    const randId = useMemo(
        () => generateRandomId(5),
        []
    );

    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(
        () => setData({
            beneficiaryIds: selectedItems.map(b => b.id)
        }),
        // eslint-disable-next-line
        [selectedItems]
    );


    return <div className={"beneficiary-selection"}>

        {errors?.beneficiaryIds &&
        <ToastError
            id={randId}
            title={t('ERROR_SELECTING')}
            message={t(errors?.beneficiaryIds)}
            visible={Object.keys(errors).length > 0}/>
        }

        <HelpHeaderExecuteComponent {...props}/>

        {beneficiaryType === BeneficiaryType.STUDENT &&
        <StudentList
            {...config}
            id={randId}
            selectionMode={true}
            maxSelection={1}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            lockedFilters={{...config}}
            onlyCompatibleWithEquipmentIds={operationConfig?.equipmentIds}
        />
        }

        {beneficiaryType === BeneficiaryType.TEACHER &&
        <TeacherList
            {...config}
            id={randId}
            selectionMode={true}
            maxSelection={1}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            lockedFilters={{...config}}
            onlyCompatibleWithEquipmentIds={operationConfig?.equipmentIds}
        />
        }

    </div>

}

export default BeneficiarySelectionComponent;
