import {useApiState} from "system/API/APIStateContext";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/login/locales/locales";
import {useMutate} from "system/Rest/Rest";
import TwoFactorAuthComponent from "app/login/components/TwoFactorAuthComponent";
import generateSession from "app/login/utils/generateSession";
import {useCallback, useEffect} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Loading from "layout/modules/Loading/Loading";

const TwoFactorAuthContainer = props => {

    const {setAuthentication, authentication} = useApiState();
    const {t, ready} = useTranslations('login', locale);
    const showToast = useShowToast();
    const navigate = useNavigate();

    const {code, token} = useParams();

    const {mutate: authenticate, loading, error} = useMutate({
        verb: "POST",
        path: "/auth/two-fa",
        localErrorOnly: true,
    });


    //use effect for code and token
    useEffect(
        () => {
            if (code && token && !authentication?.isAuthenticated && ready) {
                authenticate({
                    code: code,
                    token: token,
                }).then((response) => {
                    setAuthentication(generateSession(response));

                }).catch((error) => {
                    showToast({
                        title: t("ERROR_2FA_LINK"),
                        message: t(error?.data?.message + "_MSG"),
                        icon: faTimes,
                        className: 'error',
                    });
                    navigate("/");
                })
            }
        },
        [code, token, authenticate, navigate, authentication, setAuthentication, showToast, t, ready]
    )

    //Use effect for responses
    useEffect(
        () => {

            if (error?.status === 429 || error?.status === 412) {
                showToast({
                    title: t("LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED"),
                    message: t("LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED_MSG"),
                    icon: faTimes,
                    className: 'error',
                });
                setAuthentication({});
            } else if (error?.status === 401) {
                showToast({
                    title: t("LOGIN_TWO_FA_INVALID_CODE"),
                    message: t("LOGIN_TWO_FA_INVALID_CODE_MSG"),
                    icon: faTimes,
                    className: 'error',
                });
            }
        },
        [error, t, showToast, setAuthentication]
    );

    const handleAuthentication = useCallback(
        (data) => {
            authenticate({
                code: data,
                token: authentication?.twoFaToken
            }).then((response) => {
                setAuthentication(generateSession(response));
            })
                .catch((e) => {});
        },
        [authentication, authenticate, setAuthentication]
    );

    const handleCodeExpired = useCallback(
        () => {
            setAuthentication({})
        },
        [setAuthentication]
    );

    if (authentication?.isAuthenticated) {
        navigate("/");
        return <></>
    }

    if (code && token) {
        return <Loading visible={true}/>;
    }

    return <TwoFactorAuthComponent
        {...props}
        t={t}
        codeValidity={authentication.twoFactorValidity}
        loading={loading}
        error={error}
        onAuthenticate={handleAuthentication}
        onCodeExpired={handleCodeExpired}
    />
}

export default TwoFactorAuthContainer;
