import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {Form} from "react-bootstrap";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {ReportRecurrenceType} from "app/reporting/utils/ReportRecurrenceType";
import {getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback, useMemo} from "react";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";

const RecurrenceFormComponent = props => {

    const {errors, readOnly, schedule, setSchedule, t} = props;

    const options = useMemo(
        () => {

            let opts = [];

            switch (schedule?.recurrence) {
                case ReportRecurrenceType.MONTHLY:
                    opts = [...Array(31).keys()].map(i => (i + 1).toString());
                    break;
                case ReportRecurrenceType.DAILY:
                    opts = [...Array(24).keys()].map(i => (i + 1).toString());
                    break;
                case ReportRecurrenceType.WEEKLY:
                    return [...Array(7).keys()].map(i => i + 1)
                        .reduce((obj, i) => ({
                            ...obj,
                            ["WEEK_DAY_" + i]: i.toString()
                        }), {});
                default:
                    break;
            }

            return opts.reduce((obj, i) => ({
                ...obj,
                [i]: i
            }), {});
        },
        [schedule?.recurrence]
    );

    return <>
        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['recurrence']}>
                <Form.Label>{t('recurrence')}</Form.Label>
                <EnumDropdown
                    t={t}
                    options={ReportRecurrenceType}
                    isDisabled={readOnly}
                    isClearable={false}
                    value={getOrNull(schedule?.recurrence)}
                    onChange={useCallback(
                        (recurrence) => {
                            setSchedule(request => ({
                                ...request,
                                recurrence: recurrence,
                                recurringTime: undefined,
                            }));
                        },
                        [setSchedule]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['recurrence'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['recurringTime']}>
                <Form.Label>{t('recurrence_' + schedule.recurrence.toLowerCase())}</Form.Label>
                <EnumDropdown
                    t={t}
                    translationReady={true}
                    key={schedule.recurrence}
                    options={options}
                    isDisabled={readOnly}
                    isClearable={false}
                    value={getOrNull(schedule?.recurringTime)}
                    onChange={useCallback(
                        (recurringTime) => {
                            setSchedule(request => ({
                                ...request,
                                recurringTime: recurringTime
                            }));
                        },
                        [setSchedule]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['recurringTime'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['enabled']}>
                <Form.Label>{t('enabled')}</Form.Label>
                <BooleanDropdown
                    id={"isClosed"}
                    isClearable={false}
                    isDisabled={readOnly}
                    value={parseBoolean(schedule.enabled, false)}
                    onChange={(val) => setSchedule(schedule => ({
                        ...schedule,
                        enabled: val,
                    }))}
                />
                <Form.Text className="sub-error">
                    {t(errors['enabled'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </>
}

export default RecurrenceFormComponent;
