import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/locales/locales";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";

const BasicConfigParameters = props => {

    const {t} = useTranslations("form-editor", locale);
    const {configuration, setConfiguration} = props;

    return <>
        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('label')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(configuration?.label)}
                    autoComplete="chrome-off"
                    placeholder={t('label_ph')}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            label: e.target.value,
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('mandatory')}</Form.Label>
                <BooleanDropdown
                    value={parseBoolean(configuration?.mandatory)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            mandatory: value,
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

    </>


}

export default BasicConfigParameters;
