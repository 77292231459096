import {useMutate} from "system/Rest/Rest";
import React, {Suspense, useCallback, useEffect, useState} from "react";
import Loading from "layout/modules/Loading/Loading";
import lazyWithRetry from "system/System/lazyWithRetry";
import useDebounce from "system/System/useDebounce";
import fileDownload from "app/files/utils/download";

const FilePreview = lazyWithRetry(() => import("layout/modules/FilePreview/FilePreview"));

const ContractPreviewComponent = props => {

    const {value} = props;
    const [previewResult, setPreviewResult] = useState();

    const handleDownload = useCallback(
        () => {
            fileDownload(previewResult?.contents, "preview.pdf", previewResult?.type);
        },
        [previewResult?.contents, previewResult?.type]
    );


    const {mutate: submit, loading} = useMutate({
        verb: "POST",
        path: "/contract-models/preview-body",
        localErrorOnly: true,
    });

    const updatePreview = useDebounce(submit, 2000);

    useEffect(
        () => {

            if (value?.body) {
                updatePreview({
                    body: value.body,
                    headerImage: value?.headerImage,
                    footerImage: value?.footerImage,
                }).then((result) => {
                    setPreviewResult(result)
                }).catch(() => setPreviewResult(null));
            }
        },
        [setPreviewResult, updatePreview, value?.body, value?.headerImage, value?.footerImage]
    );


    return <div className={"contract-model-preview"}>

        <Loading visible={loading} localOnly={true}/>
        <div className={"contract-model-preview-area"}>
            {previewResult &&
            <Suspense fallback={<Loading visible={true}/>}>
                <FilePreview
                    type={previewResult.type}
                    content={previewResult.contents}
                    onDownload={handleDownload}
                />
            </Suspense>
            }
        </div>

    </div>
}

export default ContractPreviewComponent;
