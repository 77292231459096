import useTranslations from "system/Translations/UseTranslations";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";
import EquipmentTypologyDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/TypologyDropdown";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import EquipmentProfileDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentProfileDropdown";

const TypologyMapping = React.forwardRef((props, ref) => {

    const {t} = useTranslations("EquipmentSelectionRuleAction", locale);
    const {entry: equipmentType, errors = {}, readOnly = false, onChange, onDelete, globalError} = props;

    return <Row>
        <Col xs={12} sm={6} lg={6}>
            <FormGroup error={errors['typologyId'] || globalError} mandatory={true}>
                <Form.Label>{t('typology')}</Form.Label>
                <EquipmentTypologyDropdown
                    key={!equipmentType?.typologyId ? generateRandomId(10) : undefined}
                    value={getOrNull(equipmentType?.typologyId)}
                    isClearable={false}
                    isDisabled={readOnly}
                    onChange={(value) => onChange({
                        ...equipmentType,
                        typologyId: value,
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['typology'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={12} sm={6} lg={true}>
            <FormGroup error={errors['profileId'] || globalError} mandatory={false}>
                <Form.Label>{t('profile')}</Form.Label>
                <EquipmentProfileDropdown
                    isMulti={false}
                    value={getOrNull(equipmentType?.profileId)}
                    typologyIds={equipmentType?.typologyId}
                    isDisabled={readOnly || !equipmentType?.typologyId}
                    onChange={(value) => onChange({
                        ...equipmentType,
                        profileId: value,
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['profileId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        {(onDelete && !readOnly) &&
            <Col xs={1} sm={1} lg={1} xl={1} className="align-self-center text-end">
                <div className={"btn btn-rounded-danger ms-auto"} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash}/>
                </div>
            </Col>
        }

    </Row>
});

export default TypologyMapping;
