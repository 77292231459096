import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import ListFilterComponent from "./ListFilterComponent";
import ListEntryComponent from "./ListEntryComponent";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableReloadWatcher from "modules/GenericTableContainer/utils/TableReloadWatcher";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import RequestSupportContainer from "app/support/containers/RequestSupportContainer";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";

import "app/support/assets/css/list.scss";
import "app/support/assets/css/status.scss";
import {DropdownButton} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import TicketTransferType from "app/support/utils/TicketTransferType";
import TransferContainer from "app/support/containers/TransferContainer";
import BoxTitle from "layout/modules/Box/components/BoxTitle";

const ListComponent = (props) => {

    const {t} = useTranslation('support');

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        loading,
        onFilterSubmit,
        simpleMode,
        reload,
        disableSaving,
        boxTitle,
        hideSearch,
    } = props;

    const [isOpenTicketVisible, setOpenTicket] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [transferType, setTransferType] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            reload(true);
        }, 30000);
        return () => clearInterval(interval);
    }, [reload]);

    return <Container fluid>

        <TableReloadWatcher {...props} />

        {isOpenTicketVisible && <RequestSupportContainer
            {...props}
            isOpen={true}
            onClose={() => setOpenTicket(false)}
        />}

        <Box className={"ticket-list"}>

            {boxTitle && <BoxTitle>{boxTitle}</BoxTitle>}

            {!simpleMode && <TableHeader>

                {!hideSearch &&
                    <>
                        <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}
                                    onSubmit={(data) => onFilterSubmit(data)} disableSaving={disableSaving}>

                            <SearchFilter>
                                <ListFilterComponent t={t} searchFilter={searchFilter}/>
                            </SearchFilter>
                        </SearchArea>
                    </>
                }

                <TableHeaderButtonGroup>
                    <TableIconAdd onClick={() => setOpenTicket(true)} label={t('create')}/>
                </TableHeaderButtonGroup>
            </TableHeader>}

            <SearchDataPagination data={data} onPageChange={onPageChange}>
                {(!simpleMode && !hideSearch) && <UserHasPermission permission={'MANAGE_TICKETS'}>
                    <CheckBox
                        label={t('item_selected', {count: selectedItems.length})}
                        value={selectedItems.length > 0}
                        disabled={selectedItems.length === 0}
                        square={!(data?.content?.totalItems > selectedItems.length)}
                        onChange={() => setSelectedItems([])}

                    />
                    <div className={"search-buttons"}>

                        {transferType && <TransferContainer
                            {...props}
                            selectedItems={selectedItems}
                            transferType={transferType}
                            onClose={() => {
                                setTransferType(false);
                                setSelectedItems([]);
                            }}/>}

                        <DropdownButton title={t('transfer_ticket')} disabled={selectedItems.length === 0}>
                            <Dropdown.Item onClick={() => setTransferType(TicketTransferType.USER)}>
                                {t('transfer_ticket_to_operator')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setTransferType(TicketTransferType.ENTITY)}>{t('transfer_ticket_to_entity')}
                            </Dropdown.Item>
                        </DropdownButton>

                    </div>
                </UserHasPermission>}
            </SearchDataPagination>


            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data)}>

                <thead>
                <tr>

                    {!simpleMode &&
                        <UserHasPermission permission={["MANAGE_TICKETS"]}>
                            <th className={"table-checkbox"}/>
                        </UserHasPermission>
                    }

                    <th id="id" sorted>
                        {t('id')}
                    </th>

                    <th>
                        {t('subject')}
                    </th>

                    <th>
                        {t('open_by')}
                    </th>

                    <th>
                        {t('destination')}
                    </th>

                    <th id="lastUpdateTimestamp" className="d-none d-md-table-cell" sorted>
                        {t('last_update')}
                    </th>

                    <th id="status" sorted>
                        {t('status')}
                    </th>


                    <th id="priority" sorted>
                        {t('priority')}
                    </th>


                </tr>
                </thead>
                <tbody>

                <ListEntryComponent
                    t={t} data={data}
                    simpleMode={simpleMode}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />

                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 && <div className={"no-table-results"}>
                {t('no_tickets_found')}
            </div>}
        </Box>
    </Container>

}

export default ListComponent;
