import EquipmentList from "app/equipments/equipments/List";
import React, {useMemo} from "react";
import mergeFilters from "app/ruleTemplates/actions/equipmentSelection/utils/mergeFilters";

const EquipmentListComponent = props => {

    const {
        id,
        config,
        currentSelection,
        operationConfig,
        selectedEquipment,
        handleEquipmentSelection
    } = props;

    const filters = useMemo(() => ({
            ...config,
            typologyIds: mergeFilters(currentSelection?.typologyId, config?.typologyIds),
            profileIds: mergeFilters(currentSelection?.profileIds, config?.profileIds)
        }),
        // eslint-disable-next-line
        [currentSelection]
    );
    

    return <EquipmentList
        key={id}
        {...filters}
        id={id}
        selectionMode={true}
        maxSelection={1}
        selectedItems={selectedEquipment ? [selectedEquipment] : []}
        setSelectedItems={handleEquipmentSelection}
        hideAttributions={true}
        lockedFilters={{...filters}}
        schoolIds={operationConfig?.schoolIds}
        onlyCompatibleWithBeneficiaryIds={operationConfig?.beneficiaryIds}
    />
}

export default EquipmentListComponent;
