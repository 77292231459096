import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faFileContract} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/contractGenerator/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/contractGenerator/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/contractGenerator/components/FormEditor";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasBeneficiaries from "app/ruleTemplates/utils/config/ruleHasBeneficiaries";
import ExecuteComponent from "app/ruleTemplates/actions/contractGenerator/components/ExecuteComponent";
import RenderView from "app/ruleTemplates/actions/contractGenerator/components/RenderView";


class ContractGeneratorRuleAction extends RuleEngineAction {

    static hasViewMode = true;
    static type = RuleActionType.HYBRID
    static excludeTypes = [RuleType.SCHOOL];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faFileContract, t('name'), t('description'));

    static getName = () => "ContractGeneratorRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <ExecuteComponent {...props} />
    static renderView = props => <RenderView {...props}/>

    static isAllowed = (config, position) => ruleHasEquipments(config, position) && ruleHasBeneficiaries(config, position);

}

export default ContractGeneratorRuleAction;
