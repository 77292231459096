import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/schoolSelection/locales/locales";
import React, {useEffect, useMemo, useState} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import {ToastError} from "layout/modules/NotificationArea/components/ToastComponent";
import SchoolList from "app/schools/List"
import "app/ruleTemplates/actions/schoolSelection/assets/execution.scss";
import HelpHeaderExecuteComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderExecuteComponent";

const SchoolSelectionComponent = props => {

    const {setData, config, errors = {}} = props;
    const {t} = useTranslations("SchoolSelectionRuleAction", locale);

    const randId = useMemo(
        () => generateRandomId(5),
        []
    );

    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(
        () => setData({
            schoolIds: selectedItems.map(b => b.id)
        }),
        // eslint-disable-next-line
        [selectedItems]
    );


    return <div className={"rule-school-selection"}>

        {errors?.schoolIds &&
        <ToastError
            id={randId}
            title={t('ERROR_SELECTING')}
            message={t(errors?.schoolIds)}
            visible={Object.keys(errors).length > 0}/>
        }

        <HelpHeaderExecuteComponent {...props}/>

        <SchoolList
            {...config}
            id={randId}
            selectionMode={true}
            maxSelection={1}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            lockedFilters={{...config}}
        />

    </div>
}

export default SchoolSelectionComponent;
