import TicketStatus from "app/support/utils/TicketStatus";
import TicketTransferType from "app/support/utils/TicketTransferType";

const getInitials = (nameRaw) => {

    if (!nameRaw)
        return "UE";


    const namesArray = nameRaw.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}


const shouldRefresh = (location) => (location.pathname === "/support/" || location.pathname === "/");


const isLeftSide = (message, user) => {
    return (message.creationUser?.entity !== user.entity?.name);
}

const isTicketLocked = (ticket) => ticket.status === TicketStatus.TRANSFERRED;

const isTicketSelectDisabled = (ticket, selectedItems) => {

    if (isTicketLocked(ticket)) {
        return true
    }
    return !!selectedItems.find(e => (e.destinationEntity?.id !== ticket.destinationEntity.id));

}

const getTransferEntityId = (ticket, selectedItems, transferType) => {
    if (transferType === TicketTransferType.USER) {
        return ticket?.destinationEntity?.id ?? selectedItems[0]?.destinationEntity?.id;
    }
    else {
        return undefined;
    }
}


export {isLeftSide, getInitials, shouldRefresh, isTicketLocked, isTicketSelectDisabled,getTransferEntityId};
