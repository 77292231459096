import React, {createContext, useMemo, useRef} from 'react'
import Header from "./components/Header"
import locale from "locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import './css/content.scss'
import './css/content-responsive.scss'

const TitleContext = createContext({});

function useHeaderProps() {
    const context = React.useContext(TitleContext)
    if (!context) {
        throw new Error(`useSetTitle must be used within a TitleContext`)
    }
    return context
}


const ContentArea = (props) => {

    const {t} = useTranslations('global', locale);
    const ref = useRef(null);

    const [headerParams, setHeaderParams] = React.useState({
        title: "",
        linkPath: []
    });

    const value = useMemo(() => {
        return {
            setTitle: setHeaderParams,
            ref: ref
        }
    }, [setHeaderParams, ref]);

    return <TitleContext.Provider value={value}>
        <div className="content-area" ref={ref}>

            <Header title={headerParams.title} linkPath={headerParams.linkPath} {...props}/>

            <div className={"main-content-area"} id={"main-content-area"}>
                {props.children}
            </div>

            <div className={"bottom-message"}>
                {t('bottom_message')}
            </div>

        </div>
    </TitleContext.Provider>

}

export default ContentArea;
export {useHeaderProps}
