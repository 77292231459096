export const pt = {

    accept_header: "Confirmar",
    reject_header: "Confirmar rejeição",
    reject_reason: "Motivo/Razão da rejeição",

    cancel: "Cancelar",
    reject: "Rejeitar",

};

export default pt;
