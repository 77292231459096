import getAsArray from "system/Arrays/getArray";
import {isString} from "system/Utils/isString";

const GenericResolver = (data, t) => {
    if (getAsArray(data).length === 0)
        return data;

    return data.map(e => {
        const entry = {};
        Object.keys(e).forEach(k => {entry[t(k)] = isString(e[k]) ? t(e[k]) : e[k];});
        return entry;
    });

}

export default GenericResolver;