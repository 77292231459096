import React from "react";
import RelatedEquipmentAlertComponent
    from "app/equipments/equipments/components/show/alerts/RelatedEquipmentAlertComponent";
import VirtualEquipmentAlertComponent
    from "app/equipments/equipments/components/show/alerts/VirtualEquipmentAlertComponent";
import NonComplianceAlertsComponent
    from "app/equipments/equipments/components/show/alerts/NonComplianceAlertsComponent";

const EquipmentAlertsComponent = props => {

    const {equipment, selectionMode} = props;

    return <>


        {!selectionMode &&
        <NonComplianceAlertsComponent {...props}/>
        }

        {(equipment?.relatedEquipmentId && !equipment?.isVirtual) &&
        <RelatedEquipmentAlertComponent {...props} />
        }

        {equipment?.isVirtual &&
        <VirtualEquipmentAlertComponent {...props} />
        }

    </>
}


export default EquipmentAlertsComponent;
