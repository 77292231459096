import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback, useMemo} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import getNested from "system/Objects/getNested";

const GenericEnumComponent = props => {

    const {t: tAux, value, setValue, parameter, error, readOnly, options, tObject} = props;

    const tMain = useCallback((label) => {
        if (tObject) {
            return getNested(tObject, label) ?? getNested(tObject, label?.toLowerCase()) ?? tAux(label)
        } else return tAux(label)
    }, [tObject, tAux]);

    const tFinal = useMemo(() => (tAux || tObject) ? tMain : undefined, [tAux, tMain, tObject]);

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EnumDropdown
                t={tFinal}
                options={options}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default GenericEnumComponent;
