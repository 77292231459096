import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/dropdown/locales/locales";
import Select from "react-select";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const EditorComponent = props => {

    const {label, placeholder, mandatory, isMulti} = props;
    const {t} = useTranslations('FormDropdownModule', locale);

    const noOptionsMessage = useLabelCallback(props.noOptionsMessage, t('no_results'));
    const loadingMessage = useLabelCallback(props.loadingMessage, t('loading_message'));

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <Select
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            isDisabled={true}
            isMulti={isMulti}
            classNamePrefix={"rs"}
            className={"rs"}
            placeholder={placeholder}
            onChange={() => {
            }}
            value={null}
        />

    </FormGroup>

}

export default EditorComponent;
