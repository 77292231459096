const equipmentProfileResolver = data => ({
    ...data,
    equipmentSupplierId: data.equipmentSupplier?.id,
    maintenanceSupplierId: data.maintenanceSupplier?.id,
    procedureId: data.procedure?.id,
    typologyId: data?.typology?.id,

});

const equipmentProfileVersionResolver = data => ({
    ...data,
    entity: {
        ...equipmentProfileResolver(data?.entity)
    }

});

const equipmentProfileAuditLogResolver = data => ({
    ...data,
    procedureId: data.procedure,
    typologyId: data.typology,
    equipmentSupplierId: data.equipmentSupplier,
    maintenanceSupplierId: data.maintenanceSupplier,


});

export {equipmentProfileAuditLogResolver, equipmentProfileVersionResolver};
export default equipmentProfileResolver;
