import React from "react"
import locale from "locales/locales"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChalkboardUser,
    faChartLine,
    faCogs,
    faEllipsisH,
    faFileSignature,
    faHome, faLaptop,
    faMagnifyingGlassChart, faPersonChalkboard,
    faSitemap
} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";
import MultipleMenuItem from "layout/modules/BaseLayout/Menu/containers/MultipleMenuItem";
import SubMenu from "layout/modules/BaseLayout/Menu/containers/SubMenu";
import SubMenuItem from "layout/modules/BaseLayout/Menu/components/SubMenuItem";
import useBulkRuleMenuOptions from "app/ruleTemplates/utils/menu/useBulkRuleMenuOptions";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/UserType";

const MainMenu = (props) => {

    const {t} = useTranslations('global', locale);

    const bulkRulesMenuEntries = useBulkRuleMenuOptions();
    const {authentication} = useApiState();
    
    return <>

        <SingleMenuItem label={t('home')} icon={faHome} path="/" className={"hidden-mobile"}/>

        <MultipleMenuItem {...props} icon={faFileSignature} label={t('procedures')}>

            <SubMenu label={t('equipments')}>
                <SubMenuItem label={t("list_equipments")}
                             path={"/equipments"}
                             permissions={["READ_EQUIPMENT"]}/>

                <SubMenuItem label={t("equipments_profiles")} path={"/equipment-profile"}
                             permissions={["READ_EQUIPMENT", "MANAGE_EQUIPMENT_PROFILES"]} any={true}/>

            </SubMenu>

            <SubMenu label={(t('register_equipments'))}>

                <SubMenuItem label={t("validate_registration")}
                             any={true}
                             path={"/equipment-registration/"}
                             permissions={["VALIDATE_EQUIPMENT_REGISTRATION", "REGISTER_EQUIPMENTS"]}/>

                <SubMenuItem label={t("load_equipments")}
                             path={"/equipment-registration/create"}
                             permissions={["REGISTER_EQUIPMENTS"]}/>

            </SubMenu>

            <SubMenu label={t('procedures')}>
                <SubMenuItem label={t("list_procedures")}
                             path={"/procedure"}
                             permissions={["MANAGE_PROCEDURES"]}/>

                <SubMenuItem label={t("list_contracts")}
                             path={"/contract"}
                             permissions={["READ_CONTRACT"]}/>

            </SubMenu>

            <SubMenu label={(t('control'))}>
                <SubMenuItem label={t("validate_rules")}
                             path={"/rule-validation"}
                             permissions={["VALIDATE_RULES"]}/>
            </SubMenu>

            <SubMenu label={(t('no_compliance'))}>
                <SubMenuItem label={t("no_compliance_list")}
                             path={"/non-compliances"}
                             permissions={["READ_NON_COMPLIANCE"]}/>
            </SubMenu>


            {bulkRulesMenuEntries.length > 0 &&
                <SubMenu label={t('bulk_operations')}>
                    {bulkRulesMenuEntries.map((e, i) => {
                        return <SubMenuItem
                            label={e.name}
                            path={"/rule-execution/" + e.id}
                            key={e.id}
                            any={true}
                            permissions={['READ_EQUIPMENT', "READ_ENTITY", "READ_SCHOOL", "READ_SUPPLIER", "READ_BENEFICIARY"]}
                        />
                    })}
                </SubMenu>
            }

        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faMagnifyingGlassChart} label={t('audit_process')}>
            <SubMenu label={t('audit_process')}>
                <SubMenuItem label={t("list_processes")} path={"/audit-process/"}
                             permissions={["READ_AUDIT_PROCESS"]}/>

                <SubMenuItem label={t("create_process")} path={"/audit-process/create"}
                             permissions={["MANAGE_AUDIT_PROCESS"]}/>
            </SubMenu>
        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faChalkboardUser} label={t('beneficiary')}>

            <SubMenu label={t('beneficiary')}>
                <SubMenuItem label={t("list_students")}
                             path={"/student"}
                             permissions={["READ_BENEFICIARY"]}/>

                <SubMenuItem label={t("list_teachers")}
                             path={"/teacher"}
                             permissions={["READ_BENEFICIARY"]}/>

                <SubMenuItem label={t("refuse_equipment")}
                             path={"/beneficiary/bulk-refusal"}
                             permissions={["WRITE_EQUIPMENT_REFUSAL"]}/>

            </SubMenu>

            {authentication.type === UserType.SCHOOL &&
                <SubMenu label={t('old_beneficiary')}>
                    <SubMenuItem label={t("list_old_students")}
                                 path={"/student/old"}
                                 permissions={["READ_BENEFICIARY"]}/>

                    <SubMenuItem label={t("list_old_teachers")}
                                 path={"/teacher/old"}
                                 permissions={["READ_BENEFICIARY"]}/>

                </SubMenu>
            }

        </MultipleMenuItem>

        <MultipleMenuItem {...props} icon={faChartLine} label={t('reports_and_operations')}>
            <SubMenu label={t('reports')}>
                <SubMenuItem label={t('reports')} permissions={["GENERATE_REPORT"]} path="/reports"/>
                <SubMenuItem label={t('schedule_report')} permissions={["GENERATE_REPORT"]} path="/reports/schedule"/>
            </SubMenu>

            <SubMenu label={t('operations')}>
                <SubMenuItem label={t('operations')} permissions={["RUN_OPERATION"]} path="/operations"/>
                <SubMenuItem label={t('schedule_operation')} permissions={["RUN_OPERATION"]}
                             path="/operations/schedule"/>
            </SubMenu>

        </MultipleMenuItem>


        <MultipleMenuItem {...props} icon={faSitemap} label={t('my_entity')}>

            <SubMenu label={t('schools')}>
                <SubMenuItem label={t("list_school")}
                             path={"/school"}
                             permissions={["READ_SCHOOL"]}
                />

                <SubMenuItem label={t("create_school")}
                             path={"/school/create"}
                             permissions={["WRITE_SCHOOL"]}/>

            </SubMenu>

            <SubMenu label={t('suppliers')}>
                <SubMenuItem label={t("list_supplier")}
                             path={"/supplier"}
                             permissions={["READ_SUPPLIER"]}/>

                <SubMenuItem label={t("create_supplier")}
                             path={"/supplier/create"}
                             permissions={["WRITE_SUPPLIER"]}/>

            </SubMenu>

            <SubMenu label={t('users')}>
                <SubMenuItem label={t("list_users")} path={"/user"}
                             permissions={["READ_USER"]}/>

                <SubMenuItem label={t("create_users")} path={"/user/create"}
                             permissions={["WRITE_USER"]}/>
            </SubMenu>


            <SubMenu label={t('entities')}>

                <SubMenuItem label={t("my_entity")} path={"/myEntity"}
                             permissions={["READ_OWN_ENTITY"]}/>

                <SubMenuItem label={t("list_entities")} path={"/entity"}
                             permissions={["READ_ENTITY"]}/>

                <SubMenuItem label={t("create_entity")} path={"/entity/create"}
                             permissions={["WRITE_ENTITY"]}/>
            </SubMenu>

            <SubMenu label={t('files')}>
                <SubMenuItem label={t("entity_files")}
                             path={"/entity/files"}
                             permissions={["READ_ENTITY"]}/>
            </SubMenu>


        </MultipleMenuItem>


        <MultipleMenuItem {...props} icon={faCogs} label={t('admin')}>

            <SubMenu label={t('roles')}>
                <SubMenuItem label={t("list_roles")} path={"/role"}
                             permissions={["WRITE_ROLE"]}/>

                <SubMenuItem label={t("create_roles")} path={"/role/create"}
                             permissions={["WRITE_ROLE"]}/>
            </SubMenu>


            <SubMenu label={t('equipments')}>

                <SubMenuItem label={t('rule_template')} path={"/rule-template"}
                             permissions={['MANAGE_RULE_TEMPLATES']}/>

                <SubMenuItem label={t("equipments_status")} path={"/equipment-status"}
                             permissions={["MANAGE_GENERAL_EQUIPMENT_STATUS"]}/>

                <SubMenuItem label={t('equipment_typology')} path={"/equipment-typology"}
                             permissions={['MANAGE_EQUIPMENT_TYPOLOGIES']}/>


            </SubMenu>


            <SubMenu label={t('tickets')}>
                <SubMenuItem label={t("manage_categories")} path={"/ticket-category"}
                             permissions={["MANAGE_TICKET_CATEGORIES"]}/>

            </SubMenu>

            <SubMenu label={t('files')}>
                <SubMenuItem label={t("manage_files")} path={"/file-management"} permissions={["VALIDATE_DOCUMENTS"]}/>
                <SubMenuItem label={t("manage_contract_models")} path={"/contract-model"}
                             permissions={["MANAGE_CONTRACT_MODELS"]}/>
            </SubMenu>

            <SubMenu label={t('reports_and_operations')}>
                <SubMenuItem label={t("list_report_templates")} path={"/report-template"}
                             permissions={["MANAGE_REPORT_TEMPLATES"]}/>
                <SubMenuItem label={t("list_operation_templates")} path={"/operation-template"}
                             permissions={["MANAGE_OPERATION_TEMPLATES"]}/>

            </SubMenu>


            <SubMenu label={t('sla')}>
                <SubMenuItem label={t("manage_supplier_sla")} path={"/supplier-sla"}
                             permissions={["MANAGE_TICKET_SLA"]}/>
            </SubMenu>

            <SubMenu label={t('configs')}>
                <SubMenuItem label={t("configuration")} path={"/configuration"}
                             permissions={["WRITE_CONFIGURATION"]}/>

                <SubMenuItem label={t("read_audit")} path={"/audit"}
                             permissions={["READ_AUDIT_LOG"]}/>
            </SubMenu>

        </MultipleMenuItem>

    </>;

}

const MenuBottomMobile = (props) => {

    return <Row className={"menu-bottom-buttons"}>
        <Col>
            <SingleMenuItem icon={faHome} path="/"/>
        </Col>

        <Col>
            <SingleMenuItem icon={faLaptop}
                         path={"/equipments"}
                         permissions={["READ_EQUIPMENT"]}/>
        </Col>

        <Col>
            <SingleMenuItem icon={faChalkboardUser}
                            path={"/student"}
                            permissions={["READ_BENEFICIARY"]}/>
        </Col>

        <Col>
            <SingleMenuItem icon={faPersonChalkboard}
                            path={"/teacher"}
                            permissions={["READ_BENEFICIARY"]}/>
        </Col>



        <Col onMouseDown={props.onOpenMenu}>
            <div className={"icon-more"}>
                <FontAwesomeIcon icon={faEllipsisH}/>
            </div>
        </Col>
    </Row>;

}

export {MainMenu, MenuBottomMobile};
