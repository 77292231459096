import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import "app/home/assets/css/charts.scss";
import React, {useCallback, useMemo, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import createPersistedState from "system/System/PersistedState/PersistenState";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import useChartDownload from "app/home/utils/useChartDownload";
import ContractChartType from "app/home/utils/ContractChartType";
import ContractChartFilterComponent from "app/home/components/charts/contracts/ContractChartFilterComponent";
import ContractStatusResolver from "app/home/utils/resolvers/ContractStatusResolver";
import {ChartColors} from "app/home/utils/ChartColors";
import ChartLegendComponent from "app/home/components/charts/ChartLegendComponent";

const useStateChartPersistedState = createPersistedState('contracts-dashboard-chart-filter');

const ContractChartComponent = props => {

    const {t} = useTranslation('dashboard');

    const [isFilterVisible, setFilterVisible] = useState(false);
    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const [filters, setFilters] = useStateChartPersistedState({
        modelType: ContractChartType.BENEFICIARY,
    });
    const {handleChartDownload, chartRef} = useChartDownload();

    const {data, loading} = useGet({
        path: "/dashboard/contract-status",
        localErrorOnly: true,
        queryParams: useMemo(() => ({
            ...filters,
        }), [filters]),
        resolve: useCallback(data => ContractStatusResolver(data, t), [t])
    });

    return <Box>
        <BoxTitle>{t('contract_status')}</BoxTitle>

        <ChartButtonsComponent
            setFilterVisible={setFilterVisible}
            isFilterVisible={isFilterVisible}
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />


        {isFilterVisible &&
            <ContractChartFilterComponent filters={filters} setFilters={setFilters}/>
        }

        <Loading visible={loading}/>


        {data?.length > 0 &&
            <>
                {chartMode === ChartMode.CHART &&
                    <ResponsiveContainer height={350} width="100%">
                        <PieChart ref={chartRef}
                                  margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                            <Legend content={ChartLegendComponent}/>
                            <Tooltip
                                cursor={false}
                                contentStyle={{
                                    backgroundColor: undefined,
                                    border: undefined,
                                }}
                                itemStyle={{
                                    color: "var(--text-dark-color)"
                                }}
                                formatter={(value, name, props) => value + " (" + (props.payload?.percentage) + "%)"}/>

                            <Pie data={data}
                                 dataKey="total"
                                 nameKey={"status"}
                                 innerRadius={'70%'}
                                 outerRadius={'80%'}
                                 paddingAngle={2}
                            >
                                {data.map((entry, index) => <Cell key={index}
                                                                  fill={ChartColors[index % ChartColors.length]}
                                                                  stroke={ChartColors[index % ChartColors.length]}/>)}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                }

                {chartMode === ChartMode.TABLE &&
                    <TableModeComponent data={data}/>
                }
            </>
        }

        {data?.length === 0 &&
            <div className={"no-chart-results"}>
                {t('no_chart_results')}
            </div>

        }

    </Box>

}



export default ContractChartComponent;
