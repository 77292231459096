import React from "react";
import {Route} from 'react-router-dom';
import Home from "app/home/Home"
import Login from "app/login/Login"
import Recovery from "app/login/Recovery"
import PersonalArea from "app/personalArea/PersonalArea"
import Logout from "app/login/Logout"
import NotificationList from "app/notifications/List";
import SupportList from "app/support/List";
import SupportTicket from "app/support/Ticket";
import ErrorPage from "layout/modules/ErrorPages/ErrorPage";
import NotFound from "layout/modules/ErrorPages/NotFound";
import LoginLayout from "layout/LoginLayout";
import MainLayout from "layout/MainLayout";
import ErrorInterceptor from "modules/ErrorBoundary/ErrorInterceptor";
import TwoFactorAuthContainer from "app/login/containers/TwoFactorAuthContainer";
import lazyWithRetry from "system/System/lazyWithRetry";
import SetToken from "app/login/SetToken";


//Lazy Loads

const TemporaryFile = lazyWithRetry(() => import("app/files/TemporaryFile"));
const FileManagement = lazyWithRetry(() => import("app/files/FileManagement"));
const GeneralFiles = lazyWithRetry(() => import("app/files/GeneralFiles"));

const EntityList = lazyWithRetry(() => import("app/entities/List"));
const Entity = lazyWithRetry(() => import("app/entities/Entity"));
const MyEntity = lazyWithRetry(() => import("app/entities/MyEntity"));
const EntityFiles = lazyWithRetry(() => import( "app/entities/Files"));
const EntityCreate = lazyWithRetry(() => import( "app/entities/Create"));

const UserList = lazyWithRetry(() => import("app/users/List"));
const User = lazyWithRetry(() => import("app/users/User"));
const UserCreate = lazyWithRetry(() => import("app/users/Create"));
const SetPassword = lazyWithRetry(() => import("app/login/SetPassword"));

const RoleList = lazyWithRetry(() => import("app/roles/List"));
const Role = lazyWithRetry(() => import("app/roles/Role"));
const RoleCreate = lazyWithRetry(() => import("app/roles/Create"));
const RoleBulkEdit = lazyWithRetry(() => import("app/roles/BulkEdit"));

const Audit = lazyWithRetry(() => import("app/audit/List"));

const Configuration = lazyWithRetry(() => import("app/configuration/Configuration"));

const GenerateReport = lazyWithRetry(() => import("app/reporting/Generate"));
const ReportTemplateList = lazyWithRetry(() => import("app/reporting/TemplateList"));
const ReportTemplateCreate = lazyWithRetry(() => import("app/reporting/CreateTemplate"));
const ReportTemplate = lazyWithRetry(() => import("app/reporting/Template"));
const ReportTemplateClone = lazyWithRetry(() => import("app/reporting/Clone"));

const ReportScheduleList = lazyWithRetry(() => import("app/reporting/ScheduleList"));
const ReportSchedule = lazyWithRetry(() => import("app/reporting/Schedule"));
const ReportScheduleCreate = lazyWithRetry(() => import("app/reporting/ScheduleCreate"));

const OperationTemplateList = lazyWithRetry(() => import("app/operations/TemplateList"));
const OperationTemplate = lazyWithRetry(() => import("app/operations/OperationTemplate"));
const OperationTemplateClone = lazyWithRetry(() => import("app/operations/Clone"));
const OperationTemplateCreate = lazyWithRetry(() => import("app/operations/CreateTemplate"));
const ExecuteOperation = lazyWithRetry(() => import("app/operations/ExecuteOperation"));

const OperationSchedule = lazyWithRetry(() => import("app/operations/Schedule"));
const OperationScheduleList = lazyWithRetry(() => import("app/operations/ScheduleList"));
const OperationScheduleCreate = lazyWithRetry(() => import("app/operations/ScheduleCreate"));

const SchoolList = lazyWithRetry(() => import("app/schools/List"));
const SchoolCreate = lazyWithRetry(() => import("app/schools/Create"));
const School = lazyWithRetry(() => import("app/schools/School"));

const SupplierList = lazyWithRetry(() => import("app/suppliers/List"));
const SupplierCreate = lazyWithRetry(() => import("app/suppliers/Create"));
const Supplier = lazyWithRetry(() => import("app/suppliers/Supplier"));


const StudentList = lazyWithRetry(() => import("app/beneficiaries/students/List"));
const StudentCreate = lazyWithRetry(() => import("app/beneficiaries/students/Create"));
const Student = lazyWithRetry(() => import("app/beneficiaries/students/Student"));
const BeneficiaryBulkRefusal = lazyWithRetry(() => import("app/beneficiaries/beneficiary/BulkRefusal"));


const TeacherList = lazyWithRetry(() => import("app/beneficiaries/teachers/List"));
const TeacherCreate = lazyWithRetry(() => import("app/beneficiaries/teachers/Create"));
const Teacher = lazyWithRetry(() => import("app/beneficiaries/teachers/Teacher"));

const ListTicketCategories = lazyWithRetry(() => import("app/support/ListTicketCategories"));
const TicketCategory = lazyWithRetry(() => import("app/support/TicketCategory"));
const CreateTicketCategory = lazyWithRetry(() => import("app/support/CreateTicketCategory"));

const ListEquipmentStatus = lazyWithRetry(() => import("app/equipments/equipmentStatus/List"));
const CreateEquipmentStatus = lazyWithRetry(() => import("app/equipments/equipmentStatus/Create"));
const EquipmentStatus = lazyWithRetry(() => import("app/equipments/equipmentStatus/EquipmentStatus"));

const ListEquipmentTypology = lazyWithRetry(() => import("app/equipments/equipmentTypology/List"));
const CreateEquipmentTypology = lazyWithRetry(() => import("app/equipments/equipmentTypology/Create"));
const EquipmentTypology = lazyWithRetry(() => import("app/equipments/equipmentTypology/EquipmentTypology"));

const ListProcedures = lazyWithRetry(() => import("app/procedures/procedure/List"));
const CreateProcedure = lazyWithRetry(() => import("app/procedures/procedure/Create"));
const Procedure = lazyWithRetry(() => import("app/procedures/procedure/Procedure"));

const ListContractModels = lazyWithRetry(() => import("app/procedures/contractModel/List"));
const CreateContractModel = lazyWithRetry(() => import("app/procedures/contractModel/Create"));
const ContractModel = lazyWithRetry(() => import("app/procedures/contractModel/ContractModel"));

const ListEquipmentProfiles = lazyWithRetry(() => import("app/equipments/equipmentProfile/List"));
const CreateEquipmentProfile = lazyWithRetry(() => import("app/equipments/equipmentProfile/Create"));
const EquipmentProfile = lazyWithRetry(() => import("app/equipments/equipmentProfile/EquipmentProfile"));

const ListEquipments = lazyWithRetry(() => import("app/equipments/equipments/List"));
const CreateEquipment = lazyWithRetry(() => import("app/equipments/equipments/Create"));
const Equipment = lazyWithRetry(() => import("app/equipments/equipments/Equipment"));

const ListContracts = lazyWithRetry(() => import("app/procedures/contracts/List"));
const Contract = lazyWithRetry(() => import("app/procedures/contracts/Contract"));

//Experimental
const RuleTemplate = lazyWithRetry(() => import("app/ruleTemplates/RuleTemplate"));
const ListRuleTemplate = lazyWithRetry(() => import("app/ruleTemplates/List"));
const CreateRuleTemplate = lazyWithRetry(() => import("app/ruleTemplates/Create"));
const ExecuteRuleBulk = lazyWithRetry(() => import("app/ruleTemplates/ExecuteBulk"));
const CloneRuleTemplate = lazyWithRetry(() => import("app/ruleTemplates/Clone"));


const RegisterEquipments = lazyWithRetry(() => import("app/equipments/equipmentRegistration/RegisterEquipments"));
const ListRegisterEquipments = lazyWithRetry(() => import("app/equipments/equipmentRegistration/ListValidationProcesses"));
const RegisterRequest = lazyWithRetry(() => import("app/equipments/equipmentRegistration/Request"));

const ListValidation = lazyWithRetry(() => import("app/validation/List"));
const ViewValidation = lazyWithRetry(() => import("app/validation/Validation"));

const AuditProcessesList = lazyWithRetry(() => import("app/auditProcesses/List"));
const AuditProcessesCreate = lazyWithRetry(() => import("app/auditProcesses/Create"));
const AuditProcessesView = lazyWithRetry(() => import("app/auditProcesses/Process"));
const AuditProcessesEquipment = lazyWithRetry(() => import("app/auditProcesses/Equipment"));

const NonComplianceList = lazyWithRetry(() => import("app/nonCompliances/List"));
const NonComplianceView = lazyWithRetry(() => import("app/nonCompliances/View"));
const NonComplianceTriggerList = lazyWithRetry(() => import("app/nonCompliances/ListSupplierSLA"));
const NonComplianceTrigger = lazyWithRetry(() => import("app/nonCompliances/SupplierSLA"));
const NonComplianceTriggerCreate = lazyWithRetry(() => import("app/nonCompliances/CreateSupplierSLA"));

const routes = <Route error errorElement={<ErrorInterceptor/>}>

    <Route path="/temp-docs/" element={<LoginLayout/>}>
        <Route path=":id" element={<TemporaryFile/>}/>
    </Route>

    <Route path="/auth" element={<LoginLayout/>}>
        <Route index element={<Login/>}/>
        <Route path="recovery" element={<Recovery/>}/>
        <Route path="token/:token" element={<SetToken/>}/>
        <Route path="reset/:id/:token" element={<SetPassword/>}/>
        <Route path="2fa/:token/:code" element={<TwoFactorAuthContainer/>}/>
        <Route path="activate/:id/:token" element={<SetPassword activate={true}/>}/>
    </Route>

    <Route path="/" element={<MainLayout/>}>
        <Route path="/logout" element={<Logout/>}/>
        <Route index element={<Home/>}/>
        <Route path={"/personal"} element={<PersonalArea/>}/>
        <Route path={"/personal/files/:fileUniversalSearch"} element={<PersonalArea showTab={"files"}/>}/>
        <Route path={"/personal/audit"} element={<PersonalArea showTab={"audit"}/>}/>
        <Route path={"/personal/entities"} element={<PersonalArea showTab={"entities"}/>}/>
        <Route path={"/myEntity"} element={<MyEntity/>}/>
        <Route path={"/entity"} element={<EntityList/>}/>
        <Route path={"/entity/:id"} element={<Entity/>}/>
        <Route path={"/entity/create"} element={<EntityCreate/>}/>
        <Route path={"/entity/files"} element={<EntityFiles/>}/>

        <Route path={"/user"}>
            <Route index element={<UserList/>}/>
            <Route path={"create"} element={<UserCreate/>}/>
            <Route path={":id"} element={<User/>}/>
        </Route>

        <Route path={"/role"} element={<RoleList/>}/>
        <Route path={"/role/create"} element={<RoleCreate/>}/>
        <Route path={"/role/bulk"} element={<RoleBulkEdit/>}/>
        <Route path={"/role/:id"} element={<Role/>}/>

        <Route path={"/file-management"} element={<FileManagement/>}/>
        <Route path={"/general-files"} element={<GeneralFiles/>}/>

        <Route path={"/configuration"} element={<Configuration/>}/>
        <Route path={"/audit"} element={<Audit/>}/>

        <Route path={"/notifications/"} element={<NotificationList/>}/>


        <Route path={"/reports/schedule/:id"} element={<ReportSchedule/>}/>
        <Route path={"/reports/schedule"} element={<ReportScheduleList/>}/>
        <Route path={"/reports/schedule/create"} element={<ReportScheduleCreate/>}/>
        <Route path={"/reports"} element={<GenerateReport/>}/>
        <Route path={"/report-template"} element={<ReportTemplateList/>}/>
        <Route path={"/report-template/clone/:id"} element={<ReportTemplateClone/>}/>
        <Route path={"/report-template/:id"} element={<ReportTemplate/>}/>
        <Route path={"/report-template/create"} element={<ReportTemplateCreate/>}/>


        <Route path={"/operation-template"} element={<OperationTemplateList/>}/>
        <Route path={"/operation-template/clone/:id"} element={<OperationTemplateClone/>}/>
        <Route path={"/operation-template/:id"} element={<OperationTemplate/>}/>
        <Route path={"/operation-template/create"} element={<OperationTemplateCreate/>}/>
        <Route path={"/operations"} element={<ExecuteOperation/>}/>
        <Route path={"/operations/schedule/:id"} element={<OperationSchedule/>}/>
        <Route path={"/operations/schedule"} element={<OperationScheduleList/>}/>
        <Route path={"/operations/schedule/create"} element={<OperationScheduleCreate/>}/>

        <Route path={"/school"}>
            <Route index element={<SchoolList/>}/>
            <Route path={"create"} element={<SchoolCreate/>}/>
            <Route path={":id"} element={<School/>}/>
        </Route>


        <Route path={"/supplier"}>
            <Route index element={<SupplierList/>}/>
            <Route path={"create"} element={<SupplierCreate/>}/>
            <Route path={":id"} element={<Supplier/>}/>
        </Route>

        <Route path={"/student"}>
            <Route index element={<StudentList/>}/>
            <Route path={"search/:universalSearch"} element={<StudentList/>}/>
            <Route path={"old"} element={<StudentList showOld/>}/>
            <Route path={"create"} element={<StudentCreate/>}/>
            <Route path={":id"} element={<Student/>}/>
        </Route>


        <Route path={"/teacher"}>
            <Route index element={<TeacherList/>}/>
            <Route path={"search/:universalSearch"} element={<TeacherList/>}/>
            <Route path={"old"} element={<TeacherList showOld/>}/>
            <Route path={"create"} element={<TeacherCreate/>}/>
            <Route path={":id"} element={<Teacher/>}/>
        </Route>

        <Route path={"/beneficiary/bulk-refusal/"} element={<BeneficiaryBulkRefusal/>}/>

        <Route path={"/support"}>
            <Route index element={<SupportList/>}/>
            <Route path={":id"} element={<SupportTicket/>}/>
        </Route>

        <Route path={"/ticket-category"}>
            <Route index element={<ListTicketCategories/>}/>
            <Route path={"create"} element={<CreateTicketCategory/>}/>
            <Route path={":id"} element={<TicketCategory/>}/>
        </Route>

        <Route path={"/non-compliances"}>
            <Route index element={<NonComplianceList/>}/>
            <Route path={":id"} element={<NonComplianceView/>}/>
        </Route>

        <Route path={"/supplier-sla"}>
            <Route index element={<NonComplianceTriggerList/>}/>
            <Route path={"create"} element={<NonComplianceTriggerCreate/>}/>
            <Route path={":id"} element={<NonComplianceTrigger/>}/>
        </Route>

        NonComplianceTriggerList

        <Route path={"/equipment-status"}>
            <Route index element={<ListEquipmentStatus/>}/>
            <Route path={"create"} element={<CreateEquipmentStatus/>}/>
            <Route path={":id"} element={<EquipmentStatus/>}/>
        </Route>

        <Route path={"/equipment-typology"}>
            <Route index element={<ListEquipmentTypology/>}/>
            <Route path={"create"} element={<CreateEquipmentTypology/>}/>
            <Route path={":id"} element={<EquipmentTypology/>}/>
        </Route>


        <Route path={"/procedure"}>
            <Route index element={<ListProcedures/>}/>
            <Route path={"create"} element={<CreateProcedure/>}/>
            <Route path={":id"} element={<Procedure/>}/>
        </Route>

        <Route path={"/contract-model"}>
            <Route index element={<ListContractModels/>}/>
            <Route path={"create"} element={<CreateContractModel/>}/>
            <Route path={":id"} element={<ContractModel/>}/>
        </Route>

        <Route path={"/equipment-profile"}>
            <Route index element={<ListEquipmentProfiles/>}/>
            <Route path={"create"} element={<CreateEquipmentProfile/>}/>
            <Route path={":id/files"} element={<EquipmentProfile showFiles={true}/>}/>
            <Route path={":id"} element={<EquipmentProfile/>}/>
        </Route>

        <Route path={"/equipments"}>
            <Route index element={<ListEquipments/>}/>
            <Route path={"search/:universalSearch"} element={<ListEquipments search={true}/>}/>
            <Route path={"create"} element={<CreateEquipment/>}/>
            <Route path={":id"} element={<Equipment/>}/>
        </Route>

        <Route path={"/equipment-registration"}>
            <Route index element={<ListRegisterEquipments/>}/>
            <Route path={"create"} element={<RegisterEquipments/>}/>
            <Route path={":id"} element={<RegisterRequest/>}/>
        </Route>


        <Route path={"/contract"}>
            <Route index element={<ListContracts/>}/>
            <Route path={":id/:auditId/:equipmentId"} element={<Contract/>}/>
            <Route path={":id"} element={<Contract/>}/>
        </Route>

        <Route path={"/rule-template"}>
            <Route index element={<ListRuleTemplate/>}/>
            <Route path={":id"} element={<RuleTemplate/>}/>
            <Route path={"create"} element={<CreateRuleTemplate/>}/>
            <Route path={"clone/:id"} element={<CloneRuleTemplate/>}/>
        </Route>

        <Route path={"/rule-execution"}>
            <Route path={":id"} element={<ExecuteRuleBulk/>}/>
        </Route>

        <Route path={"rule-validation"}>
            <Route index element={<ListValidation/>}/>
            <Route path={":id"} element={<ViewValidation/>}/>
        </Route>


        <Route path={"audit-process"}>
            <Route index element={<AuditProcessesList/>}/>
            <Route path={"create"} element={<AuditProcessesCreate/>}/>
            <Route path={":processId/equipment/:equipmentId"} element={<AuditProcessesEquipment/>}/>
            <Route path={":id"} element={<AuditProcessesView/>}/>
        </Route>

        <Route path="error" element={<ErrorPage/>}/>
        <Route path={"notFound"} element={<NotFound/>}/>
        <Route path={"*"} element={<NotFound/>}/>

    </Route>
</Route>

export {routes};
