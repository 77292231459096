import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faSchool} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/schoolSelection/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/schoolSelection/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/schoolSelection/components/FormEditor";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasBeneficiaries from "app/ruleTemplates/utils/config/ruleHasBeneficiaries";
import SchoolSelectionComponent from "app/ruleTemplates/actions/schoolSelection/components/SchoolSelectionComponent";


class SchoolSelectionRuleAction extends RuleEngineAction {

    static type = RuleActionType.SYNC
    static excludeTypes = [RuleType.SCHOOL, RuleType.BENEFICIARY];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faSchool, t('name'), t('description'));

    static getName = () => "SchoolSelectionRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <SchoolSelectionComponent {...props} />

    static isAllowed = (config, position) => !ruleHasBeneficiaries(config, position);

}

export default SchoolSelectionRuleAction;
