import React from 'react';

import 'layout/assets/css/fonts.scss'
import 'layout/assets/css/main.scss'
import 'layout/assets/css/generic.scss'
import 'layout/assets/css/bootstrap/bootstrap.scss';
import 'layout/assets/css/plugins/react-dropdown/react-dropdown.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'layout/assets/css/dark-mode.scss'

import ContentArea from "./modules/BaseLayout/ContentArea/ContentArea"
import Menu from "./modules/BaseLayout/Menu/Menu";
import {MenuStateProvider} from "./modules/BaseLayout/Menu/MenuStateContext";
import AuthenticationInterceptor from "system/API/Authentication/AuthenticationInterceptor";
import {Outlet} from "react-router-dom";
import TopAlertMessages from "layout/modules/BaseLayout/ContentArea/components/TopAlertMessages";

const MainLayout = (props) => {

    return <div className="main-layout">
        <AuthenticationInterceptor {...props}>
            <TopAlertMessages {...props}/>

            <MenuStateProvider>
                <Menu {...props} />
            </MenuStateProvider>

            <ContentArea {...props}>
                <Outlet/>
            </ContentArea>
        </AuthenticationInterceptor>
    </div>;

}

export default MainLayout;
