import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React, {Suspense, useCallback, useEffect, useMemo, useState} from "react";
import Col from "react-bootstrap/Col";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContentToByteString} from "system/Files/ReadFileContentToByteString";
import {generateAcceptTypes} from "layout/modules/FormBuilder/modules/upload/utils/UploadSupportedTypes";
import Row from "react-bootstrap/Row";
import classnames from "classnames";
import Select from "react-select";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/upload/locales/locales";
import Loading from "layout/modules/Loading/Loading";
import lazyWithRetry from "system/System/lazyWithRetry";
import fileDownload from "app/files/utils/download";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import "layout/modules/FormBuilder/modules/upload/assets/css/form.scss";
import getArray from "system/Arrays/getArray";

const FilePreview = lazyWithRetry(() => import("layout/modules/FilePreview/FilePreview"));

const FormComponent = props => {

    const {id, setData, data, configuration, readOnly, errorMessage, getFileContent} = props;
    const {t} = useTranslations('UploadModule', locale);

    const acceptTypes = useMemo(
        () => generateAcceptTypes(configuration.acceptTypes),
        [configuration.acceptTypes]
    );

    const previewOptions = useMemo(
        () => data[id]?.map((file, position) => ({
            label: file.filename,
            id: position,

        })),
        [data, id]
    );

    const totalFiles = useMemo(() => data[id]?.length ?? 0, [data, id]);
    const remainingFiles = configuration.maxFiles - totalFiles;
    const showUploadBox = remainingFiles > 0 && !readOnly;

    const [selectedFile, setSelectFile] = useState(0);
    const [selectedFileContent, setSelectFileContent] = useState(null);

    const handleFileSelect = useCallback(
        (value) => {
            setSelectFileContent(null);
            setSelectFile(value.id)
        },
        [setSelectFile]
    );

    useEffect(
        () => {

            if (!readOnly || !data[id])
                return;


            if (getArray(data[id])[selectedFile]) {
                const currentId = data[id][selectedFile].id;
                if (currentId && !data[id][selectedFile].contents && !selectedFileContent) {
                    getFileContent(currentId)
                        .then((data) => setSelectFileContent(data?.contents))
                }
            }

        },
        // eslint-disable-next-line
        [data, selectedFile, id, readOnly, getFileContent]
    );

    const handleDelete = useCallback(
        (position) => {
            setSelectFile(0);
            setData(data => ({
                ...data,
                [id]: data[id].filter((_, i) => i !== position)
            }));
        },
        [id, setSelectFile, setData]
    );

    const handleUpload = useCallback((nf) => {
        nf.forEach((file) => {
            readFileContentToByteString(file, (content) => {

                const fileData = {
                    filename: file.name,
                    type: file.type,
                    contents: btoa(content)
                };

                setData(data => ({
                    ...data,
                    [id]: Array.isArray(data[id])
                        ? [...data[id], {...fileData}]
                        : [{...fileData}]
                }));
            });
        })
    }, [setData, id]);

    return <Col lg={12} sm={12} className={"dynamic-form-upload"}>
        <Row>

            {showUploadBox &&
                <Col lg={true} sm={12}>
                    <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
                        <Form.Label>{configuration.label}</Form.Label>
                        <DragDropFile
                            id={id}
                            maxFiles={remainingFiles}
                            disabled={readOnly}
                            acceptTypes={acceptTypes}
                            text={configuration.descriptionInfo}
                            onFileUploaded={handleUpload}
                        />

                        <Form.Text className="sub-error">
                            {t(errorMessage)}
                        </Form.Text>

                    </FormGroup>
                </Col>
            }

            {totalFiles > 0 &&
                <Col lg={showUploadBox ? 6 : 12} sm={12}>
                    <div className={classnames("file-list", {"view-mode": readOnly})}>

                        <div className={"file-selection"}>
                            <Form>
                                <FormGroup>
                                    <Form.Label>{t('files')}</Form.Label>
                                    <div className={"input-combo-upload"}>
                                        <Select
                                            isClearable={false}
                                            classNamePrefix="rs"
                                            className="rs"
                                            placeholder={() => t('select_file_ph')}
                                            noOptionsMessage={() => t('no_file_found')}
                                            isLoading={false}
                                            options={previewOptions}
                                            value={previewOptions[selectedFile]}
                                            onChange={handleFileSelect}
                                        />
                                        {!readOnly &&
                                            <Button className={classnames("btn btn-rounded-danger small")}
                                                    onClick={() => handleDelete(selectedFile)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        }
                                    </div>
                                </FormGroup>

                            </Form>
                        </div>

                        <div className={"file-preview"}>
                            {(!data[id][selectedFile].contents && !selectedFileContent)
                                ? <Loading visible={true}/>
                                : <Suspense fallback={<Loading visible={true}/>}>
                                    <FilePreview
                                        key={"preview-" + selectedFile}
                                        type={data[id][selectedFile].type}
                                        content={data[id][selectedFile].contents ?? selectedFileContent}
                                        onDownload={() => fileDownload(
                                            data[id][selectedFile].contents ?? selectedFileContent,
                                            data[id][selectedFile].filename,
                                            data[id][selectedFile].type
                                        )}
                                    />
                                </Suspense>
                            }

                        </div>


                    </div>
                </Col>
            }
        </Row>
    </Col>

}

export default FormComponent;
