import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/beneficiaries/beneficiary/locales/locales";

const SelectionModeHeaderCheckbox = props => {

    const {selectedItems = [], setSelectedItems, data} = props;
    const {t} = useTranslations("beneficiary", locale);

    return <CheckBox
        label={t('item_selected', {count: selectedItems.length})}
        value={selectedItems.length > 0}
        disabled={selectedItems.length === 0}
        square={!(data?.content?.totalItems > selectedItems.length)}
        onChange={() => setSelectedItems([])}
    />
}

export default SelectionModeHeaderCheckbox;
