import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";

const HelpHeaderExecuteComponent = (props) => {

    const {config, bulkMode} = props;
    const {t} = useTranslation("EquipmentSelectionRuleAction");

    if (config?.helpTitle && !bulkMode) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"info"}
            iconType={"info"}
            title={config?.helpTitle}
            message={config?.helpContent?.replaceAll("&nbsp;", " ")}

        />
    } else if (bulkMode) {
        return <AdvanceInfoMessage
            icon={faInfo}
            type={"info"}
            iconType={"info"}
            title={t('bulk_selection')}
            message={t('bulk_selection_msg')}

        />
    }

    return <></>

}


export default HelpHeaderExecuteComponent;
