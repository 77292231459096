import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Loading from "layout/modules/Loading/Loading";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Form from "react-bootstrap/Form";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import RankLevel from "types/RankLevel";
import useTranslations from "system/Translations/UseTranslations";
import localeStudent from "app/beneficiaries/students/locales/locales";
import localeSchool from "app/schools/locales/locales";
import StudentSchoolYearDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/student/StudentSchoolYearDropdown";
import EquipmentBillingType from "types/EquipmentBillingType";
import SupplierDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SupplierDropdown";
import ProcedureDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ProcedureDropdown";
import EquipmentTypologyDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/TypologyDropdown";
import locale from "app/equipments/equipmentProfile/locales/locales";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Audit from "app/audit/Audit";
import {EquipmentProfileActions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import EquipmentProfileVersionContainer
    from "app/equipments/equipmentProfile/containers/EquipmentProfileVersionContainer";
import {SchoolType} from "types/SchoolType";

const FormComponent = props => {

    const {
        profile: remoteProfile,
        loading = false,
        onSubmit,
        onEditMode,
        create = false,
        errors = {},
        viewMode,
        compareMode,
        changes = {},
    } = props;

    const {t: tStudent} = useTranslations('student', localeStudent);
    const {t: tSchool} = useTranslations('school', localeSchool);
    const {t} = useTranslations('equipmentProfile', locale);

    const [profile, setProfile] = useState(remoteProfile);
    const [readOnly, setReadOnly] = useState(viewMode || compareMode);
    const [versionsVisible, setVersionsVisible] = useState(false);

    useEffect(() => {
        if (profile === null)
            setProfile(remoteProfile);
    }, [remoteProfile, profile, setProfile]);

    //Update EditMode
    useEffect(() => {
        if (onEditMode)
            onEditMode(!readOnly);
    }, [onEditMode, readOnly])


    return <div className={classnames("limited-width", {
        "view-mode": (readOnly && viewMode),
        "compare-mode": compareMode,
    })}>

        <Loading visible={loading}/>

        {viewMode &&
        <EquipmentProfileVersionContainer
            id={profile?.id}
            title={t('compare_profiles')}
            visible={versionsVisible}
            onClose={() => setVersionsVisible(false)}
            onRevert={(profile) => {
                setReadOnly(false);
                setVersionsVisible(false);
                setProfile({
                    ...profile
                });
            }}
        />
        }

        {!compareMode &&
        <>
            <GenericFormError t={t} errors={errors}/>
            <FormHandleNotSaved
                remote={remoteProfile}
                local={profile}
                loading={loading}
                readOnly={readOnly}
                setLocal={setProfile}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}
            />
        </>
        }

        <Form autoComplete="chrome-off">
            <Box>
                <BoxTitle>{t('profile_data')}</BoxTitle>

                <BoxOptions visible={!create && !compareMode}>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            objectId={profile.id}
                            filterActions={{
                                ...EquipmentProfileActions,
                            }}
                            affectedObjectType={[
                                AuditObjectTypes.EquipmentProfile
                            ]}
                        />
                    </UserHasPermission>

                    <UserHasPermission permission={["MANAGE_EQUIPMENT_PROFILES"]}>
                        <Button
                            onClick={() => setReadOnly(!readOnly)}
                            className={classnames("small btn btn-rounded-danger", {"active": !readOnly})}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </Button>
                    </UserHasPermission>
                </BoxOptions>

                <Row>

                    <Col sm={12} lg={12} xl={12}>
                        <FormGroup error={errors['name']} changes={changes['name']} mandatory={true}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                placeholder={t('name_placeholder')}
                                value={getOrEmpty(profile?.name)}
                                disabled={readOnly}
                                onChange={useCallback((e) => setProfile(profile => ({
                                    ...profile,
                                    name: e.target.value,
                                })), [setProfile])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={12} xl={12}>
                        <FormGroup error={errors['description']} changes={changes['description']}>
                            <Form.Label>{t('description')}</Form.Label>
                            <FormControl
                                type="text"
                                placeholder={t('description_ph')}
                                value={getOrEmpty(profile?.description)}
                                disabled={readOnly}
                                onChange={useCallback((e) => setProfile(profile => ({
                                    ...profile,
                                    description: e.target.value,
                                })), [setProfile])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['description'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <UserHasPermission permission={"MANAGE_EQUIPMENT_PROFILES"}>
                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['idLabel']} changes={changes['idLabel']} mandatory={true}>
                                <Form.Label>{t('id_label')}</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder={t('id_label_placeholder')}
                                    value={getOrEmpty(profile?.idLabel)}
                                    disabled={readOnly}
                                    onChange={useCallback((e) => setProfile(profile => ({
                                        ...profile,
                                        idLabel: e.target.value,
                                    })), [setProfile])}
                                />

                                <Form.Text className="sub-error">
                                    {t(errors['idLabel'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['idRegex']} changes={changes['idRegex']} mandatory={true}>
                                <Form.Label>{t('id_regex')}</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder={t('id_regex_placeholder')}
                                    value={getOrEmpty(profile?.idRegex)}
                                    disabled={readOnly}
                                    onChange={useCallback((e) => setProfile(profile => ({
                                        ...profile,
                                        idRegex: e.target.value,
                                    })), [setProfile])}
                                />

                                <Form.Text className="sub-error">
                                    {t(errors['idRegex'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['schoolTypologies']} changes={changes['schoolTypologies']}>
                                <Form.Label>{t('school_typologies')}</Form.Label>
                                <EnumDropdown
                                    t={tSchool}
                                    isMulti={true}
                                    placeholder={t('school_typologies_ph')}
                                    options={SchoolType}
                                    value={getOrNull(profile?.schoolTypologies)}
                                    isDisabled={readOnly}
                                    onChange={(value) => setProfile(profile => ({
                                        ...profile,
                                        schoolTypologies: value,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['schoolTypologies'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['availableForSchool']} changes={changes['availableForSchool']}>
                                <Form.Label>{t('available_for_school')}</Form.Label>
                                <BooleanDropdown
                                    value={parseBoolean(profile?.availableForSchool, false)}
                                    isDisabled={readOnly}
                                    onChange={useCallback((value) => setProfile(profile => ({
                                        ...profile,
                                        availableForSchool: value,
                                    })), [setProfile])}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['availableForSchool'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>

                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['availableForStudent']} changes={changes['availableForStudent']}>
                                <Form.Label>{t('available_for_student')}</Form.Label>
                                <BooleanDropdown
                                    value={parseBoolean(profile?.availableForStudent, false)}
                                    isDisabled={readOnly}
                                    onChange={useCallback((value) => setProfile(profile => ({
                                        ...profile,
                                        availableForStudent: value,
                                    })), [setProfile])}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['availableForStudent'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>


                        {profile?.availableForStudent &&
                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['inDigitalManualPilot']} changes={changes['isDigitalManualPilot']}>
                                <Form.Label>{t('in_digital_manual_pilot')}</Form.Label>
                                <BooleanDropdown
                                    value={parseBoolean(profile?.inDigitalManualPilot, null)}
                                    isDisabled={readOnly}
                                    isClearable={true}
                                    onChange={(value) => setProfile(profile => ({
                                        ...profile,
                                        inDigitalManualPilot: value,
                                        availableForTeacher: false,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['inDigitalManualPilot'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                        }


                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['availableForTeacher']} changes={changes['availableForTeacher']}>
                                <Form.Label>{t('available_for_teacher')}</Form.Label>
                                <BooleanDropdown
                                    value={parseBoolean(profile?.availableForTeacher, false)}
                                    isDisabled={readOnly}
                                    onChange={useCallback((value) => setProfile(profile => ({
                                        ...profile,
                                        availableForTeacher: value,
                                    })), [setProfile])}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['availableForTeacher'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>


                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['typologyId']} changes={changes['typologyId']} mandatory={true}>
                                <Form.Label>{t('typology')}</Form.Label>
                                <EquipmentTypologyDropdown
                                    value={getOrNull(profile?.typologyId)}
                                    isDisabled={readOnly}
                                    onChange={useCallback((value) => setProfile(profile => ({
                                        ...profile,
                                        typologyId: value,
                                    })), [setProfile])}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['typologyId'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>


                        {profile?.availableForStudent &&
                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['beneficiaryRanks']} changes={changes['beneficiaryRanks']}>
                                <Form.Label>{t('rank')}</Form.Label>
                                <EnumDropdown
                                    t={tStudent}
                                    isMulti={true}
                                    placeholder={t('rank_ph')}
                                    options={RankLevel}
                                    value={getOrNull(profile?.beneficiaryRanks)}
                                    isDisabled={readOnly}
                                    onChange={(value) => setProfile(profile => ({
                                        ...profile,
                                        beneficiaryRanks: value,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors['beneficiaryRanks'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                        }

                        {profile?.availableForStudent &&
                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['schoolYears']} changes={changes['schoolYears']}>
                                <Form.Label>{t('school_years')}</Form.Label>
                                <StudentSchoolYearDropdown
                                    isMulti={true}
                                    isDisabled={readOnly}
                                    value={getOrNull(profile?.schoolYears)}
                                    onChange={(value) => setProfile(profile => ({
                                        ...profile,
                                        schoolYears: value,
                                    }))}
                                />

                                <Form.Text className="sub-error">
                                    {t(errors['schoolYears'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                        }

                        <Col sm={12} lg={6} xl={6}>
                            <FormGroup error={errors['billingType']} changes={changes['billingType']} mandatory={true}>
                                <Form.Label>{t('billing_type')}</Form.Label>
                                <EnumDropdown
                                    t={t}
                                    placeholder={t('billing_type_ph')}
                                    options={EquipmentBillingType}
                                    isDisabled={readOnly}
                                    value={getOrNull(profile?.billingType)}
                                    onChange={useCallback((value) => setProfile(profile => ({
                                        ...profile,
                                        billingType: value,
                                    })), [setProfile])}
                                />

                                <Form.Text className="sub-error">
                                    {t(errors['billingType'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    </UserHasPermission>

                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['equipmentSupplierId']} changes={changes['equipmentSupplierId']}
                                   mandatory={true}>
                            <Form.Label>{t('equipment_supplier')}</Form.Label>
                            <SupplierDropdown
                                isDisabled={readOnly}
                                value={getOrNull(profile?.equipmentSupplierId)}
                                onChange={useCallback((value) => setProfile(profile => ({
                                    ...profile,
                                    equipmentSupplierId: value,
                                })), [setProfile])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['equipmentSupplierId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['maintenanceSupplierId']} changes={changes['maintenanceSupplierId']}>
                            <Form.Label>{t('maintenance_supplier')}</Form.Label>
                            <SupplierDropdown
                                isDisabled={readOnly}
                                value={getOrNull(profile?.maintenanceSupplierId)}
                                onChange={useCallback((value) => setProfile(profile => ({
                                    ...profile,
                                    maintenanceSupplierId: value,
                                })), [setProfile])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['maintenanceSupplierId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>


                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['procedureId']} changes={changes['procedureId']} mandatory={true}>
                            <Form.Label>{t('procedure')}</Form.Label>
                            <ProcedureDropdown
                                isDisabled={readOnly}
                                value={getOrNull(profile?.procedureId)}
                                onChange={useCallback((value) => setProfile(profile => ({
                                    ...profile,
                                    procedureId: value,
                                })), [setProfile])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['procedureId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                </Row>
            </Box>

            {!readOnly &&
            <div>
                <Row>
                    <Col className={"text-end"}>
                        {!create &&
                        <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                            {t("cancel")}
                        </Button>
                        }
                        <Button variant="primary" className="submit" disabled={readOnly}
                                onClick={() => onSubmit(profile)}>
                            {create ? t("create") : t("save_changes")}
                        </Button>
                    </Col>
                </Row>
            </div>
            }
        </Form>
    </div>
}

export default FormComponent;
