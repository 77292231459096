import React from "react";
import classnames from "classnames";

const MenuDropdownContentComponent = (props, ref) => {

    const {className} = props;

    return <ul ref={ref} className={classnames("menu-drop-down-content", className)}>
        {props.children}
    </ul>

}

const MenuDropdownContent = React.forwardRef(MenuDropdownContentComponent);
export default MenuDropdownContent;
