import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";
import React from "react";

const EquipmentDropdown = props => {

    return <SimpleListDropdown
        {...props}
        remotePath={"/equipments/search"}
        labelHandler={labelHandler}
    />
}

const labelHandler = (equipment) => {

    const baseLabel =  equipment.serialNumber  + " (" + equipment.profile?.name + ")";

    if (equipment.internalCode)
        return equipment.internalCode + " - " + baseLabel;
    else
        return baseLabel;

}

export default EquipmentDropdown;
