import {faCircleDot, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import locale from "app/ruleTemplates/actions/setBeneficiary/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import isDefined from "system/Utils/isDefined";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {Trans} from "react-i18next";
import localStudents from "app/beneficiaries/students/locales/locales";

const FormEditor = configuration => {

    const {t} = useTranslations("SetBeneficiaryRuleAction", locale);
    const {t: tStudents} = useTranslations('students', localStudents);

    return <div className={"form-action-editor"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faCircleDot}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {!isDefined(configuration.props.beneficiaryEquipmentStatus)
            ? <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />
            : <div className={"description"}>
                <Trans>
                    {t('form_description', {status: tStudents(configuration.props.beneficiaryEquipmentStatus)})}
                </Trans>
            </div>
        }

    </div>
}

export default FormEditor;
