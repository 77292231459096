import React, {useEffect, useState} from 'react'
import {useLoadingContext} from "layout/modules/Loading/LoadingStateContext";
import isFunction from "system/Utils/isFunction";
import classnames from "classnames";
import useDebounce from "system/System/useDebounce";
import './loading.scss'

const Loading = ({visible = false, localOnly = false}) => {

    const {loading, setLoading} = useLoadingContext();
    const [localVisible, setLocalVisible] = useState(false);

    const setDebounceInvisible = useDebounce(setLocalVisible, 150);

    useEffect(
        () => {
            if (isFunction(setLoading) && !localOnly) {
                setLoading(!!visible)
            }

            const localVisible = localOnly && !!visible;
            if (localVisible) {
                setLocalVisible(localVisible);
            } else {
                setDebounceInvisible(localVisible);
            }
        },
        [visible, setLoading, localOnly, setDebounceInvisible]
    );

    useEffect(
        () => {
            return () => {
                if (isFunction(setLoading) && !localOnly) {
                    setLoading(false)
                }
            }
        },
        // eslint-disable-next-line
        [localOnly]
    );

    if (setLoading && !localOnly)
        return <></>;
    else if (!loading && localVisible)
        return <LoadingComponent visible={visible}/>
    else return <></>
}


const LoadingComponent = ({visible = false, className}) => {

    return <div className={classnames("loading", className)} style={{display: visible ? 'flex' : 'none'}}>
        <div className="loader-container">
            <div className="loader"/>
        </div>
    </div>
}

export default Loading;
export {LoadingComponent};
