import {useRestConfig} from "system/Rest/RestfulProvider";
import {useCallback, useEffect, useMemo, useState} from "react";
import {buildURL} from "system/Rest/utils/URL";
import useDebounce from "system/System/useDebounce";
import isFunction from "system/Utils/isFunction";
import {generateResponseObject, isJsonContent} from "system/Rest/utils/ResponseObject";
import fetchBuilder from 'fetch-retry';
import {removeEmptyParameters} from "system/Objects/Objects";

const fetchRetry = fetchBuilder(window.fetch, { retries: 3 });

const useGet = props => {

    const {lazy, resolve, debounce: dt} = props;
    const [data, setData] = useState(null);

    const {loading, error, mutate} = useMutate(useMemo(
        () => (
            {
                verb: "GET",
                ...props
            }
        ),
        [props]
    ));

    const fetchData = useCallback(
        (...args) => {
            setData(undefined);
            return mutate.apply(this, args).then((data) => {
                if (resolve)
                    setData(resolve(data));
                else
                    setData(data);
            }).catch(e => {
            });
        },
        // eslint-disable-next-line
        [setData, mutate]
    );

    const fetchDebounce = useDebounce(fetchData, dt);

    const refetchData = useCallback(
        (props) => {
            if (dt)
                return fetchDebounce(undefined, props);
            else
                return fetchData(undefined, props);
        },
        [dt, fetchData, fetchDebounce]
    )

    useEffect(
        () => {
            if (!lazy) {
                if (dt)
                    fetchDebounce();
                else
                    fetchData();
            }
        },
        [lazy, dt, fetchData, fetchDebounce]
    );


    return {
        loading: loading,
        error: error,
        data: data,
        refetch: refetchData,
    }
}

const useMutate = props => {

    //const [idRand] = useState(getRandomString(5));
    const {verb, path, queryParams, localErrorOnly, debounceRequest = true, additionalHeaders = {}} = props;
    const {base, onError, requestOptions} = useRestConfig();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const fetchConfig = useMemo(
        () => ({
            ...requestOptions,
            method: verb,
            headers: removeEmptyParameters({
                ...requestOptions?.headers,
                'Content-Type': 'application/json',
                ...additionalHeaders,
            }),
            mode: 'cors',
            cache: 'no-store'
        }),
        // eslint-disable-next-line
        [requestOptions, verb]
    );

    const promise = useCallback(
        (body, props) => new Promise(function (resolve, reject) {

            const url = buildURL({
                base: base,
                path: path,
                queryParams: queryParams,
                ...props,
            });

            const skipBodyEncoding = fetchConfig['headers']['Content-Type'] !==  'application/json';

            setLoading(true);
            setError(undefined);

            fetchRetry(url.toString(), {
                ...fetchConfig,
                body: (body && !skipBodyEncoding) ? JSON.stringify(body) : body,
            })
                .then((response) => {

                    if (isJsonContent(response.headers.get("Content-Type"))) {
                        return response.json()
                            .then(json => generateResponseObject(response, json))
                            .catch(() => generateResponseObject(response))
                    } else {
                        return response.text()
                            .then(text => generateResponseObject(response, text))
                            .catch(() => generateResponseObject(response))
                    }
                })
                .then((response) => {
                    if (response?.ok) {
                        return response.data;
                    } else {
                        throw response;
                    }
                })
                .then(response => {
                    setLoading(false);
                    resolve(response ? response : {});
                })
                .catch(e => {

                    const error = (e instanceof TypeError) ? {
                        status: -1,
                        error: e.toString(),
                        data: {}
                    } : e;

                    if (!localErrorOnly && isFunction(onError)) {
                        onError(error);
                    }

                    setLoading(false);
                    setError(error);
                    reject(error);
                });
        }),
        [path, fetchConfig, queryParams, onError, base, setLoading, localErrorOnly]
    );

    const mutatePromise = useDebounce(promise, 250, true);

    return {
        mutate: debounceRequest ? mutatePromise : promise,
        loading: loading,
        error: error
    }
}


export {
    useGet,
    useMutate
}
