import React from 'react'
import TableIconButton from "./TableIconButton"
import locale from "layout/modules/TableAdvanced/locales/locales";
import classnames from "classnames";
import useTranslations from "system/Translations/UseTranslations";

const TableIconAdd = (props) => {

    const {visible = true, className, label, ...otherProps} = props;
    const {t} = useTranslations('tableAdvanced', locale);

    if (!visible) {
        return <></>;
    }

    return <TableIconButton
        {...otherProps}
        className={classnames("btn btn-primary", className)}
        label={label ?? t('button_add')}
    />

}

export default TableIconAdd;

