import {faCheckCircle, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import locale from "app/ruleTemplates/actions/validation/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import isDefined from "system/Utils/isDefined";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";

const FormEditor = configuration => {

    const {t} = useTranslations("ValidationRuleAction", locale);

    return <div className={"form-action-editor"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faCheckCircle}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {(!isDefined(configuration.props.validationEntityType))
            ? <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />
            : <div className={"description"}>
                {t('form_description', {validationEntity: t(configuration?.props.validationEntityType)})}
            </div>
        }

    </div>
}

export default FormEditor;
