import useKeyPress from "system/Acessibility/useKeyPress";
import {useEffect, useState} from "react";

const useKeyboardNavigation = (props) => {

    const {element, enable, handleFocus, selector, preservePosition = false, initIndex = -1} = props;

    const [index, setIndex] = useState(initIndex);
    const arrowUpPressed = useKeyPress('ArrowUp', !enable);
    const arrowDownPressed = useKeyPress('ArrowDown', !enable);
    const escapePressed = useKeyPress('Escape', !enable);

    const totalElements = element?.querySelectorAll(selector)?.length;

    useEffect(
        () => {
            if (!preservePosition)
                setIndex(initIndex)
        },
        [enable, preservePosition, initIndex]
    );

    useEffect(() => {
        if (arrowUpPressed && enable) {
            setIndex((index) => index !== 0 ? index - 1 : totalElements - 1,)
        }
        // eslint-disable-next-line
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed && enable) {
            setIndex((index) => index !== totalElements - 1 ? index + 1 : 0)
        }
        // eslint-disable-next-line
    }, [arrowDownPressed]);


    useEffect(
        () => {
            if (enable && index >= 0) {
                handleFocus(element?.querySelectorAll(selector)[index]);
            }
        },
        // eslint-disable-next-line
        [index]
    );

    return escapePressed;
}


export default useKeyboardNavigation
