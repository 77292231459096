import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import locale from "../locales/locales";
import Button from "react-bootstrap/Button";
import useTranslations from "system/Translations/UseTranslations";
import {useDateTime} from "system/DateTime/DateTime";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Box from "layout/modules/Box/Box";
import {useChildrenWithProps} from "system/Objects/Components";
import EntityLinkComponent from "app/entities/components/other/EntityLinkComponent";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import VersionDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/VersionDropdown";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import {Link} from "react-router-dom";

const VersionComponent = (props) => {

    const {t} = useTranslations('versions', locale);

    const {
        loading,
        version,
        versions,
        onChange,
        hideVersionSelection,
        hideVersionAuthor,
        onRevert,
        diffs,
        changes,
        error,
        excludeVersion,
        isVersionAvailableForEdit = () => true
    } = props;

    const getDate = useDateTime();


    return <Row>
        <Loading visible={loading}/>

        {!hideVersionSelection &&
        <Col sm={12} lg={12}>
            <FormGroup>
                <Form.Label>{t('version')}</Form.Label>
                <VersionDropdown
                    t={t}
                    onChange={onChange}
                    versions={versions}
                    excludeVersion={excludeVersion}
                />
            </FormGroup>
        </Col>
        }

        {error?.status === 404 &&
        <Col sm={12} lg={12}>
            <AdvanceInfoMessage
                icon={faExclamation}
                type={"danger"}
                iconType={"danger"}
                title={t("version_not_found")}
                message={t("version_not_found_msg")}
            />
        </Col>
        }

        {version &&
        <Col sm={12} lg={12}>
            <Box className={"compare-mode"} visible={!hideVersionAuthor}>
                <FormGroup>
                    <LabelWithValue label={t('author')}>

                        {version?.creationUser?.id &&
                        <Link to={"/user/" + version?.creationUser?.id}>
                            {version?.creationUser?.name}
                        </Link>
                        }

                    </LabelWithValue>
                </FormGroup>


                <FormGroup>

                    <LabelWithValue label={t('entity')}>
                        {version?.creationUser?.entity &&
                        <EntityLinkComponent
                            name={version?.creationUser?.entity}
                            id={version?.creationUser?.entityId}
                        />
                        }
                    </LabelWithValue>
                </FormGroup>

                <FormGroup>
                    <LabelWithValue label={t('date')}>
                        {getDate(version?.revisionTimestamp)}
                    </LabelWithValue>
                </FormGroup>

            </Box>
        </Col>
        }

        <Col sm={12} lg={12} className={"version"}>
            {useChildrenWithProps(props.children, {
                version: version,
                changes: changes,
                fullChanged: (version?.revisionType !== "UPDATE"),
                diffs: diffs
            })}
        </Col>

        {(version && isVersionAvailableForEdit(version?.entity)) &&
        <Col sm={12} xl={12} className={"version-button"}>
            <Button variant="primary" className={"submit"} onClick={() => onRevert(version?.entity)}>
                {t("use_version")}
            </Button>
        </Col>
        }

    </Row>

}

export default VersionComponent;
