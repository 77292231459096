export const pt = {


    name: "Não conformidade de beneficário",
    description: "Abertura de  uma não conformidade para beneficiário",

    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é o nome.",


    form_description: "Abertura de uma não conformidade para o beneficário com a identificação <strong>{{title}}</strong>.",

    title: "Identificação/descrição da não conformidade",
    title_ph: "Indique uma identificação ",


};

export default pt;
