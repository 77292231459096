import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faUserSlash} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/unsetBeneficiary/locales/locales";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";


class UnsetBeneficiaryRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.SCHOOL];
    static disableConfiguration = true;

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faUserSlash, t('name'), t('description'));

    static getName = () => "UnsetBeneficiaryRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = () => null;

    static isAllowed = (config, position) => ruleHasEquipments(config, position);

}

export default UnsetBeneficiaryRuleAction;
