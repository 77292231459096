import React, {useMemo} from "react";
import locale from "app/equipments/equipments/locales/locales"
import ListComponent from "app/equipments/equipments/components/list/ListComponent"
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import {useParams} from "react-router-dom";

const List = (props) => {

    const {id, selectionMode} = props;
    const {universalSearch} = useParams();

    const {
        beneficiaryIds, relatedEquipmentId, deliveryNoteNumber, equipmentSupplierIds, maintenanceSupplierIds, schoolIds,
        inWarranty, procedureIds, profileIds, readyToInvoice, statusIds, typologyIds, onlyCompatibleWithBeneficiaryIds,
        assigned, availableForSchool, availableForStudent, availableForTeacher, ids, forcePath,
        procedureNumber, contractNumber, commitmentNumber, useGet
    } = props;

    return <GenericListContainer
        {...props}
        usePost={!useGet}
        module="equipments"
        id={id ?? "equipment-list"}
        hideTitle={props?.hideTitle ?? selectionMode}
        searchPath={forcePath ?? "/equipments/search"}
        deletePath={"/equipments/"}
        locale={locale}
        queryParameters={useMemo(() => ({
                //sortProperty: 'name',
                //sortDirection: 'ASC',
                currentPage: 0,
                itemsPerPage: 10,
                ids: ids,
                beneficiaryIds: beneficiaryIds,
                relatedEquipmentId: relatedEquipmentId,
                deliveryNoteNumber: deliveryNoteNumber,
                equipmentSupplierIds: equipmentSupplierIds,
                maintenanceSupplierIds: maintenanceSupplierIds,
                inWarranty: inWarranty,
                procedureIds: procedureIds,
                profileIds: profileIds,
                readyToInvoice: readyToInvoice,
                statusIds: statusIds,
                schoolIds: schoolIds,
                typologyIds: typologyIds,
                onlyCompatibleWithBeneficiaryIds: onlyCompatibleWithBeneficiaryIds,
                assigned: assigned,
                availableForSchool: availableForSchool,
                availableForStudent: availableForStudent,
                availableForTeacher: availableForTeacher,
                procedureNumber: procedureNumber,
                contractNumber: contractNumber,
                commitmentNumber: commitmentNumber,
                universalSearch: universalSearch,
            }),
            [
                beneficiaryIds, relatedEquipmentId, deliveryNoteNumber, equipmentSupplierIds,
                maintenanceSupplierIds, inWarranty, profileIds, statusIds, typologyIds,
                onlyCompatibleWithBeneficiaryIds, procedureIds, readyToInvoice, schoolIds,
                assigned, availableForSchool, availableForStudent, availableForTeacher, ids,
                procedureNumber, contractNumber, commitmentNumber, universalSearch
            ])}>
        <ListComponent {...props}/>
    </GenericListContainer>

}

export default List;
