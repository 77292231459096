export const pt = {
    name: "Formulário",
    description: "Permite adicionar ao processo formulários que servem para recolher informação e documentos.",

    form_description: "Formulário com {{count}} campo(s). Os campos definidos são: {{labels}}",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração do formulário e valide se todos os campos incluem identificação",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

};

export default pt;
