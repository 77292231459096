import React from 'react'
import 'layout/modules/Box/assets/box.scss'
import 'layout/modules/Box/assets/box-responsive.scss'

const BoxTitle = (props) => {

    const { children , className = "" } = props;

    return <div {...props} className={"box-title " + className}>
        {children}
    </div>
}

export default BoxTitle;
