import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback, useMemo} from "react";
import SupplierDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SupplierDropdown";
import SchoolDropdown, {schoolLabelHandler} from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import EquipmentStatusDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentStatusDropdown";
import FormBeneficiary from "app/equipments/equipments/components/form/FormBeneficiary";
import {SchoolType} from "types/SchoolType";
import FormSchool from "app/equipments/equipments/components/form/FormSchool";
import EquipmentDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentDropdown";
import UserHasPermission, {UserHasNoPermission} from "system/API/Authentication/UserHasPermission";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";

const FormEquipmentData = props => {

    const {equipment, setEquipment, errors, readOnly} = props;
    const {t} = useTranslation('equipments');

    const isForBeneficiary = equipment.profile.availableForTeacher || equipment.profile.availableForStudent;

    const relatedEquipmentFilter = useMemo(
        () => ({
            procedureIds: equipment?.procedureId,
            schoolId: equipment?.schoolId,
        }),
        [equipment?.procedureId, equipment?.schoolId]
    );

    return <>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['serialNumber']} mandatory={true}>
                <Form.Label>{equipment.profile.idLabel}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('serial_number_ph', {name: equipment.profile.idLabel.toLowerCase()})}
                    value={getOrEmpty(equipment?.serialNumber)}
                    disabled={readOnly}
                    onChange={useCallback((e) => setEquipment(profile => ({
                        ...profile,
                        serialNumber: e.target.value,
                    })), [setEquipment])}
                />

                <Form.Text className="sub-error">
                    {t(errors['serialNumber'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['internalCode']} mandatory={false}>
                <Form.Label>{t('internal_code')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('internal_code_ph')}
                    value={getOrEmpty(equipment?.internalCode)}
                    disabled={readOnly}
                    onChange={useCallback((e) => setEquipment(profile => ({
                        ...profile,
                        internalCode: e.target.value,
                    })), [setEquipment])}
                />

                <Form.Text className="sub-error">
                    {t(errors['internalCode'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>

            <UserHasNoPermission permission={["READ_SCHOOL"]}>
                <LabelWithValue label={t('school')}
                                value={equipment?.school ? schoolLabelHandler(equipment.school) : null}/>
            </UserHasNoPermission>

            <UserHasPermission permission={["READ_SCHOOL"]}>
                <FormGroup error={errors['schoolId']} mandatory={true}>
                    <Form.Label>{t('school')}</Form.Label>
                    <SchoolDropdown
                        isDisabled={readOnly}
                        value={getOrNull(equipment?.schoolId)}
                        typologies={useMemo(() => [SchoolType.AE, SchoolType.ENA, SchoolType.EPCCA], [])}
                        onChange={useCallback((value) => setEquipment(profile => ({
                            ...profile,
                            schoolId: value,
                        })), [setEquipment])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['schoolId'])}
                    </Form.Text>
                </FormGroup>
            </UserHasPermission>
        </Col>

        {(!readOnly || equipment?.relatedEquipmentId) &&
            <Col sm={12} lg={6} xl={6}>
                <FormGroup error={errors['relatedEquipmentId']} mandatory={true}>
                    <Form.Label>{t('related_equipment')}</Form.Label>
                    <EquipmentDropdown
                        isDisabled={readOnly}
                        value={getOrNull(equipment?.relatedEquipmentId)}
                        filterExtraProps={relatedEquipmentFilter}
                        onChange={(value) => setEquipment(profile => ({
                            ...profile,
                            relatedEquipmentId: value,
                        }))}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['relatedEquipmentId'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        }


        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['statusId']} mandatory={true}>
                <Form.Label>{t('status')}</Form.Label>
                <EquipmentStatusDropdown
                    isDisabled={readOnly}
                    value={getOrNull(equipment?.statusId)}
                    onChange={useCallback((value) => setEquipment(profile => ({
                        ...profile,
                        statusId: value,
                    })), [setEquipment])}
                />

                <Form.Text className="sub-error">
                    {t(errors['statusId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        {(isForBeneficiary && ((readOnly && equipment?.beneficiaryId) || !readOnly)) &&
            <FormBeneficiary setEquipment={setEquipment} equipment={equipment} errors={errors} readOnly={readOnly}/>
        }

        {equipment.profile.availableForSchool && equipment?.schoolId &&
            <FormSchool setEquipment={setEquipment} equipment={equipment} errors={errors} readOnly={readOnly}/>
        }

        <UserHasPermission permission={["READ_SUPPLIER"]}>
            <Col sm={12} lg={6} xl={6}>
                <FormGroup error={errors['maintenanceSupplierId']}>
                    <Form.Label>{t('maintenance_supplier')}</Form.Label>
                    <SupplierDropdown
                        isDisabled={readOnly}
                        value={getOrNull(equipment?.maintenanceSupplierId)}
                        onChange={useCallback((value) => setEquipment(profile => ({
                            ...profile,
                            maintenanceSupplierId: value,
                        })), [setEquipment])}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['maintenanceSupplierId'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </UserHasPermission>

    </>

}
export default FormEquipmentData
