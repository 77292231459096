import {faHeadset, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import locale from "app/ruleTemplates/actions/supportTicket/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import {Trans} from "react-i18next";
import isDefined from "system/Utils/isDefined";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import localeValidation from "app/ruleTemplates/actions/validation/locales/locales";

const FormEditor = configuration => {

    const {t} = useTranslations("SupportTicketAction", locale);
    const {t: tValidation} = useTranslations("ValidationRuleAction", localeValidation);

    return <div className={"form-action-editor"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faHeadset}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {(!isDefined(configuration.props.categoryId) || !isDefined(configuration.props.destinationEntityType) ||
            !isDefined(configuration.props.message) || !isDefined(configuration.props.title))
            ? <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />
            : <div className={"description"}>
                <Trans>
                {t('form_description', {
                    ...configuration?.props,
                    destinationEntityType: tValidation(configuration?.props.destinationEntityType)})}
                </Trans>
            </div>
        }

    </div>
}

export default FormEditor;
