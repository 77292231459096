import EquipmentSelectionRuleAction from "app/ruleTemplates/actions/equipmentSelection/EquipmentSelectionRuleAction";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import UseBeneficiaryEquipmentsRuleAction
    from "app/ruleTemplates/actions/useBeneficiaryEquipments/UseBeneficiaryEquipmentsRuleAction";

const equipmentActions = [
    EquipmentSelectionRuleAction.getName(),
    UseBeneficiaryEquipmentsRuleAction.getName(),
];

const ruleHasEquipments = (rule, position) => rule.type === RuleType.EQUIPMENT || rule.actions
    .some((e, index) => equipmentActions.includes(e.component) && index < position);

export default ruleHasEquipments;
