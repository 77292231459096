import {useCallback} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import {useMutate} from "system/Rest/Rest";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import TransferComponent from "app/support/components/view/TransferComponent";
import useErrorParser from "system/API/Error/ErrorParser";

const TransferContainer = props => {

    const {ticket, reload, onClose} = props;
    const {t} = useTranslation("support");
    const showToast = useShowToast();


    const {mutate: transfer, loading: transferring, error} = useMutate({
        verb: "PUT",
        path: ticket?.id
            ? "/tickets/" + ticket.id + "/transfer"
            : "/tickets/transfer"
    });

    const errors = useErrorParser(error);

    const handleTransfer = useCallback(
        (request) => {
            transfer(request)
                .then((r) => {
                    showToast({
                        title: t('SUCCESS_TRANSFER'),
                        message: request?.ticketIds?.length > 1  ?
                            t('SUCCESS_TRANSFER_BULK_MSG', {count: request?.ticketIds?.length})
                            :t('SUCCESS_TRANSFER_MSG', r),
                        icon: faCheck,
                    });
                    onClose();
                    reload();

                })
                .catch((e) => handleUnknownFormError(e, showToast, t));

        },
        [transfer, showToast, reload, t, onClose]
    );


    return <TransferComponent
        {...props}
        onTransfer={handleTransfer}
        transferring={transferring}
        errors={errors}
    />

}

export default TransferContainer;
