//Get Value array from an array of objects


import getAsArray from "system/Arrays/getArray";
import {isString} from "system/Utils/isString";
import {isBoolean} from "system/Utils/isBoolean";

const getValuesArrayFromObjectsArray = (arrayObjects, isMulti, objectParameter = 'value') => {
    if (!arrayObjects)
        return isMulti ? [] : null;
    else if (Array.isArray(arrayObjects))
        return arrayObjects.map(option => option[objectParameter]);
    else
        return isMulti ? [arrayObjects[objectParameter]] : arrayObjects[objectParameter];
}

const getStringValuesArrayFromObjectsArray = (arrayObjects, isMulti, objectParameter) => {

    const r = parseParameterInString(getValuesArrayFromObjectsArray(arrayObjects, isMulti, objectParameter));
    if (r)
        return r;
    else return null;

}

const parseParameterInString = (arr) => {
    if (Array.isArray(arr)) {
        return arr.map(String);
    } else if (arr !== null && arr !== undefined) {
        return String(arr);
    } else return undefined;
}

const getSelectedOptions = (value, options, isMulti, loadValue = false) => {

    if (!options) {
        if (loadValue)
            return isMulti ? [getValueOption(value)] : getValueOption(value);
        else
            return null;
    }

    if (isValueEmpty(value, isMulti))
        return null;
    else
        return getSelectedOption(isMulti, options, value);
}

const isValueEmpty = (value, isMulti) => (value === null || (isMulti && getAsArray(value).length === 0))

const getSelectedOption = (isMulti, options, value) =>
    !isMulti
        ? options.find(element => String(value) === String(element.value))
        : options.filter(element => getAsArray(value).map(String).includes(element.value))


const getValueOption = (value) =>
    value
        ? {
            value: value,
            label: value,
        }
        : null;


const convertEnumToOptions = (opts, t) => {

    if (!opts)
        return null;

    return Object.entries(opts).filter(e => (isString(e[1]) || isBoolean(e[1]))).map((object) => ({
        label: t(object[0]),
        value: object[1]
    }))

}

const handleParameterAsValue = (s) => s;

export {
    getValuesArrayFromObjectsArray,
    getStringValuesArrayFromObjectsArray,
    parseParameterInString,
    getSelectedOptions,
    isValueEmpty,
    convertEnumToOptions,
    handleParameterAsValue
};
