import {faGraduationCap, faPerson, faPersonChalkboard, faSchool, faTruckField} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/forms/modules/dynamicSelects/locales/locales";
import generateToolBarEntry from "layout/modules/FormBuilder/modules/module/utils/generateToolBarEntry";
import FormModule from 'layout/modules/FormBuilder/modules/module/FormModule'
import EditorComponent from "app/ruleTemplates/actions/forms/modules/dynamicSelects/components/EditorComponent";
import ConfigComponent from "app/ruleTemplates/actions/forms/modules/dynamicSelects/components/ConfigComponent";
import FormComponent from "app/ruleTemplates/actions/forms/modules/dynamicSelects/components/FormComponent";
import SubModules from "app/ruleTemplates/actions/forms/modules/dynamicSelects/utils/SubModules";

class DynamicSelects extends FormModule {

    static getName = () => "DynamicSelects";
    static getTranslations = () => locale;
    static getToolBarEntry = (t) => [
        generateToolBarEntry(faSchool, t('school'), SubModules.SCHOOLS),
        generateToolBarEntry(faPerson, t('beneficiaries'), SubModules.BENEFICIARIES),
        generateToolBarEntry(faGraduationCap, t('student'), SubModules.STUDENTS),
        generateToolBarEntry(faPersonChalkboard, t('teacher'), SubModules.TEACHERS),
        generateToolBarEntry(faTruckField, t('supplier'), SubModules.SUPPLIERS),

    ];

    static renderEditor = (props) => <EditorComponent id={props.id} subModule={props.subModule} {...props.props}/>
    static renderConfig = (props) => <ConfigComponent  {...props} />
    static renderForm = (props) => <FormComponent {...props} />


    static getDefaultProps = () => ({
        label: null,
        mandatory: false,
    });
}

export default DynamicSelects;
