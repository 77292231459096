export const pt = {

    toolbar: "Componentes",
    editor: "Editor",

    remove_component: "Remover componente",
    configure_component: "Configurar componente",

    save_configuration: "Guardar alterações",
    close: "Fechar sem guardar",

    preview_form: "Pré-visualizar formulário",
    close_preview: "Fechar",

    label: "Identificação / Etiqueta",
    label_ph: "Indique uma identificação. (ex. Comentários)",

    mandatory: "Obrigatório",
    configuration_modal: "Configuração",

    editing: "Modo edição",
    view: "Modo de visualização",


    //Generic errors
    MISSING_VALUE: "Deve indicar um valor válido",


};

export default pt;
