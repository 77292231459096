export const pt = {
    entries_one: "Registo",
    entries_other: "Registos",
    of: "de",

    //Buttons
    show_table_as_list: "Ver em lista",
    show_table_as_grid: "Ver em grelha",
    button_add: "Adicionar",

    previous: "Anterior",
    next: "Seguinte",

    aria_description: "Corpo da tabela selecionado. Utilize as teclas para navegar entre as entradas da tabela",
    mobile_page_number: "Pág. {{currentPage}} de {{totalPages}}"
};

export default pt;
