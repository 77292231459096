import React, {useMemo} from "react";
import locale from "app/beneficiaries/students/locales/locales"
import ListComponent from "app/beneficiaries/students/components/list/ListComponent"
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import useTranslations from "system/Translations/UseTranslations";
import {useParams} from "react-router-dom";

const List = (props) => {

    const {id, selectionMode, showOld} = props;
    const {t} = useTranslations('student', locale);
    const {universalSearch} = useParams();

    const {
        equipmentStatus,
        schoolTypologies,
        schoolLevel,
        schoolYears,
        ranks,
        inDigitalManualPilot,
        onlyCompatibleWithEquipmentIds
    } = props;

    return <GenericListContainer
        hideTitle={!!selectionMode}
        module="student"
        title={showOld ? t('title_old') : t('title')}
        id={showOld ? "old-students" : "student" + id}
        locale={locale}
        searchPath={useMemo(
            () => showOld ? "/students/search-old" : "/students/search",
            [showOld]
        )}
        queryParameters={useMemo(
            () => ({
                currentPage: 0,
                itemsPerPage: 10,
                equipmentStatus: equipmentStatus,
                schoolTypologies: schoolTypologies,
                schoolLevel: schoolLevel,
                schoolYears: schoolYears,
                inDigitalManualPilot: inDigitalManualPilot,
                onlyCompatibleWithEquipmentIds: onlyCompatibleWithEquipmentIds,
                ranks: ranks,
                universalSearch: universalSearch,
            }),
            [equipmentStatus, schoolTypologies, schoolLevel, inDigitalManualPilot,
                ranks, schoolYears, onlyCompatibleWithEquipmentIds, universalSearch]
        )}>
        <ListComponent {...props}/>
    </GenericListContainer>

}

export default List;
