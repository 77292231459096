import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import {parseNumber} from "system/Utils/parseNumber";
import TicketStatus from "app/support/utils/TicketStatus";
import {getOrNull} from "system/Objects/ObjectParameters";
import TicketPriority from "app/support/utils/TicketPriority";
import GlobalEntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/GlobalEntityDropdown";
import UserDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/UserDropdown";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import TicketCategoryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/TicketCategoryDropdown";


const ListFilterComponent = (props) => {

    const {searchFilter, t} = props;
    const [filterState, setFilterState] = useState();

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);


    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('is_read')}</Form.Label>
                    <BooleanDropdown
                        id={"isRead"}
                        isMulti={false}
                        value={parseBoolean(filterState.isRead, null)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                isRead: val,
                            })
                        }}
                    />

                </FormGroup>
            </Col>


            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('status')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        placeholder={t('status_ph')}
                        id={"status"}
                        isMulti={true}
                        options={TicketStatus}
                        value={getOrNull(filterState.status)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                status: val,
                            })
                        }}
                    />

                </FormGroup>
            </Col>


            <UserHasPermission permission={"MANAGE_TICKETS"}>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('priority')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            id={"priorities"}
                            placeholder={t('select_ph')}
                            isMulti={true}
                            options={TicketPriority}
                            value={getOrNull(filterState.priorities)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    priorities: val,
                                })
                            }}
                        />

                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('category')}</Form.Label>
                        <TicketCategoryDropdown
                            id={"categories"}
                            isMulti={true}
                            value={getOrNull(filterState?.categories)}
                            isClearable={true}
                            onChange={(categories) => setFilterState(r => ({
                                ...r,
                                categories: categories
                            }))}
                        />


                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('open_by_entity')}</Form.Label>
                        <GlobalEntityDropdown
                            id={"creationEntityId"}
                            value={getOrNull(filterState?.creationEntityId)}
                            onlyRootEntities={true}
                            onChange={(value) => setFilterState(r => ({
                                ...r,
                                creationEntityId: value,
                                creationUserId: null,
                            }))}
                        />

                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('open_by_user')}</Form.Label>
                        <UserDropdown
                            id={"creationUserId"}
                            entityId={getOrNull(filterState?.creationEntityId)}
                            isDisabled={!filterState?.creationEntityId}
                            value={getOrNull(filterState?.creationUserId)}
                            onChange={(value) => setFilterState(r => ({
                                ...r,
                                creationUserId: value,
                            }))}
                        />

                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('destination_entity')}</Form.Label>
                        <GlobalEntityDropdown
                            id={"destinationEntityId"}
                            value={getOrNull(filterState?.destinationEntityId)}
                            onlyRootEntities={true}
                            onChange={(value) => setFilterState(r => ({
                                ...r,
                                destinationEntityId: value,
                                destinationUserId: null,
                            }))}
                        />

                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('destination_user')}</Form.Label>
                        <UserDropdown
                            id={"destinationUserId"}
                            entityId={getOrNull(filterState?.destinationEntityId)}
                            isDisabled={!filterState?.destinationEntityId}
                            value={getOrNull(filterState?.destinationUserId)}
                            onChange={(value) => setFilterState(r => ({
                                ...r,
                                destinationUserId: value,
                            }))}
                        />

                    </FormGroup>
                </Col>

            </UserHasPermission>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('from_date')}</Form.Label>
                    <DatePicker
                        placeholder={t("date_ph")}
                        name={"fromTimestamp"}
                        value={parseNumber(filterState.fromTimestamp, undefined)}
                        toDate={parseNumber(filterState.toTimestamp, undefined)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                fromTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('to_date')}</Form.Label>
                    <DatePicker
                        placeholder={t("date_ph")}
                        name={"toTimestamp"}
                        outputEndOfDayTimestamp={true}
                        value={parseNumber(filterState.toTimestamp, undefined)}
                        fromDate={parseNumber(filterState.fromTimestamp, undefined)}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                toTimestamp: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>

        </Row>
    </>
}

export default ListFilterComponent;
