import {cloneWithoutKey} from "system/Objects/Objects";
import {SchoolType} from "types/SchoolType";
import getAsArray from "system/Arrays/getArray";

const AggregatedInstitutionTypologies = cloneWithoutKey(SchoolType, [SchoolType.AE_SCHOOL]);

const getAggregatedInstitutionTypologiesValue = (value) => {

    if (getAsArray(value).includes(SchoolType.AE))
        return getAsArray(value).concat([SchoolType.AE_SCHOOL]);
    else
        return value;

}

export {
    AggregatedInstitutionTypologies,
    getAggregatedInstitutionTypologiesValue
}