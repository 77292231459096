export const pt = {

    audit: "Audit log",
    title: "Audit log",

    date: "Data",
    action: "Operação",
    object_type: "Tipo",
    affected_id: "Documento",
    impersonator_user: "Utilizador real",
    user: "Utilizador",
    cancel: "Fechar",
    to_date: "Data final",
    from_date: "Data inicial",
    date_ph: "Data no formato (dd-mm-yyyy)",
    ip: "Origem (IP)",

    //filter
    select_ph: "Selecione uma opção",
    no_results: "Sem resultados para apresentar",

    //export
    download_menu: "Opções para exportação",
    CSV: "Ficheiro CSV",
    XLSX: "Documento Excel (xlsx)",
    PDF: "Documento PDF",
    export_to: "Descarregar {{type}}",

    //View
    view_operation: "Detalhe operação",
    operation_detail: "Dados da operação",
    document_information: "Detalhe do documento",
    raw_objects: "Dados em bruto",
    changes: "Alterações",
    object: "Documento",

    //Object Types
    User: "Utilizador",
    UserRole: "Perf. utilizador",
    Entity: "Entidade",
    EntityProfile: "Perf. entidade",
    Configuration: "Configuração",
    HelpTopic: "Ajuda",
    Observation: "Observação",
    Report: "Relatório",
    ReportSchedule: "Relatório agendado",
    ReportTemplate: "Modelo de relatório",
    Operation: "Operação",
    OperationSchedule: "Opração agendada",
    OperationTemplate: "Modelo de operação",
    LabelValue: "Etiqueta",
    Document: "Documento",

    //Actions
    LOGIN: "Login",
    LOGIN_2FA: "Login 2FA",
    LOGOUT: "Logout",
    IMPERSONATE_USER: "Impersonate",
    SELF_USER_UPDATE: "Editar dados pessoais",
    SELF_PASSWORD_CHANGE: "Alterar password",
    ACTIVATION_CODE_VALIDATED: "Ativar conta ",


    SYSTEM_ROLE_CREATED: "Criar permissão",
    SYSTEM_ROLE_EDITED: "Editar permissão",
    SYSTEM_ROLE_DELETED: "Apagar permissão",

    USER_READ: "Ler utilizador",
    USER_CREATED: "Criar utilizador",
    USER_EDITED: "Editar utilizador",
    USER_DELETED: "Apagar utilizador",

    ENTITY_CREATED: "Criar entidade",
    ENTITY_EDITED: "Editar entidade",
    ENTITY_DELETED: "Apagar entidade",
    OWN_ENTITY_EDITED: "Editar a propria entidade",

    CONFIGURATION_EDITED: "Editar configuração",

    HELP_TOPIC_DELETED: "Apagar ajuda",
    HELP_TOPIC_EDITED: "Editar ajuda",

    OBSERVATION_CREATED: "Criar observação",
    OBSERVATION_DELETED: "Apagar observação",

    ENTITY_PROFILE_CREATED: "Criar perf. entidade",
    ENTITY_PROFILE_EDITED: "Editar perf. entidade",
    ENTITY_PROFILE_DELETED: "Apagar perf. entidade",

    REPORT_GENERATED: "Gerar relatório",
    REPORT_TEMPLATE_CREATED: "Criar template de relatório",
    REPORT_TEMPLATE_EDITED: "Editar template de relatório",
    REPORT_SCHEDULE_CREATED: "Criar agendamento de relatório",
    REPORT_SCHEDULE_EDITED: "Editar agendamento de relatório",
    REPORT_TEMPLATE_DELETED: "Apagar template de relatório",
    REPORT_SCHEDULE_DELETED: "Apagar agendamento de relatório",

    OPERATION_TEMPLATE_CREATED: "Criar template de operação",
    OPERATION_TEMPLATE_EDITED: "Editar template de operação",
    OPERATION_TEMPLATE_DELETED: "Apagar template de operação",
    OPERATION_SCHEDULE_CREATED: "Criar agendamento de operação",
    OPERATION_SCHEDULE_EDITED: "Editar agendamento de operação",
    OPERATION_SCHEDULE_DELETED: "Apagar agendamento de operação",
    OPERATION_RUN: "Executar operação",


    Teacher: "Docente",
    TEACHER_CREATED: "Criar docente",
    TEACHER_EDITED: "Editar docente",
    TEACHER_DELETED: "Apagar docente",

    Student: "Estudante",
    STUDENT_CREATED: "Criar estudante",
    STUDENT_EDITED: "Editar estudante",
    STUDENT_DELETED: "Apagar estudante",


    ContractModel: "Modelo de contrato",
    CONTRACT_MODEL_CREATED: "Criar modelo de contrato",
    CONTRACT_MODEL_EDITED: "Editar modelo de contrato",
    CONTRACT_MODEL_DELETED: "Apagar modelo de contrato",

    Contract: "Contrato",
    ContractAnnex: "Anexo contrato",
    CONTRACT_REFRESHED: "Atualizar contrato",
    CONTRACT_SIGNED: "Assinar contrato",
    CONTRACT_SIGNATURES_VALIDATED: "Validar contrato",
    CONTRACT_ANNEX_SIGNATURES_VALIDATED: "Validar anexo/listagem",
    CONTRACT_DELETED: "Apagar contrato",

    Equipment: "Equipamento",
    EQUIPMENT_CREATED: "Criar equipamento",
    EQUIPMENT_EDITED: "Editar equipamento",
    EQUIPMENT_DELETED: "Apagar equipamento",

    EquipmentProfile: "Perfil de equipamento",
    EQUIPMENT_PROFILE_CREATED: "Criar perfil de equipamento",
    EQUIPMENT_PROFILE_EDITED: "Editar perfil de equipamento",
    EQUIPMENT_PROFILE_DELETED: "Apagar perfil de equipamento",

    EquipmentTypology: "Tipologia de equipamento",
    EQUIPMENT_TYPOLOGY_CREATED: "Criar tipologia de equipamento",
    EQUIPMENT_TYPOLOGY_EDITED: "Editar tipologia de equipamento",
    EQUIPMENT_TYPOLOGY_DELETED: "Apagar tipologia de equipamento",

    EquipmentStatus: "Estado dos equipamentos",
    EQUIPMENT_STATUS_CREATED: "Criar estado do equipamento",
    EQUIPMENT_STATUS_EDITED: "Editar estado do equipamento",
    EQUIPMENT_STATUS_DELETED: "Apagar  estado do equipamento",

    Procedure: "Fase",
    PROCEDURE_CREATED: "Criar procedimento",
    PROCEDURE_EDITED: "Editar procedimento",
    PROCEDURE_DELETED: "Apagar procedimento",


    School: "Escola",
    SCHOOL_CREATED: "Criar escola",
    SCHOOL_EDITED: "Editar escola",
    SCHOOL_DELETED: "Apagar escola",

    Supplier: "Fornecedor",
    SUPPLIER_CREATED: "Criar fornecedor",
    SUPPLIER_EDITED: "Editar fornecedor",
    SUPPLIER_DELETED: "Apagar fornecedor",


    TicketCategory: "Categ. ticket",
    TICKET_CATEGORY_CREATED: "Criar categ. ticket",
    TICKET_CATEGORY_EDITED: "Editar  categ. ticket",
    TICKET_CATEGORY_DELETED: "Apagar  categ. ticket",

    TicketSla: "Sla ticket",
    TICKET_SLA_CREATED: "Criar sla ticket",
    TICKET_SLA_EDITED: "Editar sla ticket",
    TICKET_SLA_DELETED: "Apagar sla ticket",

    EquipmentRefusal: "Recusa de equipamento",
    EQUIPMENT_REFUSAL_CREATED: "Registo de recusa",
    EQUIPMENT_REFUSAL_REVOKED: "Cancelamento de recusa",

    EquipmentRegistration: "Registo equipamentos",
    EQUIPMENT_REGISTRATION_VALIDATED: "Validação",
    EQUIPMENT_REGISTRATION_SUBMIT: "Submissão excel",

    Guardian: "Enc. Educação",
    SchoolYear: "Ano Escolar",


    //documents
    DOCUMENT_DELETED: "Apagar documento",
    DOCUMENT_VALIDATED: "Documento validado",

    error_platform: "Neste momento não é possível obter os registos de operações."

};

export default pt;
