import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import getModuleFromEntry from "layout/modules/FormBuilder/utils/editor/getModuleFromEntry";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import "layout/modules/FormBuilder/assets/css/preview.scss";

const PreviewComponent = props => {

    const {t} = useTranslation("form-editor");
    const {modules, data, onClose} = props;

    const [formData, setFormData] = useState({});

    return <Modal show={true} onHide={() => onClose()} size={"xl"}>
        <Modal.Header closeButton>
            <Modal.Title>{t('preview_form')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Tabs defaultActiveKey="editing" id="tab-preview-form" unmountOnExit={false} mountOnEnter={false}>
                <Tab eventKey="editing" title={t('editing')}>
                    <div className={"form-editor-preview"}>
                        <Row>
                            {data.map((entry) => getModuleFromEntry(modules, entry).renderForm({
                                id: entry.id,
                                subModule: entry.subModule,
                                configuration: entry.props,
                                key: entry.id,
                                data: formData,
                                setData: setFormData
                            }))}
                        </Row>
                    </div>
                </Tab>

                <Tab eventKey="view" title={t('view')}>
                    <div className={"view-mode"}>
                        <div className={"form-editor-preview view-mode"}>
                            <Row className={""}>
                                {data.map((entry) => getModuleFromEntry(modules, entry).renderForm({
                                    id: entry.id,
                                    readOnly: true,
                                    subModule: entry.subModule,
                                    configuration: entry.props,
                                    key: entry.id,
                                    data: formData,
                                    setData: setFormData
                                }))}
                            </Row>
                        </div>
                    </div>

                </Tab>

            </Tabs>


        </Modal.Body>

        <Modal.Footer>

            <Button variant={'secondary'} onClick={onClose}>
                {t('close_preview')}
            </Button>


        </Modal.Footer>
    </Modal>
}

export default PreviewComponent;
