import SubModules from "app/ruleTemplates/actions/forms/modules/dynamicSelects/utils/SubModules";
import BeneficiaryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BeneficiaryDropdown";
import BeneficiaryType from "types/BeneficiaryType";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import React from "react";
import SupplierDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SupplierDropdown";

const DynamicSelect = ({type, ...otherProps}) => {

    switch (type) {
        case SubModules.BENEFICIARIES:
            return <BeneficiaryDropdown {...otherProps}/>;
        case SubModules.STUDENTS:
            return <BeneficiaryDropdown type={BeneficiaryType.STUDENT} {...otherProps}/>;
        case SubModules.TEACHERS:
            return <BeneficiaryDropdown type={BeneficiaryType.TEACHER} {...otherProps}/>;
        case SubModules.SCHOOLS:
            return <SchoolDropdown {...otherProps}/>;
        case SubModules.SUPPLIERS:
            return <SupplierDropdown {...otherProps}/>;
        default:
            return <></>
    }

}
export default DynamicSelect;
