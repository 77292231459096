import getAsArray from "system/Arrays/getArray";

const BeneficiaryStatusResolver = (data, t) => {

    if (getAsArray(data).length === 0)
        return data;

    const auxData = {};

    let total = 0
    data.forEach(e => {
        const key = e.rank ? e.type + "_" + e.rank : e.type;
        data = {
            ...auxData[key],
            type: t(e.type),
            rank: t(e.rank),
            [t(e.status)]: e.total,
            label: t(key),
        }
        auxData[key] = data;
        total += e.total;
    });

    return Object.keys(auxData).map(k => {

        const totalKey =
            (auxData[k][t('NO_EQUIPMENT')] ?? 0) +
            (auxData[k][t('WITH_EQUIPMENT')]  ?? 0) +
            (auxData[k][t('REFUSED')]  ?? 0)  +
            (auxData[k][t('PARTIAL_EQUIPMENT')]  ?? 0);

        return {
            ...auxData[k],
            total: totalKey,
            percentage: Math.round((totalKey / total) * 100 * 100) / 100
        }
    }).sort((a, b) => b.total - a.total)


}

export default BeneficiaryStatusResolver;