import {useGet} from "system/Rest/Rest";
import {useEffect} from "react";
import Loading from "layout/modules/Loading/Loading";
import fileDownload from "app/files/utils/download";

const DownloadContainer = (props) => {

    const {file, onDownloadComplete} = props;

    const {data, loading} = useGet({
        path: "/documents/" + file.id
    });


    useEffect(() => {
        if (!loading && data) {
            fileDownload(data.contents, data.filename, data.type);
            onDownloadComplete();
        }
    },[loading, data, onDownloadComplete])


    if (loading) {
        return <Loading visible={true}/>
    }

   return null;


}

export default DownloadContainer;
