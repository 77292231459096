import ToolTip from "layout/modules/ToolTip/ToolTip";
import Button from "react-bootstrap/Button";
import React, {useCallback} from "react";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple, faDownload, faList, faSliders} from "@fortawesome/free-solid-svg-icons";
import ChartMode from "app/home/utils/ChartMode";
import {CSVLink} from "react-csv";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import {useTranslation} from "react-i18next";

const ChartButtonsComponent = props => {

    const {setFilterVisible, isFilterVisible, chartMode, setChartMode, data, handleChartDownload} = props;
    const {t} = useTranslation('dashboard');

    const handleFilterVisibility = useCallback(() => setFilterVisible(isFilterVisible => !isFilterVisible), [setFilterVisible]);

    return <BoxOptions>

        {setFilterVisible &&
            <ToolTip content={t('filters')}>
                <Button
                    onClick={handleFilterVisibility}
                    className={classnames("small btn", {
                        "btn-rounded-secondary": !isFilterVisible,
                        "btn-rounded-primary": isFilterVisible,

                    })}>
                    <FontAwesomeIcon icon={faSliders}/>
                </Button>
            </ToolTip>
        }

        <ToolTip content={t(chartMode === ChartMode.CHART ? 'view_table' : 'view_chart')}>
            <Button
                onClick={useCallback(() => setChartMode(chartMode => chartMode === ChartMode.CHART ? ChartMode.TABLE : ChartMode.CHART), [setChartMode])}
                className={classnames("small btn", {
                    "btn-rounded-secondary": chartMode === ChartMode.CHART,
                    "btn-rounded-primary": chartMode === ChartMode.TABLE,
                })}>
                <FontAwesomeIcon icon={chartMode === ChartMode.CHART ? faList : faChartSimple}/>
            </Button>
        </ToolTip>

        {(chartMode === ChartMode.CHART && data?.length > 0) &&
            <ToolTip content={t('download_chart')}>
                <Button
                    onClick={handleChartDownload}
                    className={classnames("small btn btn-rounded-secondary")}>
                    <FontAwesomeIcon icon={faDownload}/>
                </Button>
            </ToolTip>
        }

        {(chartMode === ChartMode.TABLE && data?.length > 0) &&
            <ToolTip content={t('download_csv')}>
                <CSVLink data={data} filename={"ed-export.csv"}>
                    <Button
                        className={classnames("small btn btn-rounded-secondary")}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                </CSVLink>
            </ToolTip>
        }


    </BoxOptions>;

}

export default ChartButtonsComponent;