import getAsArray from "system/Arrays/getArray";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";

const UploadSupportedTypes = {
    "IMAGES": "IMAGES",
    "PDF": "PDF"
}

const generateAcceptTypes = (supportedTypes) => getAsArray(supportedTypes)
    .reduce((acceptTypes, supportedType) => {

            switch (supportedType) {
                case UploadSupportedTypes.IMAGES:
                    return {
                        ...acceptTypes,
                        ...acceptTypeImages
                    }
                case UploadSupportedTypes.PDF:
                    return {
                        ...acceptTypes,
                        ...acceptTypesPdf
                    }
                default:
                    return acceptTypes
            }
        },
        {}
    );

export {generateAcceptTypes};
export default UploadSupportedTypes;
