import React, {useMemo} from 'react'
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const GuardianDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "guardianId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder, t('generic_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('generic_loading'))}
        remotePath={"/guardians/search"}
        remoteClassPath={"/guardians/search"}
        labelHandler={(o) => getGuardianLabel(o, t)}
        styles={versionStyle}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
        }), [maxItems])}/>

}


const versionStyle = {
    option: (styles, {data}) => {
        return {
            ...styles,
            "fontStyle": !data?.vatNumber ? "italic" : undefined,
        };
    },
}


const getGuardianLabel = (o, t) => {

    const vatNumber = o['vatNumber'];
    const name = o["name"];

    if (!vatNumber && !name) {
        return o['id'] + " - " + t('NO_DATA');
    }

    return vatNumber + " - " + name;


}

export default GuardianDropdown;
