import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {Form} from "react-bootstrap";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import ReportCategoryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/reports/ReportCategoryDropdown";
import RoleDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RoleDropdown";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import OperationType from "app/operations/utils/OperationType";

const BaseTemplateFormComponent = props => {

    const {errors, t, template, setTemplate, readOnly} = props;

    return <Row>
        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['name']}>
                <Form.Label>{t('name')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('name_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(template?.name)}
                    onChange={useCallback((e) => setTemplate(template => ({
                        ...template,
                        name: e.target.value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['name'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['type']}>
                <Form.Label>{t('type')}</Form.Label>
                <EnumDropdown
                    t={t}
                    isDisabled={readOnly}
                    isMulti={false}
                    options={OperationType}
                    value={getOrNull(template?.type)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        type: value,
                    })), [setTemplate])}
                />

                <Form.Text className="sub-error">
                    {t(errors['type'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['async']}>
                <Form.Label>{t('async')}</Form.Label>
                <BooleanDropdown
                    isDisabled={readOnly}
                    value={parseBoolean(template?.async, null)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        async: value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['async'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['category']}>
                <Form.Label>{t('category')}</Form.Label>
                <ReportCategoryDropdown
                    t={t}
                    isOperation={true}
                    isCreatable={true}
                    isDisabled={readOnly}
                    value={getOrNull(template?.category)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        category: value,
                    })), [setTemplate])}
                />
                <Form.Text className="sub-error">
                    {t(errors['category'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['requiredRoleIds']}>
                <Form.Label>{t('roles')}</Form.Label>
                <RoleDropdown
                    isDisabled={readOnly}
                    isMulti={true}
                    value={getOrNull(template?.requiredRoleIds)}
                    onChange={useCallback((value) => setTemplate(template => ({
                        ...template,
                        requiredRoleIds: value,
                    })), [setTemplate])}
                />

                <Form.Text className="sub-error">
                    {t(errors['requiredRoleIds'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </Row>

}

export default BaseTemplateFormComponent;
