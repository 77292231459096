import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const EditorComponent = props => {

    const {id, setData, data, configuration,readOnly, errorMessage} = props;

    return <Col lg={6} sm={12}>
        <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
            <Form.Label>{configuration.label}</Form.Label>
            <FormControl
                type={configuration.type ?? 'text'}
                disabled={readOnly}
                autoComplete="chrome-off"
                placeholder={configuration.placeholder}
                value={getOrEmpty(data[id])}
                onChange={(e) => setData(data => ({
                    ...data,
                    [id]: e.target.value,
                }))}
            />
            <Form.Text className="sub-error">
                {errorMessage}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EditorComponent;
