import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useGet} from "system/Rest/Rest";
import equipmentResolver from "app/equipments/equipments/utils/equipmentResolver";
import useEquipmentWithDetails from "app/equipments/equipmentProfile/utils/useEquipmentWithDetails";
import {Link} from "react-router-dom";

const RelatedEquipmentAlertComponent = props => {

    const {equipment, selectionMode} = props;
    const {t} = useTranslation('equipments');

    const {data: relatedEquipment} = useGet({
        path: "/equipments/" + equipment?.relatedEquipmentId,
        resolve: equipmentResolver,
        localErrorOnly: true,
    });

    const {isReady, equipmentWithDetails} = useEquipmentWithDetails(relatedEquipment);

    if (!isReady)
        return <></>

    return <AdvanceInfoMessage
        icon={faWarning}
        type={"warning"}
        iconType={"warning"}
        title={t("related_equipment_title")}>

        <Trans>
            {
                selectionMode
                    ? <>
                        {t('selection_related_equipment_msg', equipmentWithDetails)}
                    </>
                    : <>{t('related_equipment_msg', equipmentWithDetails)}
                        <Link to={"/equipments/" + equipmentWithDetails.id}>
                            {t('related_equipment_msg_link')}
                        </Link>
                    </>
            }


        </Trans>


    </AdvanceInfoMessage>
}

export default RelatedEquipmentAlertComponent;
