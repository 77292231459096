import Row from "react-bootstrap/Row";
import React from "react";
import BasicConfigParameters from "layout/modules/FormBuilder/modules/module/components/BasicConfigParameters";

const ConfigComponent = props => {

    return <Row>
        <BasicConfigParameters {...props}/>
    </Row>
}

export default ConfigComponent;
