import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import Row from "react-bootstrap/Row";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import RankLevel from "types/RankLevel";
import GuardianDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/student/GuardianDropdown";
import SchoolLevelDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/student/SchoolLevelDropdown";
import StudentSchoolYearDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/student/StudentSchoolYearDropdown";
import SchoolClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/student/SchoolClassDropdown";
import getAsArray from "system/Arrays/getArray";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import BeneficiaryEquipmentStatus from "app/beneficiaries/beneficiary/utils/BeneficiaryEquipmentStatus";
import isFunction from "system/Utils/isFunction";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import {SchoolType} from "types/SchoolType";
import useTranslations from "system/Translations/UseTranslations";
import localSchools from "app/schools/locales/locales";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";

const ListFilterComponent = (props) => {

    const {searchFilter, t, onFilterChange, readOnly, hideAttributions = false, lockedFilters = {}} = props;
    const [filterState, setFilterState] = useState(searchFilter);

    const {t: tSchools} = useTranslations('schools', localSchools);

    useEffect(() => {
        if (!isFunction(onFilterChange)) {
            setFilterState({
                ...searchFilter
            });
        }
    }, [searchFilter, onFilterChange]);


    useEffect(() => {
            if (isFunction(onFilterChange) && filterState !== searchFilter) {
                onFilterChange(filterState);
            }
        },
        // eslint-disable-next-line
        [filterState, onFilterChange]
    );


    if (!filterState) {
        return <></>;
    }

    return <>

        <Row>
            <UserHasPermission permission={["READ_SCHOOL"]}>

                {isNotDefinedNorEmpty(lockedFilters['schoolTypologies']) &&
                    <Col sm={12} lg={6}>
                        <FormGroup>
                            <Form.Label>{t('typology')}</Form.Label>
                            <EnumDropdown
                                t={tSchools}
                                isMulti={true}
                                id={"schoolTypologies"}
                                options={SchoolType}
                                isDisabled={readOnly}
                                value={getOrNull(filterState?.schoolTypologies)}
                                placeholder={tSchools('typology_ph')}
                                onChange={(val) => setFilterState((filterState) => ({
                                    ...filterState,
                                    schoolTypologies: val,
                                }))}
                            />
                        </FormGroup>
                    </Col>
                }

                {(!hideAttributions && isNotDefinedNorEmpty(lockedFilters['schoolIds'])) &&

                    <>
                        {isNotDefinedNorEmpty(filterState?.schoolTypologies)
                            ? <SchoolFilterComponent
                                value={filterState?.schoolIds}
                                isDisabled={readOnly}
                                id={'schoolIds'}
                                isMulti={true}
                                setValue={(value) => setFilterState(state => ({
                                    ...state, schoolIds: value,
                                    schoolClass: undefined,
                                }))}
                            />
                            : <Col sm={12} lg={6}>
                                <FormGroup>
                                    <Form.Label>{t('school_or_group')}</Form.Label>
                                    <SchoolDropdown
                                        isMulti={true}
                                        typologies={getOrNull(filterState?.schoolTypologies)}
                                        id={"schoolIds"}
                                        isDisabled={readOnly}
                                        value={getOrNull(filterState?.schoolIds)}
                                        onChange={(val) => setFilterState((filterState) => ({
                                            ...filterState,
                                            schoolIds: val,
                                            schoolClass: undefined,
                                        }))}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </>
                }
            </UserHasPermission>

            {isNotDefinedNorEmpty(lockedFilters['ranks']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('rank')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            id={"ranks"}
                            isMulti={true}
                            isDisabled={readOnly}
                            options={RankLevel}
                            value={getOrNull(filterState?.ranks)}
                            placeholder={t('rank_ph')}
                            onChange={(val) => setFilterState(filterState => ({
                                ...filterState,
                                ranks: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['schoolLevel']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('school_level')}</Form.Label>
                        <SchoolLevelDropdown
                            id={"schoolLevel"}
                            isDisabled={readOnly}
                            value={getOrNull(filterState?.schoolLevel)}
                            onChange={(val) => setFilterState(filterState => ({
                                ...filterState,
                                schoolLevel: val,
                                schoolYear: null
                            }))}
                        />
                    </FormGroup>
                </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['schoolYears']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('school_year')}</Form.Label>
                        <StudentSchoolYearDropdown
                            isMulti={true}
                            id={"schoolYears"}
                            isDisabled={readOnly}
                            schoolLevel={getOrNull(filterState?.schoolLevel)}
                            value={getOrNull(filterState?.schoolYears)}
                            onChange={(val) => setFilterState(filterState => ({
                                ...filterState,
                                schoolYears: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }

            {(!hideAttributions && isNotDefinedNorEmpty(lockedFilters['schoolClass'])) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('school_class')}</Form.Label>
                        <SchoolClassDropdown
                            id={"schoolClass"}
                            schoolId={filterState?.schoolIds}
                            isDisabled={readOnly || getAsArray(filterState?.schoolIds).length === 0}
                            placeholder={!filterState?.schoolIds ? t("select_entity_required") : undefined}
                            value={getOrNull(filterState.schoolClass)}
                            onChange={(val) => setFilterState(filterState => ({
                                ...filterState,
                                schoolClass: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }

            {(isNotDefinedNorEmpty(lockedFilters['guardianIds']) && !hideAttributions) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('guardian')}</Form.Label>
                        <GuardianDropdown
                            id={"guardianIds"}
                            isMulti={true}
                            options={RankLevel}
                            isDisabled={readOnly}
                            value={getOrNull(filterState?.guardianIds)}
                            onChange={(val) => setFilterState(filterState => ({
                                ...filterState,
                                guardianIds: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['equipmentStatus']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('equipment_status')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            options={BeneficiaryEquipmentStatus}
                            id={"equipmentStatus"}
                            isDisabled={readOnly}
                            value={getOrNull(filterState?.equipmentStatus)}
                            onChange={(val) => setFilterState((filterState) => ({
                                ...filterState,
                                equipmentStatus: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }


            {isNotDefinedNorEmpty((lockedFilters['inDigitalManualPilot'])) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('in_digital_manual_pilot')}</Form.Label>
                        <BooleanDropdown
                            isClearable={true}
                            id={"inDigitalManualPilot"}
                            autoComplete="chrome-off"
                            placeholder={t('generic_ph')}
                            value={parseBoolean(filterState?.inDigitalManualPilot)}
                            isDisabled={readOnly}
                            onChange={(val) => setFilterState((filterState) => ({
                                ...filterState,
                                inDigitalManualPilot: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
            }


        </Row>

    </>
}

export default ListFilterComponent;
