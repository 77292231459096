const generateSession = (response, extraProps) => {

    return {
        ...extraProps,
        isAuthenticated: true,
        type: response.type,
        id: response.id,
        entity: response.entity,
        legalWarningReadTimestamp: response.legalWarningReadTimestamp,
        entitiesAccessList: response.entitiesAccessList,
        role: response.role,
        entityProfile: response?.entity?.profile,
        timeZone: response.timeZone,
        language: response.language,
        user: response.email,
        name: response.name,
        session: {
            validity: response.sessionValidity,
            token: response.sessionToken
        }
    };
}

const generateTwoFactorSession = (props, type) => {
    return {
        ...props,
        type: type,
        twoFactorAuthenticationRequired: true,
        twoFactorValidity: Date.now() + 5 * 60 * 1000,
    }
}

export {generateTwoFactorSession};
export default generateSession;
