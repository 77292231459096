import React, {useCallback, useState} from 'react'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import SearchControl from "./components/SearchControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import SavedSearch from "layout/modules/SearchArea/components/savedSearches/SavedSearch";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/SearchArea/locales/locales";
import 'layout/modules/SearchArea/assets/css/search-area.scss'
import 'layout/modules/SearchArea/assets/css/search-area-responsive.scss'
import {Button} from "react-bootstrap";

const SearchArea = (props) => {

    const {hideInput, value, onSearch, children, liveSearch, onSubmit, disableSaving, ...otherProps} = props;

    const {t} = useTranslations('searchArea', locale);
    const [activeFilters, setActiveFilters] = useState({});
    const isSearchFilterPresent = (!!children?.type);
    const [isOpen, setOpen] = useState(false);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                onSearch(e.target.value)
            }
        },
        [onSearch]
    );

    const handleBlur = useCallback(
        (e) => {
            onSearch(e.target.value)
        },
        [onSearch]
    );

    const handleSearch = useCallback(
        (e) => {
            if (liveSearch) {
                onSearch(e.target.value);
            } else if (e.target.value === "") {
                onSearch("");
            }
        },
        [onSearch, liveSearch]
    );

    const handleSubmit = useCallback(
        (filters) => {
            onSubmit(filters);
            setActiveFilters(filters);
        },
        [onSubmit]
    );

    return <div className="search-area">
        <div className="search-area-bar">

            {!hideInput &&
            <SearchControl
                {...otherProps}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onChange={handleSearch}
                defaultValue={getOrEmpty(value)}
            />
            }

            {isSearchFilterPresent &&
            <>
                <ToolTip content={t('filter')}>
                    <Button
                        aria-expanded={isOpen}
                        aria-pressed={isOpen}
                        aria-label={t('filter')}
                        className={isOpen ? "btn btn-rounded-primary active" : "btn btn-rounded-primary"}
                        onClick={() => setOpen(!isOpen)}
                    >
                        <FontAwesomeIcon icon={faFilter}/>
                    </Button>
                </ToolTip>
                <SavedSearch disableSaving={disableSaving} activeFilters={activeFilters} onSubmit={handleSubmit} isFilterOpen={isOpen}/>
            </>
            }

        </div>

        {isSearchFilterPresent && React.cloneElement(children, {
                isOpen: isOpen,
                activeFilters: activeFilters,
                onClose: () => setOpen(false),
                onSubmit: handleSubmit,
            }
        )}

    </div>

}

export default SearchArea;
