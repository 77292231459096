import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/locale/locales";
import ShowExecutionComponent from "app/ruleTemplates/components/show/ShowExecutionComponent";
import "app/ruleTemplates/assets/css/rule-view.scss";

const ExecutionModalComponent = props => {

    const {onClose, execution} = props;
    const {template} = execution;

    const {t} = useTranslations('rule-template', locale);


    return <Modal show={true} onHide={onClose} backdrop={false} size={"xl"} className={"execute-view-modal"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{template.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ShowExecutionComponent {...props}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant={'secondary'} onClick={onClose}>
                {t('close')}
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ExecutionModalComponent;
