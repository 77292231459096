const ChartDataSetType = {

    KITS: "1,2,3",
    VIDEO: "12",
    DESKTOP: "13",
    LED: "19",

}

export default ChartDataSetType;
