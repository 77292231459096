import Row from "react-bootstrap/Row";
import React, {useCallback} from "react";
import BasicConfigParameters from "layout/modules/FormBuilder/modules/module/components/BasicConfigParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Col from "react-bootstrap/Col";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/upload/locales/locales";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import UploadSupportedTypes from "layout/modules/FormBuilder/modules/upload/utils/UploadSupportedTypes";

const ConfigComponent = props => {

    const {configuration, setConfiguration} = props;
    const {t} = useTranslations('UploadModule', locale);

    return <Row>
        <BasicConfigParameters {...props}/>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup>
                <Form.Label>{t('max_files')}</Form.Label>
                <FormControl
                    type="number"
                    value={getOrEmpty(configuration?.maxFiles)}
                    autoComplete="chrome-off"
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            maxFiles: e.target.value > 0 ? parseInt(e.target.value) : 1,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('accept_types')}</Form.Label>
                <EnumDropdown
                    isMulti={true}
                    t={t}
                    placeholder={t('select_accept_types')}
                    options={UploadSupportedTypes}
                    value={getOrEmpty(configuration?.acceptTypes)}
                    autoComplete="chrome-off"
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            acceptTypes: value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={12} xl={12}>
            <FormGroup>
                <Form.Label>{t('description_info')}</Form.Label>
                <Form.Control
                    type="textarea"
                    as="textarea"
                    rows={3}
                    value={getOrEmpty(configuration.descriptionInfo)}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            descriptionInfo: e.target.value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>


    </Row>
}

export default ConfigComponent;
