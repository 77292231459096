import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import React, {useMemo} from "react";

const TeacherBondStatusDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "bondStatusId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder, t('teacher_bond_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('generic_loading'))}
        remotePath={"/bond-status/search"}
        remoteClassPath={"/bond-status/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
        }), [maxItems])}/>
}

export default TeacherBondStatusDropdown;
