import {faFileInvoice, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useMemo} from "react";
import locale from "app/ruleTemplates/actions/forms/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {getOrDefault} from "system/Objects/ObjectParameters";

const FormEditor = props => {

    const labels = useMemo(
        () => props.props?.formModel?.map(prop => getOrDefault(prop.props.label,prop.props.title)),
        [props.props]
    );

    const {t} = useTranslations("FormRuleAction", locale);

    return <div className={"form-action-editor"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faFileInvoice}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {(labels.length === 0 || labels.some(a => a === null || a === undefined))
            ? <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />
            : <div className={"description"}>
                {t('form_description', {count: labels.length, labels: labels.join(", ")})}
            </div>
        }

    </div>
}

export default FormEditor;
