const equipmentResolver = data => ({
    ...data,
    equipmentSupplierId: data.equipmentSupplier?.id,
    maintenanceSupplierId: data.maintenanceSupplier?.id,
    procedureId: data.procedure?.id,
    schoolId: data?.school?.id,
    beneficiaryId: data?.beneficiary?.id,
    profileId: data?.profile?.id,
    beneficiaryType: data?.beneficiary?.type,
    statusId: data?.status?.id,
    installationSchoolId: data?.installationSchool?.id,
    relatedEquipmentId: data?.relatedEquipment?.id !== data?.id ? data?.relatedEquipment?.id : undefined,
    isVirtual: data?.relatedEquipment?.id  === data?.id,

});

const equipmentLogResolver = data => ({
    ...data,
    maintenanceSupplierId: data.maintenanceSupplier,
    profileId: data.profile,
    schoolId: data.school,
    statusId: data.status,
    installationSchoolId: data.installationSchool,
    //relatedEquipmentId: data?.relatedEquipment


});

export {equipmentLogResolver};
export default equipmentResolver;
