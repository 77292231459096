import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Loading from "layout/modules/Loading/Loading";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Form from "react-bootstrap/Form";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import ContractModelType from "app/procedures/contractModel/utils/ContractModelType";
import Tabs from "layout/modules/Tabs/Tabs";
import ContractModelBodyType from "app/procedures/contractModel/utils/ContractModelBodyType";
import Tab from "layout/modules/Tabs/Tab";
import GenerateContractBody, {
    GetBodyFromType,
    SetBodyFromType
} from "app/procedures/contractModel/utils/GenerateContractBody";
import FormBodyComponent from "app/procedures/contractModel/components/form/FormBodyComponent";
import "app/procedures/contractModel/assets/css/form.scss";
import HeaderAndBottomComponent from "app/procedures/contractModel/components/form/HeaderAndBottomComponent";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import VariableComponent from "app/procedures/contractModel/components/form/VariableComponent";
import getNested from "system/Objects/getNested";
import Audit from "app/audit/Audit";
import {ContractModelActions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import ContractModelVersionContainer from "app/procedures/contractModel/containers/ContractModelVersionContainer";

const FormComponent = props => {

    const {
        model: remoteModel,
        loading = true,
        onSubmit,
        create = false,
        errors = {},
        viewMode,
        compareMode,
    } = props;

    const {t} = useTranslation('contractModel');

    const [model, setModel] = useState(remoteModel);

    const [readOnly, setReadOnly] = useState(viewMode || compareMode);
    const [versionsVisible, setVersionsVisible] = useState(false);

    useEffect(() => {
        if (model === null)
            setModel(remoteModel);
    }, [remoteModel, model, setModel]);


    const handleVariablesChange = useCallback(
        (fnc) => setModel((data) => ({
            ...data,
            variables: fnc(data.variables),
        })),
        [setModel]
    );


    return <div className={classnames("form-contract-model limited-width", {
        "view-mode": (readOnly && viewMode),
        "compare-mode": compareMode,
    })}>


        <Loading visible={loading}/>

        {viewMode &&
        <ContractModelVersionContainer
            id={model?.id}
            title={t('compare_models')}
            visible={versionsVisible}
            onClose={() => setVersionsVisible(false)}
            onRevert={(model) => {
                setReadOnly(false);
                setVersionsVisible(false);
                setModel({
                    ...model
                });
            }}
        />
        }

        {!compareMode &&
        <>
            <GenericFormError t={t} errors={errors}/>
            <FormHandleNotSaved
                remote={remoteModel}
                local={model}
                loading={loading}
                readOnly={readOnly}
                setLocal={setModel}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}
            />
        </>

        }

        <Form autoComplete="chrome-off" onSubmit={(e) => e.preventDefault()}>
            <Box>
                <BoxTitle>{t('model_data')}</BoxTitle>

                <BoxOptions visible={!create && !compareMode}>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            objectId={model.id}
                            filterActions={{
                                ...ContractModelActions,
                            }}
                            affectedObjectType={[
                                AuditObjectTypes.ContractModel
                            ]}
                        />
                    </UserHasPermission>

                    {/**
                     <UserHasPermission permission="READ_VERSIONS">
                     <ToolTip content={t('compare_models')}>
                     <Button className={classnames("btn btn-rounded-primary small", {"active": versionsVisible})}
                     onClick={() => setVersionsVisible(true)}>
                     <FontAwesomeIcon icon={faCodeBranch}/>
                     </Button>
                     </ToolTip>
                     </UserHasPermission>
                     **/}


                    <Button
                        onClick={() => setReadOnly(!readOnly)}
                        className={classnames("btn btn-rounded-danger small", {"active": !readOnly})}
                    >
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </Button>
                </BoxOptions>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['type']} mandatory={true}>
                            <Form.Label>{t('type')}</Form.Label>
                            <EnumDropdown
                                t={t}
                                options={ContractModelType}
                                value={getOrEmpty(model?.type)}
                                isDisabled={!create}
                                onChange={useCallback(
                                    (value) => setModel({
                                        type: value,
                                        ...GenerateContractBody(value)
                                    }), [setModel]
                                )}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['type'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>


                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['name']} mandatory={true}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                placeholder={t('name_ph')}
                                value={getOrEmpty(model?.name)}
                                disabled={readOnly}
                                onChange={useCallback((e) => setModel(model => ({
                                    ...model,
                                    name: e.target.value,
                                })), [setModel])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={12} xl={12}>
                        <FormGroup error={errors['description']}>
                            <Form.Label>{t('description')}</Form.Label>
                            <FormControl
                                type="text"
                                placeholder={t('description_ph')}
                                value={getOrEmpty(model?.description)}
                                disabled={readOnly}
                                onChange={useCallback((e) => setModel(model => ({
                                    ...model,
                                    description: e.target.value,
                                })), [setModel])}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['description'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                </Row>
            </Box>

            {(model?.type === ContractModelType.BENEFICIARY) &&
            <Box visible={model?.variables?.length > 0 || !readOnly}>
                <BoxTitle>{t('variables')}</BoxTitle>
                <MultiOptionComponent
                    data={model?.variables}
                    errors={errors?.variables}
                    readOnly={readOnly}
                    onChange={handleVariablesChange}>
                    <VariableComponent/>
                </MultiOptionComponent>

            </Box>
            }

            {(!readOnly && model?.type) &&
            <Box className={classnames("body-box", {"error": errors['bodies']})}>
                <BoxTitle>{t('bodies')}</BoxTitle>

                <Tabs id="tab-bodies" unmountOnExit={false} mountOnEnter={false}>

                    {Object.keys(ContractModelBodyType.from(model?.type)).map((type, index) => {
                            const localErrors = getNested(errors, 'bodies', index);

                            if (readOnly && !GetBodyFromType(model, type)?.body)
                                return null;
                            else {
                                return <Tab eventKey={type} title={t(type)} key={type}>

                                    <FormBodyComponent
                                        errors={localErrors}
                                        id={type}
                                        value={getOrEmpty(GetBodyFromType(model, type))}
                                        disabled={readOnly}
                                        onChange={(html) => setModel(model => ({
                                            ...model,
                                            bodies: SetBodyFromType(model, type, {
                                                ...GetBodyFromType(model, type),
                                                body: html
                                            }),
                                        }))}
                                    />

                                    <HeaderAndBottomComponent
                                        id={type}
                                        value={GetBodyFromType(model, type)}
                                        disabled={readOnly}
                                        onChange={(params) => setModel(model => ({
                                            ...model,
                                            bodies: SetBodyFromType(model, type, {
                                                ...GetBodyFromType(model, type),
                                                ...params
                                            }),
                                        }))}
                                    />

                                </Tab>
                            }

                        }
                    )}

                </Tabs>
            </Box>


            }


            {!readOnly &&
            <div>
                <Row>
                    <Col className={"text-end"}>
                        {!create &&
                        <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                            {t("cancel")}
                        </Button>
                        }
                        <Button variant="primary" className="submit" disabled={readOnly}
                                onClick={() => onSubmit(model)}>
                            {create ? t("create") : t("save_changes")}
                        </Button>
                    </Col>
                </Row>
            </div>
            }
        </Form>
    </div>
}

export default FormComponent;
