import React, {useMemo} from "react";
import ListComponent from "app/audit/components/ListComponent";
import locale from "app/audit/locales/locales";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const ListContainer = (props) => {

    const {id = "audit", objectId, userId, affectedObjectType, actions, objectType, hideTitle=true} = props;

    return <GenericListContainer
        id={id}
        module="audit"
        searchPath={"/audit/search"}
        hideTitle={hideTitle}
        locale={locale}
        queryParameters={useMemo(() => ({
            objectId: affectedObjectType ? undefined : objectId,
            affectedObjectType: affectedObjectType,
            affectedObjectId: affectedObjectType ? objectId : undefined,
            objectType: objectType,
            actions: actions,
            userIds: userId ? [userId] : undefined,
            sortProperty: 'timestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 15,
        }), [objectId, userId, actions, affectedObjectType, objectType])}>

        <ListComponent
            {...props}
        />

    </GenericListContainer>
}

export default ListContainer;
