import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faSchoolCircleCheck} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/setSchool/locales/locales";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasSchools from "app/ruleTemplates/utils/config/ruleHasSchools";


class SetSchoolRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.BENEFICIARY];
    static disableConfiguration = true;

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faSchoolCircleCheck, t('name'), t('description'));

    static getName = () => "SetSchoolRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = () => null;

    static isAllowed = (config, position) => ruleHasEquipments(config, position) && ruleHasSchools(config, position);

}

export default SetSchoolRuleAction;
