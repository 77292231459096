const ChartColors = ["#2BCCAF", "#FBB845", "var(--color-14)", "var(--color-102)"];

const ChartFreshColors = [
    "#2EA1DA",
    "#00BBDF",
    "#00D3CE",
    "#4BE5AE",
    "#A6F389",
    "#F9F871",
    "#4D8FD6",
    "#6E7ACA",
    "#8963B4",
    "#9C4A95",
    "#A52F71",

]

const getChartRandomColor = (color) => {
    let p = 1,
        temp,
        random = Math.random(),
        result = '#';

    while (p < color.length) {
        temp = parseInt(color.slice(p, p += 2), 16)
        temp += Math.floor((255 - temp) * random);
        result += temp.toString(16).padStart(2, '0');
    }
    return result;
}

export {ChartColors, ChartFreshColors, getChartRandomColor};