import ResultComponent from "app/reporting/components/generate/ResultComponent";
import {useMutate} from "system/Rest/Rest";
import {useCallback, useState} from "react";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {useTranslation} from "react-i18next";
import fileDownload from "app/files/utils/download";
import ResultStatsComponent from "app/reporting/components/generate/ResultStatsComponent";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const GenerateResultContainer = props => {

    const showToast = useShowToast();
    const {t} = useTranslation("reports");
    const {request, result} = props;

    const [originalRequest] = useState(request);


    const {mutate: downloadZip, loading: loadingZip} = useMutate({
        verb: "POST",
        path: "/documents/get-zip",
    });

    const {mutate: submit, loading} = useMutate({
        verb: "POST",
        path: "/reports/generate-report",
    });

    const handleDownload = useCallback(
        (format) => {
            submit({
                ...originalRequest,
                format: format,
            }).then((result) => {
                fileDownload(result.exportData, result.exportFilename);
            }).catch(error => handleUnknownFormError(error, showToast, t))

        },
        [submit, showToast, t, originalRequest]
    );

    const handleDownloadFiles = useCallback(
        (fileIds) => {
            downloadZip({
                documentIds: fileIds
            }).then(r => {
                showToast({
                    title: t('SUCCESS_ZIP_REQUEST'),
                    message: t('SUCCESS_ZIP_REQUEST_MSG'),
                    icon: faDownload,
                });
            })
        },
        [downloadZip, showToast, t]
    );

    if (result?.resultSets) {
        return <div className={"results"}>

            <ResultStatsComponent
                t={t}
                result={result}
            />

            {result?.resultSets.map((value, index) =>
                <ResultComponent
                    key={index}
                    {...props}
                    result={value}
                    disableExport={true}
                    downloading={loading || loadingZip}
                    onDownload={handleDownload}
                    onDownloadFiles={handleDownloadFiles}
                />
            )}
        </div>
    } else {
        return <ResultComponent
            {...props}
            downloading={loading || loadingZip}
            onDownload={handleDownload}
            onDownloadFiles={handleDownloadFiles}
        />
    }


}

export default GenerateResultContainer;
