const mergeFilters = (filter1, filter2) => {

    let filter = [];

    if (filter1) {
        filter = filter.concat(filter1);
    }

    if (filter2) {
        filter = filter.concat(filter2);
    }

    return filter;

}

export default mergeFilters;
