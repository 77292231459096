import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {faCheck, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import Loading from "layout/modules/Loading/Loading";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Box from "layout/modules/Box/Box";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import DateTime from "system/DateTime/DateTime";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import "app/observations/assets/observations.scss";

const ListComponent = (props) => {

    const {t} = useTranslation("observation");
    const {data, onPageChange, errors = {}, onSubmit, loading, reload, submitting, onDelete} = props;
    const [deletedInfo, setDeletedInfo] = useState({});
    const showToast = useShowToast();

    const [comment, setComment] = useState("");
    const [showAdd, setShowAdd] = useState(false);

    const handleSubmit = useCallback(
        () => onSubmit({
            observation: comment,
        }).then(() => {
            reload();
            setComment("");
            setShowAdd(false);
        }),
        [onSubmit, reload, comment, setComment,setShowAdd]
    );

    return <div className={"observation-box"}>

        <Loading visible={loading}/>

        <div
            className={classnames("add-observation-button", {"active": showAdd})}
            onClick={() => setShowAdd(true)}>

            <div className={"btn btn-rounded-primary"}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
            {t('create_new_title')}
        </div>

        {showAdd &&
        <Form>
            <GenericFormError t={t} errors={errors}/>
            <Box className={"comment-add"}>
                <BoxTitle>
                    {t('create_new_title')}
                </BoxTitle>
                <Loading visible={submitting}/>
                <FormGroup error={errors['observation']} mandatory={true}>
                    <Form.Label>{t('create_new')}</Form.Label>
                    <Form.Control
                        name={"observation"}
                        type="textarea"
                        as="textarea"
                        rows={3}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['observation'])}
                    </Form.Text>
                </FormGroup>


                <div className={"text-end"}>
                    <Button variant="secondary" onClick={() => setShowAdd(false)}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {t('create')}
                    </Button>
                </div>
            </Box>
        </Form>
        }
        <PopupConfirm
            title={t('DELETE_OBSERVATION_CONFIRM')}
            message={t('DELETE_OBSERVATION_CONFIRM_MSG', {name: deletedInfo.name})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={useCallback(() => {
                setDeletedInfo({})
            }, [setDeletedInfo])}
            onConfirm={useCallback(() => {
                setDeletedInfo({})
                onDelete(deletedInfo.id, deletedInfo, () => {
                    reload();
                    showToast({
                        title: t('SUCCESS_DELETED'),
                        message: t('SUCCESS_DELETED_MSG', deletedInfo),
                        icon: faCheck,
                    });
                });
            }, [onDelete, setDeletedInfo, deletedInfo, reload, showToast, t])}
        />

        {!showAdd &&
        <>
            {data?.content?.length > 0 &&
            <SearchDataPagination data={data} onPageChange={onPageChange}/>
            }

            <div className={"observation-comments"}>
                {data?.content?.map((observation, index) => {
                    return <Box key={index} className={"observation"}>
                        <BoxTitle>
                            <span className={"user-title"}>{observation.user?.name}</span>
                            <span className={"date-title"}>
                        <DateTime format="DD-MM-YYYY HH:mm">
                            {observation?.creationTimestamp}
                        </DateTime>
                        </span>

                        </BoxTitle>

                        <BoxOptions>
                            <div
                                className={classnames("btn btn-rounded-danger small")}
                                onClick={() => setDeletedInfo({
                                    id: observation.id,
                                    name: observation.user?.name
                                })}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </div>
                        </BoxOptions>


                        <div className="comment">
                            {observation.observation}
                        </div>

                    </Box>
                })}

                {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_observations')}
                </div>
                }
            </div>
        </>
        }

    </div>
}

export default ListComponent;
