import React, {useCallback} from "react";
import locale from "modules/Forms/NotSaved/locale/locales";
import useTranslations from "system/Translations/UseTranslations";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";

const PopupNotSavedComponent = (props) => {

    const {isVisible, onCancel, onConfirm} = props;
    const {t} = useTranslations("notSaved", locale)

    return <PopupConfirm
        title={t('CHANGES_NOT_SAVED')}
        message={t('CHANGES_NOT_SAVED_MSG')}
        labelCancel={t('NO')}
        labelConfirm={t('YES')}
        classConfirm={"danger"}
        classCancel={"primary"}
        visible={isVisible}
        onCancel={useCallback(() => onCancel(), [onCancel])}
        onConfirm={useCallback(
            () => new Promise(() => onConfirm()),
            [onConfirm]
        )}
    />
}

export default PopupNotSavedComponent;
