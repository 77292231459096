import {useCallback, useMemo} from "react";
import {useMenuState} from "layout/modules/BaseLayout/Menu/MenuStateContext";
import useWindowSize from "system/Window/WindowSize";
import getAsArray from "system/Arrays/getArray";
import {maxWidthForResponsive, useIsMenuSelected} from "layout/modules/BaseLayout/Menu/utils/Utils";
import SubMenuComponent from "layout/modules/BaseLayout/Menu/components/SubMenuComponent";

const SubMenu = (props) => {

    const {label, children} = props;
    const [menuState, setMenuState] = useMenuState();
    const {width} = useWindowSize();

    //Extract all paths
    const paths = useMemo(
        () => getAsArray(children).reduce(
            (paths, subMenu) => paths.concat(subMenu.props.path),
            []
        ),
        [children]
    );

    //Extract all permissions
    const permissions = useMemo(
        () => getAsArray(children).reduce(
            (permissions, subMenu) => permissions.concat(subMenu.props.permissions),
            []
        ).filter(item => item !== undefined),
        [children]
    );

    const orUserTypes = useMemo(
        () => getAsArray(children).reduce(
            (orUserTypes, subMenu) => orUserTypes.concat(subMenu.props.orUserTypes),
            []
        ).filter(item => item !== undefined),
        [children]
    );

    const isSelected = useIsMenuSelected(paths);

    const isOpen = useMemo(() => menuState.subMenu === label, [menuState, label]);

    const setOpen = useCallback((val) => {
        setMenuState(menuState => ({
            ...menuState,
            subMenu: val ? label : undefined,
        }))
    }, [setMenuState, label]);

    return <SubMenuComponent
        {...props}
        permissions={permissions}
        orUserTypes={orUserTypes}
        setOpen={setOpen}
        isOpen={width > maxWidthForResponsive || isOpen}
        isSelected={isSelected}
    />


}

export default SubMenu;
