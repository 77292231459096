import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";

const EditorComponent = props => {

    const {label, mandatory} = props;
    const {t} = useTranslations('FormTextAreaModule', locale);

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <Form.Control
            type="textarea"
            as="textarea"
            rows={3}
            value={""}
            onChange={(e) => {
            }}
        />
    </FormGroup>

}

export default EditorComponent;
