import {
    faChartLine,
    faCheckCircle,
    faCheckToSlot,
    faEnvelope,
    faFileContract,
    faFileZipper,
    faInfoCircle,
    faLaptopMedical,
    faMessage,
    faSignature
} from "@fortawesome/free-solid-svg-icons";
import DocumentType from "types/Documents";


const NotificationIcons = {
    NEW_TICKET: faEnvelope,
    NEW_TICKET_CONVERSATION: faMessage,
    REPORT: faChartLine,
    REPORT_FAILED: faChartLine,
    NEW_CONTRACT_AVAILABLE: faFileContract,
    NEW_CONTRACT_ANNEX_AVAILABLE: faFileContract,
    NEW_CONTRACT_ANNEX_SIGNATURE: faSignature,
    NEW_CONTRACT_SIGNATURE: faSignature,
    NEW_DOCUMENT_TO_VALIDATE: faCheckToSlot,
    EQUIPMENT_REGISTRATION_TO_VALIDATE: faLaptopMedical,
    EQUIPMENT_REGISTRATION_NOT_VALIDATED: faLaptopMedical,
    EQUIPMENT_REGISTRATION_VALIDATED: faLaptopMedical,
    OPERATION: faInfoCircle,
    OPERATION_VALIDATION_PENDING: faCheckCircle,
    NEW_DOCUMENT_ZIP_FILE: faFileZipper,
};

const parseNewDocumentValidation = (n) => {

    switch (n.additionalInfo?.documentType) {
        case DocumentType.EQUIPMENT_PROFILE:
            return "/equipment-profile/" + n.additionalInfo?.documentTypeId + "/files";
        case DocumentType.CONTRACT:
            return "/contract/" + n.additionalInfo?.documentTypeId;
        default:
            return false;
    }

}

const NotificationLink = {
    NEW_TICKET: (n) => "/support/" + n.objectId,
    NEW_TICKET_CONVERSATION: (n) => "/support/" + n.objectId,
    REPORT: (n) => "/personal/files/" + n.additionalInfo?.filename?.replace(" ", "+"),
    NEW_CONTRACT_AVAILABLE: (n) => "/contract/" + n.objectId,
    NEW_CONTRACT_ANNEX_AVAILABLE: (n) => "/contract/" + n.objectId,
    NEW_CONTRACT_ANNEX_SIGNATURE: (n) => "/contract/" + n.objectId,
    NEW_CONTRACT_SIGNATURE: (n) => "/contract/" + n.objectId,
    NEW_DOCUMENT_TO_VALIDATE: parseNewDocumentValidation,
    EQUIPMENT_REGISTRATION_TO_VALIDATE: (n) => "/equipment-registration/" + n.objectId,
    EQUIPMENT_REGISTRATION_NOT_VALIDATED: (n) => "/equipment-registration/" + n.objectId,
    EQUIPMENT_REGISTRATION_VALIDATED: (n) => "/equipment-registration/" + n.objectId,
    OPERATION_VALIDATION_PENDING: (n) => "/rule-validation/" + n.additionalInfo?.validationId,
    NEW_DOCUMENT_ZIP_FILE: (n) => "/personal/files/" + n.additionalInfo?.filename?.replace(" ", "+"),
};


export {
    NotificationLink
};
export default NotificationIcons;
