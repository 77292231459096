import React from "react";
import {useTranslation} from "react-i18next"
import {faChalkboardUser} from "@fortawesome/free-solid-svg-icons";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import BeneficiaryType from "types/BeneficiaryType";
import ToolTip from "layout/modules/ToolTip/ToolTip";

const BeneficiaryListComponent = (props) => {

    const {t} = useTranslation('beneficiary');
    const {data, loading, onPageChange, onPageSort} = props;


    return <>

        <SearchDataPagination data={data} onPageChange={onPageChange}/>

        <TableAdvanced hover
                       sort={data?.sortBy}
                       order={data?.sortOrder?.toLowerCase()}
                       onSortClick={onPageSort}
                       loading={(loading || !data)}>
            <thead>
            <tr>

                <th id="vatNumber" className={"d-none d-sm-table-cell"}>
                    {t('vat_number')}
                </th>

                <th id="name" sorted>
                    {t('name')}
                </th>

                <th id="type">
                    {t('type')}
                </th>
                
                <th/>


            </tr>
            </thead>
            <tbody>

            {data?.content?.map((beneficiary, index) => {

                return <tr key={index}>


                    <td className="fill-empty d-none d-sm-table-cell">
                        {beneficiary.vatNumber}
                    </td>

                    <td className="td-sorted fill-empty">
                        {beneficiary.name}
                    </td>

                    <td className={"fill-empty"}>
                        {t(beneficiary.type)}
                    </td>

                    <td className="table-buttons">
                        <div className={"buttons"}>
                            <ToolTip content={t('show_beneficiary')}>
                                <TableIconButton
                                    path={beneficiary.type === BeneficiaryType.STUDENT
                                        ? "/student/" + beneficiary?.id
                                        : "/teacher/" + beneficiary?.id
                                    }
                                    icon={faChalkboardUser}
                                    className={"table-btn btn-rounded-primary small"}
                                />
                            </ToolTip>
                        </div>
                    </td>

                </tr>
            })}
            </tbody>
        </TableAdvanced>
    </>

}

export default BeneficiaryListComponent;
