import {useGet} from "system/Rest/Rest";
import equipmentResolver from "app/equipments/equipments/utils/equipmentResolver";
import equipmentProfileResolver from "app/equipments/equipmentProfile/utils/equipmentProfileResolver";
import {useMemo} from "react";

const useNewEquipmentProfile = (equipmentId, profileMap) => {

    const {data: equipment} = useGet({
        path: "/equipments/" + equipmentId,
        lazy: !equipmentId,
        resolve: equipmentResolver,
    });

    const {data: profile} = useGet({
        path: "/equipment-profiles/" + equipment?.profile?.id,
        lazy: !equipment?.profile?.id,
        resolve: equipmentProfileResolver,
    });

    const newEquipmentProfileId = useMemo(
        () => {
            if (!profile)
                return null;

            const mapping = profileMap ?? {};
            return mapping[profile?.typologyId]
        },
        [profileMap, profile]
    );


    const {data: newProfile} = useGet({
        path: "/equipment-profiles/" + newEquipmentProfileId,
        lazy: !newEquipmentProfileId,
        resolve: equipmentProfileResolver,
    });


    return newProfile;
}

export default useNewEquipmentProfile;
