export const pt = {

    title: "Processos de auditoria",


    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    CREATE_SUCCESS: "Processo criado",
    CREATE_SUCCESS_MSG: "O processo de auditoria foi criado com sucesso!",
    UPDATE_SUCCESS: "Processo atualizado",
    UPDATE_SUCCESS_MSG: "O processo de auditoria foi atualizado com sucesso!",

    //List
    placeholder: "Selecione uma opção",
    no_processes_found: "Não foram encontrados resultados",
    id: "Num.",
    auditor: "Auditor",
    result: "Resultado",
    creation_date: "Data de criação",
    update_date: "Ult. atualização",


    COMPLIANT: "Conforme",
    NON_COMPLIANT: "Não conforme",
    NOT_CONFERRABLE: "Não conferível",
    PENDING: "Por concluir",
    INCOMPLETE: "Incompleto",
    COMPLETED: "Concluído",

    //Filter
    to_date: "Data final",
    from_date: "Data inicial",
    date_ph: "Data no formato (dd-mm-yyyy)",

    //Form
    create: "Criar processo",
    save_changes: "Guardar alterações",
    cancel: "Cancelar",
    process_data: "Dados gerais",
    name: "Identificação do processo",
    name_ph: "Inidque um nome / descrição para o processo",
    process_date: "Data do processo",
    create_process: "Criar processo",
    description: "Descrição do processo",
    associated_equipments: "Equipamentos do processo",
    observations: "Observações de auditoria",
    audit_data: "Dados da auditoria",
    MISSING_NAME: "Deve indicar um nome/descrição",
    MISSING_AUDITOR: "Deve indicar o utilizador que irá realizar a auditoria",
    MISSING_EQUIPMENTS: "Deve indicar pelo menos um equipamento",

    //view
    equipments: "Equipamentos",
    download_report: "Download do relatório",

    //equipment
    equipment: "Dados equipamento",
    equipment_historic: "Histórico",
    equipment_contracts: "Contratos",
    tasks: "Tarefas / Pedidos",

    //chart
    totalEquipments: "Num. equipamentos",
    totalProcesses: "Num. processos",


};

export default pt;
