import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import Row from "react-bootstrap/Row";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import RecruitmentGroupDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/teacher/RecruitmentGroupDropdown";
import TeacherBondStatusDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/teacher/TeacherBondStatusDropdown";
import TeachingComponentDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/teacher/TeachingComponentDropdown";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import BeneficiaryEquipmentStatus from "app/beneficiaries/beneficiary/utils/BeneficiaryEquipmentStatus";
import isFunction from "system/Utils/isFunction";
import {SchoolType} from "types/SchoolType";
import useTranslations from "system/Translations/UseTranslations";
import localSchools from "app/schools/locales/locales";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";

const ListFilterComponent = (props) => {

    const {searchFilter, t, onFilterChange, readOnly, schoolIds, hideAttributions = false, lockedFilters = {}} = props;
    const [filterState, setFilterState] = useState(searchFilter);

    const {t: tSchools} = useTranslations('schools', localSchools);

    useEffect(() => {
        if (!isFunction(onFilterChange)) {
            setFilterState({
                ...searchFilter
            });
        }
    }, [searchFilter, onFilterChange]);


    useEffect(() => {
            if (isFunction(onFilterChange) && filterState !== searchFilter) {
                onFilterChange(filterState);
            }
        },
        // eslint-disable-next-line
        [filterState, onFilterChange]
    );



    if (!filterState) {
        return <></>;
    }

    return <>

        <Row>

            {!schoolIds &&
            <UserHasPermission permission={["READ_SCHOOL"]}>

                {isNotDefinedNorEmpty(lockedFilters['schoolTypologies']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('typology')}</Form.Label>
                        <EnumDropdown
                            t={tSchools}
                            isMulti={true}
                            id={"schoolTypologies"}
                            options={SchoolType}
                            isDisabled={readOnly}
                            value={getOrNull(filterState?.schoolTypologies)}
                            placeholder={tSchools('typology_ph')}
                            onChange={(val) => setFilterState((filterState) => ({
                                ...filterState,
                                schoolTypologies: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
                }

                {(!hideAttributions && isNotDefinedNorEmpty(lockedFilters['schoolIds'])) &&

                    <>
                        {isNotDefinedNorEmpty(filterState?.schoolTypologies)
                            ? <SchoolFilterComponent
                                value={filterState?.schoolIds}
                                isDisabled={readOnly}
                                id={'schoolIds'}
                                isMulti={true}
                                setValue={(value) => setFilterState(state => ({
                                    ...state, schoolIds: value,
                                    schoolClass: undefined,
                                }))}
                            />
                            : <Col sm={12} lg={6}>
                                <FormGroup>
                                    <Form.Label>{t('school_or_group')}</Form.Label>
                                    <SchoolDropdown
                                        isMulti={true}
                                        typologies={getOrNull(filterState?.schoolTypologies)}
                                        id={"schoolIds"}
                                        isDisabled={readOnly}
                                        value={getOrNull(filterState?.schoolIds)}
                                        onChange={(val) => setFilterState((filterState) => ({
                                            ...filterState,
                                            schoolIds: val,
                                            schoolClass: undefined,
                                        }))}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </>
                }
            </UserHasPermission>
            }

            {isNotDefinedNorEmpty(lockedFilters['recruitmentGroupIds']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('recruitment_group')}</Form.Label>
                    <RecruitmentGroupDropdown
                        isDisabled={readOnly}
                        isMulti={true}
                        id={"recruitmentGroupIds"}
                        value={getOrNull(filterState?.recruitmentGroupIds)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            recruitmentGroupIds: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['bondStatusIds']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('bond_status')}</Form.Label>
                    <TeacherBondStatusDropdown
                        isDisabled={readOnly}
                        isMulti={true}
                        id={"bondStatusIds"}
                        value={getOrNull(filterState?.bondStatusIds)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            bondStatusIds: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }


            {isNotDefinedNorEmpty(lockedFilters['teachingComponent']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('teaching_component')}</Form.Label>
                    <TeachingComponentDropdown
                        isDisabled={readOnly}
                        id={"teachingComponent"}
                        value={getOrNull(filterState?.teachingComponent)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            teachingComponent: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['equipmentStatus']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('equipment_status')}</Form.Label>
                    <EnumDropdown
                        isDisabled={readOnly}
                        t={t}
                        options={BeneficiaryEquipmentStatus}
                        id={"equipmentStatus"}
                        value={getOrNull(filterState?.equipmentStatus)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            equipmentStatus: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }


        </Row>

    </>
}

export default ListFilterComponent;
