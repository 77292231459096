import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React, {useMemo} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/upload/locales/locales";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {generateAcceptTypes} from "layout/modules/FormBuilder/modules/upload/utils/UploadSupportedTypes";

const EditorComponent = props => {

    const {label, mandatory, descriptionInfo, acceptTypes, maxFiles} = props;
    const {t} = useTranslations('UploadModule', locale);

    const systemAcceptTypes = useMemo(
        () => generateAcceptTypes(acceptTypes),
        [acceptTypes]
    );

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <DragDropFile
            maxFiles={maxFiles}
            acceptTypes={systemAcceptTypes}
            text={descriptionInfo ?? t('description_help')}
            onFileUploaded={() => {
            }}
        />

    </FormGroup>

}

export default EditorComponent;
