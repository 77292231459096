import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import locale from "app/files/locales/locales";
import React, {useCallback, useMemo} from "react";
import ListComponent from "app/files/components/ListComponent";
import {useMutate} from "system/Rest/Rest";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";

const ListContainer = props => {

    const {reload, type, id, fileUniversalSearch} = props;
    const {t} = useTranslations('document', locale);
    const showToast = useShowToast();

    const {mutate: validate, loading} = useMutate({
        verb: "PUT",
        path: useCallback(({id}) => `/documents/${id}/validate`, []),
    });


    return <GenericListContainer
        {...props}
        id={"files-" + type + "-" + id}
        module="document"
        locale={locale}
        hideTitle={true}
        queryParameters={useMemo(() => ({
            documentType: type,
            documentTypeId: id,
            sortProperty: 'creationTimestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            universalSearch: fileUniversalSearch,
            itemsPerPage: 10,
        }), [type, id, fileUniversalSearch])}>

        <ListComponent
            {...props}
            validating={loading}
            onValidate={useCallback(
                (file, valid, comments) => {
                    validate({
                        valid: valid,
                        comments: comments
                    }, {pathParams: {id: file.id}})
                        .then(() => {
                            showToast({
                                title: t("SUCCESS_VALIDATE"),
                                message: valid
                                    ? t("SUCCESS_VALIDATE_MSG", {name: file.filename})
                                    : t("SUCCESS_INVALIDATE_MSG", {name: file.filename}),
                                icon: faCheck,
                            });
                            reload();
                        })
                        .catch(error => {
                            showToast({
                                title: t('ERROR_VALIDATE'),
                                message: t('ERROR_VALIDATE_MSG', {name: file.filename}),
                                icon: faTimes,
                                className: 'error',
                            });
                        });
                },
                [validate, reload, showToast, t]
            )}
        />

    </GenericListContainer>

}

export default ListContainer;
