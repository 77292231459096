import React, {useRef, useState} from "react";
import {Overlay} from "react-bootstrap";
import classnames from "classnames";
import useHandleMouseGestures from "layout/modules/MenuDropdown/utils/useHandleMouseGestures";
import useMenuDropdownAccessibility from "layout/modules/MenuDropdown/utils/useMenuDropdownAccessibility";
import './assets/css/menu-dropdown.scss'

const MenuDropdown = (props) => {

    const {children, disabled, placement = "bottom", className, onChange, ariaLabel, tabIndex = 0} = props;

    const [isVisible, setVisible] = useState(false);

    const target = useRef(null);
    const menuRef = useRef(null);

    const handleClick = useHandleMouseGestures({
        target, isVisible, menuRef, setVisible, onChange, disabled
    });

    const handleKeyboard = useMenuDropdownAccessibility({
        isVisible, menuRef, setVisible, onChange, disabled, target
    });


    return <div className={"menu-drop-down"} ref={target}>
        <div
            tabIndex={tabIndex}
            onClick={handleClick}
            onKeyDown={handleKeyboard}
            aria-expanded={isVisible}
            aria-label={ariaLabel}>
            {React.cloneElement(children[0], {
                className: classnames(children[0].props.className, {'active': isVisible, 'disabled': disabled})
            })}
        </div>

        <Overlay
            target={target.current}
            show={isVisible}
            ref={menuRef}
            placement={placement}
            popperConfig={{
                modifiers: [{
                    name: 'preventOverflow',
                    options: {
                        mainAxis: true, // true by default
                        altAxis: true,
                    },
                },]
            }}>
            {({placement, arrowProps, show: _show, popper, hasDoneInitialMeasure, ...props}) => (
                <div {...props} onClick={handleClick} className={classnames("menu-drop-down-container", className)}
                     autoFocus={true}>
                    {children[1]}
                </div>
            )}
        </Overlay>
    </div>

}

export default MenuDropdown;
