import React, {useCallback, useMemo} from 'react'
import locale from "../locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const SchoolDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "groupId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        typologies,
        parentIds,
    } = props;

    return <UserHasPermission permission={["READ_SCHOOL"]}>
        <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder, t('sg_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('sg_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('sg_loading'))}
        remotePath={"/schools/search"}
        remoteClassPath={"/schools/search"}
        labelHandler={useCallback((d) => schoolLabelHandlerWithType(d,t),[t])}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
            typologies: typologies,
            parentIds: parentIds,
        }), [maxItems, typologies,parentIds])}/>
    </UserHasPermission>

}

const schoolLabelHandler = (o) => o['dre'] ? o['dre'] + " - " + o["name"] : o["name"];
const schoolLabelHandlerWithType = (o, t) => schoolLabelHandler(o) + " - " + t(o['typology']);

export {schoolLabelHandler, schoolLabelHandlerWithType};
export default SchoolDropdown;
