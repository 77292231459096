import React, {useCallback, useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"
import locale from "../locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const EntityDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "entityId",
        value,
        defaultValue,
        isDisabled = false,
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        key,
        onlyRootEntities,
        isMulti = false,
        classValue = 'id',
        remotePath,
        types,
        excludeSelf
    } = props;


    return <UserHasPermission permission={["READ_ENTITY","READ_SCHOOL", "READ_SUPPLIER"]} any={true}>
        <GenericRemoteClassDropdown
            {...props}
            key={key}
            value={value}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            id={id}
            isMulti={isMulti}
            placeholder={usePlaceHolder(placeholder, t('entity_ph'))}
            noOptionsMessage={useLabelCallback(noOptionsMessage, t('entity_no'))}
            loadingMessage={useLabelCallback(loadingMessage, t('entity_loading'))}
            classValue={classValue}
            labelHandler={useCallback(o => {
                if (o['dre'])
                    return o['name'] + " - " + o["dre"];
                else if (o['vatNumber'])
                    return o['name'] + " - " + o["vatNumber"];
                else
                    return o['name']
            }, [])}

            remotePath={remotePath ?? "/entities/search"}
            searchFilter={useMemo(() => ({
                sortProperty: 'name',
                sortDirection: 'ASC',
                currentPage: 0,
                itemsPerPage: maxItems,
                onlyRootEntities: onlyRootEntities,
                types: types,
                excludeSelf: excludeSelf

            }), [maxItems, onlyRootEntities, types, excludeSelf])}
        />
    </UserHasPermission>

}

export default EntityDropdown;
