import FormActionModule from "app/ruleTemplates/actions/forms/FormActionModule";
import ValidationRuleAction from "app/ruleTemplates/actions/validation/ValidationRuleAction";
import EquipmentStatusRuleAction from "app/ruleTemplates/actions/equipmentStatus/EquipmentStatusRuleAction";
import EquipmentSelectionRuleAction from "app/ruleTemplates/actions/equipmentSelection/EquipmentSelectionRuleAction";
import ContractGeneratorRuleAction from "app/ruleTemplates/actions/contractGenerator/ContractGeneratorRuleAction";
import SetBeneficiaryRuleAction from "app/ruleTemplates/actions/setBeneficiary/SetBeneficiaryRuleAction";
import AllowInvoicingRuleAction from "app/ruleTemplates/actions/allowInvoicing/AllowInvoicingRuleAction";
import UnsetBeneficiaryRuleAction from "app/ruleTemplates/actions/unsetBeneficiary/UnsetBeneficiaryRuleAction";
import UseBeneficiaryEquipmentsRuleAction
    from "app/ruleTemplates/actions/useBeneficiaryEquipments/UseBeneficiaryEquipmentsRuleAction";
import BeneficiarySelectionRuleAction from "app/ruleTemplates/actions/beneficiarySelection/BeneficiarySelectionRuleAction";
import SchoolSelectionRuleAction from "app/ruleTemplates/actions/schoolSelection/SchoolSelectionRuleAction";
import SetSchoolRuleAction from "app/ruleTemplates/actions/setSchool/SetSchoolRuleAction";
import SupportTicketRuleAction from "app/ruleTemplates/actions/supportTicket/SupportTicketRuleAction";
import NotificationRuleAction from "app/ruleTemplates/actions/notification/NotificationRuleAction";
import RecoverEquipmentStatusRuleAction
    from "app/ruleTemplates/actions/recoveryEquipmentStatus/RecoverEquipmentStatusRuleAction";
import InfoPageRuleAction from "app/ruleTemplates/actions/infoPage/InfoPageRuleAction";
import ReplaceEquipmentRuleAction from "app/ruleTemplates/actions/replaceEquipment/ReplaceEquipmentRuleAction";
import ReplaceEquipmentExecutionRuleAction
    from "app/ruleTemplates/actions/replaceEquipmentExecution/ReplaceEquipmentExecutionRuleAction";
import BeneficiaryNonCompliance from "app/ruleTemplates/actions/beneficiaryNonCompliance/BeneficiaryNonCompliance";
import CloseBeneficiaryNonComplianceAction
    from "app/ruleTemplates/actions/beneficiaryNonCoplianceClose/CloseBeneficiaryNonComplianceAction";

const RuleEngineActiveModules = [
    InfoPageRuleAction,
    FormActionModule,
    BeneficiarySelectionRuleAction,
    EquipmentSelectionRuleAction,
    SchoolSelectionRuleAction,
    UseBeneficiaryEquipmentsRuleAction,
    EquipmentStatusRuleAction,
    RecoverEquipmentStatusRuleAction,
    ValidationRuleAction,
    SupportTicketRuleAction,
    NotificationRuleAction,
    ContractGeneratorRuleAction,
    SetBeneficiaryRuleAction,
    SetSchoolRuleAction,
    UnsetBeneficiaryRuleAction,
    AllowInvoicingRuleAction,
    ReplaceEquipmentRuleAction,
    ReplaceEquipmentExecutionRuleAction,
    BeneficiaryNonCompliance,
    CloseBeneficiaryNonComplianceAction,
]


export {RuleEngineActiveModules};
