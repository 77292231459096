export const pt = {

    school: "Dropdown de escolas",
    beneficiaries: "Dropdown beneficiários",
    equipments: "Dropdown equipamentos",
    student: "Dropdown estudantes",
    teacher: "Dropdown docentes",
    supplier: "Dropdown fornecedor",
    set_label: "Defina o nome do campo",


};

export default pt;
