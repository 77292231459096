import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/calendar/locales/locales";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";

const EditorComponent = props => {

    const {label, mandatory, onlyPastDates, onlyFutureDates, fromDate, toDate} = props;
    const {t} = useTranslations('CalendarModule', locale);

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <DatePicker
            placeholder={t("date_ph")}
            onlyPastDates={onlyPastDates}
            onlyFutureDates={onlyFutureDates}
            fromDate={fromDate}
            toDate={toDate}
            timezone={"UTC"}
            value={""}
            onChange={(e) => {
            }}
        />
    </FormGroup>

}

export default EditorComponent;
