import {faList} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import locale from "app/audit/locales/locales";
import PopupComponent from "./PopupComponent";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useTranslations from "system/Translations/UseTranslations";
import {useIsMobile} from "system/Window/Mobile";
import ToolTip from "layout/modules/ToolTip/ToolTip";

const ButtonComponent = (props) => {


    const {t} = useTranslations("audit", locale)
    const {className, lg = false, icon, tooltip} = props;

    const [showPopup, setShowPopup] = useState(false);

    const handleClose = useCallback(
        () => setShowPopup(false),
        [setShowPopup]
    );
    const handleOpen = useCallback(() => setShowPopup(true), [setShowPopup]);
    const isMobile = useIsMobile();

    if (isMobile)
        return <></>

    return <>

        <ToolTip content={tooltip ?? t('audit')}>
            <div
                onClick={handleOpen}
                className={classnames("btn btn-rounded-primary", className, {"small": !lg})}
            >
                <FontAwesomeIcon icon={icon ? icon : faList}/>
            </div>

        </ToolTip>

        {showPopup &&
        <PopupComponent
            {...props}
            onClose={handleClose}

        />
        }

    </>

}

export default ButtonComponent;
