import {useCallback, useEffect, useState} from "react";
import useKeyboardNavigation from "system/Acessibility/useKeyboardNavigation";
import useKeyPress from "system/Acessibility/useKeyPress";

const useMenuDropdownAccessibility = props => {

    const {focusActive, ref} = props;

    const [active, setActive] = useState(false);

    const arrowRightPressed = useKeyPress('ArrowRight');
    const arrowLeftPressed = useKeyPress('ArrowLeft');
    const escapePressed = useKeyPress('Escape');

    const [activeElement, setActiveElement] = useState();
    const [expanded, setExpanded] = useState(false);

    useEffect(
        () => {
            setActive(focusActive)
        },
        [focusActive]
    );

    useEffect(
        () => {
            if (arrowRightPressed && active) {
                if (expanded) {
                    setActive(false);
                }
            }
        },
        // eslint-disable-next-line
        [arrowRightPressed]
    );

    useEffect(
        () => {
            if ((arrowLeftPressed || escapePressed) && !active && focusActive) {
                activeElement.focus();
                setActive(true);
            }
        },

        // eslint-disable-next-line
        [arrowLeftPressed, escapePressed]
    );


    useKeyboardNavigation({
        element: ref?.current,
        enable: active,
        selector: '.main-menu-item',
        preservePosition: true,
        handleFocus: useCallback(
            (element) => {
                element.focus();
                setExpanded(element.getAttribute("aria-haspopup"));
                setActiveElement(element);
            },
            []
        )
    });


}

export default useMenuDropdownAccessibility;
