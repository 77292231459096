import List from "app/support/List";
import {useMemo} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";

const RenderView = props => {

    const {result} = props;

    const id = useMemo(() => generateRandomId(10), []);

    return <div className={"view-rule-support-tickets"}>
        <List hideTitle={true} id={id} ids={result.ticketIds} simpleMode={true} />
    </div>
}

export default RenderView;
