import React from "react"
import ErrorPage from "layout/modules/ErrorPages/ErrorPage"
import locale from "layout/modules/ErrorPages/locales/locales"
import useTranslations from "system/Translations/UseTranslations";

const NotFound = (props) => {

    const {t} = useTranslations('errors', locale);

    return <ErrorPage {...props} message={t("404_NOT_FOUND")}/>
}

export default NotFound;
