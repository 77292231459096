import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "system/Objects/ObjectParameters";
import ContractModelDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ContractModelDropdown";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import GlobalEntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/GlobalEntityDropdown";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import BeneficiaryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BeneficiaryDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import ContractModelType from "app/procedures/contractModel/utils/ContractModelType";
import ProcedureDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ProcedureDropdown";

const ListFilterComponent = (props) => {

    const {searchFilter, t, beneficiaryIds, entityIds} = props;
    const [filterState, setFilterState] = useState();

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);


    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('procedure')}</Form.Label>
                    <ProcedureDropdown
                        id={'procedureId'}
                        isMulti={false}
                        value={getOrNull(filterState?.procedureId)}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            procedureId: value,
                        }))}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('model')}</Form.Label>
                    <ContractModelDropdown
                        id={'modelIds'}
                        isMulti={true}
                        value={getOrNull(filterState?.modelIds)}
                        type={beneficiaryIds ? ContractModelType.BENEFICIARY : undefined}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            modelIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>

            {!entityIds &&
            <UserHasPermission permission={"READ_ENTITY"}>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('entities')}</Form.Label>
                        <GlobalEntityDropdown
                            id={'entityIds'}
                            isMulti={true}
                            value={getOrNull(filterState?.entityIds)}
                            onChange={(value) => setFilterState(state => ({
                                ...state,
                                entityIds: value,
                            }))}
                        />
                    </FormGroup>
                </Col>
            </UserHasPermission>
            }

            {!beneficiaryIds &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('beneficiary')}</Form.Label>
                    <BeneficiaryDropdown
                        id={'beneficiaryIds'}
                        isMulti={true}
                        value={getOrNull(filterState?.beneficiaryIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            beneficiaryIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>
            }

            <UserHasPermission permission={"VALIDATE_CONTRACT_SIGNATURES"}>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('validated')}</Form.Label>
                        <BooleanDropdown
                            isClearable={true}
                            id={'validatedSignatures'}
                            value={parseBoolean(filterState?.validatedSignatures)}
                            onChange={(value) => setFilterState(state => ({
                                ...state,
                                validatedSignatures: value,
                            }))}
                        />
                    </FormGroup>
                </Col>
            </UserHasPermission>


            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('valid')}</Form.Label>
                    <BooleanDropdown
                        id={'valid'}
                        isClearable={true}
                        value={parseBoolean(filterState?.valid)}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            valid: value,
                        }))}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('signed_by_origin')}</Form.Label>
                    <BooleanDropdown
                        id={'signedByOrigin'}
                        isClearable={true}
                        value={parseBoolean(filterState?.signedByOrigin)}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            signedByOrigin: value,
                        }))}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('signed_by_counterparty')}</Form.Label>
                    <BooleanDropdown
                        id={'signedByCounterparty'}
                        isClearable={true}
                        value={parseBoolean(filterState?.signedByCounterparty)}
                        onChange={(value) => setFilterState(state => ({
                            ...state,
                            signedByCounterparty: value,
                        }))}
                    />
                </FormGroup>
            </Col>


        </Row>

    </>
}

export default ListFilterComponent;
