import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import classnames from "classnames";
import "app/support/assets/css/attachments.scss";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import DownloadContainer from "app/files/containers/DownloadContainer";

const AttachmentComponent = props => {

    const {attachment, iconClassName} = props;

    const [download, setDownload] = useState(false);

    const handleFileClick = useCallback(
        () => {

            if (attachment.onClick) {
                attachment.onClick();
            } else {
                //fileDownload(attachment.content, attachment.filename, attachment.type);
                setDownload(true);
            }
        },
        [attachment]
    );

    return <div className={"attachment"}>

        {download &&
        <DownloadContainer file={attachment} onDownloadComplete={() => setDownload(false)}/>
        }

        <div className={"attachment-button"} {...generateOnClickAndEnter(handleFileClick)}>
            <div className={"file-name"}>
                {attachment.title}
            </div>

            <div className={classnames("icon", iconClassName)}>
                <FontAwesomeIcon icon={attachment.icon ? attachment.icon : faFileDownload}/>
            </div>
        </div>
    </div>;

}

export default AttachmentComponent;
