export const pt = {

    title: "Lista de perfis",
    create_profile: "Criar perfil de equipamento",
    view_profile: "Visualizar perfil",
    
    DELETE_PROFILE_CONFIRM: "Confirmação",
    DELETE_PROFILE_CONFIRM_MSG: "Deseja realmente apagar o perfil \"<strong>{{name}}</strong>\"?",

    DELETE_SUCCESS: "Perfil apagado",
    DELETE_SUCCESS_MSG: "O perfil {{name}} foi apagado com sucesso",

    DELETE_FAILED: "Impossível apagar perfil ",
    EQUIPMENT_PROFILE_IN_USE: "O perfil está em uso e não pode ser apagado",

    MISSING_NAME: "Deve indicar um nome/identificação único",
    NAME_ALREADY_EXISTS: "O nome introduzido já existe na plataforma",
    CREATE_SUCCESS: "Perfil criado",
    CREATE_SUCCESS_MSG: "O perfil {{name}} foi criado com sucesso!",
    UPDATE_SUCCESS: "Perfil atualizado",
    UPDATE_SUCCESS_MSG: "O perfil {{name}} foi atualizado com sucesso!",
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",



    compare_profiles: "Controlo de versões",
    profile_data: "Dados do perfil",
    name: "Identificação do perfil",
    name_placeholder: "Indique uma identificação única para o perfil",
    description: "Descrição do equipamento",
    description_ph: "Indique uma descrição do equipamento",
    id_label: "Identificador do ID/SN",
    id_label_placeholder: "Indique um identificador para o o ID. ex. IMEI",
    id_regex: "Regex para validação de ID/SN",
    id_regex_placeholder: "Indique uma regex",
    available_for_student: "Atribuível a aluno",
    available_for_school: "Atribuível a escola",
    available_for_teacher: "Atribuível a docente",
    rank: "Escalão ",
    rank_ph: "Indique opcionalmente o escalão",
    school_years: "Anos escolares",
    billing_type: "Tipo de faturação",
    equipment_supplier: "Fornecedor do equipamento",
    maintenance_supplier: "Fornecedor após garantia",
    procedure: "Fase",
    billing_type_ph: "Escolha uma opção",
    typology: "Tipologia",
    placeholder: "Escolha uma opção",
    in_digital_manual_pilot: "Aluno em piloto de manuais digitais",
    school_typologies: "Tipo de escolas autorizadas",
    school_typologies_ph: "Indique o tipo para limitar a atribuição",

    //Billing Type
    ONE_TIME: "Única",
    MONTHLY_SUBSCRIPTION: "Mensal",

    create: "Criar perfil",
    save_changes: "Guardar alterações",
    cancel: "Cancelar",
    no_profile_found: "Não foram encontrados perfis",
    true: "Sim",
    false: "Não",


    //Show
    files: "Documentos",


    MISSING_ID_LABEL: "Indique um identificador",
    MISSING_ID_REGEX: "Indique a regex de validação",
    MISSING_BILLING_TYPE: "Indique o tipo de faturação",
    MISSING_SUPPLIER_ID: "Indique qual é o fornecedor do equipamento",
    MISSING_PROCEDURE_ID: "Indique a que procedimento está associado o equipamento",
    MISSING_EQUIPMENT_TYPOLOGY_ID: "Indique a tipologia do equipamento",

};

export default pt;
