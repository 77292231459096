import en from "layout/modules/FormBuilder/modules/textArea/locales/en"
import pt from "layout/modules/FormBuilder/modules/textArea/locales/pt"

const locale = [
    {
        lang: 'en-US',
        name: "English",
        translation: en
    },
    {
        lang: 'pt-PT',
        name: "Português",
        translation: pt
    }

];

export default locale;

