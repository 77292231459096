import React from "react"
import ErrorPage from "./ErrorPage"
import locale from "./locales/locales"
import {ReactComponent as ErrorImage} from "./assets/images/no-internet.svg";
import useTranslations from "system/Translations/UseTranslations";

const NotFound = (props) => {

    const {t} = useTranslations('errors', locale);

    return <ErrorPage {...props} message={t("INTERNET_ERROR")}>
        <ErrorImage/>
    </ErrorPage>
}

export default NotFound;
