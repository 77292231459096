import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import React from "react";
import DynamicSelect from "app/ruleTemplates/actions/forms/modules/dynamicSelects/components/DynamicSelect";

const EditorComponent = props => {

    const {label, mandatory, subModule} = props;
    const {t} = useTranslations('Formdgec-selectsModule', locale);

    return <FormGroup mandatory={mandatory}>
        <Form.Label>{label ?? <i>{t('set_label')}</i>}</Form.Label>
        <DynamicSelect
            type={subModule}
            value={null}
            onChange={(val) => {
            }}
        />

    </FormGroup>

}


export default EditorComponent;
