import React from "react";
import formModules from "app/ruleTemplates/actions/forms/config";
import FormRender from "layout/modules/FormBuilder/components/editor/execute/FormRender";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/forms/locales/locales";

const FormExecution = props => {

    const {t} = useTranslations("FormRuleAction", locale);
    const {config: {formModel}, data, setData, errors} = props;
    //const [formData, setFormData] = useState({});

    return <div className={"action-form-execution"}>
        <GenericFormError t={t} errors={errors}/>
        <FormRender
            modules={formModules}
            data={formModel}
            formData={data}
            setFormData={setData}
            errors={errors}
        />
    </div>
}

export default FormExecution;
