import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {UserType} from "types/UserType";
import StatusDropdown from "layout/modules/Forms/Dropdowns/StatusDropdown/StatusDropdown";
import RoleDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RoleDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import GlobalEntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/GlobalEntityDropdown";

const ListFilterComponent = (props) => {


    const {searchFilter, disableEntity, t} = props;
    const {entityId, includeChildrenEntities, roleId, status} = searchFilter;

    const [filterState, setFilterState] = useState();

    useEffect(() => {

        setFilterState({
                entityId: entityId,
                includeChildrenEntities: includeChildrenEntities,
                roleId: roleId,
                status: status,
            }
        );

    }, [entityId, includeChildrenEntities, roleId, status, setFilterState]);

    if (!filterState) {
        return <></>;
    }

    return <>

        <Row>

            <Col sm={12} lg={true}>
                <FormGroup>
                    <Form.Label>{t('role')}</Form.Label>
                    <RoleDropdown
                        isMulti={true}
                        userEntityId={filterState.entityId}
                        value={filterState.roleId ? filterState.roleId : null}
                        onChange={(val) => setFilterState({
                            ...filterState,
                            roleId: val,
                        })}
                    />
                </FormGroup>
            </Col>

            <Col sm={12} lg={true}>
                <FormGroup>
                    <Form.Label>{t('status').label}</Form.Label>
                    <StatusDropdown
                        value={filterState.status}
                        onChange={(val) => {
                            setFilterState({
                                ...filterState,
                                status: val,
                            })
                        }}
                    />
                </FormGroup>
            </Col>
        </Row>

        {!disableEntity &&
        <UserHasPermission permission={"READ_ENTITY"}>

            <Row>

                <Col sm={12} lg={4}>
                    <FormGroup>
                        <Form.Label>{t('type')}</Form.Label>
                        <EnumDropdown
                            id={"type"}
                            value={getOrNull(filterState?.type)}
                            isClearable={true}
                            options={UserType}
                            onChange={(id) => setFilterState({
                                ...filterState,
                                type: id,
                                entityId: null,
                            })}
                        />
                    </FormGroup>
                </Col>


                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('entity')}</Form.Label>
                        <GlobalEntityDropdown
                            isMulti={true}
                            userType={getOrNull(filterState?.type)}
                            value={getOrNull(filterState?.entityId)}
                            onChange={(id) => setFilterState({
                                ...filterState,
                                entityId: id,
                                roleId: id ? null : filterState.roleId
                            })}/>
                    </FormGroup>
                </Col>

                <Col sm={12} lg={2}>
                    <FormGroup filter-ignore-values={[true]}>
                        <Form.Label>{t('include_sub_entities')}</Form.Label>
                        <BooleanDropdown
                            id="includeChildrenEntities"
                            value={filterState.includeChildrenEntities ? filterState.includeChildrenEntities : false}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    includeChildrenEntities: val
                                })
                            }}
                            isDisabled={!filterState.entityId}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </UserHasPermission>
        }
    </>

}

export default ListFilterComponent;
