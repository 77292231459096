import {faEnvelope, faHeadset} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import locale from "app/support/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import RequestSupportContainer from "app/support/containers/RequestSupportContainer";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";
import {UserHasNoPermission} from "system/API/Authentication/UserHasPermission";
import {parseNumber} from "system/Utils/parseNumber";


const SupportMenuButtonsComponet = React.forwardRef((props, ref) => {

    const {data, preventOutput} = props;
    const {t} = useTranslations('support', locale);

    const [isOpen, setOpen] = useState(false);

    const [request, setRequest] = useState(null);
    const handleClosePopup = useCallback(() => setOpen(false), [setOpen]);

    const handleOpenPopup = useCallback(
        (req) => {
            setRequest(req);
            setOpen(true);

        },
        [setOpen, setRequest]
    );

    const totalMessages = useMemo(
        () => {
            return parseNumber(data?.totalItems, "0");
        },
        [data?.totalItems]
    );

    useEffect(() => {
        if (ref) {
            ref.current = {
                openTicket: handleOpenPopup,
            }
        }
    }, [ref, handleOpenPopup]);


    return <>

        {!preventOutput &&
        <>

            <UserHasNoPermission permission={"MANAGE_TICKETS"}>
                <SingleMenuItem
                    label={t('request_support')}
                    icon={faHeadset}
                    aria-description={t('request_support')}
                    onClick={() => handleOpenPopup()}
                    className={classnames({active: isOpen})}
                />
            </UserHasNoPermission>
            <SingleMenuItem
                label={t('title')}
                icon={faEnvelope}
                aria-description={t('title')}
                path={"/support/"}
                counter={totalMessages}
            />
        </>
        }

        {isOpen &&
        <RequestSupportContainer
            {...props}
            isOpen={isOpen}
            onClose={handleClosePopup}
            request={request}
        />
        }
    </>
});

export default SupportMenuButtonsComponet;
