const ReportParamType = {
    TEXT: "TEXT",
    BOOLEAN: "BOOLEAN",
    NUMERIC: "NUMERIC",
    DATE: "DATE",
    ENTITY: "ENTITY",
    DISTRICT: "DISTRICT",
    COUNTRY: "COUNTRY",
    COUNTY: "COUNTY",
    PARISH: "PARISH",
    GENDER: "GENDER",
    USER: "USER",
    ENTITY_TYPE: "ENTITY_TYPE",
    OPTIONAL: "OPTIONAL",
    LOG_PARAMETER: "LOG_PARAMETER",
    SCHOOL: "SCHOOL",
    INSTITUTION_TYPOLOGY: "INSTITUTION_TYPOLOGY",
    SUPPLIER: "SUPPLIER",
    BENEFICIARY: "BENEFICIARY",
    BENEFICIARY_EQUIPMENT_STATUS: "BENEFICIARY_EQUIPMENT_STATUS",
    BENEFICIARY_TYPE: "BENEFICIARY_TYPE",
    STUDENT: "STUDENT",
    STUDENT_SCHOOL_YEAR: "STUDENT_SCHOOL_YEAR",
    STUDENT_SCHOOL_LEVEL: "STUDENT_SCHOOL_LEVEL",
    TEACHER: "TEACHER",
    BOND_STATUS: "BOND_STATUS",
    RECRUITMENT_GROUP: "RECRUITMENT_GROUP",
    CONTRACT_MODEL: "CONTRACT_MODEL",
    EQUIPMENT_PROFILE: "EQUIPMENT_PROFILE",
    EQUIPMENT_STATUS: "EQUIPMENT_STATUS",
    EQUIPMENT_TYPE: "EQUIPMENT_TYPE",
    EQUIPMENT: "EQUIPMENT",
    TEACHING_COMPONENT: "TEACHING_COMPONENT",
    RANK: "RANK",
    PROCEDURE: "PROCEDURE",
    TICKET_CATEGORY: "TICKET_CATEGORY",
    TICKET_PRIORITY: "TICKET_PRIORITY",
    TICKET_STATUS: "TICKET_STATUS",
    NUT2: "NUT2",
    NUT3: "NUT3",
    CONTRACT_NUMBER: "CONTRACT_NUMBER",

};

export {ReportParamType};
