import ContractModelType from "app/procedures/contractModel/utils/ContractModelType";


const ContractModelBodySchool = {
    AE: "AE",
    ENA: "ENA",
    EPCCA: "EPCCA",
    ANNEX: "ANNEX",
}


const ContractModelBodyBeneficiary = {
    TEACHER: "TEACHER",
    GUARDIAN: "GUARDIAN",
    STUDENT: "STUDENT",
}


class ContractModelBodyType {

    static from = (contractModelType) => {
        switch (contractModelType) {
            case ContractModelType.SCHOOL:
                return ContractModelBodySchool;
            case ContractModelType.BENEFICIARY:
                return ContractModelBodyBeneficiary;
            default:
                return {};
        }
    }

}



export default ContractModelBodyType;
