import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/action/components/help/locales/locales";
import Collapse from "layout/modules/Collapse/Collapse";
import Col from "react-bootstrap/Col";
import HTMLEditor from "layout/modules/Forms/HTMLEditor/HTMLEditor";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";

const HelpHeaderConfigComponent = props => {

    const {setConfiguration, configuration, readOnly} = props;

    const {t} = useTranslations("ActionGenericComponent", locale);

    const handleContent = useCallback((data) => {
        setConfiguration((config) => ({
            ...config,
            props: {
                ...config.props,
                helpContent: data
            }
        }))
    }, [setConfiguration]);


    if (readOnly) {
        return <></>
    }


    return <Collapse title={t('help_collapse')} open={false}>

        <Row>

            <Col sm={12} lg={12} xl={12}>
                <FormGroup>
                    <Form.Label>{t('title')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('title_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(configuration?.props?.helpTitle)}
                        onChange={(e) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                helpTitle: e.target.value,
                            }

                        }))}/>
                </FormGroup>
            </Col>

            <Col sm={12} lg={12} xl={12}>
                <FormGroup>
                    <Form.Label>{t('message')}</Form.Label>
                    <HTMLEditor
                        {...props}
                        height={100}
                        disabled={readOnly}
                        value={getOrEmpty(configuration?.props?.helpContent)}
                        onChange={handleContent}
                    />
                </FormGroup>
            </Col>


        </Row>


    </Collapse>

}

export default HelpHeaderConfigComponent;
