export const pt = {

    name: "Escolha do(s) equipamento(s)",
    description: "Permite a seleção manual ou automática (em bulk) dos equipamentos.",

    form_description: "Define os equipamento(s) como <strong> {{name}}</strong><strong>.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Deve sempre indicar os tipos de equipamentos que devem ser escolhidos",

    new_status: "Novo estado do equipamento",
    selection_limitation: "Limitações na listagem / atribuição",
    selection_configuration: "Configuração da seleção",
    automatic_related_selection: "Selecionar/incluir automáticamente equipamentos relacionados",
    kit_selection: "Conjunto de equipamentos / kit",
    profile: "Perfil/Tipo de equipamento",
    mandatory: "Obrigatório",

    typology: "Tipologia",
    entry_description: "Descrição",
    entry_description_ph: "Indique uma descrição da tipologia",

    select_equipment: "Escolher",
    remove_equipment: "Remover",


    confirm: "Avançar >",
    cancel: "< Voltar",
    select_equipment_title: "Escolha de equipamento",
    select_mandatory_equipment_msg: "Por favor escolha um <strong>{{description}}</strong> da lista abaixo. Quando tiver selecionado o equipamento utilize  botão <strong>avançar.</strong>",
    select_optional_equipment_msg: "Por favor escolha <strong>opcionalmente</strong> um <strong>{{description}}</strong> da lista abaixo. . Quando tiver selecionado o equipamento, ou no caso de não querer selecionar nenhum equipamento, utilize  botão <strong>avançar.</strong>",

    equipment_selection_title: "Seleção de equipamentos",
    equipment_selection_msg: "Deve utilizar o botão escolher para selecionar os equipamentos de acordo com as instruções.",

    true: "Sim",
    false: "Não",

    FORM_ERROR: "Problema com os equipamentos selecionados",
    FORM_ERROR_MSG: "Por favor verifique a lista de equipamentos selecionados.",
    MISSING_EQUIPMENT: "Deve obrigatóriamente indicar um equipamento",


    bulk_selection: "Seleção de equipamentos em bulk",
    bulk_selection_msg: "Na atribuição de equipamentos a vários beneficiários a escolha dos equipamento(s) é feita de forma automática pela plataforma.",

};

export default pt;
