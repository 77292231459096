import Box from "layout/modules/Box/Box";
import React, {useEffect} from "react";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import {useTranslation} from "react-i18next";
import {useDateTime} from "system/DateTime/DateTime";

const HistoricAgentsComponent = props => {

    const {t} = useTranslation("support");
    const {agents, onLoadAgents, loading} = props;

    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm");

    useEffect(
        () => {
            onLoadAgents();
        },
        [onLoadAgents]
    );

    return <Box>

        <TableAdvanced
            TableAdvanced hover
            loading={loading}>
            <thead>
            <tr>
                <th>
                    {t('date')}
                </th>

                <th>
                    {t('agent')}
                </th>

                <th>
                    {t('user_responsible')}
                </th>


            </tr>
            </thead>
            <tbody>
            {agents?.map((value, key) =>
                <tr key={key}>
                    <td>{getDateAndTime(value.creationTimestamp)}</td>
                    <td className={"fill-empty"}>{value.agent?.name}</td>
                    <td className={"fill-empty"}>{value.creationUser?.name}</td>
                </tr>
            )}

            </tbody>

        </TableAdvanced>


    </Box>
}

export default HistoricAgentsComponent;
