import Modal from "react-bootstrap/Modal";
import React, {Suspense} from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import lazyWithRetry from "system/System/lazyWithRetry";
import "app/files/assets/css/preview.scss";
import Loading from "layout/modules/Loading/Loading";

const FilePreview = lazyWithRetry(() => import("layout/modules/FilePreview/FilePreview"));

const PreviewComponent = (props) => {

    const {file, loading, onClose} = props;
    const {t} = useTranslation("file");

    return <Modal show={true} onHide={onClose} size={"xl"} className={"popup-preview"} backdrop="static"
                  keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{file?.filename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Loading visible={loading}/>
            {file?.contents &&
            <Suspense fallback={<Loading visible={true}/>}>
                <FilePreview
                    type={file.type}
                    content={file.contents}
                />
            </Suspense>
            }

            <div className={"buttons"}>
                <Button variant="primary" onClick={onClose}>
                    {t("close")}
                </Button>
            </div>

        </Modal.Body>
    </Modal>
}

export default PreviewComponent;
