import {useMutate} from "system/Rest/Rest";
import LoginComponent from "app/login/components/login/LoginComponent";
import generateSession, {generateTwoFactorSession} from "app/login/utils/generateSession";
import {useApiState} from "system/API/APIStateContext";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {getOrDefault} from "system/Objects/ObjectParameters";

const LoginContainer = (props) => {

    const {setAuthentication, authentication} = useApiState();
    const location = useLocation();
    const [isSessionExpired, setSessionExpired] = useState(false);

    const {mutate: login, loading, error} = useMutate({
        verb: "POST",
        path: "/auth/",
    });

    useEffect(
        () => {
            if (authentication?.sessionExpired) {
                setSessionExpired(true);
                setAuthentication({});
            }
        },
        [setAuthentication, authentication?.sessionExpired, setSessionExpired]
    );

    return <LoginComponent

        sessionExpired={isSessionExpired}
        error={error}
        state={getOrDefault(location?.state, {})}
        loading={loading}
        onLogin={(data) => {
            if (data.email && data.password) {
                login(data).then((response) => {
                    if (response?.twoFaToken) {
                        setAuthentication(generateTwoFactorSession(response));
                    } else {
                        setAuthentication(generateSession(response));
                    }
                }).catch(() => {
                    setAuthentication({});
                });
            }
        }}

    />

}

export default LoginContainer;
