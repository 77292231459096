import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";
import generateToolBarEntry from "layout/modules/FormBuilder/modules/module/utils/generateToolBarEntry";
import FormModule from 'layout/modules/FormBuilder/modules/module/FormModule'
import EditorComponent from "layout/modules/FormBuilder/modules/input/components/EditorComponent";
import ConfigComponent from "layout/modules/FormBuilder/modules/input/components/ConfigComponent";
import FormComponent from "layout/modules/FormBuilder/modules/input/components/FormComponent";

class InputModule extends FormModule {

    static getName = () => "FormInputModule";
    static getTranslations = () => locale;
    static getToolBarEntry = (t) => generateToolBarEntry(faPenToSquare, t('name'));

    static renderEditor = (props) => <EditorComponent id={props.id} {...props.props}/>
    static renderConfig = (props) => <ConfigComponent  {...props} />
    static renderForm = (props) => <FormComponent {...props} />


    static getDefaultProps = () => ({
        label: null,
        type: "Text",
        mandatory: false,
        placeholder: "",
        validationExpression: ''

    });
}

export default InputModule;
