import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import Button from "react-bootstrap/Button";
import {parseBoolean} from "system/Utils/parseBoolean";

const VariableComponent = React.forwardRef((props, ref) => {

    const {t} = useTranslation('contractModel');
    const {entry: variable, errors = {}, readOnly = false, onChange, onDelete, globalError} = props;

    return <Row>

        <Col xs={12} sm={true} lg={true}>
            <FormGroup error={errors['label'] || globalError} mandatory={true}>
                <Form.Label>{t('variable_label')}</Form.Label>
                <FormControl
                    type="text"
                    ref={ref}
                    autoComplete="chrome-off"
                    placeholder={t('variable_label_ph')}
                    value={getOrEmpty(variable?.label)}
                    disabled={readOnly}
                    ignoresubmit={"true"}
                    onChange={(e) => onChange({
                        ...variable,
                        label: e.target.value,
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['label'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={5} lg={3}>
            <FormGroup error={errors['mandatory'] || globalError}>
                <Form.Label>{t('variable_mandatory')}</Form.Label>
                <BooleanDropdown
                    value={parseBoolean(variable?.mandatory, false)}
                    isClearable={false}
                    isSearchable={false}
                    isDisabled={readOnly || !variable?.label}
                    onChange={(value) => onChange({
                        ...variable,
                        mandatory: value,
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['mandatory'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={true} lg={3}>
            <FormGroup error={errors['variable'] || globalError} mandatory={true}>
                <Form.Label>{t('variable_variable')}</Form.Label>

                <FormControl
                    type="text"
                    placeholder={t('variable_ph')}
                    autoComplete="chrome-off"
                    value={getOrEmpty(variable?.variable)}
                    disabled={readOnly || !variable?.label}
                    ignoresubmit={"true"}
                    onChange={(e) => onChange({
                        ...variable,
                        variable: e.target.value.trim().toLowerCase().replace(/\s/g, ''),
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['variable'])}
                    {t(globalError)}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={7} sm={2} lg={1} className="align-self-center">
            {(onDelete && !readOnly) &&
            <Button className={"btn btn-rounded-danger ms-auto"} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </Button>
            }
        </Col>


    </Row>
});


export default VariableComponent;
