import React from 'react'
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";

const ContractDropdown = (props) => {

    return <RemoteListDropdown
        {...props}
        url={"/equipments/contract-numbers/list"}
        isCreatable={false}
    />


}


export default ContractDropdown;
