export const pt = {
    search: "Pesquisar",
    filter_submit: "Pesquisar",

    filter: "Filtros",
    save_filter: "Guardar pesquisa",
    saved_filters: "Pesquisas guardadas",

    save_filter_confirm_msg: "Caso deseje guardar a pesquisa por filtros que está atualmente ativa deverá indicar um nome identificativo",
    filter_name_ph: "Indique um nome para a pesquisa (ex. Utilizadores Ativos)",
    delete_saved_filter: "Apagar pesquisa guardada",

    CONFIRM_DELETE_SAVED_SEARCH: "Confirmação",
    CONFIRM_DELETE_SAVED_SEARCH_MSG: "Deseja realmente apagar a pesquisa com o nome \"<strong>{{name}}</strong>\"?",
};


export default pt;
