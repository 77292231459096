import EntityComponent from "app/reporting/components/params/types/EntityComponent";
import UserComponent from "app/reporting/components/params/types/UserComponent";
import BooleanComponent from "app/reporting/components/params/types/BooleanComponent";
import TextComponent from "app/reporting/components/params/types/TextComponent";
import DateComponent from "app/reporting/components/params/types/DateComponent";
import DistrictComponent from "app/reporting/components/params/types/DistrictComponent";
import CountyOrParishComponent from "app/reporting/components/params/types/CountyOrParishComponent";
import {ReportParamType} from "app/reporting/utils/ReportParamType";
import OptionalComponent from "app/reporting/components/params/types/OptionalComponent";
import GenderComponent from "app/reporting/components/params/types/GenderComponent";
import GenericEnumComponent from "app/reporting/components/params/types/GenericEnumComponent";
import {EntityType} from "app/entities/utils/EntityType";
import BeneficiaryComponent from "app/reporting/components/params/types/BeneficiaryComponent";
import BeneficiaryType from "types/BeneficiaryType";
import EquipmentProfileComponent from "app/reporting/components/params/types/EquipmentProfileComponent";
import ContractModelComponent from "app/reporting/components/params/types/ContractModelComponent";
import EquipmentTypeComponent from "app/reporting/components/params/types/EquipmentTypeComponent";
import EquipmentStatusComponent from "app/reporting/components/params/types/EquipmentStatusComponent";
import EquipmentComponent from "app/reporting/components/params/types/EquipmentComponent";
import CountryComponent from "app/reporting/components/params/types/CountryComponent";
import RankLevel from "types/RankLevel";
import TeachingComponent from "types/TeachingComponent";
import ProcedureComponent from "app/reporting/components/params/types/ProcedureComponent";
import {SchoolType} from "types/SchoolType";
import TicketCategoryComponent from "app/reporting/components/params/types/TicketCategoryComponent";
import TicketPriority from "app/support/utils/TicketPriority";
import TicketStatus from "app/support/utils/TicketStatus";
import localUsers from "app/users/locales/locales";
import localSchools from "app/schools/locales/locales";
import localEquipments from "app/equipments/equipments/locales/locales";
import localStudents from "app/beneficiaries/students/locales/locales";
import localTickets from "app/support/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import NutComponent from "app/reporting/components/params/types/NutComponent";
import {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import SchoolLevel from "types/SchoolLevel";
import StudentSchoolYear from "types/StudentSchoolYear";
import BeneficiaryEquipmentStatus from "app/beneficiaries/beneficiary/utils/BeneficiaryEquipmentStatus";
import BondStatusComponent from "app/reporting/components/params/types/BondStatusComponent";
import RecruitmentGroupComponent from "app/reporting/components/params/types/RecruitmentGroupComponent";
import ContractNumberComponent from "app/reporting/components/params/types/ContracNumberComponent";

const ReportParameter = props => {

    const {parameter} = props;
    const {t: tUsers} = useTranslations('users', localUsers);
    const {t: tStudents} = useTranslations('students', localStudents);
    const {t: tEquipments} = useTranslations('equipments', localEquipments);
    const {t: tSchools} = useTranslations('schools', localSchools);
    const {t: tDropdown} = useTranslations('classDropdown', locale);
    const {t: tTickets} = useTranslations('tickets', localTickets);

    switch (parameter?.type) {
        case ReportParamType.COUNTRY:
            return <CountryComponent {...props} />
        case ReportParamType.GENDER:
            return <GenderComponent {...props}/>
        case ReportParamType.USER:
            return <UserComponent {...props}/>
        case ReportParamType.BOOLEAN:
            return <BooleanComponent {...props}/>
        case ReportParamType.NUMERIC:
            return <TextComponent {...props} onlyNumeric={true}/>
        case ReportParamType.TEXT:
        case ReportParamType.LOG_PARAMETER:
            return <TextComponent {...props}/>
        case ReportParamType.DATE:
            return <DateComponent {...props}/>
        case ReportParamType.DISTRICT:
            return <DistrictComponent {...props}/>
        case ReportParamType.COUNTY:
            return <CountyOrParishComponent {...props} isParish={false}/>
        case ReportParamType.PARISH:
            return <CountyOrParishComponent {...props} isParish={true}/>
        case ReportParamType.OPTIONAL:
            return <OptionalComponent {...props}/>
        case ReportParamType.ENTITY_TYPE:
            return <GenericEnumComponent {...props} options={EntityType} tObject={tUsers('entityType')}/>
        case ReportParamType.ENTITY:
            return <EntityComponent {...props}/>
        case ReportParamType.SUPPLIER:
            return <EntityComponent {...props} types={EntityType.SUPPLIER}/>
        case ReportParamType.SCHOOL:
            return <EntityComponent {...props} types={EntityType.SCHOOL}/>
        case ReportParamType.BENEFICIARY:
            return <BeneficiaryComponent {...props} />
        case ReportParamType.BENEFICIARY_EQUIPMENT_STATUS:
            return <GenericEnumComponent {...props} options={BeneficiaryEquipmentStatus} t={tStudents}/>
        case ReportParamType.STUDENT:
            return <BeneficiaryComponent {...props} type={BeneficiaryType.STUDENT}/>
        case ReportParamType.TEACHER:
            return <BeneficiaryComponent {...props} type={BeneficiaryType.TEACHER}/>
        case ReportParamType.CONTRACT_MODEL:
            return <ContractModelComponent {...props} />
        case ReportParamType.EQUIPMENT_PROFILE:
            return <EquipmentProfileComponent {...props}/>
        case ReportParamType.EQUIPMENT_TYPE:
            return <EquipmentTypeComponent {...props}/>
        case ReportParamType.EQUIPMENT_STATUS:
            return <EquipmentStatusComponent {...props}/>
        case ReportParamType.EQUIPMENT:
            return <EquipmentComponent {...props} />
        case ReportParamType.BOND_STATUS:
            return <BondStatusComponent {...props}/>
        case ReportParamType.RECRUITMENT_GROUP:
            return <RecruitmentGroupComponent {...props}/>
        case ReportParamType.TEACHING_COMPONENT:
            return <GenericEnumComponent {...props} options={TeachingComponent} t={tDropdown}/>
        case ReportParamType.RANK:
            return <GenericEnumComponent {...props} options={RankLevel} t={tStudents}/>
        case ReportParamType.PROCEDURE:
            return <ProcedureComponent {...props}/>
        case ReportParamType.INSTITUTION_TYPOLOGY:
            return <GenericEnumComponent {...props} options={SchoolType} t={tSchools}/>
        case ReportParamType.BENEFICIARY_TYPE:
            return <GenericEnumComponent {...props} options={BeneficiaryType} t={tEquipments}/>
        case ReportParamType.TICKET_CATEGORY:
            return <TicketCategoryComponent  {...props} />
        case ReportParamType.TICKET_PRIORITY:
            return <GenericEnumComponent {...props} options={TicketPriority} t={tTickets}/>
        case ReportParamType.TICKET_STATUS:
            return <GenericEnumComponent {...props} options={TicketStatus} t={tTickets}/>
        case ReportParamType.STUDENT_SCHOOL_LEVEL:
            return <GenericEnumComponent {...props} options={SchoolLevel} t={tDropdown}/>
        case ReportParamType.STUDENT_SCHOOL_YEAR:
            return <GenericEnumComponent {...props} options={StudentSchoolYear} t={tDropdown}/>
        case ReportParamType.NUT2:
            return <NutComponent {...props} type={NUTType.NUT2}/>
        case ReportParamType.NUT3:
            return <NutComponent {...props} type={NUTType.NUT3}/>
        case ReportParamType.CONTRACT_NUMBER:
            return <ContractNumberComponent {...props}/>
        default:
            return <></>
    }
}

export default ReportParameter;
