import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import ShowComponent from "./ShowComponent";
import OperationDetailComponent from "./OperationDetailComponent";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import "app/audit/assets/css/modal.scss";

const ModalComponent = (props) => {

    const {onClose, operation} = props;
    const {t} = useTranslation('audit');

    return <Modal
        show={true}
        size={"xl"}
        centered
        onHide={onClose}
        className={"audit-modal-operation"}
    >

        <Modal.Header closeButton>
            <Modal.Title>{t('view_operation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <OperationDetailComponent operation={operation}/>
            <ShowComponent {...props} />

        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>
        </ModalButtons>
    </Modal>


}

export default ModalComponent;
