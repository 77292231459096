import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import HTMLEditor from "layout/modules/Forms/HTMLEditor/HTMLEditor";

const FormComponent = (props) => {

    const {article: remoteArticle, errors, onSubmit} = props;
    const {t} = useTranslation('help')

    const [article, setArticle] = useState(remoteArticle);

    return <Form autoComplete="chrome-off">
        <GenericFormError t={t} errors={errors}/>
        <Row>
            <Col sm={12} xl={12} className={"title"}>
                <FormGroup error={errors['title']}>
                    <Form.Label>{t('title')}</Form.Label>
                    <FormControl
                        type="text"
                        id="title"
                        placeholder={t('title_ph')}
                        value={article?.title}
                        onChange={useCallback(
                            e => setArticle(article => ({
                                ...article,
                                title: e.target.value
                            })), [setArticle])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['title'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col sm={12} xl={12} className={"category"}>
                <FormGroup error={errors['category']}>
                    <Form.Label>{t('category')}</Form.Label>
                    <FormControl
                        type="text"
                        id="category"
                        placeholder={t('category_ph')}
                        value={article?.category}
                        onChange={useCallback(
                            e => setArticle(article => ({
                                ...article,
                                category: e.target.value
                            })), [setArticle])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['category'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col sm={12} xl={12} className={"contents"}>
                <FormGroup error={errors['contents']}>
                    <Form.Label>{t('contents')}</Form.Label>
                    <HTMLEditor
                        value={article?.contents}
                        onChange={useCallback(
                            value => setArticle(article => ({
                                ...article,
                                contents: value
                            })), [setArticle])}
                        id={"contents"}/>
                    <Form.Text className="sub-error">
                        {t(errors['contents'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col className={"text-end"}>
                <Button variant="primary" type={"button"} onClick={() => onSubmit(article)}>
                    {t("save_changes")}
                </Button>
            </Col>
        </Row>
    </Form>
}

export default FormComponent;
