import React, {useCallback} from "react";
import useTranslations from "system/Translations/UseTranslations";
import localSchools from "app/schools/locales/locales";
import ListFilterSchoolComponent from "app/schools/components/list/ListFilterComponent";
import HelpHeaderConfigComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderConfigComponent";

const FormConfiguration = props => {

    const {setConfiguration, configuration, readOnly} = props;

    const {t: tSchools} = useTranslations("schools", localSchools);

    const handleFilterChange = useCallback(
        (filter) => {
            setConfiguration((config) => ({
                ...config,
                props: {
                    ...config.props,
                    ...filter
                }
            }))
        },
        [setConfiguration]
    );

    return <>

        <ListFilterSchoolComponent
            t={tSchools}
            readOnly={readOnly}
            searchFilter={configuration.props}
            hideAttributions={true}
            onFilterChange={readOnly ? undefined : handleFilterChange}

        />

        <HelpHeaderConfigComponent {...props}/>

    </>

}

export default FormConfiguration;
