import {UserType} from "types/UserType";

class EntityType {

    static ENTITY = "entity";
    static SCHOOL = "school";
    static SUPPLIER = "supplier";

    static fromUserType = (userType) => {
        switch (userType) {

            case UserType.SUPPLIER:
                return EntityType.SUPPLIER;
            case UserType.SCHOOL:
                return EntityType.SCHOOL;
            case UserType.NORMAL:
                return EntityType.ENTITY;
            default:
                return undefined;

        }
    }

}


export {EntityType};
