import NotificationAreaContainer from "./containers/NotificationAreaContainer";
import Poll from "system/Rest/Poll";
import {useMemo} from "react";
import 'app/notifications/assets/css/notification-popup.scss'

const NotificationArea = props => {


    return <Poll
        path="/notifications/search"
        localErrorOnly={true}
        interval={30 * 1000}
        //interval={20 * 1000} //FOR DEMO
        queryParams={useMemo(() => ({
            //maxAge: 60 * 1000,
            unread: true,
            itemsPerPage: 5,
        }),[])}>
        {(data, refetch) => {
            return <NotificationAreaContainer
                {...props}
                data={data}
                refetch={refetch}/>

        }}
    </Poll>

}

export default NotificationArea;
