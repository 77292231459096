import Row from "react-bootstrap/Row";
import DestinationComponent from "app/support/components/form/DestinationComponent";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import TicketCategoryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/TicketCategoryDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import FormControl from "layout/modules/Forms/Control/FormControl";
import NewMessageComponent from "app/support/components/form/NewMessageComponent";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/support/locales/locales";

const FormRequestSupportComponent = props => {

    const {errors = {}, request, setRequest, actionMode} = props;
    const {t} = useTranslations('support', locale);

    return <>

        <Row>
            {!actionMode &&
            <DestinationComponent
                {...props}
                excludeSelf={true}
                request={request}
                setRequest={setRequest}
                errors={errors}
            />
            }

            <Col sm={12} md={12}>
                <FormGroup mandatory={true} error={errors['categoryId']}>
                    <Form.Label>{t('category')}</Form.Label>
                    <TicketCategoryDropdown
                        value={getOrNull(request?.categoryId)}
                        isClearable={false}
                        onChange={useCallback(
                            (type) => setRequest(r => ({
                                ...r,
                                categoryId: type
                            })),
                            [setRequest]
                        )}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['categoryId'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} md={12}>
                <FormGroup mandatory={true} error={errors['title']}>
                    <Form.Label>{t('subject')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('subject_ph')}
                        value={getOrEmpty(request.title)}
                        onChange={useCallback((e) => setRequest(r => ({
                            ...r,
                            title: e.target.value,
                        })), [setRequest])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['title'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>

        <NewMessageComponent actionMode={actionMode} request={request} setRequest={setRequest} id={"message"} errors={errors}/>
    </>


}

export default FormRequestSupportComponent;
