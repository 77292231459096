import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import {useGet} from "system/Rest/Rest";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrDefault, getOrNull} from "system/Objects/ObjectParameters";


const DistrictComponent = props => {

    const {value, setValue, parameter, error, readOnly} = props;

    const {data: districts, loading} = useGet({
        path: "/address/search-district",
        resolve: (data) => data.reduce((data, item) => ({
            ...data,
            [item.name]: item.id
        }), {})
    });

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EnumDropdown
                isLoading={loading}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                options={getOrDefault(districts, {})}
                value={getOrNull(value)}
                onChange={setValue}
            />

            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default DistrictComponent;
