import {isString} from "system/Utils/isString";

const regexLink = /(?:LINK\()([\s\S]*)(?:\))/gm;
const regexOperation = /(?:OPERATION\()([\s\S]*)(?:\))/gm;

const isByRegex = (entry, regex) => {

    if (!isString(entry))
        return false;

    const result = entry?.match(regex);
    return !!result;

}


const isLink = (entry) => isByRegex(entry, regexLink);
const isOperation = (entry) => isByRegex(entry, regexOperation);



const geLinkUrl = (entry) => entry?.replace("LINK(", "").replace(")","");
const getOperationId = (entry) => entry?.replace("OPERATION(", "").replace(")","");

export {isLink, geLinkUrl, getOperationId, isOperation}
