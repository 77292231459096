import React from "react";
import GenericVersionsContainer from "modules/VersionsContainer/VersionsContainer";
import FormComponent from "app/procedures/contractModel/components/form/FormComponent";
import ContractModelResolver from "app/procedures/contractModel/utils/ContractModelResolver";

const ContractModelVersionContainer = (props) => {

    return <GenericVersionsContainer
        {...props}
        path={"/contract-models/" + props.id + "/versions"}
        onResolve={ContractModelResolver}>
        <CompareComponent/>
    </GenericVersionsContainer>
}


const CompareComponent = (props) => {

    const {version, changes, diffs, fullChanged, disableChanges} = props;

    if (!version)
        return <></>;

    return <FormComponent
        owner={version.entity}
        compareMode={true}
        fullChanged={disableChanges ? undefined : fullChanged}
        changes={disableChanges ? {} : changes}
        errors={diffs}
    />
}


export default ContractModelVersionContainer;
export {CompareComponent};
