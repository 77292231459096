export const pt = {


    placeholder: "Escola uma opção",
    no_results: "Nenhum resultado encontrado",


    role_ph: "Selecione um perfil",
    role_no: "Nenhum perfil encontrado",
    role_loading: "A procurar Perfis..",

    entity_ph: "Selecione uma entidade",
    entity_loading: "A procurar entidades..",
    entity_no: "Nenhuma entidade encontrada",

    entity_vat_no: "Sem resultados. Por favor introduza o NIF completo",
    entity_vat_loading: "A procurar entidade pelo NIF...",
    entity_vat_ph: "Por favor introduza o NIF da entidade",

    generic_no: "Nenhum resultado encontrado",
    generic_loading: "A procurar..",
    generic_ph: "Selecione uma opção",

    versions_no: "Versão não encontrada",
    version_ph: "Selecione uma versão",
    versions_loading: "A procurar versão...",

    sg_ph: "Escolha ou pesquise uma unid. orgânica",
    sg_no: "Sem resultados. Por favor introduza o nome da U.O",
    sg_loading: "A procurar uo...",

    category_ph: "Escolha ou pesquise uma categoria",
    category_no: "Não foram encontrados resultados",
    category_loading: "A procurar categoria...",

    supplier_ph: "Escolha um fornecedor",

    school_level_ph: "Por favor indique um nível de ensino",
    BASIC: "Básico",
    SECONDARY: "Secundário",

    school_year_ph: "Por favor indique um ano de ensino",
    Y1: "1º",
    Y2: "2º",
    Y3: "3º",
    Y4: "4º",
    Y5: "5º",
    Y6: "6º",
    Y7: "7º",
    Y8: "8º",
    Y9: "9º",
    Y10: "10º",
    Y11: "11º",
    Y12: "12º",

    recruitment_group_ph: "Escolha ou pesquise um grupo de recrutamento",
    teacher_bond_ph: "Escolha ou pesquise o vínculo",
    teaching_component_ph: "Indique a componente de ensino",
    FULL: "Total",
    PARTIAL: "Parcial",

    school_class_ph: "Escolha ou pesquise uma turma",
    AE: "Agrupamento Escolas",
    AE_SCHOOL: "Escola de agrupamento",
    ENA: "Escola não agrupada",

    NO_DATA: "Sem dados",



};

export default pt;
