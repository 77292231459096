import React, {useMemo} from 'react'
import locale from "../locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const SimpleListDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "equipmentStatusId",
        ids,
        remotePath,
        remoteClassPath,
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 20,
        filterExtraProps = {},
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder, t('generic_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('generic_loading'))}
        remotePath={remotePath}
        remoteClassPath={remoteClassPath ?? remotePath}
        searchFilter={useMemo(() => ({
            ids: ids,
            ...filterExtraProps,
            currentPage: 0,
            itemsPerPage: maxItems,
        }), [maxItems, filterExtraProps, ids])}
    />

}

export default SimpleListDropdown;
