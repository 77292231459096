import React from 'react';
import LoginContainer from "app/login/containers/LoginContainer";
import BasePageLayout from "app/login/components/BasePageLayout";

const LoginComponent = (props) => {

    return <BasePageLayout>
        <LoginContainer {...props} />
    </BasePageLayout>
}

export default LoginComponent;
