import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import Tab from "layout/modules/Tabs/Tab";
import Tabs from "layout/modules/Tabs/Tabs";
import HistoricStatusComponent from "app/support/components/view/historic/HistoricStatusComponent";
import HistoricAgentsComponent from "app/support/components/view/historic/HistoricAgentsComponent";

const HistoricComponent = props => {

    const {onClose} = props;
    const {t} = useTranslation("support");

    return <Modal show={true} onHide={() => onClose()} size={"xl"}>
        <Modal.Header closeButton>
            <Modal.Title>{t('historic')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tabs defaultActiveKey="status" id="tab-ticket-historic" unmountOnExit={true} mountOnEnter={true}>
                <Tab eventKey="status" title={t('historic_status')}>
                    <HistoricStatusComponent {...props} />
                </Tab>

                <Tab eventKey="agents" title={t('historic_agents')}>
                    <HistoricAgentsComponent {...props}/>
                </Tab>

            </Tabs>


        </Modal.Body>

        <Modal.Footer>

            <Button variant={'danger'} onClick={onClose}>
                {t('close')}
            </Button>

        </Modal.Footer>

    </Modal>
}

export default HistoricComponent;
