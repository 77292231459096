import {useGet} from "system/Rest/Rest";
import ContractModelResolver from "app/procedures/contractModel/utils/ContractModelResolver";
import Loading from "layout/modules/Loading/Loading";
import {faFileContract} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/contractGenerator/locales/locales";
import Row from "react-bootstrap/Row";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Col from "react-bootstrap/Col";
import GenericFormError from "modules/Forms/Errors/FormGenericError";

const ExecuteComponent = (props) => {

    const {config, setData, data, errors = {}} = props;
    const {t} = useTranslations("ContractGeneratorRuleAction", locale);

    const {data: model, loading} = useGet({
        path: "/contract-models/" + config?.contractModelId,
        lazy: !config?.contractModelId,
        resolve: ContractModelResolver,
    });

    if (!model) {
        return <Loading visible={true}/>
    }

    return <div className={"execute-contract-generator-rule"}>
        <GenericFormError t={t} errors={errors}/>
        <Loading visible={loading}/>
        <AdvanceInfoMessage
            icon={faFileContract}
            type={"success"}
            iconType={"success"}
            title={t('contract_info_title')}
            message={t('contract_info_message', model)}
        />

        {(Array.isArray(model?.variables) && model?.variables.length > 0) &&

            <div className={"contract-variables"}>
                <Row>

                    {model?.variables.map((variable, index) => {

                        const varName = variable.variable;

                        return <Col sm={12} lg={12} key={varName + "-" + index}>
                            <FormGroup error={errors[varName]} mandatory={variable.mandatory}>
                                <Form.Label>{variable.label}</Form.Label>
                                <FormControl
                                    type="textarea"
                                    as="textarea"
                                    rows={3}
                                    value={getOrEmpty(data[varName])}
                                    onChange={(e) => setData((data) => ({
                                        ...data,
                                        [varName]: e.target.value,
                                    }))}
                                />
                                <Form.Text className="sub-error">
                                    {t(errors[varName])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    })}


                </Row>


            </div>

        }

    </div>
}

export default ExecuteComponent;
