import React, {useMemo} from 'react'
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import {Status} from "types/Status";

const TicketCategoryDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "categoryId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        restrictedUserType,
        status = Status.ACTIVE,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder, t('category_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('category_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('category_loading'))}
        remotePath={"/ticket-categories/search"}
        remoteClassPath={"/ticket-categories/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
            restrictedUserType: restrictedUserType,
            status: status
        }), [maxItems, restrictedUserType,status])}/>

}


export default TicketCategoryDropdown;
