import {faSchool} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import locale from "app/ruleTemplates/actions/schoolSelection/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import FormConfiguration from "app/ruleTemplates/actions/schoolSelection/components/FormConfiguration";
import "app/ruleTemplates/actions/schoolSelection/assets/editor.scss";

const FormEditor = configuration => {

    const {t} = useTranslations("SchoolSelectionRuleAction", locale);

    return <div className={"form-action-editor form-action-select-equipment"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faSchool}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        <div className={"description view-mode"}>
            <FormConfiguration configuration={configuration} readOnly={true}/>
        </div>
    </div>
}

export default FormEditor;
