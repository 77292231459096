import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faComment} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/notification/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/notification/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/notification/components/FormEditor";


class NotificationRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faComment, t('name'), t('description'));

    static getName = () => "NotificationAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static isAllowed = (config, position) => true;

}

export default NotificationRuleAction;
