import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const EditorComponent = props => {

    const {id, setData, data, configuration, readOnly, errorMessage} = props;

    return <Col lg={12} sm={12}>
        <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
            <Form.Label>{configuration.label}</Form.Label>
            <Form.Control
                disabled={readOnly}
                type="textarea"
                as="textarea"
                rows={3}
                value={getOrEmpty(data[id])}
                onChange={(e) => setData(data => ({
                    ...data,
                    [id]: e.target.value,
                }))}
            />
            <Form.Text className="sub-error">
                {errorMessage}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EditorComponent;
