import getModuleFromEntry from "layout/modules/FormBuilder/utils/editor/getModuleFromEntry";
import {useMemo} from "react";
import InputModule from "layout/modules/FormBuilder/modules/input/InputModule";
import TextAreaModule from "layout/modules/FormBuilder/modules/textArea/TextAreaModule";
import DropdownModule from "layout/modules/FormBuilder/modules/dropdown/DropdownModule";
import CalendarModule from "layout/modules/FormBuilder/modules/calendar/CalendarModule";
import UploadModule from "layout/modules/FormBuilder/modules/upload/UploadModule";
import HelpModule from "layout/modules/FormBuilder/modules/help/HelpModule";
import Row from "react-bootstrap/Row";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/locales/locales";
import "layout/modules/FormBuilder/assets/css/execute.scss";

const FormRender = props => {

    const {modules: externalModules = [], ...otherProps} = props;
    const {setFormData, formData, data, readOnly, errors = {}, getFileContent} = otherProps;
    const {t} = useTranslations("form-editor", locale);

    const modules = useMemo(
        () => [
            InputModule,
            TextAreaModule,
            DropdownModule,
            CalendarModule,
            UploadModule,
            HelpModule,
            ...externalModules
        ],
        [externalModules]
    );

    return <Row className={"form-execution-row"}>
        {data.map((entry) => getModuleFromEntry(modules, entry).renderForm({
            id: entry.id,
            getFileContent: getFileContent,
            error: errors[entry.id],
            errorMessage: errors[entry.id] ? t(errors[entry.id]) : undefined,
            subModule: entry.subModule,
            configuration: entry.props,
            readOnly: readOnly,
            key: entry.id,
            data: formData,
            setData: setFormData
        }))}
    </Row>

}

export default FormRender;
