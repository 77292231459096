import appConfig from "configs/config";

export const pt = {
    title_dashboard: appConfig.name,

    alerts: "Informações",
    close: "Fechar",
    close_and_discard: "Fechar e não voltar a mostrar",


    //Search
    EQUIPMENTS: "Equipamentos",
    BENEFICIARIES: "Beneficiários",

    search_label: "Pesquisar",
    search_ph_equipments: "Pesquisar equipamentos",
    search_ph_beneficiary: "Pesquisar beneficiários",
    no_results: "Não foram encontrados <strong>{{type}}</strong> para a pesquisa <strong>\"{{query}}\"</strong>",

    //tab;
    notifications: "Notificações",
    statistics: "Gráficos",

    //Charts
    placeholder: "Selecione uma opção",
    kits: "Tipo",
    KITS: "Kit portáteis",
    LED: "LED",
    VIDEO: "Videoprojetores",
    DESKTOP: "Computadores desktop",
    nut3: "NUT III",
    procedure: "Fase",
    supplier: "Fornecedor",
    school_type: "Tipo de unidade orgânica",
    school: "Unidade orgânica",

    AE: "Agrup. Escolas",
    ENA: "Escola não agrupada",
    AE_SCHOOL: "Escola de agrupamento",

    no_chart_results: "Os critérios de pesquisa/filtro que definiu não produziram resultados. Por favor tente alterar as configurações do filtro",

    //buttons
    view_table: "Modo tabela",
    view_chart: "Modo gráfico",
    filters: "Filtros",
    download_chart: "Exportar/download gráfico",
    download_csv: "Exportar csv",

    //Equipment Status
    contract_number: "Contrato",
    equipment_status: "Estado dos equipamentos",
    beneficiary_status: "Estado dos beneficiários",
    status: "Estado",
    total: "Total",
    percentage: "Percentagem",

    //Students
    type: "Tipo",
    rank: "Escalão",
    NO_EQUIPMENT: "Sem equipamento",
    WITH_EQUIPMENT: "Com equipamento",
    NO_RANK: "Sem escalão",
    REFUSED: "Com equipamento recusado",
    PARTIAL_EQUIPMENT: "Com kit incompleto",
    STUDENT: "Aluno",
    STUDENT_NO_RANK: "Aluno sem escalão",
    STUDENT_A: "Aluno escalão A",
    STUDENT_B: "Aluno escalão B",
    STUDENT_C: "Aluno escalão C",
    TEACHER: "Docente",

    //Contracts
    contract_status: "Estado dos contratos",
    model_type: "Tipo contrato",
    SCHOOL: "Escola - SGEC",
    BENEFICIARY: "Escola - Beneficiário",
    SIGNED_BOTH: "Totalmente assinado",
    SIGNED_ORIGIN: "Assinado pelo primeiro outorgante",
    SIGNED_COUNTERPARTY: "Assinado apenas pelo segundo outorgante",
    NOT_SIGNED: "Sem assinaturas",

    //needs
    equipment_needs: "Equipamentos em falta",
    equipmentNeeds: "Saldo",
    equipmentTypology: "Tipologia",
    totalProcesses: "Num. Processos",
    totalBeneficiaries: "Beneficiários",
    totalBeneficiariesWithEquipment: "Beneficiários com equipamento",
    totalBeneficiariesWithoutEquipment: "Beneficiários sem equipamento",
    totalEquipments: "Equipamentos",
    less_equipments: "Existem {{value}} equipamentos em falta",
    more_equipments: "Existem {{value}} equipamentos em excesso",

    //Non compliance
    non_compliance: "Não conformidades",
    open: "A decorrer",
    closed: "Fechadas",
    SUPPLIER: "Fornecedor",

    //audits
    audits: "Estado dos processos de auditoria",





};

export default pt;
