import FormToolbarEntryComponent
    from "layout/modules/FormBuilder/components/editor/toolbar/FormToolbarEntryComponent";

const FormToolbarComponent = props => {

    const {onAddComponent, modules} = props;

    return <div className={"toolbar-entries"}>
        {modules.map((module) => <FormToolbarEntryComponent
            onAddComponent={onAddComponent}
            module={module}
            key={module.getName()}/>)}
    </div>
}

export default FormToolbarComponent;
