const StudentSchoolYearBasic = {
    Y1: "Y1",
    Y2: "Y2",
    Y3: "Y3",
    Y4: "Y4",
    Y5: "Y5",
    Y6: "Y6",
    Y7: "Y7",
    Y8: "Y8",
    Y9: "Y9",
};

const StudentSchoolYearSecondary = {
    Y10: "Y10",
    Y11: "Y11",
    Y12: "Y12"
};

const StudentSchoolYear = {
    ...StudentSchoolYearBasic,
    ...StudentSchoolYearSecondary,
};



export default StudentSchoolYear;
export {StudentSchoolYearBasic,StudentSchoolYearSecondary}
