import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import classnames from "classnames";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import BaseTemplateForm from "./BaseTemplateForm";
import ParamsComponent from "./ParamsComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import RestrictionsComponent from "./RestrictionsComponent";
import AgreementComponent from "app/reporting/components/templates/form/AgreementComponent";
import Audit from "app/audit/Audit";
import {GeneratedReportActions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes, ReportObjectTypes} from "app/audit/utils/AuditObjectTypes";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import "app/reporting/assets/css/form.scss";
import QueryComponent from "app/reporting/components/templates/form/QueryComponent";
import Loading from "layout/modules/Loading/Loading";

const FormComponent = props => {

    const {t} = useTranslation("reports");
    const {create, loading, template: remoteTemplate = {}, errors = {}, onSubmit, compare} = props;

    const [readOnly, setReadOnly] = useState(!create);
    const [template, setTemplate] = useState(remoteTemplate);

    return <div className={classnames("report-template form-component", {
        "view-mode": readOnly,
        "compare-mode": compare,
    })}>
        <Loading visible={loading}/>
        <Form>
            {!compare && <GenericFormError t={t} errors={errors}/>}
            <FormHandleNotSaved
                remote={remoteTemplate}
                local={template}
                loading={loading}
                readOnly={readOnly}
                setLocal={setTemplate}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}/>

            <Box>
                <BoxTitle>{t('template_base_config')}</BoxTitle>
                <BoxOptions>

                    {(!create && !compare) &&
                    <>
                        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                            <Audit
                                objectId={template?.id}
                                filterActions={{
                                    ...GeneratedReportActions,
                                }}
                                filterObjectTypes={ReportObjectTypes}
                                affectedObjectType={[
                                    AuditObjectTypes.Report,
                                    AuditObjectTypes.ReportSchedule,
                                ]}
                            />
                        </UserHasPermission>

                        <ToolTip content={t('edit_template')}>
                            <div className={classnames("btn btn-rounded-danger small", {"active": !readOnly})}
                                 onClick={() => setReadOnly(readOnly => !readOnly)}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </div>
                        </ToolTip>

                    </>
                    }


                </BoxOptions>

                <BaseTemplateForm
                    t={t}
                    template={template}
                    setTemplate={setTemplate}
                    readOnly={readOnly}
                    errors={errors}
                />

            </Box>

            <Box>
                <BoxTitle>{t('query')}</BoxTitle>
                <QueryComponent
                    t={t}
                    template={template}
                    setTemplate={setTemplate}
                    readOnly={readOnly}
                    errors={errors}

                />

            </Box>


            {(!readOnly || template?.parameters?.length > 0) &&
            <ParamsComponent
                t={t}
                template={template}
                setTemplate={setTemplate}
                readOnly={readOnly}
                errors={errors}/>
            }

            {(!readOnly || template?.restrictions?.length > 0) &&
            <RestrictionsComponent
                t={t}
                template={template}
                setTemplate={setTemplate}
                readOnly={readOnly}
                errors={errors}/>
            }

            <AgreementComponent
                t={t}
                template={template}
                setTemplate={setTemplate}
                readOnly={readOnly}
                errors={errors}/>


            {!readOnly &&
            <Row>
                <Col className={"text-end"}>
                    <Button variant="primary" className={"submit"} onClick={() => onSubmit(template)}>
                        {create ? t("create_form") : t("save_form")}
                    </Button>
                </Col>
            </Row>
            }

        </Form>
    </div>

}

export default FormComponent;
