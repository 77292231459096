import {useCallback} from "react";
import {useHeaderProps} from "layout/modules/BaseLayout/ContentArea/ContentArea";
import {useIsMobile} from "system/Window/Mobile";

const useScrollToError = () => {

    const {ref} = useHeaderProps();
    const isMobile = useIsMobile();

    return useCallback(
        () => {
            const items = document.getElementsByClassName('form-group error');
            const visible = [...items].filter((el) => {
                return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
            });

            if (visible.length > 0) {
                const currentScroll = isMobile
                    ? document.documentElement.scrollTop
                    : ref?.current?.scrollTop;

                const element = items[0];
                const topPos = element.getBoundingClientRect().top + currentScroll;

                if (isMobile) {
                    window.scrollTo({
                        top: topPos - 200,
                        behavior: 'smooth'
                    });
                } else {
                    ref?.current?.scrollTo({
                        top: topPos - 100,
                        behavior: 'smooth'
                    });
                }
            }
        },
        [ref, isMobile]
    );

}

export default useScrollToError;
