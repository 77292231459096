import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";

const ConfigurationComponent = props => {

    const {t} = useTranslation("form-editor");
    const {onClose, onEditComponentProps, configuration: defaultConfig, module, otherProps} = props;
    const [configuration, setConfiguration] = useState(defaultConfig);

    return <Modal show={true} onHide={() => onClose()} size={"lg"} centered>
        <Modal.Header closeButton>
            <Modal.Title>{t('configuration_modal')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {module.renderConfig({
                ...otherProps,
                configuration: configuration,
                setConfiguration: setConfiguration,
            })}


        </Modal.Body>

        <Modal.Footer>

            <Button variant={'secondary'} onClick={onClose}>
                {t('close')}
            </Button>

            <Button variant={'primary'} onClick={() => {
                onEditComponentProps(configuration);
                onClose();
            }}>
                {t('save_configuration')}
            </Button>


        </Modal.Footer>
    </Modal>
}

export default ConfigurationComponent;
