export const pt = {

    ALL: "Todos",
    GEOFENCE: "Limitado Geograficamente",
    IN_ENTITY: "Apenas da Entidade",

    YES: "Sim",
    NO: "Não",

    TRUE: "Sim",
    FALSE: "Não",

    ACTIVE: "Ativo",
    INACTIVE: "Inativo",

    NORMAL: "Utilizador Entidade",
    SCHOOL: "Utilizador escola",
    SUPPLIER: "Utilizador fornecedor",
    ANY: "Todos",


    ADD: "Adição",
    EDIT: "Edição",

    placeholder: "Selecione uma opção",
    no_results: "Não foram encontrados resultados",
    loading_message: "A pesquisar..",

    select_or_type_placeholder: "Escolha ou escreva uma opção",
};

export default pt;
