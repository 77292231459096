import {useMutate} from "system/Rest/Rest";

const useRuleRemoteControl = props => {

    const {mutate: validate, loading, error} = useMutate({
        verb: "POST",
        debounceRequest: true,
        path: "/rule-executions/validate",
        localErrorOnly: true,
    });


    const {mutate: execute, loading: executing} = useMutate({
        verb: "POST",
        debounceRequest: true,
        path: "/rule-executions/",
    });


    return {
        validate: validate,
        execute: execute,
        loading: executing || loading,
        errors: error?.data?.errors,
    }

}

export default useRuleRemoteControl;
