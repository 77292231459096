import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faLaptop, faMagnifyingGlassChart, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const AlertsComponent = props => {

    const {ticket} = props;
    const {t} = useTranslation("support");


    return <>

        {ticket?.nonComplianceId &&
            <AdvanceInfoMessage
                icon={faMagnifyingGlassChart}
                type={"info"}
                iconType={"info"}
                title={t("ticket_is_associated_with_non_compliance")}
            >
                <Trans>
                    {t('ticket_is_associated_with_non_compliance_msg', ticket)}
                    <UserHasPermission permission={["READ_NON_COMPLIANCE"]} any={true}>
                        <br/><Link to={"/non-compliances/" + ticket.nonComplianceId}>
                        {t('ticket_is_associated_with_non_compliance_link')}
                    </Link>
                    </UserHasPermission>
                </Trans>
            </AdvanceInfoMessage>
        }

        {ticket?.auditProcessId &&
            <AdvanceInfoMessage
                icon={faMagnifyingGlassChart}
                type={"info"}
                iconType={"info"}
                title={t("ticket_is_associated_with_audit")}
            >
                <Trans>
                    {t('ticket_is_associated_with_audit_msg', ticket)}
                    <UserHasPermission permission={["UPDATE_AUDIT_PROCESS", "MANAGE_AUDIT_PROCESS"]} any={true}>
                        <br/><Link to={"/audit-process/" + ticket.auditProcessId}>
                            {t('ticket_is_associated_with_audit_msg_link')}
                        </Link>
                    </UserHasPermission>
                </Trans>
            </AdvanceInfoMessage>
        }

        {ticket?.equipment?.id &&
            <AdvanceInfoMessage
                icon={faLaptop}
                type={"info"}
                iconType={"info"}
                title={t("ticket_is_associated_with_equipment", ticket.equipment)}
            >
                <Trans>
                    {t('ticket_is_associated_with_equipment_msg', ticket.equipment)}
                    <Link to={"/equipments/" + ticket.equipment.id}>
                        {t('ticket_is_associated_with_equipment_msg_link')}
                    </Link>
                </Trans>
            </AdvanceInfoMessage>

        }

        <UserHasPermission permission={'MANAGE_TICKETS'}>
            {ticket?.transferredToId &&
                <AdvanceInfoMessage
                    icon={faPaperPlane}
                    type={"info"}
                    iconType={"info"}
                    title={t("ticket_is_transferred")}
                >
                    {t('ticket_was_transferred_msg')}
                    <Link to={"/support/" + ticket.transferredToId}>
                        {t('ticket_is_transferred_msg_link')}
                    </Link>
                </AdvanceInfoMessage>
            }

            {ticket?.originTicketId &&
                <AdvanceInfoMessage
                    icon={faPaperPlane}
                    type={"info"}
                    iconType={"info"}
                    title={t("ticket_is_transferred")}
                >
                    <Trans>
                        {t('ticket_is_transferred_msg', {entityName: ticket.destinationEntity?.name})}
                    </Trans>
                    <Link to={"/support/" + ticket.originTicketId}>
                        {t('ticket_is_transferred_msg_link')}
                    </Link>
                </AdvanceInfoMessage>

            }
        </UserHasPermission>
    </>
}

export default AlertsComponent;
