import getAsArray from "system/Arrays/getArray";

const FILE_ID = "FILE_ID";

const HiddenHeaders = [
    FILE_ID
];


export default HiddenHeaders;


const getFileIDs = (result) => {

    const fileIds = [];
    const filePositions = getAsArray(result?.headers?.map((h, p) => h === FILE_ID ? p : false).filter(e => e !== false));

    result?.rows.forEach(
        (r) => {
            const partFileId = filePositions.map(p => r[p]);
            fileIds.push(...partFileId);
        }
    );

    return fileIds.filter(id => id != null);
}

export {
    FILE_ID,
    getFileIDs
};
