import isFunction from "system/Utils/isFunction";

const replaceConfigProps = (data, position, props) => data.map(
    (d, index) =>
        index === position
            ? {
                ...d,
                props: isFunction(props) ? props(d.props) : {...props}
            }
            : {...d}
);


export default replaceConfigProps;
