import {EntityType} from "app/entities/utils/EntityType";

class UserType {

    static NORMAL = "NORMAL";
    static SCHOOL = "SCHOOL";
    static SUPPLIER = "SUPPLIER";

    static fromEntityType = (entityType) => {
        switch (entityType) {

            case EntityType.SUPPLIER:
                return UserType.SUPPLIER;
            case EntityType.SCHOOL:
                return UserType.SCHOOL;
            case EntityType.ENTITY:
                return UserType.SCHOOL;
            default:
                return undefined;

        }
    }

}

export {UserType};

