import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContractPreviewComponent from "app/procedures/contractModel/components/form/ContractPreviewComponent";
import "layout/modules/Forms/HTMLEditor/assets/htmlEditor.scss";
import HTMLEditor from "layout/modules/Forms/HTMLEditor/HTMLEditor";
import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {useTranslation} from "react-i18next";

const FormBodyComponent = props => {

    const {value, errors = {}} = props;
    const {t} = useTranslation('contractModel');

    return <Row>

        <Col lg={6} className={"col-html-editor"}>
            <FormGroup error={Object.keys(errors).length > 0} mandatory={true}>
                <HTMLEditor
                    {...props}
                    value={value?.body}
                />
                <Form.Text className="sub-error">
                    {t(errors['body'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col lg={6}>
            <ContractPreviewComponent {...props}/>
        </Col>
    </Row>

}


export default FormBodyComponent;
