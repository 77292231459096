import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import Select from 'react-select'
import useTimeZoneList, {DefaultTimeZone} from "app/users/utils/timezones"
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Audit from "app/audit/Audit";
import {useApiState} from "system/API/APIStateContext";
import useTranslationList from "app/users/utils/translations";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {Actions} from "app/audit/utils/AuditActions";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import Observations from "app/observations/Observations";
import Loading from "layout/modules/Loading/Loading";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {UserType} from "types/UserType";
import RoleDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RoleDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import StatusDropdown from "layout/modules/Forms/Dropdowns/StatusDropdown/StatusDropdown";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import GlobalEntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/GlobalEntityDropdown";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import {Status} from "types/Status";


const FormComponent = (props) => {

    const {
        user: remoteUser,
        loading = true,
        onSubmit,
        create = false,
        errors = {},
        onEditMode,
        compare,
        viewMode
    } = props;

    const {t} = useTranslation('user');
    const {authentication} = useApiState();
    const translations = useTranslationList();
    const timeZones = useTimeZoneList();

    const [user, setUser] = useState(remoteUser ?? {
        timeZone: DefaultTimeZone.value,
        status: Status?.ACTIVE,
        receiveNotifications: true,
        entity: authentication?.entity,
        entityId: authentication?.entity.id,

    });
    const [readOnly, setReadOnly] = useState(viewMode);

    //Load student
    useEffect(() => {
        if (user === null)
            setUser(remoteUser);
    }, [remoteUser, user, setUser]);

    //Update EditMode
    useEffect(() => {
        if (onEditMode)
            onEditMode(!readOnly);
    }, [onEditMode, readOnly]);

    return <div className={classnames("limited-width", {"view-mode": (viewMode && readOnly)})}>
        <Loading visible={loading}/>

        <FormHandleNotSaved
            remote={remoteUser}
            local={user}
            loading={loading}
            readOnly={readOnly}
            setLocal={setUser}
            setReadOnly={setReadOnly}
            onSubmit={onSubmit}
        />

        {!compare && <GenericFormError t={t} errors={errors}/>}
        <Form autoComplete="chrome-off">
            <Box className={"mandatory"}>
                <BoxTitle>
                    {create ? t("create_user") : t('general')}
                </BoxTitle>

                <BoxOptions>
                    {(viewMode && !compare) &&
                    <>
                        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                            <Audit
                                userId={user.id}
                                filterActions={Actions}
                                filterObjectTypes={AuditObjectTypes}
                            />
                        </UserHasPermission>

                        <UserHasPermission permission={["READ_OBSERVATION"]}>
                            <Observations/>
                        </UserHasPermission>

                        <UserHasPermission permission="WRITE_USER">
                            <Button className={classnames("btn btn-rounded-danger small", {"active": !readOnly})}
                                    onClick={() => setReadOnly(!readOnly)}>
                                <FontAwesomeIcon icon={faPencilAlt}/>
                            </Button>
                        </UserHasPermission>
                    </>
                    }
                </BoxOptions>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['name']}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                id="name"
                                placeholder={t('name_ph')}
                                value={getOrEmpty(user?.name)}
                                disabled={readOnly}
                                onChange={useCallback((e) => setUser((data) => ({
                                    ...data,
                                    name: e.target.value,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['email']}>
                            <Form.Label>{t('email')}</Form.Label>
                            <FormControl
                                type="text"
                                id="email"
                                placeholder={t('email_ph')}
                                value={getOrEmpty(user?.email)}
                                disabled={!create}
                                onChange={useCallback((e) => setUser((data) => ({
                                    ...data,
                                    email: e.target.value,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['email'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <UserHasPermission permission={["READ_ENTITY", "READ_BENEFICIARY", "READ_SUPPLIER"]}>
                        <Col sm={12} lg={6}>
                            <FormGroup error={errors['entityId']}>
                                <Form.Label>{t('entity')}</Form.Label>
                                <GlobalEntityDropdown
                                    id={"entityId"}
                                    value={getOrNull(user?.entityId)}
                                    isDisabled={readOnly}
                                    onChange={(id, entity) => setUser(user => ({
                                        ...user,
                                        entityId: id,
                                        entity: entity,
                                        roleId: null,
                                    }), [setUser])}

                                />

                                <Form.Text className="sub-error">
                                    {t(errors['entityId'])}
                                </Form.Text>
                            </FormGroup>
                        </Col>
                    </UserHasPermission>

                    {readOnly &&
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['userType']}>
                            <Form.Label>{t('user_type')}</Form.Label>
                            <EnumDropdown
                                value={getOrNull(user?.type)}
                                isDisabled={true}
                                options={UserType}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['entityId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                    }

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['roleId']}>
                            <Form.Label>{t('role')}</Form.Label>
                            <RoleDropdown
                                value={getOrNull(user?.roleId)}
                                userEntityId={user?.entityId || authentication?.entity?.id}
                                isDisabled={readOnly || !user?.entityId}
                                excludeUnavailableForUser={true}
                                onChange={(id) => setUser(user => ({
                                    ...user,
                                    roleId: id,
                                }))}
                            />

                            <Form.Text className="sub-error">
                                {t(errors['roleId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['language']}>
                            <Form.Label>{t('language')}</Form.Label>
                            <Select
                                name="language"
                                id="language"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('language_ph')}
                                noOptionsMessage={useLabelCallback(null, t('language_no'))}
                                options={translations}
                                isDisabled={readOnly}
                                isClearable={true}
                                value={useMemo(
                                    () => getOrNull(translations.find((el) => el.value === user?.language)),
                                    [user?.language, translations]
                                )}
                                onChange={useCallback((data) => setUser(user => ({
                                    ...user,
                                    language: data?.value,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['language'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['timeZone']}>
                            <Form.Label>{t('timezone')}</Form.Label>
                            <Select
                                name="timeZone"
                                id="timeZone"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('timezone_ph')}
                                noOptionsMessage={useLabelCallback(null, t('timezone_no'))}
                                options={timeZones}
                                isDisabled={readOnly}
                                isClearable={true}
                                value={useMemo(
                                    () => getOrNull(timeZones.find((el) => el.value === user?.timeZone)),
                                    [user?.timeZone, timeZones]
                                )}
                                onChange={useCallback((data) => setUser(user => ({
                                    ...user,
                                    timeZone: data?.value,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['timeZone'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['status']}>
                            <Form.Label>{t('status').label}</Form.Label>
                            <StatusDropdown
                                value={getOrNull(user?.status)}
                                isDisabled={readOnly}
                                onChange={useCallback((data) => setUser(user => ({
                                    ...user,
                                    status: data,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['status'])}
                            </Form.Text>
                        </FormGroup>

                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['receiveNotifications']}>
                            <Form.Label>{t('receive_notifications')}</Form.Label>
                            <BooleanDropdown
                                id={"receiveNotifications"}
                                value={parseBoolean(user?.receiveNotifications)}
                                isDisabled={readOnly}
                                onChange={useCallback((data) => setUser(user => ({
                                    ...user,
                                    receiveNotifications: data,
                                })), [setUser])}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['receiveNotifications'])}
                            </Form.Text>
                        </FormGroup>

                    </Col>

                </Row>
            </Box>


            {!readOnly &&
            <div>
                <Row>
                    <Col className={"text-end"}>
                        {!create &&
                        <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                            {t("cancel")}
                        </Button>
                        }
                        <UserHasPermission permission="WRITE_USER">
                            <Button variant="primary" className="submit" disabled={readOnly}
                                    onClick={() => onSubmit(user)}>
                                {create ? t("create_user") : t("save_changes")}
                            </Button>
                        </UserHasPermission>
                    </Col>
                </Row>
            </div>
            }

        </Form>
    </div>

}

export default FormComponent;
