import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormComponent from "app/equipments/equipmentProfile/components/form/FormComponent";
import React from "react";
import {getDiffsInErrorFormat} from "app/audit/utils/AuditDiff";
import {equipmentProfileAuditLogResolver} from "app/equipments/equipmentProfile/utils/equipmentProfileResolver";

const EquipmentProfileComponent = props => {

    const {operation} = props;
    const diffs = getDiffsInErrorFormat(operation.changes);

    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                <FormComponent
                    profile={equipmentProfileAuditLogResolver(operation.objectRaw)}
                    errors={diffs}
                    create={false}
                    compareMode={true}
                    viewMode={true}
                    loading={false}
                />
            </Col>

        </Row>

    </div>
}

export default EquipmentProfileComponent;
