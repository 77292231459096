import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/beneficiarySelection/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/beneficiarySelection/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/beneficiarySelection/components/FormEditor";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasBeneficiaries from "app/ruleTemplates/utils/config/ruleHasBeneficiaries";
import BeneficiarySelectionComponent
    from "app/ruleTemplates/actions/beneficiarySelection/components/BeneficiarySelectionComponent";


class BeneficiarySelectionRuleAction extends RuleEngineAction {

    static type = RuleActionType.SYNC
    static excludeTypes = [RuleType.BENEFICIARY, RuleType.SCHOOL];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faUserPlus, t('name'), t('description'));

    static getName = () => "BeneficiarySelectionRuleAction";
    static getDefaultProps = () => ({});

    static isAllowed = (config, position) => !ruleHasBeneficiaries(config, position);

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <BeneficiarySelectionComponent {...props} />

}

export default BeneficiarySelectionRuleAction;
