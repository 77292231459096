import NotificationMainMenuComponent from "app/notifications/components/NotificationMainMenuComponent";
import {useCallback, useEffect} from "react";
import locale from "app/notifications/locales/locales";
import NotificationIcons, {NotificationLink} from "app/notifications/utils/style";
import {useMutate} from "system/Rest/Rest";
import {useNavigate} from "react-router";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useTranslations from "system/Translations/UseTranslations";
import {parseToCss} from "system/Utils/css";

const NotificationAreaContainer = props => {

    const {data, refetch} = props;

    const navigate = useNavigate();
    const showToast = useShowToast();

    const {t, ready} = useTranslations('remoteNotifications', locale);

    const {mutate: markAsRead} = useMutate({
        verb: "PUT",
        localErrorOnly: true,
        path: useCallback(({id}) => `/notifications/read/${id}`, []),
    });

    const handleNotificationClose = useCallback(
        (id, onClose) => {
            markAsRead({}, {pathParams: {id: id}})
                .then(() => {
                    onClose(id);
                    refetch();
                });

        },
        [markAsRead, refetch]
    );

    useEffect(
        () => {
            if (ready && data?.content?.length > 0) {
                data.content.forEach((notification) => showToast({
                    id: notification.id,
                    title: t(notification.type, {
                        documentId: notification.objectId,
                        additionalInfo: notification.additionalInfo
                    }),
                    message: t(notification.type + '_MSG', {
                        documentId: notification.objectId,
                        additionalInfo: notification.additionalInfo
                    }),
                    icon: NotificationIcons[notification.type],
                    className: "info " + parseToCss(notification.type),
                    //duration: 60 * 1000,
                    duration: 0,
                    onClose: handleNotificationClose,
                    onView: NotificationLink[notification.type]
                        ? (id, onClose) => {
                            handleNotificationClose(id, onClose);
                            navigate(NotificationLink[notification.type](notification));
                        }
                        : undefined

                }));
            }
        },
        [ready, data?.content, t, showToast, handleNotificationClose, navigate]
    )

    return <NotificationMainMenuComponent {...props} notifications={data?.content?.length}/>

}

export default NotificationAreaContainer;
