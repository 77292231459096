import {useMemo} from "react";
import TicketStatus from "app/support/utils/TicketStatus";
import {getOrDefault} from "system/Objects/ObjectParameters";

const useStatusOptionsFromCategory = (category, ticket = {status: TicketStatus.OPEN}) => {

    return useMemo(
        () => {
            const status = getOrDefault(
                category?.ticketStatuses?.reduce((result, status) => ({
                    ...result,
                    [status]: status
                }), {}), {});

            if (ticket.status === TicketStatus.OPEN && !status.hasOwnProperty(TicketStatus.OPEN)) {
                return {
                    ...status,
                    [TicketStatus.OPEN]: TicketStatus.OPEN,
                }
            } else return status;

        },
        [category?.ticketStatuses, ticket?.status]
    );
}

export default useStatusOptionsFromCategory;
