import useKeyPress from "system/Acessibility/useKeyPress";
import {useCallback, useEffect, useState} from "react";
import useKeyboardNavigation from "system/Acessibility/useKeyboardNavigation";

const useMultipleMenuAccessibility = props => {

    const {isOver, ref} = props;

    const [active, setActive] = useState(false);
    const arrowRightPressed = useKeyPress('ArrowRight', !isOver);
    const arrowLeftPressed = useKeyPress('ArrowLeft', !isOver);
    const escapePressed = useKeyPress('Escape', !isOver);


    useEffect(
        () => {
            if (isOver && arrowRightPressed) {
                setActive(true);

                if (ref.current &&  ref.current.querySelector('.sub-menu-item'))
                    ref.current.querySelector('.sub-menu-item').focus();
            }

        },
        // eslint-disable-next-line
        [arrowRightPressed]
    );

    useEffect(
        () => {
            if (isOver && (arrowLeftPressed || escapePressed)) {
                setActive(false);
            }

        },
        // eslint-disable-next-line
        [arrowLeftPressed, escapePressed]
    );


    useKeyboardNavigation({
        element: ref?.current,
        enable: active,
        selector: '.sub-menu-item',
        initIndex: 0,
        handleFocus: useCallback(
            (element) => {
                element.focus();
            },
            []
        )
    });

}

export default useMultipleMenuAccessibility;
