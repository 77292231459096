import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faUserXmark} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/beneficiaryNonCompliance/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/beneficiaryNonCompliance/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/beneficiaryNonCompliance/components/FormEditor";
import RuleType from "app/ruleTemplates/utils/types/RuleType";

class BeneficiaryNonCompliance extends RuleEngineAction {

    static hasViewMode = false;
    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.SCHOOL];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faUserXmark, t('name'), t('description'));

    static getName = () => "GenerateBeneficiaryNonComplianceAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static isAllowed = (config, position) => true;

}

export default BeneficiaryNonCompliance;
