import React, {useCallback, useState} from "react";
import PopupComponent from "app/observations/components/PopupComponent";
import locale from "app/observations/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {faComment} from "@fortawesome/free-regular-svg-icons/faComment";
import {faCommentSlash} from "@fortawesome/free-solid-svg-icons/faCommentSlash";
import {Button} from "react-bootstrap";

const ObservationButtonComponent = (props) => {

    const {t} = useTranslations("observation", locale)

    const {count, path, reload, className, label} = props;
    const [showComments, setShowComments] = useState(false);

    const handleClose = useCallback(
        () => {
            setShowComments(false);
            reload();
        },
        [setShowComments, reload]
    );
    const handleOpen = useCallback(() => setShowComments(true), [setShowComments]);

    return <>

        <ToolTip content={t('observations')}>
            <Button
                onClick={handleOpen}
                className={classnames(className ?? "btn btn-rounded-primary small", {"has-observations": (count > 0)})}
                aria-label={t('observations')}
                aria-description={t('observations')}
            >
                {label
                    ? label
                    : <FontAwesomeIcon icon={(count > 0) ? faComment : faCommentSlash}/>
                }
            </Button>
        </ToolTip>

        {showComments &&
        <PopupComponent onClose={handleClose} path={path}/>
        }

    </>


}

export default ObservationButtonComponent;
