import RuleType from "app/ruleTemplates/utils/types/RuleType";
import SchoolSelectionRuleAction from "app/ruleTemplates/actions/schoolSelection/SchoolSelectionRuleAction";


const schoolActions = [
    SchoolSelectionRuleAction.getName(),
];

const ruleHasSchools = (rule, position) => rule.type === RuleType.SCHOOL || rule.actions
    .some((e, index) => schoolActions.includes(e.component) && index < position);


export default ruleHasSchools;
