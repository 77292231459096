import SelectedEquipmentsComponent
    from "app/ruleTemplates/actions/equipmentSelection/components/execution/SelectedEquipmentsComponent";
import {useMemo} from "react";
import List from "app/equipments/equipments/List";
import getRandomString from "system/Randoom/Random";

const RenderView = props => {

    const {config, data, result} = props;

    const id = useMemo(() => getRandomString(10), []);

    if (data.selectedEquipments) {
        return <div className={"rule-view-equipment-selection"}>
            <SelectedEquipmentsComponent
                viewMode={true}
                requiredEquipments={config.requiredEquipments}
                selectedEquipments={data.selectedEquipments}

            />
        </div>
    } else if (Array.isArray(result?.equipmentIds)) {
        return <List
            id={id}
            hideTitle={true}
            pendingMode={true}
            ids={result?.equipmentIds}/>
    }

}

export default RenderView;
