export const pt = {

    name: "Validação",
    description: "Obriga a que o processo até ao momento seja validado por um utilizador de uma entidade.",

    form_description: "Processo de validação por parte de {{validationEntity}}.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é a entidade que deve validar o processo bem como o estado em que os equipamentos ficam após rejeição e aceitação.",

    //Config
    validation_entity_type: "Quem valida",
    validation_entity: "Entidade manual",
    pending_status: "Estado enquanto aguarda validação",
    rejected_status: "Estado em caso de rejeição",

    placeholder: "Escolha uma opção",

    ASSIGNED_SCHOOL: "Unid. Orgânica associada ao equipamento",
    SELECTED_SCHOOL: "Unid. Orgânica escolhida anteriormente",
    EQUIPMENT_SUPPLIER: "Fornecedor do equipamento(s)",
    SUPPORT_SUPPLIER: "Fornecedor de suporte do equipamento(s)",
    FINANCING_ENTITY: "Entidade financiadora do equipamento(s)",
    DEFINED_ENTITY: "Entidade definida manualmente",




};

export default pt;
