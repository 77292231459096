export const pt = {

    title: "Lista de docentes",
    title_old: "Antigos docentes com equipamentos",

    create_title: "Criar docente",


    //list
    no_teacher_found: "Não foram encontrados docentes",
    name: "Nome",
    vat: "NIF",
    school: "Escola",
    equipment_status: "Equipamento",

    //Equipment Status
    NO_EQUIPMENT: "Não atribuído",
    WITH_EQUIPMENT: "Atribuído",
    PARTIAL_EQUIPMENT: "Atribuído parcialmente",
    REFUSED: "Rejeitado",

    //Filters
    school_or_group: "Unidade orgânica",
    typology: "Tipo de Unid. Orgânica",


    //form
    teacher_data: "Dados gerais",
    address: "Morada",
    generic_ph: "Por favor preencha o campo",
    recruitment_group: "Grupo de recrutamento",
    bond_status: "Estado do vínculo",
    teaching_component: "Componente de ensino",
    teaching_component_hours: "Componente horas",
    teaching_component_hours_ph: "Por favor indique a compoente horas",
    placeholder: "Escolha uma opção",

    create: "Criar",
    cancel: "Cancelar",
    save_changes: "Guardar Alterações",

    //Teaching component
    FULL: "Total",
    PARTIAL: "Parcial",

    MISSING_RECRUITMENT_GROUP: "Indique um grupo de recrutamento",
    MISSING_BOND_STATUS: "Indique o estado do vínculo",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    CREATE_SUCCESS: "Docente criado",
    CREATE_SUCCESS_MSG: "O docente {{name}} foi criado com sucesso!",

    UPDATE_SUCCESS: "Docente atualizado",
    UPDATE_SUCCESS_MSG: "O docente {{name}} foi atualizado com sucesso!",

    //Viwew
    general: "Dados gerais",


};

export default pt;
