const NutDatabase = [
    {
        label: "Norte",
        value: "Norte",
        active: true,
        codeINE: "11",
        codeED: "101",
        nuts: [
            {
                label: "Alto Minho",
                value: "Alto Minho",
                active: true,
                codeINE: "111",
                codeED: "10101",
            },
            {
                label: "Cávado",
                value: "Cávado",
                active: true,
                codeINE: "112",
                codeED: "10105",
            },
            {
                label: "Ave",
                value: "Ave",
                active: true,
                codeINE: "119",
                codeED: "10104",
            },
            {
                label: "Área Metropolitana do Porto",
                value: "Área Metropolitana do Porto",
                active: true,
                codeINE: "11A",
                codeED: "10103",
            },
            {
                label: "Alto Tâmega e Barroso",
                value: "Alto Tâmega e Barroso",
                active: true,
                codeINE: "11B",
                codeED: "10102",
            },
            {
                label: "Tâmega e Sousa",
                value: "Tâmega e Sousa",
                active: true,
                codeINE: "11C",
                codeED: "10107",
            },
            {
                label: "Douro",
                value: "Douro",
                active: true,
                codeINE: "11D",
                codeED: "10106",
            },
            {
                label: "Terras de Trás-os-Montes",
                value: "Terras de Trás-os-Montes",
                active: true,
                codeINE: "11E",
                codeED: "10108",
            }
        ],
    },
    {
        label: "Centro",
        value: "Centro",
        active: true,
        codeINE: "19",
        codeED: "102",
        nuts: [
            {
                label: "Região de Aveiro",
                value: "Região de Aveiro",
                active: true,
                codeINE: "191",
                codeED: "10203",
            },
            {
                label: "Região de Coimbra",
                value: "Região de Coimbra",
                active: true,
                codeINE: "192",
                codeED: "10204",
            },
            {
                label: "Região de Leiria",
                value: "Região de Leiria",
                active: true,
                codeINE: "193",
                codeED: "10205",
            },
            {
                label: "Viseu Dão Lafões",
                value: "Viseu Dão Lafões",
                active: true,
                codeINE: "194",
                codeED: "10206",
            },
            {
                label: "Beira Baixa",
                value: "Beira Baixa",
                active: true,
                codeINE: "195",
                codeED: "10201",
            },
            {
                label: "Beiras e Serra da Estrela",
                value: "Beiras e Serra da Estrela",
                active: true,
                codeINE: "196",
                codeED: "10202",
            }
        ]
    },
    {
        label: "Oeste e Vale do Tejo",
        value: "Oeste e Vale do Tejo",
        active: true,
        codeINE: "1D",
        codeED: "103",
        nuts: [
            {
                label: "Oeste",
                value: "Oeste",
                active: true,
                codeINE: "1D1",
                codeED: "10303",
            },
            {
                label: "Lezíria do Tejo",
                value: "Lezíria do Tejo",
                active: true,
                codeINE: "1D3",
                codeED: "10301",
            },
            {
                label: "Médio Tejo",
                value: "Médio Tejo",
                active: true,
                codeINE: "1D2",
                codeED: "10302",
            },

        ]
    },
    {
        label: "Grande Lisboa",
        value: "Grande Lisboa",
        active: true,
        codeINE: "1A",
        codeED: "104",
        nuts: [
            {
                label: "Grande Lisboa",
                value: "Grande Lisboa",
                active: true,
                codeINE: "1A0",
                codeED: "10401",
            }
        ]
    },
    {
        label: "Península de Setúbal",
        value: "Península de Setúbal",
        active: true,
        codeINE: "1B",
        codeED: "105",
        nuts: [
            {
                label: "Península de Setúbal",
                value: "Península de Setúbal",
                active: true,
                codeINE: "1B0",
                codeED: "10501",
            }
        ]
    },
    {
        label: "Alentejo",
        value: "Alentejo",
        active: true,
        codeINE: "1C",
        codeED: "106",
        nuts: [
            {
                label: "Alentejo Litoral",
                value: "Alentejo Litoral",
                active: true,
                codeINE: "1C1",
                codeED: "10602",
            },
            {
                label: "Baixo Alentejo",
                value: "Baixo Alentejo",
                active: true,
                codeINE: "1C2",
                codeED: "10604",
            },
            {
                label: "Alto Alentejo",
                value: "Alto Alentejo",
                active: true,
                codeINE: "1C3",
                codeED: "10603",
            },
            {
                label: "Alentejo Central",
                value: "Alentejo Central",
                active: true,
                codeINE: "1C4",
                codeED: "10601",
            }
        ]
    },
    {
        label: "Algarve",
        value: "Algarve",
        active: true,
        codeINE: "15",
        codeED: "107",
        nuts: [
            {
                label: "Algarve",
                value: "Algarve",
                active: true,
                codeINE: "150",
                codeED: "10701",
            }
        ]
    },
    {
        label: "Região Autónoma da Madeira",
        value: "Região Autónoma da Madeira",
        active: true,
        codeINE: "30",
        codeED: "15000",
        nuts: [
            {
                label: "Região Autónoma da Madeira",
                value: "Região Autónoma da Madeira",
                active: true,
                codeINE: "300",
                codeED: "150000",
            }
        ]
    },
    {
        label: "Região Autónoma dos Açores",
        value: "Região Autónoma dos Açores",
        active: true,
        codeINE: "20",
        codeED: "200000",
        nuts: [
            {
                label: "Região Autónoma dos Açores",
                value: "Região Autónoma dos Açores",
                active: true,
                codeINE: "200",
                codeED: "200000",
            }
        ]
    }

];


export default NutDatabase;