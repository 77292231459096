import Row from "react-bootstrap/Row";
import React, {useCallback} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Col from "react-bootstrap/Col";
import FormControl from "layout/modules/Forms/Control/FormControl";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/help/locales/locales";

const ConfigComponent = props => {

    const {configuration, setConfiguration} = props;
    const {t} = useTranslations('HelpModule', locale);

    return <Row>

        <Col sm={12} lg={12} xl={12}>
            <FormGroup>
                <Form.Label>{t('title')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(configuration?.title)}
                    autoComplete="chrome-off"
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            title: e.target.value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={12} xl={12}>
            <FormGroup>
                <Form.Label>{t('text')}</Form.Label>
                <Form.Control
                    type="textarea"
                    as="textarea"
                    rows={3}
                    value={getOrEmpty(configuration.text)}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            text: e.target.value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>
    </Row>
}

export default ConfigComponent;
