import RuleType from "app/ruleTemplates/utils/types/RuleType";
import BeneficiarySelectionRuleAction from "app/ruleTemplates/actions/beneficiarySelection/BeneficiarySelectionRuleAction";


const beneficiaryActions = [
    BeneficiarySelectionRuleAction.getName(),
];

const ruleHasBeneficiaries = (rule, position) => rule.type === RuleType.BENEFICIARY || rule.actions
    .some((e, index) => beneficiaryActions.includes(e.component) && index < position);


export default ruleHasBeneficiaries;
