import React from 'react'
import 'layout/modules/Box/assets/box.scss'
import 'layout/modules/Box/assets/box-responsive.scss'
import classnames from "classnames";

const BoxOptions = (props) => {

    const {children, visible = true, className, ...otherProps} = props;

    if (!visible) {
        return null;
    }

    return <div
        {...otherProps}
        className={classnames("box-options", className)}>

        {children}
    </div>
}

export default BoxOptions;
