import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import Row from "react-bootstrap/Row";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {SchoolType} from "types/SchoolType";
import NutDropdown, {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import StatusDropdown from "layout/modules/Forms/Dropdowns/StatusDropdown/StatusDropdown";
import isFunction from "system/Utils/isFunction";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";

const ListFilterComponent = (props) => {

    const {searchFilter, t, onFilterChange, readOnly, parentIds, lockedFilters = {}} = props;
    const [filterState, setFilterState] = useState(searchFilter);

    useEffect(() => {
        if (!isFunction(onFilterChange)) {
            setFilterState({
                ...searchFilter
            });
        }
    }, [searchFilter, onFilterChange]);


    useEffect(() => {
            if (isFunction(onFilterChange) && filterState !== searchFilter) {
                onFilterChange(filterState);
            }
        },
        // eslint-disable-next-line
        [filterState, onFilterChange]
    );

    if (!filterState) {
        return <></>;
    }

    return <>

        <Row>

            {isNotDefinedNorEmpty(parentIds) &&
            <>

                {isNotDefinedNorEmpty(lockedFilters['typologies']) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('typology')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            isMulti={true}
                            id={"typologies"}
                            options={SchoolType}
                            isDisabled={readOnly}
                            value={getOrNull(filterState?.typologies)}
                            placeholder={t('typology_ph')}
                            onChange={(val) => setFilterState((filterState) => ({
                                ...filterState,
                                typologies: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
                }

                {isNotDefinedNorEmpty(lockedFilters['parentIds']) &&
                <Col sm={12} lg={true}>
                    <FormGroup>
                        <Form.Label>{t('group')}</Form.Label>
                        <SchoolDropdown
                            isMulti={true}
                            id={"parentIds"}
                            isDisabled={readOnly}
                            typologies={[SchoolType.AE]}
                            value={getOrNull(filterState?.parentIds)}
                            onChange={(val) => setFilterState((filterState) => ({
                                ...filterState,
                                parentIds: val,
                            }))}
                        />
                    </FormGroup>
                </Col>
                }
            </>
            }

            {(isNotDefinedNorEmpty(lockedFilters['nut2']) && isNotDefinedNorEmpty(lockedFilters['nut3'])) &&
            <Col sm={12} lg={6}>
                <FormGroup prevent-close={filterState?.nut3 ? "true" : ""}>
                    <Form.Label>{t('nut2')}</Form.Label>
                    <NutDropdown
                        isMulti={false}
                        isClearable={true}
                        isDisabled={readOnly}
                        id={"nut2"}
                        value={getOrNull(filterState?.nut2)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            nut2: val,
                            nut3: null,
                        }))}
                    />
                </FormGroup>
            </Col>
            }


            {isNotDefinedNorEmpty(lockedFilters['nut3']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('nut3')}</Form.Label>
                    <NutDropdown
                        isMulti={false}
                        id={"nut3"}
                        isClearable={true}
                        type={NUTType.NUT3}
                        isDisabled={!filterState?.nut2 || readOnly}
                        nut2={filterState?.nut2}
                        value={getOrNull(filterState?.nut3)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            nut3: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }

            {isNotDefinedNorEmpty(lockedFilters['status']) &&
            <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('status').label}</Form.Label>
                    <StatusDropdown
                        isDisabled={readOnly}
                        value={getOrNull(filterState?.status)}
                        onChange={(val) => setFilterState((filterState) => ({
                            ...filterState,
                            status: val,
                        }))}
                    />
                </FormGroup>
            </Col>
            }

        </Row>

    </>
}

export default ListFilterComponent;
