import {useMutate} from "system/Rest/Rest";
import {useCallback} from "react";

const useGetRelatedEquipmentsWithDetails = props => {


    const {mutate: getRelated, loading} = useMutate({
        verb: "GET",
        path: "/equipments/search",
    });

    const {mutate: getProfiles, loading: loadingProfiles} = useMutate({
        verb: "GET",
        path: "/equipment-profiles/search",
    });

    const getRelatedEquipments = useCallback(
        (equipment) => {

            const profileIds = new Set();

            return getRelated(null, {
                queryParams: {
                    fullRelatedEquipmentIds: [equipment?.relatedEquipment?.id, equipment?.id],
                    itemsPerPage: 200,
                }
            }).then((data) => {

                const relatedEquipments = data?.content?.map((relatedEquipment) => {
                    profileIds.add(relatedEquipment?.profile?.id);
                    return relatedEquipment;
                });

                return getProfiles(null, {
                    queryParams: {
                        ids: [...profileIds],
                        itemsPerPage: profileIds.size,
                    }
                }).then((profiles) => relatedEquipments.map((equipment) => ({
                    ...equipment,
                    profile: {
                        ...equipment.profile,
                        ...profiles?.content?.find((p) => p.id === equipment.profile.id)
                    }
                })))
            });
        },
        [getRelated, getProfiles]
    );

    return {
        getRelatedEquipments,
        loading: loading || loadingProfiles
    }
}


export default useGetRelatedEquipmentsWithDetails;
