import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";
import React, {useMemo} from "react";
import {getOrDefault} from "system/Objects/ObjectParameters";

const EquipmentProfileDropdown = props => {

    const {procedureIds, typologyIds, equipmentSupplierIds} = props;

    return <SimpleListDropdown
        {...props}
        remotePath={"/equipment-profiles/search"}
        filterExtraProps={useMemo(() => ({
            typologyIds: getOrDefault(typologyIds, undefined),
            procedureIds: getOrDefault(procedureIds, undefined),
            equipmentSupplierIds: getOrDefault(equipmentSupplierIds, undefined),
        }),[procedureIds, typologyIds, equipmentSupplierIds])}

    />

}

export default EquipmentProfileDropdown;
