import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import cloneWithoutKeys from "system/Objects/Objects";
import ruleHasSchools from "app/ruleTemplates/utils/config/ruleHasSchools";

const ValidationEntityType = {

    ASSIGNED_SCHOOL: "ASSIGNED_SCHOOL",
    SELECTED_SCHOOL: "SELECTED_SCHOOL",
    EQUIPMENT_SUPPLIER: "EQUIPMENT_SUPPLIER",
    SUPPORT_SUPPLIER: "SUPPORT_SUPPLIER",
    FINANCING_ENTITY: "FINANCING_ENTITY",
    DEFINED_ENTITY: "DEFINED_ENTITY",

}


const generateDestinationEntityList = (rule, position) => {

    let options = {
        ...ValidationEntityType
    }

    if (!ruleHasEquipments(rule, position)) {
        options = cloneWithoutKeys(options, ['EQUIPMENT_SUPPLIER', 'SUPPORT_SUPPLIER', 'FINANCING_ENTITY', 'ASSIGNED_SCHOOL'])
    }

    if (!ruleHasSchools(rule, position)) {
        options = cloneWithoutKeys(options, ['SELECTED_SCHOOL'])
    }

    return options;
}

export {generateDestinationEntityList} ;
export default ValidationEntityType;
