import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/forms/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/forms/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/forms/components/FormEditor";
import FormExecution from "app/ruleTemplates/actions/forms/components/FormExecution";
import RenderView from "app/ruleTemplates/actions/forms/components/RenderView";


class FormActionModule extends RuleEngineAction {

    static hasViewMode = true;
    static type = RuleActionType.SYNC
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faFileInvoice, t('name'), t('description'));

    static getName = () => "FormRuleAction";
    static getDefaultProps = () => ({
        formModel: []
    });

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <FormExecution {...props} />
    static renderView = props => <RenderView {...props}/>
}

export default FormActionModule;
