import React from "react";
import GenericVersionsContainer from "modules/VersionsContainer/VersionsContainer";
import FormComponent from "app/equipments/equipmentProfile/components/form/FormComponent";
import {equipmentProfileVersionResolver} from "app/equipments/equipmentProfile/utils/equipmentProfileResolver";

const EquipmentProfileVersionContainer = (props) => {

    return <GenericVersionsContainer
        {...props}
        path={"/equipment-profiles/" + props.id + "/versions"}
        onResolve={equipmentProfileVersionResolver}>
        <CompareComponent/>
    </GenericVersionsContainer>
}


const CompareComponent = (props) => {

    const {version, changes, diffs, fullChanged, disableChanges} = props;

    if (!version)
        return <></>;


    return <FormComponent
        profile={version.entity}
        compareMode={true}
        fullChanged={disableChanges ? undefined : fullChanged}
        changes={disableChanges ? {} : changes}
        errors={diffs}

    />
}


export default EquipmentProfileVersionContainer;
export {CompareComponent};
