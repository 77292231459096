import React, {useMemo} from 'react'
import locale from "layout/modules/TableAdvanced/locales/locales"
import BootstrapPagination from "react-bootstrap/Pagination"
import useTranslations from "system/Translations/UseTranslations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import {useIsMobile} from "system/Window/Mobile";
import {ReactComponent as DoubleArrow} from '../assets/images/double-arrow.svg'
import "layout/modules/TableAdvanced/assets/css/pagination.scss"

const Pagination = (props) => {

    const {t} = useTranslations('tableAdvanced', locale);
    const {itemsPerPage, totalItems, currentPage, pages, children, onPageChange} = props;

    const isMobile = useIsMobile();

    const startElement = useMemo(
        () => (currentPage - 1) * itemsPerPage + 1,
        [currentPage, itemsPerPage]
    );

    const endElement = useMemo(
        () => (currentPage === pages) ? totalItems : currentPage * itemsPerPage,
        [totalItems, currentPage, pages, itemsPerPage]
    );

    const isQuickPagination = useMemo(
        () => (totalItems === 0),
        [totalItems]
    );

    if (!Number.isInteger(itemsPerPage) || !Number.isInteger(totalItems)
        || !Number.isInteger(currentPage) || !Number.isInteger(pages)) {
        return <div className={"pagination"}/>;
    }


    return <BootstrapPagination>

        {children &&
        <div className={"pagination-dynamic"}>
            {children}
        </div>
        }

        <div className={classnames("pagination-controls", {"quick": isQuickPagination})}>
            <div className={"pagination-label"}>
                {isMobile
                    ? <>
                        {(totalItems > itemsPerPage) && t('mobile_page_number', {
                            currentPage: currentPage,
                            totalPages: pages
                        })}
                    </>
                    : <>
                        {(totalItems > itemsPerPage) && <>{startElement} - {endElement} {t('of')} </>}
                        {totalItems} {t('entries', {count: totalItems})}
                    </>
                }
            </div>

            <BootstrapPagination.First onClick={() => onPageChange(1)} className={"first"}>
                <DoubleArrow className={"double-arrow-first"}/>
            </BootstrapPagination.First>


            <BootstrapPagination.Prev
                className={"previous"}
                disabled={currentPage <= 1}
                onClick={() => onPageChange(currentPage - 1)}>

                <div className={"page-with-label"}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                    <div className={"previous-label"}>
                        {t('previous')}
                    </div>
                </div>
            </BootstrapPagination.Prev>


            {getPagingRange(
                currentPage,
                {
                    min: 1,
                    total: pages,
                    length: 5
                }).map((page) => {
                    return (<BootstrapPagination.Item
                        key={page}
                        onClick={() => onPageChange(page)}
                        active={currentPage === page}>
                        {page}
                    </BootstrapPagination.Item>);
                }
            )}
            <BootstrapPagination.Next
                className={"next"}
                disabled={currentPage >= pages}
                onClick={() => onPageChange(currentPage + 1)}>
                <div className={"page-with-label"}>
                    <div className={"next-label"}>
                        {t('next')}
                    </div>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </div>
            </BootstrapPagination.Next>
            <BootstrapPagination.Last onClick={() => onPageChange(pages)} className={"last"}>
                <DoubleArrow className={"double-arrow-last"}/>
            </BootstrapPagination.Last>
        </div>

    </BootstrapPagination>

}

const getPagingRange = (current, {min = 1, total = 20, length = 5} = {}) => {
    if (length > total) length = total;

    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length)
    return Array.from({length: length}, (el, i) => start + i);
};

export default Pagination;
