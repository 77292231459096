import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import locale from "locales/locales";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useTranslations from "system/Translations/UseTranslations";
import {useApiState} from "system/API/APIStateContext";
import useExitImpersonate from "app/personalArea/utils/useExitImpersonate";
import Impersonate from "app/personalArea/Impersonate";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import {getInitials} from "system/Utils/text";
import "layout/modules/BaseLayout/Menu/css/user-button.scss";

const UserComponent = (props) => {

    const {t} = useTranslations('global', locale);

    const {authentication} = useApiState();
    const exitImpersonate = useExitImpersonate();
    const [showImpersonate, setShowImpersonate] = useState(false);

    return <>

        <UserHasPermission permission={['IMPERSONATE_USER']}>
            <Impersonate {...props} isVisible={showImpersonate} onClose={() => setShowImpersonate(false)}/>
        </UserHasPermission>

        <MenuDropdown ariaLabel={t('account') + " " + authentication?.name} placement={"auto"}>
            {props?.children
                ? <>{props.children}</>
                : <div className={"user-button"}>

                    <div className={"user-ball"}>
                        <div className={"letters"}>
                            {getInitials(authentication?.name)}
                        </div>
                    </div>

                    <div className={"text"}>
                        <div className={"name"}>
                            {authentication?.name}
                        </div>
                        <div className={"entity fill-empty"}>
                            {authentication?.entity?.name}
                        </div>
                    </div>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </div>
                </div>
            }
            <MenuDropdownContent>
                <MenuDropdownOption>
                    <Link to="/personal">{t('personal')}</Link>
                </MenuDropdownOption>

                <UserHasPermission permission={"READ_OWN_ENTITY"}>
                    <MenuDropdownOption>
                        <Link to="/myEntity">{t('my_entity')}</Link>
                    </MenuDropdownOption>
                </UserHasPermission>

                <MenuDropdownOption>
                    <Link to="/personal/audit">{t('user_historic')}</Link>
                </MenuDropdownOption>


                {authentication.impersonate
                    ? <MenuDropdownOption>
                        <div {...generateOnClickAndEnter(exitImpersonate)} >
                            {t('exit_impersonate')}
                        </div>
                    </MenuDropdownOption>
                    : <UserHasPermission permission={['IMPERSONATE_USER']}>
                        <MenuDropdownOption>
                            <div {...generateOnClickAndEnter(() => setShowImpersonate(true))}>
                                {t('impersonate')}
                            </div>
                        </MenuDropdownOption>
                    </UserHasPermission>
                }

                {!authentication.impersonate &&
                <MenuDropdownOption>
                    <Link to="/logout">{t('sign_out')}</Link>
                </MenuDropdownOption>
                }

            </MenuDropdownContent>
        </MenuDropdown>
    </>

}

export default UserComponent;
