import classnames from "classnames";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import UserHasPermission, {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import {getOrDefault, getOrNull} from "system/Objects/ObjectParameters";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import Box from "layout/modules/Box/Box";
import DateTime from "system/DateTime/DateTime";
import TicketPriority from "app/support/utils/TicketPriority";
import {DropdownButton} from "react-bootstrap";
import Observations from "app/observations/Observations";
import TransferContainer from "app/support/containers/TransferContainer";
import {isTicketLocked} from "app/support/utils/utils";
import Dropdown from 'react-bootstrap/Dropdown';
import TicketTransferType from "app/support/utils/TicketTransferType";
import Button from "react-bootstrap/Button";
import HistoricContainer from "app/support/containers/HistoricContainer";
import useStatusOptionsFromCategory from "app/support/utils/generateStatusOptionsFromCategory";
import useCanChangeTicketStatus from "app/support/utils/useCanChangeTicketStatus";

const PanelComponent = (props) => {

    const {t} = useTranslation("support");
    const {ticket, onStatusChange, onPriorityChange} = props;

    const userHasPermission = useUserHasPermission();


    const [transferType, setTransferType] = useState(false);
    const [isHistoricVisible, setShowHistoricVisible] = useState(false);

    const ticketStatusOptions = useStatusOptionsFromCategory(ticket?.category, ticket);

    const canChangeTicketStatus = useCanChangeTicketStatus(ticket);


    return <div className={"right-menu-panel"}>
        <Box>
            <div className={"main-title"}>
                {t('details')}
            </div>

            <div className={"title"}>{t('status')}</div>
            <div className={"value"}>

                {(!canChangeTicketStatus)
                    ? <div className={classnames("ticket-status", ticket.status?.toLowerCase().replace(/_/g, "-"))}>
                        {t(ticket.status)}
                    </div>
                    : <EnumDropdown
                        t={t}
                        id={"status"}
                        isMulti={false}
                        isClearable={false}
                        options={ticketStatusOptions}
                        value={getOrNull(ticket.status)}
                        onChange={onStatusChange}
                    />
                }
            </div>

            <div className={"title"}>{t('priority')}</div>
            <div className={"value"}>

                {(!userHasPermission({permission: 'MANAGE_TICKETS'}) || isTicketLocked(ticket))
                    ? <div className={classnames("ticket-priority", ticket.priority?.toLowerCase().replace(/_/g, "-"))}>
                        {t(getOrDefault(ticket.priority, TicketPriority.NORMAL))}
                    </div>
                    : <EnumDropdown
                        t={t}
                        id={"status"}
                        isMulti={false}
                        isClearable={false}
                        options={TicketPriority}
                        value={getOrDefault(ticket.priority, TicketPriority.NORMAL)}
                        onChange={onPriorityChange}
                    />
                }
            </div>

            <div className={"title"}>{t('category')}</div>
            <div className={"value"}>
                {t(ticket.category?.name)}
            </div>


            <div className={"title"}>{t('creation_date')}</div>
            <div className={"value"}>
                <DateTime format={"DD-MM-YYYY HH:mm:ss"}>
                    {ticket.creationTimestamp}
                </DateTime>
            </div>


            <div className={"title"}>{t('last_update')}</div>
            <div className={"value"}>
                <DateTime format={"DD-MM-YYYY HH:mm:ss"}>
                    {ticket.lastUpdateTimestamp}
                </DateTime>
            </div>

            <div className={"title"}>{t('open_by')}</div>
            <div className={"value fill-empty"}>
                {ticket.creationEntity?.name}

                {ticket.creationUser &&
                <>
                    &nbsp;-&nbsp;{ticket.creationUser?.name}
                </>
                }
            </div>

            <div className={"title"}>{t('destination')}</div>
            <div className={"value fill-empty"}>
                {ticket.destinationEntity?.name}
                {ticket.destinationUser &&
                <>
                    &nbsp;-&nbsp;{ticket.destinationUser?.name}
                </>
                }
            </div>


            {ticket.currentUrl &&
            <>
                <div className={"title"}>{t('url')}</div>
                <div className={"value fill-empty"}>
                    <a href={ticket.currentUrl} target="_blank" rel="noopener noreferrer">
                        {ticket.currentUrl}
                    </a>
                </div>
            </>
            }


            <div className={"options"}>
                {!isTicketLocked(ticket) &&
                <UserHasPermission permission={'SET_TICKET_DESTINATION'}>
                    {transferType &&
                    <TransferContainer
                        {...props}
                        transferType={transferType}
                        onClose={() => setTransferType(false)}/>
                    }

                    <DropdownButton title={t('transfer_ticket')}>
                        <Dropdown.Item onClick={() => setTransferType(TicketTransferType.USER)}>
                            {t('transfer_ticket_to_operator')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => setTransferType(TicketTransferType.ENTITY)}>{t('transfer_ticket_to_entity')}
                        </Dropdown.Item>
                    </DropdownButton>
                </UserHasPermission>
                }

                <UserHasPermission permission={'MANAGE_TICKETS'}>
                    {isHistoricVisible &&
                    <HistoricContainer  {...props} onClose={() => setShowHistoricVisible(false)}/>}
                    <Button variant="primary" onClick={() => setShowHistoricVisible(true)} active={isHistoricVisible}>
                        {t("historic")}
                    </Button>
                </UserHasPermission>

                <UserHasPermission permission={["READ_OBSERVATION"]}>
                    <Observations className={"btn btn-primary"} label={t('observations')}/>
                </UserHasPermission>


            </div>
        </Box>
    </div>
}

export default PanelComponent;
