import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faShuffle} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/replaceEquipment/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/replaceEquipment/components/config/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/replaceEquipment/components/config/FormEditor";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ExecutionComponent from "app/ruleTemplates/actions/replaceEquipment/components/execution/ExecutionComponent";
import ViewComponent from "app/ruleTemplates/actions/replaceEquipment/components/view/ViewComponent";


class ReplaceEquipmentRuleAction extends RuleEngineAction {

    static hasViewMode = true;
    static type = RuleActionType.SYNC
    static excludeTypes = [RuleType.SCHOOL, RuleType.BENEFICIARY];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faShuffle, t('name'), t('description'));

    static getName = () => "ReplaceEquipmentRuleAction";
    static getDefaultProps = () => ({
        typologyProfileMap: {}
    });

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <ExecutionComponent {...props} />
    static renderView = props => <ViewComponent {...props}/>

    static isAllowed = (config, position) => ruleHasEquipments(config, position);


}

export default ReplaceEquipmentRuleAction;
