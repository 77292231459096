import React from "react";
import DateTime from "system/DateTime/DateTime";
import classnames from "classnames";
import AttachmentComponent from "app/support/components/view/messages/AttachmentComponent";
import "app/support/assets/css/message.scss";
import {getInitials} from "app/support/utils/utils";

const MessageComponent = props => {

    const {attachments, message, timestamp, leftSide, user, entity} = props;

    return <>
        <div className={classnames("message-block", {
            "left": leftSide,
            "right": !leftSide,
        })}>

            <div className={"main-block"}>

                <div className={"logo-user"}>
                    <div className={"image-spacing"}>
                        <div className={"letters"}>
                            {getInitials(user)}
                            </div>
                    </div>
                </div>

                <div className={"message-content"}>
                    <div className={"message"} >
                        {message}
                    </div>

                    <div className={"attachments"}>
                        {attachments?.map((at, aid) =>
                            <AttachmentComponent key={aid} attachment={at}/>
                        )}
                    </div>
                </div>
            </div>
            <div className={"sub-message-info"}>
                <DateTime format={"DD-MM-YYYY - HH:mm"}>
                    {timestamp}
                </DateTime>
                &nbsp;|&nbsp;{user}&nbsp;|&nbsp;{entity}

            </div>
        </div>


    </>
}

export default MessageComponent;
