import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Trans, useTranslation} from "react-i18next";

const VirtualEquipmentAlertComponent = props => {

    const {equipment, selectionMode} = props;
    const {t} = useTranslation('equipments');

    return <AdvanceInfoMessage
        icon={faInfo}
        type={"info"}
        iconType={"info"}
        title={t("virtual_equipment_title")}>

        <Trans>
            {t(selectionMode ? 'virtual_equipment_msg_sm' : 'virtual_equipment_msg', equipment)}
        </Trans>


    </AdvanceInfoMessage>
}

export default VirtualEquipmentAlertComponent;
