import React, {useCallback} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";
import localeEquipments from "app/equipments/equipments/locales/locales";
import Collapse from "layout/modules/Collapse/Collapse";
import ListFilterComponent from "app/equipments/equipments/components/list/ListFilterComponent";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import RequiredEquipmentType
    from "app/ruleTemplates/actions/equipmentSelection/components/config/RequiredEquipmentType";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {parseBoolean} from "system/Utils/parseBoolean";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import HelpHeaderConfigComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderConfigComponent";


const FormConfiguration = props => {

    const {setConfiguration, configuration, errors = {}, readOnly} = props;

    const {t} = useTranslations("EquipmentSelectionRuleAction", locale);
    const {t: tEquipments} = useTranslations("equipments", localeEquipments);

    const handleFilterChange = useCallback(
        (filter) => {
            setConfiguration((config) => ({
                ...config,
                props: {
                    ...config.props,
                    ...filter
                }
            }))
        },
        [setConfiguration]
    );

    return <>

        <Collapse title={t('kit_selection')}>
            <MultiOptionComponent
                data={configuration.props.requiredEquipments}
                errors={errors?.requiredEquipments}
                readOnly={readOnly}
                onChange={useCallback((fnc) => {
                    setConfiguration((config) => ({
                        ...config,
                        props: {
                            ...config.props,
                            requiredEquipments: fnc(config.props.requiredEquipments)

                        }
                    }))
                }, [setConfiguration])}>
                <RequiredEquipmentType/>
            </MultiOptionComponent>

        </Collapse>

        <Collapse title={t('selection_configuration')} isOpen={!readOnly}>
            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('automatic_related_selection')}</Form.Label>
                        <BooleanDropdown
                            id={'autoSelectRelated'}
                            isDisabled={readOnly}
                            isClearable={false}
                            value={parseBoolean(configuration?.props?.autoSelectRelated, false)}
                            onChange={(value) => setConfiguration((config) => ({
                                ...config,
                                props: {
                                    ...config.props,
                                    autoSelectRelated: value
                                }
                            }))}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Collapse>

        <Collapse title={t('selection_limitation')} isOpen={!readOnly}>
            <ListFilterComponent
                t={tEquipments}
                readOnly={readOnly}
                searchFilter={configuration.props}
                hideAttributions={true}
                onFilterChange={readOnly ? undefined : handleFilterChange}

            />
        </Collapse>

        <HelpHeaderConfigComponent {...props}/>

    </>

}

export default FormConfiguration;
