import React, {useCallback, useEffect, useState} from "react";
import './FormGroup.scss';
import Form from "react-bootstrap/Form"

import classnames from 'classnames';
import useCloneChildrenWithAriaAndProps from "layout/modules/Forms/Group/utils/useCloneChildrenWithAriaAndProps";

const ForumGroup = (props) => {

    const {error: propError, changes, mandatory, ...rest} = props;
    const [error, setError] = useState(propError);

    useEffect(
        () => setError(propError),
        [propError]
    );

    const childrenWithFocus = useCloneChildrenWithAriaAndProps(props.children, {
        error: !!propError,
        required: mandatory,
        onFocus: useCallback(
            () => setError(false),
            [setError]
        )
    });

    return <Form.Group
        {...rest}
        className={classnames(props.className, 'form-group', {
            'error': error,
            'has-changed': changes,
            'mandatory': mandatory,
        })}
    >
        {childrenWithFocus}
    </Form.Group>
};


export default ForumGroup;
