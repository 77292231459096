import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import locale from "layout/modules/SearchArea/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import 'layout/modules/SearchArea/assets/css/search-area.scss'
import 'layout/modules/SearchArea/assets/css/search-area-responsive.scss'
import FormControl from "layout/modules/Forms/Control/FormControl";


const SearchControl = (props) => {

    const {value, searchControl, ...otherProps} = props;

    const {t} = useTranslations('searchArea', locale);

    return <div className={"search-control"}>
        <InputGroup>
            <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch}/>
            </InputGroup.Text>
            {searchControl
                ? searchControl(props)
                : <FormControl {...otherProps} placeholder={t('search')} type="text" value={value}/>
            }
        </InputGroup>
    </div>;

}

export default SearchControl;
