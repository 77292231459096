import {faAlignJustify} from "@fortawesome/free-solid-svg-icons";
import locale from "layout/modules/FormBuilder/modules/textArea/locales/locales";
import generateToolBarEntry from "layout/modules/FormBuilder/modules/module/utils/generateToolBarEntry";
import FormModule from 'layout/modules/FormBuilder/modules/module/FormModule'
import EditorComponent from "layout/modules/FormBuilder/modules/textArea/components/EditorComponent";
import ConfigComponent from "layout/modules/FormBuilder/modules/textArea/components/ConfigComponent";
import FormComponent from "layout/modules/FormBuilder/modules/textArea/components/FormComponent";

class TextAreaModule extends FormModule {

    static getName = () => "FormTextAreaModule";
    static getTranslations = () => locale;
    static getToolBarEntry = (t) => generateToolBarEntry(faAlignJustify, t('name'));

    static renderEditor = (props) => <EditorComponent id={props.id} {...props.props}/>
    static renderConfig = (props) => <ConfigComponent  {...props} />
    static renderForm = (props) => <FormComponent {...props} />


    static getDefaultProps = () => ({
        label: null,
        mandatory: false,
    });
}

export default TextAreaModule;
