import React from "react";

const ChartLegendComponent = ({payload}) => {
    return <div className={"chart-custom-legend"}>
        {payload.map(({value, color, payload}, id) => <div className={"legend-entry"} key={id}>
            <div className={"legend-block"}>
                <div className={"legend-ball"} style={{backgroundColor: color}}/>
                {value}
            </div>
            <div className={"legend-value"}>{payload.value}</div>
        </div>)}
    </div>
}

export default ChartLegendComponent;