import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import BeneficiaryType from "types/BeneficiaryType";
import {getOrNull} from "system/Objects/ObjectParameters";
import BeneficiaryDropdown, {
    beneficiaryLabelHandler
} from "modules/Forms/Dropdowns/ClassDropdown/components/BeneficiaryDropdown";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {cloneWithoutKey} from "system/Objects/Objects";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import {Link} from "react-router-dom";

const FormBeneficiary = props => {

    const {equipment, setEquipment, errors, readOnly} = props;
    const {t} = useTranslation('equipments');

    const allowedBeneficiaries = useMemo(
        () => {

            let types = {...BeneficiaryType};

            if (!equipment.profile.availableForTeacher)
                types = cloneWithoutKey(types, BeneficiaryType.TEACHER);

            if (!equipment.profile.availableForStudent)
                types = cloneWithoutKey(types, BeneficiaryType.STUDENT);

            return types;

        },
        [equipment.profile.availableForTeacher, equipment.profile.availableForStudent]
    );

    const handleBeneficiaryChange = useCallback(
        (value) => setEquipment(profile => ({
            ...profile,
            beneficiaryId: value,
        })),
        [setEquipment]
    );

    return <>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['beneficiaryId']}>
                <Form.Label>{t('beneficiary_type')}</Form.Label>
                <EnumDropdown
                    t={t}
                    placeholder={t('beneficiary_type_ph')}
                    options={allowedBeneficiaries}
                    isClearable={false}
                    isDisabled={readOnly}
                    value={getOrNull(equipment?.beneficiaryType)}
                    onChange={useCallback((value) => setEquipment(profile => ({
                        ...profile,
                        beneficiaryType: value,
                        beneficiaryId: null,
                    })), [setEquipment])}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>

            {!readOnly
                ? <FormGroup error={errors['beneficiaryId']}>
                    <Form.Label>{t('beneficiary')}</Form.Label>
                    <BeneficiaryDropdown
                        isDisabled={readOnly || !equipment?.beneficiaryType}
                        value={getOrNull(equipment?.beneficiaryId)}
                        type={equipment?.beneficiaryType}
                        schoolId={equipment.schoolId}
                        schoolYears={equipment.profile.schoolYears}
                        schoolTypologies={equipment.profile.schoolTypologies}
                        ranks={equipment.profile.beneficiaryRank}
                        onChange={handleBeneficiaryChange}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['beneficiaryId'])}
                    </Form.Text>
                </FormGroup>
                : <LabelWithValue label={t('beneficiary')}>
                    <Link to={equipment?.beneficiary.type === BeneficiaryType.STUDENT
                        ? "/student/" + equipment?.beneficiary?.id
                        : "/teacher/" + equipment?.beneficiary?.id
                    }>
                        {beneficiaryLabelHandler(equipment?.beneficiary)}
                    </Link>
                </LabelWithValue>
            }
        </Col>
    </>
}

export default FormBeneficiary;
