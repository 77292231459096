import {useEffect, useState} from "react";
import {objectIsEqual, removeEmptyParameters} from "system/Objects/Objects";

const useSavedSearch = (activeFilters, savedSearches) => {

    const [isSaved, setSaved] = useState(false);

    useEffect(
        () => {

            const found = Object.entries(savedSearches).find((filter) => {
                return  objectIsEqual(removeEmptyParameters(activeFilters), removeEmptyParameters(filter[1].value));
            });

            setSaved(found?.length > 0 ? found[0] : false);
        },
        [setSaved, savedSearches, activeFilters]
    );

    return isSaved;

}



export {useSavedSearch}
