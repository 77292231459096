import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import React, {useCallback, useEffect, useState} from "react"
import {Trans} from "react-i18next"
import locale from "./locales/locales"
import 'layout/modules/Popups/assets/css/popupConfirm.scss'
import useTranslations from "system/Translations/UseTranslations";
import FormControl from "layout/modules/Forms/Control/FormControl";


const PopupConfirm = (props) => {

    const {t} = useTranslations('_popup', locale);

    const {
        title, message, labelConfirm, classConfirm = "primary",
        labelCancel, classCancel = "secondary", visible = false
        , onCancel, onConfirm, backdrop = true, confirmationPlaceHolder,
    } = props;

    //Popup stuff
    const [show, setShow] = useState(visible);
    const [confirmationString, setConfirmationString] = useState("");

    const handleCancel = useCallback(
        () => {
            if (onCancel)
                onCancel();

            setShow(false);
        },
        [setShow, onCancel]
    );

    const handleConfirm = useCallback(
        () => {

            if (onConfirm)
                onConfirm(confirmationString);

            setShow(false);

        },
        [setShow, onConfirm, confirmationString]
    );

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    if (!show) {
        return <></>;
    }

    return <Modal show={show} onHide={handleCancel} centered backdrop={backdrop} className={"confirm"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Trans>{message}</Trans>

            <FormControl
                type="text"
                placeholder={confirmationPlaceHolder}
                value={confirmationString}
                onChange={(input) => setConfirmationString(input.target.value)}
                className="input-popup-confirm"

            />

            {props.children}

        </Modal.Body>
        <Modal.Footer>
            <Button variant={classCancel} onClick={handleCancel}>
                {labelCancel ? labelCancel : t('cancel')}
            </Button>
            <Button variant={classConfirm} onClick={handleConfirm} disabled={!confirmationString}>
                {labelConfirm ? labelConfirm : t('continue')}
            </Button>
        </Modal.Footer>
    </Modal>

}

export default PopupConfirm;

