import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Link} from "react-router-dom"
import {getOrDefault} from "system/Objects/ObjectParameters";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import 'layout/modules/TableAdvanced/assets/css/table-buttons.scss'
import classnames from "classnames";
import Button from "react-bootstrap/Button";

const TableIconButtonComponent = (props, ref) => {

    const {permission, orUserTypes, path, icon, className, label, ...otherProps} = props;
    const {visible = true} = props;

    if (!visible) {
        return <></>;
    }


    const link = <>
        {path && <Link to={getOrDefault(path?.pathname, path)} state={path?.state}
                       className={classnames("btn",className)} ref={ref} {...otherProps} tabIndex={0}>
            {icon && <FontAwesomeIcon icon={icon}/>}
            {label && <>{label}</>}
        </Link>}

        {!path && <Button {...otherProps} className={classnames("btn",className)} ref={ref} tabIndex={0}>
            {icon && <FontAwesomeIcon icon={icon}/>}
            {label && <>{label}</>}

        </Button>}

    </>;

    if (permission) {
        return <UserHasPermission permission={permission} orUserTypes={orUserTypes}>
            {link}
        </UserHasPermission>
    }

    return link;

};

const TableIconButton = React.forwardRef(TableIconButtonComponent);
export default TableIconButton;
