import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HTMLEditor from "layout/modules/Forms/HTMLEditor/HTMLEditor";
import {getOrEmpty} from "system/Objects/ObjectParameters";


const FormConfiguration = props => {

    const {setConfiguration, configuration} = props;

    return <Row>

        <Col sm={12} lg={12} xl={12}>
            <HTMLEditor
                {...props}
                height={450}
                value={getOrEmpty(configuration?.props?.content)}
                onChange={useCallback((data) => {
                    setConfiguration((config) => ({
                        ...config,
                        props: {
                            ...config.props,
                            content: data
                        }
                    }))
                }, [setConfiguration])}
            />
        </Col>


    </Row>

}

export default FormConfiguration;
