import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faChalkboardTeacher} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/setBeneficiary/locales/locales";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ruleHasBeneficiaries from "app/ruleTemplates/utils/config/ruleHasBeneficiaries";
import FormEditor from "app/ruleTemplates/actions/setBeneficiary/components/FormEditor";
import FormConfiguration from "app/ruleTemplates/actions/setBeneficiary/components/FormConfiguration";
import React from "react";


class SetBeneficiaryRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.SCHOOL];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faChalkboardTeacher, t('name'), t('description'));

    static getName = () => "SetBeneficiaryRuleAction";
    static getDefaultProps = () => ({});
    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static isAllowed = (config, position) => ruleHasEquipments(config, position) && ruleHasBeneficiaries(config, position);

}

export default SetBeneficiaryRuleAction;
