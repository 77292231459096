import {faArrowRotateBack, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useMemo} from "react";
import locale from "app/ruleTemplates/actions/recoveryEquipmentStatus/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import isDefined from "system/Utils/isDefined";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {useGet} from "system/Rest/Rest";
import {Trans} from "react-i18next";
import getAsArray from "system/Arrays/getArray";

const FormEditor = configuration => {

    const {t} = useTranslations("RecoverEquipmentStatusRuleAction", locale);

    const {data: equipmentStatusList} = useGet({
        path: "/equipment-status/search",
        lazy: !configuration.props.statusIds,
        localErrorOnly: true,
        queryParams: useMemo(() => ({
                itemsPerPage: 50,
                ids: configuration.props.statusIds
            }),
            [configuration.props.statusIds]
        )
    });


    return <div className={"form-action-editor"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faArrowRotateBack}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {(!isDefined(configuration.props.statusIds) || getAsArray(configuration.props.statusIds).length < 1)
            ? <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />
            : <div className={"description"}>
                <Trans>
                    {t('form_description', {
                        status: equipmentStatusList?.content
                            ? equipmentStatusList?.content.map(e => e.name)
                            : "-",
                    })}
                </Trans>
            </div>
        }

    </div>
}

export default FormEditor;
