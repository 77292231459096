export const pt = {

    name: "Envio de notificação",
    description: "É enviada uma notificação com um conteudo dinâmico para uma entidade a definir.",

    form_description: "Envio de notificação com o titulo <strong>{{title}}</strong> para <strong>{{destinationEntityType}}</strong>.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é a entidade que vai receber a notificação, bem como  titulo e corpo da mensagem",

    //Config
    destination_entity_type: "Entidade de destino",
    destination_entity: "Entidade manual",
    title: "Titulo",
    title_ph: "Indique o titulo da notificação",
    model: "Mensagem / Modelo",

    placeholder: "Escolha uma opção",







};

export default pt;
