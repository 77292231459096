import React, {useCallback} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/beneficiarySelection/locales/locales";
import localeTeachers from "app/beneficiaries/teachers/locales/locales";
import localeStudents from "app/beneficiaries/students/locales/locales";
import ListFilterTeacherComponent from "app/beneficiaries/teachers/components/list/ListFilterComponent";
import ListFilterStudentComponent from "app/beneficiaries/students/components/list/ListFilterComponent";
import Row from "react-bootstrap/Row";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {Form} from "react-bootstrap";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import BeneficiaryType from "types/BeneficiaryType";
import Col from "react-bootstrap/Col";
import {getOrNull} from "system/Objects/ObjectParameters";
import Collapse from "layout/modules/Collapse/Collapse";
import HelpHeaderConfigComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderConfigComponent";

const FormConfiguration = props => {

    const {setConfiguration, configuration, errors = {}, readOnly} = props;

    const {t} = useTranslations("BeneficiarySelectionRuleAction", locale);
    const {t: tTeacher} = useTranslations("teachers", localeTeachers);
    const {t: tStudents} = useTranslations("students", localeStudents);

    const handleFilterChange = useCallback(
        (filter) => {
            setConfiguration((config) => ({
                ...config,
                props: {
                    ...config.props,
                    ...filter
                }
            }))
        },
        [setConfiguration]
    );

    return <>

        <Row>
            <Col sm={12} lg={12} xl={12}>
                <FormGroup error={errors['beneficiaryType']} mandatory={true}>
                    <Form.Label>{t('beneficiary_type')}</Form.Label>
                    <EnumDropdown
                        t={t}
                        isMulti={false}
                        options={BeneficiaryType}
                        isDisabled={readOnly}
                        value={getOrNull(configuration?.props?.beneficiaryType)}
                        onChange={useCallback((value) => setConfiguration((configuration) => ({
                            ...configuration,
                            props: {
                                beneficiaryType: value,
                            }

                        })), [setConfiguration])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['beneficiaryType'])}
                    </Form.Text>
                </FormGroup>
            </Col>

        </Row>


        {configuration.props?.beneficiaryType &&
        <Collapse title={t('selection_filter', {type: t(configuration.props?.beneficiaryType)})}>

            {(configuration.props?.beneficiaryType === BeneficiaryType.TEACHER) &&
            <ListFilterTeacherComponent
                t={tTeacher}
                readOnly={readOnly}
                searchFilter={configuration.props}
                hideAttributions={true}
                onFilterChange={readOnly ? undefined : handleFilterChange}

            />
            }

            {(configuration.props?.beneficiaryType === BeneficiaryType.STUDENT) &&
            <ListFilterStudentComponent
                t={tStudents}
                readOnly={readOnly}
                searchFilter={configuration.props}
                hideAttributions={true}
                onFilterChange={readOnly ? undefined : handleFilterChange}

            />
            }
        </Collapse>
        }

        <HelpHeaderConfigComponent {...props}/>

    </>

}

export default FormConfiguration;
