import isFunction from "system/Utils/isFunction";

const generateRunningData = (operation, typeObject, ruleExtraProps = {}) => {

    return {
        templateId: operation.id,
        [operation.type.toLowerCase() + "Ids"]: [typeObject?.id],
        props: Array(operation.actions.length).fill({}),
        ...ruleExtraProps,
    }
}

const updateRunningData = (setExecutionData, step, data) => {
    setExecutionData(prevData => ({
        ...prevData,
        props: prevData.props.map(
            (d, index) =>
                index === step
                    ? isFunction(data) ? data(d) : {...data}
                    : {...d}
        )
    }));
}

export {updateRunningData};
export default generateRunningData;
