import ViewComponent from "app/support/components/view/ViewComponent";
import {useGet, useMutate} from "system/Rest/Rest";
import {useCallback, useEffect, useState} from "react";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import Loading from "layout/modules/Loading/Loading";
import {handleUnknownFormError} from "modules/Forms/Utils/useGenericFormSubmit";

const ViewContainer = props => {

    const {id} = props;
    const {t} = useTranslation("support");
    const showToast = useShowToast();

    const [ticket, setTicket] = useState();

    const {data: remoteTicket, refetch: reload} = useGet({
        path: "/tickets/" + id,
    });

    const {mutate: submitComment, loading: sendingMessage, error} = useMutate({
        verb: "POST",
        path: "/tickets/" + id + "/conversation",
    });

    const {mutate: submitStatus, loading: settingStatus} = useMutate({
        verb: "PUT",
        path: "/tickets/" + id + "/status",
    });

    const {mutate: submitPriority, loading: settingPriority} = useMutate({
        verb: "PUT",
        path: "/tickets/" + id + "/priority",
    });

    //Update remote ticket
    useEffect(
        () => {
            if (remoteTicket) {
                setTicket(remoteTicket)
            }
        },
        [remoteTicket]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            reload();
        }, 5000);
        return () => clearInterval(interval);
    }, [reload]);


    const handleNewMessage = useCallback(
        (comment) => {
            submitComment(comment).then(() => {
                reload();
            }).catch(() => {
            });
        },
        [submitComment, reload]
    );

    const handleSetStatus = useCallback(
        (status) => {

            if (status === ticket?.status)
                return;

            submitStatus({
                status: status
            })
                .then((r) => {
                    showToast({
                        title: t('SUCCESS_SET_STATUS'),
                        message: t('SUCCESS_SET_STATUS_MSG', {...r, status: t(status)}),
                        icon: faCheck,
                    });
                    reload();
                })
                .catch((e) => handleUnknownFormError(e, showToast, t));

        },
        [submitStatus, showToast, reload, t, ticket?.status]
    );

    const handleSetPriority = useCallback(
        (priority) => {

            submitPriority({
                priority: priority
            })
                .then((r) => {
                    showToast({
                        title: t('SUCCESS_SET_PRIORITY'),
                        message: t('SUCCESS_SET_PRIORITY_MSG', {...r, priority: t(priority)}),
                        icon: faCheck,
                    });
                    reload();
                })
                .catch((e) => handleUnknownFormError(e, showToast, t));

        },
        [submitPriority, showToast, reload, t]
    );

    const errors = useErrorParser(error);

    if (!ticket) {
        return <Loading visible={true}/>
    }

    return <ViewComponent
        {...props}
        ticket={ticket}
        errors={errors}
        loading={settingStatus || settingPriority}
        sendingMessage={sendingMessage}
        onSubmit={handleNewMessage}
        onStatusChange={handleSetStatus}
        onPriorityChange={handleSetPriority}
    />
}

export default ViewContainer;
