import React from 'react'
import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";

const ProcedureDropdown = (props) => {

    return <SimpleListDropdown
        {...props}
        remotePath={"/procedures/"}
    />

}

export default ProcedureDropdown;
