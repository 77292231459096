import React, {useMemo} from "react";
import locale from "app/schools/locales/locales"
import ListComponent from "./components/list/ListComponent"
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";

const List = (props) => {

    const {id, parentIds, nut2, nut3, status, typologies, hideTitle} = props;

    return <GenericListContainer
        {...props}
        module="school"
        id={"school" + (id ?? parentIds)}
        locale={locale}
        hideTitle={!!parentIds || hideTitle}
        queryParameters={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 10,
            excludeSelf: true,
            parentIds: parentIds,
            nut2: nut2,
            nut3: nut3,
            status:status,
            typologies: typologies,

        }),[parentIds, nut2, nut3, status, typologies])}>
        <ListComponent {...props}/>
    </GenericListContainer>

}

export default List;
