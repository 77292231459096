import React, {useMemo} from "react";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import BeneficiaryListComponent from "app/ruleTemplates/components/show/beneficiaries/BeneficiaryListComponent";
import locale from "app/beneficiaries/beneficiary/locales/locales";

const BeneficiaryTabComponent = props => {

    const {beneficiaries} = props;

    const randId = useMemo(() => generateRandomId(), []);

    return <div className={"beneficiary-rule-list"}>
        <GenericListContainer
            hideTitle={true}
            module="beneficiary"
            id={"beneficiary" + randId}
            locale={locale}
            queryParameters={useMemo(
                () => ({
                    currentPage: 0,
                    itemsPerPage: 10,
                    ids: beneficiaries.map(e => e.id)
                }),
                [beneficiaries]
            )}>
            <BeneficiaryListComponent {...props}/>
        </GenericListContainer>

    </div>
}

export default BeneficiaryTabComponent;
