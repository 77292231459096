import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import Container from "react-bootstrap/Container";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import "app/procedures/contracts/assets/css/list.scss";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import ListFilterComponent from "app/procedures/contracts/components/list/ListFilterComponent";
import DateTime from "system/DateTime/DateTime";
import classnames from "classnames";
import generateValidOrInvalidCss from "app/procedures/contracts/utils/generateValidOrInvalidCss";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {beneficiaryLabelHandler} from "modules/Forms/Dropdowns/ClassDropdown/components/BeneficiaryDropdown";

const ListComponent = (props) => {

    const {t} = useTranslation('contract');

    const {
        data,
        onPageChange,
        onPageSort,
        loading,
        onDelete,
        onSearch,
        searchFilter,
        onFilterSubmit,
        disableSaving,
        beneficiaryIds,
        entityIds,
        boxTitle,
        listOnly,
        auditId,
        equipmentId
    } = props;

    const [deletedInfo, setDeletedInfo] = useState({});


    return <Container fluid>

        <Box className={"contract-list"}>

            {boxTitle &&
                <BoxTitle>{boxTitle}</BoxTitle>
            }

            <PopupConfirm
                title={t('DELETE_CONTRACT_CONFIRM')}
                message={t('DELETE_CONTRACT_CONFIRM_MSG', deletedInfo)}
                classConfirm={"danger"}
                visible={!!deletedInfo.id}
                onCancel={() => {
                    setDeletedInfo({})
                }}
                onConfirm={useCallback(() => {
                    setDeletedInfo({})
                    onDelete(deletedInfo.id, {name: deletedInfo.id});
                }, [setDeletedInfo, deletedInfo.id, onDelete])}
            />

            {!listOnly &&
                <>
                    <TableHeader>
                        <SearchArea disableSaving={disableSaving} onSearch={onSearch}
                                    value={searchFilter.universalSearch}
                                    onSubmit={(data) => onFilterSubmit(data)}>
                            <SearchFilter>
                                <ListFilterComponent
                                    t={t}
                                    searchFilter={searchFilter}
                                    entityIds={entityIds}
                                    beneficiaryIds={beneficiaryIds}
                                />
                            </SearchFilter>
                        </SearchArea>
                    </TableHeader>

                    <SearchDataPagination data={data} onPageChange={onPageChange}/>
                </>
            }

            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data)}>

                <thead>
                <tr>

                    <th sorted id={"id"}>
                        {t('id')}
                    </th>

                    <th className={"d-none d-md-table-cell"}>
                        {t('model')}
                    </th>

                    <th>
                        {t('entities')}
                    </th>

                    <th className={"text-end d-none d-md-table-cell"}>
                        {t('status')}
                    </th>


                    <th className={"text-end d-none d-sm-table-cell"} id={"lastUpdateTimestamp"} sorted>
                        {t('dates')}
                    </th>


                    <th/>

                </tr>
                </thead>
                <tbody>


                {data?.content?.map((value, index) => {

                    return <tr key={index}>

                        <td className="fill-empty td-sorted">
                            {value.id}
                        </td>

                        <td className="fill-empty d-none d-md-table-cell">
                            {t(value.model.name)}
                        </td>

                        <td className="fill-empty">
                            <div
                                className={classnames("table-line", generateValidOrInvalidCss(value.originSignTimestamp))}>
                                {value?.origin.name}
                            </div>
                            <div
                                className={classnames("table-line", generateValidOrInvalidCss(value.counterpartySignTimestamp))}>
                                {value?.entity?.name}
                                {beneficiaryLabelHandler(value?.beneficiary)}
                            </div>
                        </td>

                        <td className="fill-empty d-none d-md-table-cell">
                            <div className={"table-line-info"}>
                                <div className={"table-line-title"}>{t('valid')}</div>
                                <div className={classnames("table-line-value", generateValidOrInvalidCss(value.valid))}>
                                    {t(value.valid)}
                                </div>
                            </div>

                            <div className={"table-line-info"}>
                                <div className={"table-line-title"}>{t('validated')}</div>
                                <div
                                    className={classnames("table-line-value", generateValidOrInvalidCss(value.validatedSignatures))}>
                                    {t(value.validatedSignatures)}
                                </div>
                            </div>

                        </td>


                        <td className="fill-empty d-none d-sm-table-cell">

                            <div className={"table-line-info"}>
                                <div className={"table-line-title"}>{t('last_update')}</div>
                                <div className={"table-line-value"}>
                                    <DateTime format="DD-MM-YYYY HH:mm">
                                        {value.lastUpdateTimestamp}
                                    </DateTime>
                                </div>
                            </div>

                            <div className={"table-line-info"}>
                                <div className={"table-line-title"}>{t('creation_date')}</div>
                                <div className={"table-line-value"}>
                                    <DateTime format="DD-MM-YYYY HH:mm">
                                        {value.creationTimestamp}
                                    </DateTime>
                                </div>
                            </div>


                        </td>


                        <td className="table-buttons">

                            <div className="buttons">

                                {auditId
                                    ? <TableIconButton
                                        path={"/contract/" + value.id + "/" + auditId + "/" + equipmentId}
                                        icon={faSearch}
                                        className={"table-btn btn-rounded-primary small"}
                                    />
                                    : <TableIconButton
                                        path={"/contract/" + value.id}
                                        icon={faSearch}
                                        className={"table-btn btn-rounded-primary small"}
                                    />
                                }

                                {!listOnly &&
                                    <TableIconButton
                                        icon={faTrash}
                                        permission={"DELETE_CONTRACT"}
                                        className={"table-btn btn-rounded-danger small"}
                                        onClick={() => {
                                            setDeletedInfo(value)
                                        }}
                                    />
                                }

                            </div>
                        </td>
                    </tr>
                })}


                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
                <div className={"no-table-results"}>
                    {t('no_contracts_found')}
                </div>
            }
        </Box>
    </Container>

}


export default ListComponent;
