import React from "react";
import locale from "locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import {useApiState} from "system/API/APIStateContext";
import useExitImpersonate from "app/personalArea/utils/useExitImpersonate";
import HeaderMessage from "layout/modules/InfoMessages/HeaderMessage";
import AnnouncementComponent from "layout/modules/BaseLayout/ContentArea/components/header/AnnouncementComponent";

const TopAlertMessages = (props) => {

    const {t} = useTranslations('global', locale);

    const {authentication} = useApiState();
    const exitImpersonate = useExitImpersonate();

    //const generalAnnouncement = useGeneralAnnouncementState();
    return <div className={"header-messages"}>
        {authentication.impersonate &&
        <HeaderMessage
            type={"danger"}
            message={t('user_impersonate_msg', {name: authentication?.name})}
            action={exitImpersonate}
            actionLabel={t('exit_impersonate')}
        />
        }

        <AnnouncementComponent {...props}/>

    </div>

}


export default TopAlertMessages;
