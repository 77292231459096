import React from 'react'
import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";

const EquipmentStatusDropdown = (props) => {

    return <SimpleListDropdown
        {...props}
        remotePath={"/equipment-status/search"}
    />

}

export default EquipmentStatusDropdown;
