import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/allowInvoicing/locales/locales";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";


class AllowInvoicingRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [];
    static disableConfiguration = true;

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faFileInvoiceDollar, t('name'), t('description'));

    static getName = () => "AllowInvoicingRuleAction";
    static getDefaultProps = () => ({});
    static renderEditor = () => null;

    static isAllowed = (config, position) => ruleHasEquipments(config, position);

}

export default AllowInvoicingRuleAction;
