export const pt = {

    name: "Caixa dropdown",
    set_label: "Defina o nome do campo",

    placeholder: "Ajuda / Descrição de preenchimento",
    placeholder_ph: "Este texto é um placeholder",

    allow_multiple: "Multiplos valores",
    no_results: "Não existem opções",
    loading_message: "A pesquisar",

    add_option_placeholder: "Escreva a nova opção",
    options: "Opções"


};

export default pt;
