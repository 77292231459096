import {useCurrentPng} from "recharts-to-png";
import {useCallback} from "react";
import fileDownload from "app/files/utils/download";
import getRandomString from "system/Randoom/Random";

const useChartDownload = props => {

    const [getChartPng, {ref: chartRef}] = useCurrentPng();
    const handleChartDownload = useCallback(async () => {

        const fileName = getRandomString(10);
        const png = await getChartPng();
        if (png) {
            fileDownload(png.replace("data:image/png;base64,", ""), fileName + ".png");
        }
    }, [getChartPng]);

    return {handleChartDownload, chartRef}

}

export default useChartDownload;