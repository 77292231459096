import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault} from "system/Objects/ObjectParameters";
import React from "react";

const SelectionModeEntryCheckbox = props => {

    const {selectedItems = [], setSelectedItems, beneficiary, isDisabled} = props;

    return <td className={"table-checkbox"}>
        <CheckBox
            disabled={isDisabled}
            value={getOrDefault(selectedItems.find(e => e.id === beneficiary.id), false)}
            onChange={(value) => setSelectedItems((items) => {
                if (value) {
                    return [...selectedItems, beneficiary];
                } else {
                    return [...items].filter(item => item.id !== beneficiary.id)
                }
            })}
        />
    </td>


}

export default SelectionModeEntryCheckbox;

