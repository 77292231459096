import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelWithValue from "layout/modules/LabelWithValue/LabelWithValue";
import {useTranslation} from "react-i18next";
import DateTime from "system/DateTime/DateTime";
import Box from "layout/modules/Box/Box";

const ExecuteDetailComponent = props => {

    const {execution} = props;

    const {t} = useTranslation('rule-template');


    return <div className={"rule-view-tab-content"}>
        <Box>
            <Row>

                <Col md={12} sm={12}>
                    <LabelWithValue label={t('name')} value={execution?.template?.name}/>
                </Col>


                <Col md={6} sm={12}>
                    <LabelWithValue label={t('status')} value={t(execution.status)}/>
                </Col>

                <Col md={6} sm={12}>
                    <LabelWithValue label={t('execution_user')} value={t(execution.creationUser?.name)}/>
                </Col>

                <Col md={6} sm={12}>
                    <LabelWithValue label={t('execution_entity')} value={t(execution.creationUser?.entity)}/>
                </Col>

                <Col md={6} sm={12}>
                    <LabelWithValue label={t('execution_date')}>
                        <DateTime format="DD-MM-YYYY HH:mm:ss">
                            {execution.creationTimestamp}
                        </DateTime>
                    </LabelWithValue>
                </Col>
            </Row>

        </Box>

    </div>
}

export default ExecuteDetailComponent;
