export const pt = {

    title: "Lista de contratos",
    view_contract: "Visualizar contrato {{id}}",

    //List
    no_contracts_found: "Não foram encontrados contrato",
    id: "Id",
    model: "Tipo / Modelo",
    entities: "Entidades / Beneficiário",
    status: "Estado",
    valid: "Em vigor",
    validated: "Validado",
    total_signatures: "Num. assinaturas",
    beneficiary: "Beneficiário",
    dates: "Datas",
    creation_date: "Data de criação",
    last_update: "Últ. atualização",
    signed_by_origin: "Assinado pelo primeiro outorgante",
    signed_by_counterparty: "Assinado pelo segundo outorgante",
    procedure: "Fase",


    true: "Sim",
    false: "Não",

    origin: "Primeiro outorgante",
    entity: "Segundo outorgante",

    //View
    version_id: "Versão",
    general: "Contrato/Acordo",
    general_details: "Detalhes do contrato/acordo",
    signed_by: "Assinado por {{name}}",
    select_file: "Documento",
    annexes_versions: "Versões do anexo",
    annexes: "Anexos / Listagem",
    annexes_details: "Detalhes do anexo/listagem",
    files_count: "Total de versões",
    annex_version: "Versão do anexo",


    annex_not_validated: "Anexo/Listagem não validado",
    annex_not_validated_msg: "O anexo/listagem ainda não foi validado. Caso esteja tudo de acordo carregue no botão para validar. No caso do acordo anexado não estar assinado utilize o botão invalidar",

    contract_not_validated: "Contrato/Acordo não validado",
    contract_not_validated_msg: "O contrato/acordo ainda não foi validado. Caso esteja tudo de acordo carregue no botão para validar",

    contract_not_valid: "O contrato/acordo já não está em vigor",
    contract_not_valid_msg: "Este contrato/acordo já não está em vigor. Isto pode indicar que os equipamentos associados já não se encontram com o beneficiário",

    annex_signatures_missing_msg: "Neste momento estão em falta assinaturas no anexo.",

    signatures_missing: "Assinaturas em falta",
    signatures_missing_msg: "Neste momento estão em falta assinaturas no contrato/acordo.",

    signatures_required: "Assinatura em falta",
    signatures_required_msg: "Neste momento está em falta, <strong>no contrato/acordo e no anexo/listagem</strong>, a assinatura do responsável da sua entidade. O acordo e o anexo devem ser assinado com a maior brevidade possível.",

    signature_required: "Assinatura(s) em falta",
    signature_required_msg: "Neste momento está em falta, <strong>no contrato/acordo</strong>, a assinatura do responsável da sua entidade. O acordo deve ser assinado com a maior brevidade possível.",

    annex_signature_required: "Assinatura(s) no anexo em falta",
    annex_signature_required_msg: "Neste momento está em falta, <strong>no anexo/listagem</strong>, a assinatura do responsável da sua entidade.",

    sign_contract: "Assinar",
    validate_contract: "Validar",
    invalidate_contract: "Invalidar documentos",

    add_new_document: "Enviar {{type}} assinado",
    add_new_document_description: "Para carregar o documento assinado  deve utilizar o botão escolher ou arrastar o documento",
    add_file: "Carregar documento",
    close: "Fechar",
    delete_file: "Apagar ficheiro",
    refresh: "Gerar novamente",

    CONTRACT: "contrato",
    ANNEX: "anexo",
    SUCCESS_SIGN: "Assinatura enviada",
    SUCCESS_SIGN_MSG: "O {{type}} foi assinado com sucesso.",

    SUCCESS_VALIDATE: "Assinaturas validadas",
    SUCCESS_VALIDATE_MSG: "O {{type}} foi validado com sucesso.",

    SUCCESS_INVALIDATE: "Assinaturas invalidadas",
    SUCCESS_INVALIDATE_MSG: "O {{type}} foi invalidado. Deve agora requisitar uma nova assinatura do ficheiro",

    VALIDATE_CONFIRM: "Confirmação",
    VALIDATE_CONFIRM_MSG: "Deseja realmente validar as assinaturas do {{type}}?",
    INVALIDATE_CONFIRM_MSG: "Deseja realmente <strong>NÃO</strong> validar as assinaturas do {{type}}?",

    DELETE_CONTRACT_CONFIRM: "Confirmação",
    DELETE_CONTRACT_CONFIRM_MSG: "Deseja realmente apagar o contrato/acordo {{id}}?",

    CONFIRM_REFRESH: "Confirmação",
    CONFIRM_REFRESH_MSG: "Ao gerar novamente o contrato/acordo o mesmo terá de ser assinado e validado. Deseja realmente gerar novamente o contrato?",

    REFRESH_SUCCESS: "Atualizado",
    REFRESH_SUCCESS_MSG: "O contrato/acordo foi gerado novamente e deve ser agora assinado.",

    ERROR_SIGNING: "Erro ao assinar",
    ERROR_SIGNING_MSG: "Ocorreu um erro a assinar o documento. Por favor tente mais tarde ou abra um novo pedido de suporte.",

    save_changes: "Guardar alterações",
    cancel: "Cancelar",

};

export default pt;
