const auditUserResolver = (user) => ({
    ...user,
    entityId: user.entity,
    roleId: user.role,
});


const userResolver = (user) => ({
    ...user,
    entityId: user.entity?.id,
})

export {auditUserResolver, userResolver}
