export const pt = {

    name: "Definir beneficiário do(s) equipamento(s)",
    description: "Esta ação define o beneficiário dos equipamentos e altera o estado do beneficiário.",

    new_status: "Novo estado de equipamento no beneficiário",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração. Deve indicar o novo estado do beneficiário",

    form_description: "Atribui os equipamentos ao(s) beneficiário(s) e define o estado do beneficiário relativamente ao equipamento como <strong>{{status}}.</strong>",

};

export default pt;
