import Col from "react-bootstrap/Col";
import NutDropdown, {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {SchoolType} from "types/SchoolType";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import {useTranslation} from "react-i18next";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";

const ContractChartAdvancedFilter = props => {

    const {setFilters, filters} = props;
    const {t} = useTranslation('dashboard');


    return <>

        <Col md={6} xxl={12}>
            <div className={"label"}>
                {t('nut3')}
            </div>
            <div className={"value"}>
                <NutDropdown
                    isClearable={true}
                    isMulti={true}
                    value={getOrNull(filters.nut3)}
                    type={NUTType.NUT3}
                    onChange={useCallback(
                        (value) => setFilters(filter => ({...filter, nut3: value})),
                        [setFilters]
                    )}
                />

            </div>

        </Col>

        <Col md={6} xxl={12}>
            <div className={"label"}>
                {t('school_type')}
            </div>
            <div className={"value"}>
                <EnumDropdown
                    t={t}
                    options={SchoolType}
                    isClearable={true}
                    isMulti={true}
                    value={getOrNull(filters.institutionTypologies)}
                    onChange={useCallback(
                        (value) => setFilters(filter => ({...filter, institutionTypologies: value})),
                        [setFilters]
                    )}
                />

            </div>
        </Col>

        {isNotDefinedNorEmpty(filters?.institutionTypologies)
            ? <SchoolFilterComponent
                md={6}
                sm={6}
                lg={6}
                xxl={12}
                value={filters?.schoolIds}
                isMulti={true}
                setValue={(value) => setFilters(filter => ({
                    ...filter,
                    schoolIds: value,
                }))}
            />
            : <Col sm={6} lg={6} xxl={12}>
                <FormGroup>
                    <Form.Label>{t('school')}</Form.Label>
                    <SchoolDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.schoolIds)}
                        typologies={filters.institutionTypologies}
                        onChange={(value) => setFilters(filter => ({...filter, schoolIds: value}))}
                    />
                </FormGroup>
            </Col>
        }

    </>
}
export default ContractChartAdvancedFilter