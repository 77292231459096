import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormComponent from "app/procedures/contractModel/components/form/FormComponent";
import React from "react";
import {getDiffsInErrorFormat} from "app/audit/utils/AuditDiff";
import {ContractModelLoggerResolver} from "app/procedures/contractModel/utils/ContractModelResolver";

const ContractModelComponent = props => {

    const {operation} = props;
    const diffs = getDiffsInErrorFormat(operation.changes);

    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                <FormComponent
                    model={ContractModelLoggerResolver(operation.objectRaw)}
                    errors={diffs}
                    create={false}
                    compareMode={true}
                    viewMode={true}
                    loading={false}
                />
            </Col>

        </Row>

    </div>
}

export default ContractModelComponent;
