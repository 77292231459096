import HelpComponent from "layout/modules/BaseLayout/Menu/components/fixed/HelpComponent";
import UserComponent from "layout/modules/BaseLayout/Menu/components/fixed/UserComponent";
import React, {useRef, useState} from "react";
import NotificationArea from "app/notifications/NotificationArea";
import DarkModeComponent from "layout/modules/BaseLayout/Menu/components/fixed/DarkModeComponent";
import "layout/modules/BaseLayout/Menu/css/fixed-items.scss";
import useMenuDropdownAccessibility from "layout/modules/BaseLayout/Menu/utils/useMenuDropdownAccessibility";
import SupportButtons from "app/support/SupportButtons";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const FixedItemsComponent = props => {

    const [focusActive, setFocusActive] = useState(false);

    const ref = useRef(null);
    useMenuDropdownAccessibility({ref, focusActive});

    return <div className={"menu-fixed-buttons"}>

        <div className={"separator"}/>
        <div className={"main-menu-items"} tabIndex={0} onFocus={() => setFocusActive(true)}
             onBlur={() => setFocusActive(false)} ref={ref} role="menubar"
             aria-orientation="vertical">

            <UserHasPermission permission={'USE_TICKETS'}>
                <SupportButtons {...props}/>
            </UserHasPermission>
            <NotificationArea {...props}/>
            <HelpComponent {...props}/>
        </div>

        <div className={"separator"}/>
        <div className={"menu-user-button"}>
            <UserComponent/>
        </div>

        <div className={"separator"}/>
        <DarkModeComponent/>

    </div>
}

export default FixedItemsComponent;
