import {useMutate} from "system/Rest/Rest";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useMemo} from "react";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import useErrorParser from "system/API/Error/ErrorParser";
import FormComponent from "app/help/components/FormComponent";

const FormContainer = (props) => {

    const {id, currentPageArticleId, onClose} = props;

    const showToast = useShowToast();
    const {t} = useTranslation('help');

    const {mutate: submit, loading, error: errorSubmitting} = useMutate({
        verb: "PUT",
        path: "/help/",
        queryParams: useMemo(() => ({
            language: i18next.language,
            url: id ? id : currentPageArticleId,
        }), [id, currentPageArticleId])
    });

    const errors = useErrorParser(errorSubmitting);

    return <FormComponent
        {...props}
        loading={loading}
        errors={errors}
        onSubmit={(help) => {
            submit(help).then((result) => {
                showToast({
                    title: t('SUCCESS_HELP'),
                    message: t('SUCCESS_HELP_MSG'),
                    icon: faCheck,
                });
                onClose(result);

            }).catch(() => {})
        }}

    />

}

export default FormContainer;
