import React, {useCallback, useEffect, useRef, useState} from 'react'
import classnames from "classnames";
import {useMenuState} from "./MenuStateContext";
import {useLocation} from "react-router";
import {useIsMobile} from "system/Window/Mobile";
import {useClickedOutsideMobile} from "system/Window/HandleClickOutside";
import {MainMenu, MenuBottomMobile} from "app/menu";
import useMenuDropdownAccessibility from "layout/modules/BaseLayout/Menu/utils/useMenuDropdownAccessibility";
import FixedItemsComponent from "layout/modules/BaseLayout/Menu/components/fixed/FixedItemsComponent";
import './css/menu-responsive.scss'
import './css/menu-responsive-effects.scss'
import './css/menu.scss'

const Menu = (props) => {

    const ref = useRef(null);
    const mainMenuRef = useRef(null);
    const isMobile = useIsMobile();
    const location = useLocation();

    const [menuState, setMenuState] = useMenuState();
    const [focusActive, setFocusActive] = useState(false);

    useMenuDropdownAccessibility({ref: mainMenuRef, focusActive});

    const setMenuVisible = useCallback(
        (value) => {
            setMenuState((state) => ({
                ...state,
                visible: value
            }));
        },
        [setMenuState]
    );


    useEffect(
        () => setMenuVisible(!isMobile),
        [setMenuVisible, isMobile]
    );

    useEffect(() => {
        setMenuVisible(!isMobile);
    }, [location.pathname, setMenuVisible, isMobile]);


    //Close on outsiide click
    useClickedOutsideMobile(ref, useCallback(() => {
            if (menuState?.visible && isMobile) {
                setMenuVisible(false)
            } else if (menuState?.active) {
                setMenuState(menuState => ({...menuState, active: undefined}))
            }
        }, [setMenuVisible, setMenuState, menuState?.visible, menuState?.active, isMobile])
    );

    const switchMenu = useCallback((e) => {
        e.stopPropagation();
        setMenuVisible(!menuState?.visible);
    }, [menuState?.visible, setMenuVisible]);

    return <>
        <div className={classnames({'menu': true, 'is-visible': menuState?.visible})} ref={ref}>
            <div>
                <div className={"menu-header"}>
                    <div className={"menu-title-regular"}>
                        Escola<br/>Digital
                    </div>


                </div>
                <div className={classnames({
                    "main-menu-items": true,
                    "sub-menu-active": menuState.active
                })}
                     ref={mainMenuRef}
                     tabIndex={0}
                     role="menubar"
                     aria-orientation="vertical"
                     onFocus={() => setFocusActive(true)}
                     onBlur={() => setFocusActive(false)}

                >
                    <MainMenu {...props}/>

                </div>
                
                <FixedItemsComponent {...props} />

            </div>
        </div>

        <div className={menuState?.visible ? 'menu-bottom menu-is-visible' : 'menu-bottom'}>
            <MenuBottomMobile onOpenMenu={switchMenu} {...props} />
        </div>
    </>


}

export default Menu;
