import {useTranslation} from "react-i18next";
import React from "react";
import ParamsComponent from "./ParamsComponent";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import TemplateComponent from "app/reporting/components/generate/TemplateComponent";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Loading from "layout/modules/Loading/Loading";
import TemplateAgreementComponent from "app/reporting/components/generate/TemplateAgreementComponent";
import Row from "react-bootstrap/Row";
import GenerateResultContainer from "app/reporting/containers/GenerateResultContainer";
import "app/reporting/assets/css/generate.scss";

const FormComponent = props => {

    const {t} = useTranslation("reports");
    const {errors, loading, template, onSubmit, result, request, setRequest, readOnly, t: tAux} = props;

    return <div className={"reports"}>

        {!readOnly &&
        <TemplateAgreementComponent
            {...props}
            t={t}
            template={template}
            request={request}
            setRequest={setRequest}
        />
        }

        <Form>
            <GenericFormError t={t} errors={errors}/>
            <Loading visible={loading}/>

            {!readOnly &&
            <Box>
                <BoxTitle>
                    {tAux('generate_report')}
                </BoxTitle>

                <Row>
                    <TemplateComponent
                        {...props}
                        t={t}
                        template={template}
                        request={request}
                        setRequest={setRequest}
                    />
                </Row>

            </Box>
            }

            {(template && template?.parameters?.length > 0) &&
            <ParamsComponent
                {...props}
                t={t}
                request={request}
                setRequest={setRequest}
            />
            }

            {(request?.templateId && !readOnly) &&
            <>
                <div className={"buttons"}>
                    {template?.previewQuery &&
                    <Button variant="primary" onClick={() => onSubmit({
                        ...request,
                        isPreview: true
                    })}>
                        {t('preview_report')}
                    </Button>
                    }

                    <Button variant="primary" onClick={() => onSubmit(request)}>
                        {t('request_report')}
                    </Button>
                </div>
            </>
            }

            {result &&
            <GenerateResultContainer
                t={t}
                result={result}
                request={request}
            />
            }

        </Form>

    </div>


}

export default FormComponent;
