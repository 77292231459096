import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import "app/home/assets/css/charts.scss";
import React, {useCallback, useMemo, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import createPersistedState from "system/System/PersistedState/PersistenState";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import useChartDownload from "app/home/utils/useChartDownload";
import {ChartFreshColors} from "app/home/utils/ChartColors";
import GenericResolver from "app/home/utils/resolvers/GenericResolver";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/auditProcesses/locales/locales";
import AuditChartFilterComponent from "app/home/components/charts/audit/AuditChartFilterComponent";
import ChartLegendComponent from "app/home/components/charts/ChartLegendComponent";

const useStateChartPersistedState = createPersistedState('audit-dashboard-chart-filter');

const AuditChartComponent = props => {

    const {t} = useTranslation('dashboard');
    const {t: tAuditProcess} = useTranslations('audit-process', locale);

    const [isFilterVisible, setFilterVisible] = useState(false);
    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const [filters, setFilters] = useStateChartPersistedState({});
    const {handleChartDownload, chartRef} = useChartDownload();

    const {data, loading} = useGet({
        path: "/dashboard/audit-processes",
        localErrorOnly: true,
        queryParams: useMemo(() => ({
            ...filters,
        }), [filters]),
        resolve: useCallback(data => GenericResolver(data, tAuditProcess), [tAuditProcess])
    });

    return <Box>
        <BoxTitle>{t('audits')}</BoxTitle>

        <ChartButtonsComponent
            setFilterVisible={setFilterVisible}
            isFilterVisible={isFilterVisible}
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />


        {isFilterVisible &&
            <AuditChartFilterComponent filters={filters} setFilters={setFilters}/>
        }

        <Loading visible={loading}/>

        {data?.length > 0 &&
            <>
                {chartMode === ChartMode.CHART &&
                    <ResponsiveContainer height={350} width="100%">
                        <BarChart data={data} ref={chartRef} barCategoryGap={10} barGap={0}
                                  margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                            <XAxis dataKey={t('Resultado')}
                                   stroke={"var(--chart-line-color)"}
                                   tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                   padding={{left: 0, right: 10}}/>

                            <YAxis
                                stroke={"var(--chart-line-color)"}
                                tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                padding={{left: 0, right: 10}}/>

                            <Legend content={ChartLegendComponent}/>

                            <Tooltip
                                cursor={false}
                                //formatter={handleLabel}
                                contentStyle={{
                                    backgroundColor: undefined, border: undefined,
                                }}
                            />
                            <CartesianGrid stroke="var(--chart-grid-line)" strokeDasharray="3 1" vertical={false}/>
                            <Bar dataKey={tAuditProcess('totalEquipments')} stackId={1} fill={ChartFreshColors[7]} barSize={10}/>
                            <Bar dataKey={tAuditProcess('totalProcesses')} stackId={2} fill={ChartFreshColors[8]} barSize={10}/>

                        </BarChart>
                    </ResponsiveContainer>
                }

                {chartMode === ChartMode.TABLE &&
                    <TableModeComponent data={data}/>
                }
            </>
        }

        {data?.length === 0 &&
            <div className={"no-chart-results"}>
                {t('no_chart_results')}
            </div>

        }

    </Box>

}

export default AuditChartComponent;
