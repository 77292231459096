import React, {useEffect, useState} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/replaceEquipment/locales/locales";
import Collapse from "layout/modules/Collapse/Collapse";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import TypologyMapping from "app/ruleTemplates/actions/replaceEquipment/components/config/TypologyMapping";
import HelpHeaderConfigComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderConfigComponent";
import isFunction from "system/Utils/isFunction";


const FormConfiguration = props => {

    const {setConfiguration, configuration, errors = {}, readOnly} = props;

    const {t} = useTranslations("ReplaceEquipmentRuleAction", locale);

    const [mapping, setMapping] = useState(
        Object.keys(configuration.props?.typologyProfileMap).map((key, i) => ({
            typologyId: key,
            profileId: configuration.props.typologyProfileMap[key]
        }))
    );

    useEffect(() => {
        if (!isFunction(setConfiguration)) {
            setMapping(Object.keys(configuration.props?.typologyProfileMap).map((key, i) => ({
                typologyId: key,
                profileId: configuration.props.typologyProfileMap[key]
            })));
        }
        // eslint-disable-next-line
    }, [configuration.props?.typologyProfileMap]);


    useEffect(() => {
        if (isFunction(setConfiguration)) {


            setConfiguration((config) => ({
                ...config,
                props: {
                    ...config.props,
                    typologyProfileMap: mapping?.reduce(
                        (obj, value) => ({
                            ...obj,
                            [value.typologyId]: value.profileId
                        }), {}
                    )
                }
            }));
        }
        // eslint-disable-next-line
    }, [mapping]);


    return <>

        <Collapse title={t('profile_mapping')}>
            <MultiOptionComponent
                data={mapping}
                errors={errors?.typologyProfileMap}
                readOnly={readOnly}
                onChange={setMapping}>
                <TypologyMapping/>
            </MultiOptionComponent>

        </Collapse>


        <HelpHeaderConfigComponent {...props}/>

    </>

}

export default FormConfiguration;
