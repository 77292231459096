import {faUserPlus, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import locale from "app/ruleTemplates/actions/beneficiarySelection/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import FormConfiguration from "app/ruleTemplates/actions/beneficiarySelection/components/FormConfiguration";
import "app/ruleTemplates/actions/beneficiarySelection/assets/editor.scss";

const FormEditor = configuration => {

    const {t} = useTranslations("BeneficiarySelectionRuleAction", locale);

    return <div className={"form-action-editor form-action-select-equipment"}>
        <div className={"editor-action-header"}>

            <div className={"icon"}>
                <FontAwesomeIcon icon={faUserPlus}/>
            </div>
            <div className={"title"}>
                {t('name')}
            </div>
        </div>

        {configuration?.props?.beneficiaryType
            ? <div className={"description view-mode"}>
                <FormConfiguration configuration={configuration} readOnly={true}/>
            </div>
            : <AdvanceInfoMessage
                icon={faWarning}
                type={"warning"}
                iconType={"warning"}
                title={t("invalid_configuration")}
                message={t('invalid_configuration_msg')}

            />

        }

    </div>
}

export default FormEditor;
