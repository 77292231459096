import React from "react";
import {useParams} from "react-router";
import ListContainer from "app/files/containers/ListContainer";

const FileList = (props) => {

    const {fileUniversalSearch} = useParams();

    return <ListContainer {...props} fileUniversalSearch={fileUniversalSearch}/>


}

export default FileList;
