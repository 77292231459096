export const pt = {
    version: "Versão",
    use_version: "Usar versão para edição",
    no_entity: "(Sem entidade)",
    entity: "Entidade",
    author: "Nome do autor",
    date: "Data da versão",


    version_not_found: "Versão não encontrada",
    version_not_found_msg: "A versão do documento não está disponível pelo que não é possível apresentar nenhuma informação",

};

export default pt;
