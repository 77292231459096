import HistoricComponent from "app/support/components/view/historic/HistoricComponent";
import {useGet} from "system/Rest/Rest";

const HistoricContainer = props => {

    const {ticket} = props;

    const {data: historic, loading} = useGet({
        path: "/tickets/" + ticket.id + "/status",
    });

    const {data: agents, loading: loadingAgents, refetch: loadAgents} = useGet({
        path: "/tickets/" + ticket.id + "/agent",
        lazy: true,
    });

    return <HistoricComponent
        {...props}
        historic={historic}
        agents={agents}
        onLoadAgents={loadAgents}
        loading={loading || loadingAgents}
        />

}


export default HistoricContainer;
