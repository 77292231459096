import Row from "react-bootstrap/Row";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import {acceptTypeImages} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";
import {readFileContentToByteString} from "system/Files/ReadFileContentToByteString";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const HeaderAndBottomComponent = props => {

    const {value, onChange} = props;
    const {t} = useTranslation('contractModel');

    return <Row>

        <Col sm={12} lg={12} xl={12}>
            <FormGroup>
                <Form.Label>{t('header_image')}</Form.Label>
                <ImageUploadAndPreview
                    placeholder={t('header_image_upload_text')}
                    onDelete={useCallback(
                        () => {
                            onChange({
                                headerImage: undefined
                            })
                        },
                        [onChange]
                    )}
                    onUpload={useCallback((nf) => {
                        nf.forEach((file) => {
                            readFileContentToByteString(file, (content) => {
                                onChange({
                                    headerImage: btoa(content),
                                });
                            });
                        })
                    }, [onChange])}
                    value={value?.headerImage}
                    />

            </FormGroup>
        </Col>


        <Col sm={12} lg={12} xl={12}>
            <FormGroup>
                <Form.Label>{t('footer_image')}</Form.Label>
                <ImageUploadAndPreview
                    placeholder={t('footer_image_upload_text')}
                    onDelete={useCallback(
                        () => {
                            onChange({
                                footerImage: undefined
                            })
                        },
                        [onChange]
                    )}
                    onUpload={useCallback((nf) => {
                        nf.forEach((file) => {
                            readFileContentToByteString(file, (content) => {
                                onChange({
                                    footerImage: btoa(content),
                                });
                            });
                        })
                    }, [onChange])}
                    value={value?.footerImage}
                />

            </FormGroup>
        </Col>

    </Row>;
}


const ImageUploadAndPreview = props => {

    const {onUpload, onDelete, value, placeholder} = props;

    return <>
        {!value
            ? <DragDropFile
                maxFiles={1}
                acceptTypes={acceptTypeImages}
                text={placeholder}
                onFileUploaded={onUpload}
            />
            : <div className={"preview-and-delete"}>

                <div className={"image-preview"}>
                    <img src={`data:image/jpeg;base64,${value}`} alt={"Preview"}/>
                </div>

                <Button type={'danger'} className={classnames("btn btn-rounded-danger small")} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>

            </div>
        }
    </>
}

export default HeaderAndBottomComponent;
