import {Trans, useTranslation} from "react-i18next";
import React, {useCallback, useMemo} from "react";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "system/DateTime/DateTime";
import {parseToCss} from "system/Utils/css";
import {useNavigate} from "react-router";
import NotificationIcons, {NotificationLink} from "app/notifications/utils/style";

const ListEntryComponent = (props) => {

    const {t} = useTranslation('remoteNotifications');
    const {notification} = props;

    const navigate = useNavigate();

    const hasLink = useMemo(
        () => !!NotificationLink[notification.type],
        [notification.type]
    );

    const handleClick = useCallback(
        () => NotificationLink[notification.type] ? navigate(NotificationLink[notification.type](notification)) : void 0,
        [navigate, notification]
    );


    return <div
        onClick={handleClick}
        className={classnames("entry", parseToCss(notification.type), {
            "with-link": hasLink,
        })}>

        <div className={"icon"}>
            <FontAwesomeIcon icon={NotificationIcons[notification.type]}/>
        </div>

        <div className={"text"}>
            <div className={"title"}>
                <Trans>
                    {t(notification.type, {
                        documentId: notification.objectId,
                        additionalInfo: notification.additionalInfo
                    })}
                </Trans>
            </div>

            <div className={"message"}>
                <Trans>
                    {t(notification.type + '_MSG', {
                        documentId: notification.objectId,
                        additionalInfo: notification.additionalInfo
                    })}
                </Trans>
            </div>
        </div>

        <div className={"date"}>
            <DateTime format="DD-MM-YYYY">
                {notification.date}
            </DateTime>
            <div>
                <DateTime format="HH:mm:ss">
                    {notification.date}
                </DateTime>
            </div>
        </div>

    </div>

}

export default ListEntryComponent;
