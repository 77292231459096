import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React from "react";
import Col from "react-bootstrap/Col";
import {getOrNull} from "system/Objects/ObjectParameters";
import DynamicSelect from "app/ruleTemplates/actions/forms/modules/dynamicSelects/components/DynamicSelect";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";

const EditorComponent = props => {

    const {id, subModule, setData, data, configuration, readOnly, errorMessage} = props;
    const {t} = useTranslations('Formdgec-selectsModule', locale);

    return <Col lg={6} sm={12}>
        <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
            <Form.Label>{configuration.label}</Form.Label>
            <DynamicSelect
                type={subModule}
                isDisabled={readOnly}
                value={getOrNull(data[id])}
                onChange={(value) => setData(data => ({
                    ...data,
                    [id]: value,
                }))}
            />
        </FormGroup>
        <Form.Text className="sub-error">
            {t(errorMessage)}
        </Form.Text>
    </Col>

}

export default EditorComponent;
