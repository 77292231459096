import List from "app/equipments/equipments/List";
import {useMemo} from "react";

const EquipmentTabComponent = props => {

    const {id, validationId, equipments} = props;

    const equipmentIdList = useMemo(
        () => equipments.map(e => e.id),
        [equipments]
    );

    return <div className={"execution-show-list-equipments"}>
        <List
            id={"rule-execution" + id}
            hideTitle={true}
            useGet={validationId}
            ids={equipmentIdList}
            embeddedMode={true}
            forcePath={validationId
                ? "/rule-validations/" + validationId + "/search-equipments"
                : undefined
            }
        />
    </div>
}

export default EquipmentTabComponent;
