import Modal from "react-bootstrap/Modal";
import Loading from "layout/modules/Loading/Loading";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import DestinationComponent from "app/support/components/form/DestinationComponent";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import TicketTransferType from "app/support/utils/TicketTransferType";
import {getTransferEntityId} from "app/support/utils/utils";

const TransferComponent = props => {

    const {ticket, onClose, transferring, onTransfer, transferType, selectedItems} = props;
    const {t} = useTranslation("support");

    const [request, setRequest] = useState({
        destinationEntityId: getTransferEntityId(ticket, selectedItems, transferType),
        ticketIds: selectedItems
            ? selectedItems.map(t => t.id)
            : undefined
    });

    return <Modal show={true} onHide={() => onClose()} size={"xl"}>
        <Modal.Header closeButton>
            <Modal.Title>{t('transfer_ticket')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loading visible={transferring} localOnly={true}/>

            <Row>
                <DestinationComponent
                    {...props}
                    request={request}
                    setRequest={setRequest}
                    disableEntitySelection={transferType === TicketTransferType.USER}
                />
            </Row>
        </Modal.Body>

        <Modal.Footer>

            <Button variant={'danger'} onClick={onClose}>
                {t('close_and_discard')}
            </Button>

            <Button variant={'primary'} onClick={() => onTransfer(request)}>
                {t('transfer')}
            </Button>
        </Modal.Footer>

    </Modal>
}

export default TransferComponent;
