import React from "react";
import Col from "react-bootstrap/Col";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/help/locales/locales";

const EditorComponent = props => {

    const {configuration, readOnly} = props;
    const {t} = useTranslations('HelpModule', locale);

    if (readOnly)
        return <></>;


    return <Col lg={12} sm={12}>

        <div className={"dynamic-form-help"}>
            <AdvanceInfoMessage
                icon={faInfo}
                type={"primary"}
                iconType={"primary"}
                title={configuration.title ?? t("title_default")}
                message={configuration.text ?? t('text_default')}

            />
        </div>
    </Col>

}

export default EditorComponent;
