import React, {useCallback, useEffect, useState} from "react"
import {useMutate} from "system/Rest/Rest"
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {removeEmptyParameters} from "system/Rest/utils/URL";
import useFilterState from "modules/GenericTableContainer/utils/useFilterState";

const GenericTableContainer = React.forwardRef((props, ref) => {

    const {
        t,
        id = 'genericTable',
        searchParameter = 'universalSearch',
        path,
        pathDelete,
        queryParameters,
        children,
        usePost,
        disableFilterCache,
    } = props


    const [searchFilter, setSearchFilter] = useFilterState(id, queryParameters, disableFilterCache);
    const [filteredData, setFilteredData] = useState({});
    const [loading, setLoading] = useState(true);
    const showToast = useShowToast();

    const {mutate: submitSearch, error} = useMutate({
        verb: usePost ? "POST" : "GET",
        //debounceRequest: false,
        path: path,
        queryParams: usePost ? undefined : searchFilter,
    });

    const {mutate: del, error: errorDelete} = useMutate({
        verb: 'DELETE',
        path: useCallback(({id}) => `${pathDelete}${id}`, [pathDelete]),
    });

    const reloadData = useCallback(
        (silent = false) => {
            if (!silent)
                setLoading(true);

            submitSearch(usePost ? removeEmptyParameters(searchFilter) : undefined)
                .then(data => setFilteredData(data))
                .catch(() => setFilteredData({}))
                .then(() => setLoading(false));
        },
        [setFilteredData, submitSearch, searchFilter, setLoading, usePost]
    )


    //Auto reload filters
    useEffect(() => reloadData(), [reloadData]);

    //Update search filter on disable cache
    useEffect(
        () => {
            if (disableFilterCache) {
                setSearchFilter(queryParameters);
            }
        },
        [setSearchFilter, queryParameters, disableFilterCache]
    )


    //Function to filter results
    const handleFilterSubmit = useCallback((data) => {
        if (!data || Object.keys(data).length === 0) {
            setSearchFilter(queryParameters);
        } else {
            setSearchFilter((searchFilter) => ({
                ...searchFilter,
                ...data,
                currentPage: 0,
            }));
        }
    }, [queryParameters, setSearchFilter]);


    useEffect(() => {
        if (ref) {
            ref.current = {
                reload: reloadData,
                onFilterSubmit: handleFilterSubmit
            }
        }
    }, [ref, reloadData, handleFilterSubmit]);


    return React.cloneElement(
        children, {
            data: filteredData,
            queryParameters: queryParameters,
            searchFilter: searchFilter,
            loading: loading,
            error: error ? error : errorDelete,
            reload: reloadData,
            onPageChange: useCallback(
                (pageNumber) => setSearchFilter((searchFilter) => ({
                    ...searchFilter,
                    currentPage: pageNumber - 1
                })),
                [setSearchFilter]
            ),
            onPageSort: useCallback(
                (field, direction) => setSearchFilter((searchFilter) => ({
                    ...searchFilter,
                    sortProperty: field,
                    sortDirection: direction.toUpperCase(),
                })),
                [setSearchFilter]
            ),
            onSearch: useCallback(
                (search) => {
                    if (search !== searchFilter[searchParameter]) {
                        setSearchFilter((searchFilter) => ({
                            ...searchFilter,
                            [searchParameter]: search,
                            currentPage: 0,
                        }))
                    }
                },
                [setSearchFilter, searchParameter, searchFilter]
            ),
            onDelete: useCallback(
                (id, props = {}, successCallBack, errorCallBack) => {
                    setLoading(true);
                    del(null, {pathParams: {id: id}})
                        .then(() => {
                            reloadData();
                            if (successCallBack) {
                                successCallBack();
                            } else {
                                showToast({
                                    title: t('DELETE_SUCCESS'),
                                    message: t('DELETE_SUCCESS_MSG', {...props}),
                                    icon: faCheck,
                                });
                            }
                        })
                        .catch((error) => {
                            setLoading(false);

                            if (errorCallBack) {
                                errorCallBack(error);
                            } else {
                                showToast({
                                    title: t('DELETE_FAILED'),
                                    message: t(error.data?.message),
                                    icon: faTimes,
                                    className: 'error',
                                });

                            }
                        });
                },
                [setLoading, t, del, reloadData, showToast]
            ),
            onFilterSubmit: handleFilterSubmit
        }
    )

})

export default GenericTableContainer;
