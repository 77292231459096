import ContractList from "app/procedures/contracts/List";
import {useMemo} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";

const RenderView = props => {

    const {result} = props;
    const id = useMemo(() => generateRandomId(10), []);

    return <ContractList
        id={id}
        hideTitle={true}
        disableSaving={true}
        ids={result.contractId ?? result.contractIds}
    />
}

export default RenderView;
