import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import React from "react";

const SelectionModeEntryCheckBox = props => {

    const {equipment, selectionMode, selectedItems, setSelectedItems, disable, isInExcludeMode} = props;

    if (!selectionMode) {
        return <></>
    }


    const value = isInExcludeMode
        ? !selectedItems.find(e => e.id === equipment.id)
        : !!selectedItems.find(e => e.id === equipment.id)


    return <td className={"table-checkbox"}>
        <CheckBox
            disabled={disable}
            value={value}
            onChange={(value) => setSelectedItems((items) => {
                if ((value && !isInExcludeMode) || (!value && isInExcludeMode)) {
                    return [...selectedItems, equipment];
                } else {
                    return [...items].filter(item => item.id !== equipment.id)
                }
            })}
        />
    </td>

}



export default SelectionModeEntryCheckBox;
