import {useGet} from "system/Rest/Rest";
import {useMemo} from "react";

const useBulkRuleMenuOptions = props => {

    const {data} = useGet({
        path: "/rule-templates/bulk"
    });

    const dataSorted = useMemo(
        () => Array.isArray(data)
            ? data.sort((a, b) => Intl.Collator().compare(a.name, b.name))
            : [],
        [data]
    );

    return dataSorted;
}

export default useBulkRuleMenuOptions;
