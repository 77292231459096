import {useMemo} from "react"
import getNested from "system/Objects/getNested";


const useErrorParser = (serverError) => useMemo(
    () => parseServerFormError(serverError?.data?.errors),
    [serverError?.data?.errors]
);


const parseServerFormError = (errors) => {
    //For Single Errors
    if (Array.isArray(errors)) {
        return parseSingleError(errors);
    } else if (typeof errors === 'object' && errors !== null) {
        return parseMultipleError(errors)
    } else {
        return {};
    }
}

const parseSingleError = (errorArray, errorObject = {}) => {
    return errorArray.reduce((obj, e) => parseError(e, obj), errorObject);
}

const parseError = (error, object) => {

    const arrayWithParamPattern = /^(\w+)\[([0-9])\]\.(\w+)$/g;
    const arrayWithoutParamPattern = /^(\w+)\[(\w+)\]$/g;

    if (error.property.match(arrayWithParamPattern)) {
        const e = error.property.split(arrayWithParamPattern).filter(i => i !== "");
        return {
            ...object,
            [e[0]]: {
                ...object[e[0]],
                [e[1]]: {
                    ...getNested(object, e[0], e[1]),
                    [e[2]]: error.error
                }
            }
        }
    } else if (error.property.match(arrayWithoutParamPattern)) {
        const e = error.property.split(arrayWithoutParamPattern).filter(i => i !== "");
        return {
            ...object,
            [e[0]]: {
                ...object[e[0]],
                [e[1]]: error.error
            }
        }

    } else {
        return {
            ...object,
            [error.property]: error.error
        }
    }
}


const parseMultipleError = (errorObject) => {
    let r = {};
    Object.keys(errorObject).forEach(id => {
        r = {
            ...r,
            [id]: parseSingleError(errorObject[id]),
        }
    });

    return r;

}
export default useErrorParser;

export {parseSingleError, parseMultipleError, parseServerFormError}
