export const pt = {

    title: "Lista de beneficiários",
    show_beneficiary: "Visualizar beneficiário",

    //form
    name: "Nome",
    name_ph: "Indique o nome completo",
    document_type: "Tipo de doc. de identificação",
    id_document: "Número do documento",
    id_ph: "Indique o número de identificação",
    placeholder: "Por favor escolha uma opção",

    nationality: "Nacionalidade",
    vat_number: "Num. Identificação Fiscal",
    vat_ph: "Indique um nif válido",
    gender: "Género",
    gender_ph: "Por favor escolha uma opção",
    birthdate: "Data de nascimento",
    date_ph: "Data no formato (dd-mm-yyyy)",

    TEACHER: "Docente",
    STUDENT: "Estudante",
    type: "Tipo",

    school_data: "Informação de escola",
    school: "Escola",

    email: "E-mail",
    email_ph: "Indique um email",

    phone: "Num. telefone",
    phone_ph: "Indique um num. telefone",

    platform_status: "Estado na plataforma",

    //Gender
    MALE: "Masculino",
    FEMALE: "Feminino",
    OTHER: "Outro",

    MISSING_NATIONALITY: "Indique uma nacionalidade",
    MISSING_ID_DOCUMENT_TYPE: "Indique um tipo de documento",
    MISSING_ID_DOCUMENT: "Indique o num. do documento",
    MISSING_GENDER: "Indique um género",
    MISSING_SCHOOL_ID: "Indique uma escola",

    CC: "Cartão do cidadão",
    PASSPORT: "Passaporte",
    RESIDENCY_AUTHORIZATION: "Autorização de Residência",
    PERSONAL_BALLOT: "Cédula Pessoal",

    //tabs
    contracts: "Contratos",
    equipments: "Equipamentos",
    equipment_historic: "Hist. Equipamentos",

    //View
    current_equipments: "Equipamentos atuais",
    contract_list: "Lista de contratos",

    //Options
    options: "Opções / Operações",
    edit: "Editar dados",
    register_refusal: "Registar recusa de equipamento",
    unregister_refusal: "Cessar recusa de equipamento",


    //refusal
    submit: "Submeter",
    close: "Fechar",
    observation: "Motivo",
    refusal_title: "Registar recusa de equipamentos",
    refusal_title_msg: "Deve escolher ou indicar o motivo da recusa de equipamentos",
    beneficiary_refused_observation: "<strong>Motivo de recusa</strong>: {{observations}}",

    beneficiary_refused: "Beneficiário com recusa de equipamento",
    beneficiary_refused_msg: "Este beneficiário tem uma recusa de equipamento registada e ativa desde <strong>{{date}}</strong> em vigor na instituição {{school.name}}.<br/>" +
        "Caso queira atribuir um equipamento deve, no menu de operações cessar a mesma.",

    DELETE_REFUSAL_CONFIRM: "Confirmação",
    DELETE_REFUSAL_CONFIRM_MSG: "Deseja realmente cessar a recusa de equipamentos deste beneficiário?",

    SUCCESS_REVOKE: "Recusa cessada",
    SUCCESS_REVOKE_MSG: "Foi cessada a recusa de equipamentos. A partir deste momento poderão ser atribuidos equipamentos ao beneficiário",

    SUCCESS_REFUSE: "Recusa(s) registada(s)",
    SUCCESS_REFUSE_MSG: "Foi registada a recusa de equipamentos. A partir deste momento não poderão ser atribuidos equipamentos ao(s) beneficiário(s)",

    item_selected_one: "{{count}} aluno selecionado",
    item_selected_other: "{{count}} alunos selecionados",


    //Bulk refusal
    advance: "Avançar >",

    //LIST
    NO_EQUIPMENT: "Não atribuído",
    WITH_EQUIPMENT: "Atribuído",
    PARTIAL_EQUIPMENT: "Atribuído parcialmente",
    REFUSED: "Rejeitado",
    equipment_status: "Equipamento",
    vat: "NIF",

    bulk_refusal: "Recusa de equipamentos",
    bulk_refusal_msg: "Deve selecionar os beneficiários aos quais deseja registar a recusa de equipamentos. Uma vez selecionados utilize o botão avançar",

    no_beneficiary_found: "Não foram encontrados resultados",

    non_compliances: "Não conformidades",
    more_results_available_beneficiaries: "No dashboard apenas são apresentados 5 resultados. Caso deseje mais opções deve utilizar o menu de listagem/pesquisa ou alternativamente poderá ",
    more_results_available_beneficiaries_link_students: "carregar <u>aqui para pesquisar alunos</u> ",
    more_results_available_beneficiaries_link_teachers: "ou carregar <u>aqui para pesquisar docentes</u>.",



};

export default pt;
