import {cloneWithoutKey, objectIsEmpty} from "system/Objects/Objects";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilterCircleXmark, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {useLocation} from "react-router";
import PopupWithInput from "layout/modules/Popups/PopupWithInput";
import generateHashCode from "system/Utils/generateHashCode";
import {useSavedSearch} from "layout/modules/SearchArea/utils/savedSearch";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import ListSavedSearches from "layout/modules/SearchArea/components/savedSearches/ListSavedSearches";
import useUserPreference from "modules/UserPreferences/useUserPreference";
import Button from "react-bootstrap/Button";

const SavedSearch = props => {

    const {onSubmit, activeFilters, isFilterOpen, disableSaving} = props;
    const {t} = useTranslation('searchArea');

    const location = useLocation();

    const [isSaving, setSaving] = useState(false);
    const [isDelete, setDelete] = useState(false);

    const savedFilterPreferenceName = useMemo(
        () => location?.pathname?.replace(/\/+/g, ''),
        [location?.pathname]
    );

    const [savedFilters, setSavedFilters] = useUserPreference("saved-filters", savedFilterPreferenceName, {}, true, disableSaving);
    const savedId = useSavedSearch(activeFilters, savedFilters);

    const deleteCurrentFilter = useCallback(
        (name, value) => {
            setDelete(false);
            setSavedFilters(sf => cloneWithoutKey(sf, savedId));
        },
        [setSavedFilters, savedId, setDelete]
    );

    const saveCurrentFilter = useCallback(
        (name, value) => {

            setSaving(false);
            setSavedFilters(sf => ({
                ...sf,
                [generateHashCode(name)]: {
                    name: name,
                    value: value,
                }
            }));
        },
        [setSavedFilters, setSaving]
    );

    if (isFilterOpen || disableSaving)
        return <></>;

    return <>

        {isSaving &&
        <PopupWithInput
            title={t('save_filter')}
            message={t('save_filter_confirm_msg')}
            confirmationPlaceHolder={t('filter_name_ph')}
            labelConfirm={t('save_filter')}
            visible={true}
            onCancel={() => setSaving(false)}
            onConfirm={(filterName) => saveCurrentFilter(filterName, activeFilters)}
        />
        }


        {isDelete &&
        <PopupConfirm
            title={t('CONFIRM_DELETE_SAVED_SEARCH')}
            message={t('CONFIRM_DELETE_SAVED_SEARCH_MSG', {name: savedFilters[savedId]?.name})}
            classConfirm={"danger"}
            visible={isDelete}
            onCancel={() => setDelete(false)}
            onConfirm={deleteCurrentFilter}
            backdrop={true}
        />
        }

        {(!objectIsEmpty(activeFilters) && !savedId) &&
        <ToolTip content={t('save_filter')}>
            <Button
                className={classnames("btn btn-rounded-primary filter-management", {"active": isSaving})}
                onClick={() => setSaving(!isSaving)}
                aria-expanded={isSaving}
                aria-label={t('save_filter')}
            >
                <FontAwesomeIcon icon={faFloppyDisk}/>
            </Button>
        </ToolTip>
        }

        {savedId &&
        <ToolTip content={t('delete_saved_filter')}>
            <Button
                className={classnames("btn btn-rounded-primary filter-management", {"active": isDelete})}
                onClick={() => setDelete(true)}
                aria-expanded={isDelete}
                aria-label={t('delete_saved_filter')}
            >
                <FontAwesomeIcon icon={faFilterCircleXmark}/>
            </Button>
        </ToolTip>
        }


        {!objectIsEmpty(savedFilters) &&
        <ListSavedSearches savedFilters={savedFilters} onSubmit={onSubmit}/>
        }


    </>


}

export default SavedSearch;
