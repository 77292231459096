import {useCallback, useEffect, useState} from "react";
import {useGet, useMutate} from "system/Rest/Rest";
import isFunction from "system/Utils/isFunction";

/**
 * This hook is used to store and use preferences on remote server
 * @param group
 * @param key
 * @param defaultValue
 * @param saveAsString
 * @returns {unknown[]}
 */
const useUserPreference = (group, key, defaultValue, saveAsString, disable = false) => {

    const [cachedPreference, setCachedPreference] = useState(defaultValue);

    const {data} = useGet({
        path: "/user-preferences/" + encodeURI(group) + "/" + encodeURI(key),
        localErrorOnly: true,
        lazy: disable,
        resolve: useCallback(
            (data) => saveAsString ? JSON.parse(data?.value?.data, jsonReplacer) : data?.value?.data,
            [saveAsString]
        )
    });

    const {mutate: save} = useMutate({
        verb: "POST",
        path: "/user-preferences/",
    });

    const setPreference = useCallback(
        (value) => {

            setCachedPreference(currentValue => {
                let newValue = value;
                if (isFunction(value)) {
                    newValue = value(currentValue);
                }

                save({
                    group: encodeURI(group),
                    key: encodeURI(key),
                    value: {
                        data: saveAsString ? JSON.stringify(newValue,jsonReplacer) : newValue
                    },
                }).then(() => true);

                return newValue;
            });
        },
        [group, key, setCachedPreference, save, saveAsString]
    );


    useEffect(
        () => {
            if (data) {
                setCachedPreference(data)
            }
        },
        [setCachedPreference, data]
    );

    return [cachedPreference, setPreference];
}

const jsonReplacer = (key, value) =>
    typeof value === 'undefined' ? null : value;

export default useUserPreference;
