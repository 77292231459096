import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import ExecutionModalComponent from "app/ruleTemplates/components/show/ExecutionModalComponent";

const ShowOperationContainer = props => {

    const {id} = props;

    const {data: execution, loading} = useGet({
        path: "/rule-executions/" + id,
        localErrorOnly: true,
    });

    if (!execution)
        return <Loading visible={loading}/>;
    else
        return <ExecutionModalComponent {...props} execution={execution}/>

}

export default ShowOperationContainer;
