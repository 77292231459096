import UserHasPermission, {useUserHasPermission} from "system/API/Authentication/UserHasPermission";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import React, {useMemo, useRef, useState} from "react";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import Audit from "app/audit/Audit";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import {EquipmentActions} from "app/audit/utils/AuditActions";
import {useTranslation} from "react-i18next";
import RequestSupportContainer from "app/support/containers/RequestSupportContainer";
import EquipmentIssueType from "app/support/utils/EquipmentIssueType";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/UserType";
import ExecuteSingleOperationContainer from "app/ruleTemplates/containers/ExecuteSingleOperationContainer";
import useRuleTemplateOperations from "app/ruleTemplates/utils/execution/useRuleTemplateOperations";

const BeneficiaryGeneralOptions = props => {

    const {t} = useTranslation('equipments');
    const userHasPermission = useUserHasPermission();
    const {authentication} = useApiState();

    const {equipment, setReadOnly} = props;
    const [supportRequest, setSupportRequest] = useState(false);

    const ruleTemplateRef = useRef();
    const {operations} = useRuleTemplateOperations(RuleType.EQUIPMENT, equipment);

    const canCreateTickets = useMemo(
        () => userHasPermission("USE_TICKETS") && !userHasPermission("SET_TICKET_DESTINATION"),
        [userHasPermission]
    );

    return <>

        {(supportRequest && canCreateTickets) &&
        <RequestSupportContainer
            {...props}
            isOpen={true}
            onClose={() => setSupportRequest(false)}
            request={supportRequest}
        />
        }

        <ExecuteSingleOperationContainer {...props} ref={ruleTemplateRef} data={equipment}/>

        <UserHasPermission permission={["READ_AUDIT_LOG"]}>
            <Audit
                objectId={equipment?.id}
                affectedObjectType={[
                    AuditObjectTypes.Equipment,
                ]}
                filterActions={{
                    ...EquipmentActions,
                }}

            />
        </UserHasPermission>

        <MenuDropdown>
            <Button variant={"info"} className={"btn-rounded-primary with-icon with-space "} type={"button"}>
                {t('options')}
                <FontAwesomeIcon icon={faCaretDown}/>
            </Button>

            <MenuDropdownContent>
                <MenuDropdownOption visible={userHasPermission("EDIT_EQUIPMENT")}>
                    <div {...generateOnClickAndEnter(() => setReadOnly((readOnly) => !readOnly))}>
                        {t('edit')}
                    </div>
                </MenuDropdownOption>

                <MenuDropdownOption visible={canCreateTickets && authentication?.type !== UserType.SUPPLIER}>
                    <div {...generateOnClickAndEnter(() => setSupportRequest({
                        equipmentIssueType: EquipmentIssueType.EQUIPMENT,
                        equipmentId: equipment.id
                    }))}>
                        {t('request_equipment_support')}
                    </div>
                </MenuDropdownOption>

                <MenuDropdownOption visible={canCreateTickets}>
                    <div {...generateOnClickAndEnter(() => setSupportRequest({
                        equipmentIssueType: EquipmentIssueType.PLATFORM,
                        equipmentId: equipment.id
                    }))}>
                        {t('request_platform_support')}
                    </div>
                </MenuDropdownOption>

                {operations.map((operation, id) => {
                    return <MenuDropdownOption key={id}>
                        <div {...generateOnClickAndEnter(() => ruleTemplateRef.current.runOperation(operation))}>
                            {operation.name}
                        </div>
                    </MenuDropdownOption>
                })}

            </MenuDropdownContent>
        </MenuDropdown>

    </>
}

export default BeneficiaryGeneralOptions;
