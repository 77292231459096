import React, {useCallback, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isString} from "system/Utils/isString";
import MultiOptionEntryComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionEntryComponent";
import './MultiOption.scss';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import update from "immutability-helper";
import MultiOptionDragAndDropComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionDragAndDropComponent";

const MultiOptionComponent = (props) => {

    const {data = [], onChange, errors, readOnly, children, isDraggable} = props;

    const [focusLastItem, setFocusLastItem] = useState();

    const focusNewItem = useCallback((element, shouldFocus) => {
        if (shouldFocus && element) {
            setFocusLastItem(false);
            element.focus();
        }
    }, []);

    const handleRef = useCallback((e) => focusNewItem(e, focusLastItem), [focusNewItem, focusLastItem]);

    const handleCreate = useCallback((entry) => {
        onChange((data = []) => [...data, entry]);
        setFocusLastItem(true);
    }, [onChange, setFocusLastItem]);

    const moveEntry = useCallback((dragIndex, hoverIndex) => {

        onChange((prevData = []) => update(prevData, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, prevData[dragIndex]],],
        }),);
    }, [onChange]);


    if (data.length === 0 && readOnly) {
        return null;
    }

    return <>
        <Row className={"form-multi-option"}>
            <Col sm={12} lg={12} xl={12}>

                {(isDraggable && !readOnly) ?
                    <DndProvider backend={HTML5Backend}>
                    {data?.map((entry, index, arr) => <MultiOptionDragAndDropComponent
                        key={index}
                        id={entry.id ?? entry}
                        index={index}
                        position={index}
                        moveEntry={moveEntry}
                        readOnly={readOnly}
                        entry={entry}
                        onChange={onChange}
                        errors={errors}
                        ref={(arr.length - 1 === index) ? handleRef : undefined}
                    >
                        {children}
                    </MultiOptionDragAndDropComponent>)}
                </DndProvider> : <>
                    {data?.map((entry, index, arr) => <MultiOptionEntryComponent
                        key={index}
                        index={index}
                        readOnly={readOnly}
                        entry={entry}
                        onChange={onChange}
                        errors={errors}
                        ref={(arr.length - 1 === index) ? handleRef : undefined}
                    >
                        {children}
                    </MultiOptionEntryComponent>)}
                </>}


                {!readOnly && <div className={"form-multi-option-item"}>
                    {React.cloneElement(children, {
                        globalError: isString(errors) ? errors : undefined, onChange: handleCreate
                    })}
                </div>}

            </Col>
        </Row>
    </>

}

export default MultiOptionComponent;
