export const pt = {

    title: "Regras / Operações",
    create_rule: "Criar modelo / operação",
    view_template: "{{name}}",
    view_template_empty: "-",

    //list
    no_rules_found: "Não foram encontradas operações",
    clone: "Clonar regra / operação",

    //Form
    editor_general_data: "Dados da operação",
    actions: "Ações",
    actions_menu: "Ações ({{count}})",
    create: "Criar modelo / operação",
    edit: "Guardar alterações",

    name: "Indentificador da operação",
    name_ph: "Indique um nome identificativo",

    status: "Estado",
    ACTIVE: "Ativo",
    INACTIVE: "Inativo",

    type: "Tipo de operação",
    placeholder: "Escolha uma opção",

    EQUIPMENT: "Operação em equipamento",
    BENEFICIARY: "Operação em beneficiário",
    SCHOOL: "Operação em escola",
    roles: "Perfis de permissão autorizados",
    execution_message: "Mensagem após execução",
    bulk_allowed: "Aplicável em bulk",
    single_allowed: "Aplicável individualmente",
    bulk_description: "Descrição / instruções em bulk",


    //Equipment properties
    equipment_requirements: "Requisitos do equipamento",
    is_assigned: "Atribuido a beneficiário",
    is_available_for_student: "Possível de atribuir a aluno",
    is_available_for_teacher: "Possível de atribuir a docente",
    is_available_for_school: "Possível de atribuir a escola",
    equipment_status: "Estado(s) do equipamento",
    equipment_procedure: "Fase do equipamento",
    equipment_profile: "Perfil de equipamento",
    equipment_typology: "Tipologia de equipamento",
    is_in_warranty: "Em garantia",
    with_support_supplier: "Com fornecedor de suporte",

    //Equipment bulk
    equipment_bulk_requirements: "Seleção de equipamentos em bulk",
    force_same_delivery_note: "Forçar terem mesma guia de remessa",
    force_same_school: "Forçar serem da mesma escola",
    force_same_profile: "Forçar mesmo perfil",
    force_same_typology: "Forçar mesma tipologia",
    force_same_procedure: "Forçar mesma fase",
    force_same_procedure_number: "Forçar mesmo num. de procedimento",
    force_same_contract: "Forçar mesmo num. de contrato",
    force_same_commitment: "Forçar o mesmo num. de compromisso",
    auto_select_related: "Incluir automaticamente equipamentos relacionados",



    //School properties
    school_requirements: "Requisitos da escola",
    school_type: "Tipo(s) de escola",
    AE: "Agrup. Escolas",
    ENA: "Escola não agrupada",
    AE_SCHOOL: "Escola de agrupamento",


    //Beneficiary
    beneficiary_requirements: "Requisitos do beneficiário",
    beneficiary_type: "Tipo de beneficiário",
    school_typologies: "Tipo(s) de escola(s) do beneficiário",
    school_typologies_ph: "Indique os tipos de escolas ou deixe em branco para todos",
    TEACHER: "Docente",
    STUDENT: "Estudante",
    beneficiary_equipment_status: "Estado do beneficiário",
    NO_EQUIPMENT: "Sem equipamento atribuído",
    WITH_EQUIPMENT: "Com equipamento atribuído",
    PARTIAL_EQUIPMENT: "Com equipamento atribuído parcialmente",
    REFUSED: "Com recusa de equipamentos",
    school_nut3: "Unidade territorial da escola",

    //Student & Teachers
    student_requirements: "Requisitos do estudante",
    teacher_requirements: "Requisitos do docente",

    //school
    nut3: "Unidade territorial",

    YES: "Obrigatório",
    NO: "Apenas quando não estiver",
    ANY: "Não Aplicável / Indiferente",
    TRUE: "Sim",
    FALSE: "Não",

    //Steps
    add_action_title: "Adicionar {{name}}",
    add_action: "Adicionar ação",
    configuration_action_modal: "Configurar {{name}}",
    no_actions_to_list: "Ainda não existem Ações associadas à operação.<br/>Deve utilizar o botão + para adicionar o conjunto de regras desejadas",
    close: "Fechar",
    previous: "< Voltar",
    next: "Avançar >",
    execute: "Executar operação",
    add: "Adicionar",
    configure_action: "Configurar",
    remove_action: "Remover",
    save_configuration: "Guardar configuração",


    //Errors
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",
    UPDATE_SUCCESS: "Modelo / Operação atualizada",
    UPDATE_SUCCESS_MSG: "A \"{{name}}\" foi atualizada com sucesso!",
    CREATE_SUCCESS: "Novo modelo / operação criado",
    CREATE_SUCCESS_MSG: "A \"{{name}}\" foi criada com sucesso!",

    MISSING_TYPE: "Indique o tipo de operação",
    MISSING_NAME: "Indique o nome da operação",
    MISSING_ROLES: "Indique os perfis de permissão autorizados a executar a operação",
    error_in_actions: "Existem erros nas ações",

    MISSING_ACTIONS: "Por favor deve definir pelo menos uma ação",
    DELETE_RULE_CONFIRM: "Confirmação",
    DELETE_RULE_CONFIRM_MSG: "Deseja realmente apagar a regra \"{{name}}\"?",

    SUCCESS_EXECUTED_SINGLE_MSG: "Operação realizada com sucesso",
    SUCCESS_EXECUTED_BULK_TITLE: "Operação realizada com sucesso",

    SUCCESS_EXECUTED_BULK_MSG_one: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> equipamento",
    SUCCESS_EXECUTED_BULK_MSG_other: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> equipamentos",


    SUCCESS_EXECUTED_SCHOOL_BULK_MSG_one: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> escola",
    SUCCESS_EXECUTED_SCHOOL_BULK_MSG_other: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> escolas",

    SUCCESS_EXECUTED_BENEFICIARY_BULK_MSG_one: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> beneficiário",
    SUCCESS_EXECUTED_BENEFICIARY_BULK_MSG_other: "A operação \"<strong>{{name}}</strong>\" foi executada com sucesso em <strong>{{count}}</strong> beneficiários",


    //BULK
    bulk_equipment_selection: "Escolha de equipamentos",
    pre_filter_title: "Pré seleção de equipamentos",
    pre_filter_help_title_msg: "Deve preencher o formulário abaixo de acordo com os equipamentos que deseja incluir na execução da operação \"{{name}}\".<br/>Após o preenchimento do formulário o botão <strong>avançar</strong> ficará disponível e deverá ser utilizado para avançar para o próximo passo da operação.",
    return_home: "Voltar para página inicial",

    //Show details
    execution_detail: "Estado da operação",
    FormRuleAction: "Formulário",
    EquipmentSelectionRuleAction: "Equipamentos",
    ContractGeneratorRuleAction: "Contratos",
    SupportTicketAction: "Tickets",
    ReplaceEquipmentRuleAction: "Substituição",
    beneficiaries: "Beneficiário",
    equipments: "Equipamentos",

    //Status
    WAITING_PROCESSING: "A aguardar",
    PENDING: "Pendente",
    ONGOING: "Em execução",
    WAITING_USER_INPUT: "A aguardar interação de utilizador",
    DONE: "Concluída",
    ERROR: "Em erro",

    execution_user: "Utilizador",
    execution_entity: "Entidade",
    execution_date: "Data da execução",

    DELETE_SUCCESS: "Operação apagada",
    DELETE_SUCCESS_MSG: "A operação \"{{name}}\" foi apagada com sucesso!",



};

export default pt;
