import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import {EntityType} from "app/entities/utils/EntityType";

const GlobalEntityDropdown = props => {

    const {userType, ...otherProps} = props;

    return <EntityDropdown {...otherProps} remotePath={"/entities/search-all"}
                           types={EntityType.fromUserType(userType)}/>;

}

export default GlobalEntityDropdown;
