import React, {useMemo} from 'react'
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";


const UserDropdown = (props) => {

    const {t} = useTranslations('classDropdown', locale);
    const {
        id = "userId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        userType,
        entityId,
    } = props;

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder,t('generic_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage,t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage,t('generic_loading'))}
        remotePath={"/users/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
            entityId: entityId,
            type: userType ? userType : "ANY",
        }), [maxItems, userType, entityId])}
    />

}

export default UserDropdown;
