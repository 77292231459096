import Modal from "react-bootstrap/Modal";
import React from "react";
import ModalContentComponent from "./ModalContentComponent";
import locale from "app/help/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import Loading from "layout/modules/Loading/Loading";
import "app/help/assets/css/help.scss";

const ModalComponent = (props) => {

    const {t} = useTranslations('help', locale)
    const {onClose, isVisible, loading, article} = props;

    return <Modal show={isVisible}
                  onHide={() => onClose()}
                  size={"xl"}
                  keyboard={false} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>{t('help')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"modal-help"}>
            <Loading visible={loading}/>
            {(article && !loading) &&
            <ModalContentComponent {...props} />
            }
        </Modal.Body>
    </Modal>
}

export default ModalComponent;
