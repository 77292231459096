const readFileContentToByteString = (file, onFinish) => {

    const fileReader = new FileReader();
    fileReader.onloadend = () => {

        let binary = '';
        const bytes = new Uint8Array(fileReader.result);
        const length = bytes.byteLength;

        for (let i = 0; i < length; i++) {
            const a = bytes[i];
            const b = String.fromCharCode(a)
            binary += b;
        }


        onFinish(binary);
    }

    fileReader.readAsArrayBuffer(file);
}

const readFileContentToByteArray = (file, onFinish) => {

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        onFinish(new Uint8Array(fileReader.result));
    }

    fileReader.readAsArrayBuffer(file);
}


export {readFileContentToByteString, readFileContentToByteArray};
