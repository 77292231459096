import FormBuilder from "layout/modules/FormBuilder/FormBuilder";
import React, {useCallback} from "react";
import getAsArray from "system/Arrays/getArray";
import formModules from "app/ruleTemplates/actions/forms/config";
import "app/ruleTemplates/actions/forms/assets/config.scss"

const FormConfiguration = props => {

    const {setConfiguration, configuration} = props;

    const handleFormConfigChanges = useCallback(
        (config) => {
            setConfiguration(c => ({
                ...c,
                props: {
                    ...c.props,
                    formModel: config,
                }
            }))
        },
        [setConfiguration]
    );

    return <div className={"action-form-builder-config"}>
        <FormBuilder
            modules={formModules}
            data={getAsArray(configuration?.props?.formModel)}
            onChange={handleFormConfigChanges}
        />
    </div>
}

export default FormConfiguration;
