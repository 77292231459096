import Button from "react-bootstrap/Button";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/locale/locales";
import {getActionFromConfig} from "app/ruleTemplates/utils/config/getActionFromConfig";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateRunningData, {updateRunningData} from "app/ruleTemplates/utils/execution/generateRunningData";
import useRuleRemoteControl from "app/ruleTemplates/utils/execution/useRuleRemoteControl";
import isFunction from "system/Utils/isFunction";
import {hasAnyVisibleStepAfter, hasAnyVisibleStepBefore} from "app/ruleTemplates/utils/execution/hasAnyVisibleStep";

const ExecuteOperationComponent = React.forwardRef((props, ref) => {

    const {bulkMode, onEnd, operation, data, children, ruleExtraProps, goToSelectionList} = props;
    const {t, ready: readyTranslation} = useTranslations('rule-template', locale);

    const [step, setStep] = useState(0);
    const [replacedButtonComponent, setReplacedButtonComponent] = useState(false);
    const [executionData, setExecutionData] = useState(generateRunningData(operation, data, ruleExtraProps));
    const [isDone, setDone] = useState(false);

    const {validate, loading, errors, execute} = useRuleRemoteControl();

    const previousStep = useMemo(
        () => hasAnyVisibleStepBefore(step, operation),
        // eslint-disable-next-line
        [step]
    );

    const hasNextStep = useMemo(
        () => hasAnyVisibleStepAfter(step, operation),
        [step, operation]
    );

    const action = useMemo(
        () => operation.actions[step]
            ? getActionFromConfig(operation.actions[step])
            : null
        ,
        [step, operation.actions]
    );

    const jumpToNextStep = useCallback(
        (step, data) => {
            if (action?.type === RuleActionType.SILENT) {
                setStep(step => step + 1);
                return;
            }

            validate({stepNum: step, ...data})
                .then(() => setStep(step => step + 1))
                .catch((e) => console.log("error validating", e));
        },
        [setStep, validate, action?.type]
    );



    useEffect(
        () => {
            if (!action || action?.type === RuleActionType.ASYNC) {
                execute(executionData).then(() => {
                    setDone(true);
                    if (isFunction(onEnd)) {
                        onEnd();
                    }
                });
            }

            if (action?.type === RuleActionType.SILENT) {
                jumpToNextStep();
            }
        },

        // eslint-disable-next-line
        [action]
    );


    const availableButtons = useMemo(
        () => {

            if (!readyTranslation) {
                return <></>;
            }

            return <>

                {!replacedButtonComponent &&
                <>

                    {(executionData.bulkMode && goToSelectionList && !previousStep) &&
                        <Button variant={'primary'} onClick={() => goToSelectionList()}>
                            {t('previous')}
                        </Button>
                    }

                    {previousStep !== false &&
                    <Button variant={'primary'} onClick={() => setStep(previousStep)}>
                        {t('previous')}
                    </Button>
                    }

                    <Button variant={hasNextStep ? 'primary' : 'success'}
                            onClick={() => jumpToNextStep(step, executionData)}>
                        {hasNextStep ? t('next') : t('execute')}
                    </Button>
                </>
                }

                {replacedButtonComponent && replacedButtonComponent}

            </>
        },
        [replacedButtonComponent, step, hasNextStep, jumpToNextStep, t, executionData, readyTranslation, previousStep, goToSelectionList]
    );

    return React.cloneElement(children, {
        ...props,
        done: isDone,
        loading: loading,
        availableButtons: availableButtons,
        children: action?.renderExecution({
            bulkMode: bulkMode,
            operation: operation,
            operationConfig: executionData,
            errors: Array.isArray(errors) ? errors[step] : {},
            config: operation.actions[step]?.props,
            data: executionData.props[step] ?? {},
            setReplacedButtonComponent: setReplacedButtonComponent,
            setData: (data) => updateRunningData(setExecutionData, step, data)
        })


    });
});

export default ExecuteOperationComponent;
