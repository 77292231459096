import NutDatabase from "layout/modules/Forms/Dropdowns/NutDropdown/NutDatabase";

const getNut = (nut2) => {

    if (nut2) {
        return getNut2Values(nut2)?.sort((a, b) => Intl.Collator().compare(a.label, b.label))
            .map(item => ({
                label: item.label,
                value: normalizeNutKey(item.value)
            }));
    } else {
        const result = [];
        NutDatabase.forEach(nut2 => result.push.apply(result, getNut(nut2?.value)));
        return result;
    }
}

const getNut2Values = (nut2) => {
    for (const entry of NutDatabase) {
        if (normalizeNutKey(entry.value) === normalizeNutKey(nut2))
            return entry.nuts;
    }
    return null;

}


const normalizeNutKey = (key) => {

    if (Array.isArray(key)) {
        return key.map(key => key?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    } else if (key) {
        return key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else return key;
}

export {getNut, normalizeNutKey};
