import "app/ruleTemplates/actions/infoPage/assets/execute.scss";


const ExecutionComponent = props => {

    return <div className={'info-page'} dangerouslySetInnerHTML={{__html: props?.config?.content}}/>

}

export default ExecutionComponent;
