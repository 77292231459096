export const pt = {

    title: "Lista de equipamentos",
    create: "Criar equipamento",
    view: "Equipamento {{serialNumber}}",


    //List
    serial_number: "Num. Série",
    serial_number_ph: "Escreva o {{name}} ",
    profile: "Perfil",
    profile_tab: "Perfil do equipamento ",
    status: "Estado",
    school: "Unidade orgânica",

    beneficiary: "Beneficiário",
    procedure: "Fase",
    with_maintenance_supplier: "Com suporte fora da garantia",
    maintenance_supplier: "Fornecedor de suporte (fora da garantia)",
    equipment_supplier: "Fornecedor",
    in_warranty: "Em garantia",
    typology: "Tipologia",
    installation_school: "Escola de instalação",
    related_equipment: "Equipamento complementar",

    assigned: "Atribuído a beneficiário",
    available_for_student: "Atribuível a estudante",
    available_for_teacher: "Atribuível a docente",
    available_for_school: "Atribuível a unidade orgânica",

    virtual_equipment_title: "Equipmento de agregação em kit",
    virtual_equipment_msg: "Este equipamento é virtual e serve para agragação de um conjunto de equipamentos em kit.<br/>Utilize o serparador equipamentos para visualizar o conjunto de  equipamentos que está agrupado.",
    virtual_equipment_msg_sm: "Este equipamento é virtual e serve para agragação de um conjunto de equipamentos em kit.<br/>Ao escolher este equipamento serão automáticamente selecionados todos os equipamentos associados.",

    //form
    equipment_data: "Dados do equipamento",
    beneficiary_type: "Tipo de beneficiário",
    beneficiary_type_ph: "Escolha uma opção",
    warranty_start_date: "Data inicio de garantia",
    warranty_end_date: "Data final de garantia",
    date_ph: "Data no formato (dd-mm-yyyy)",
    internal_code: "Código imobilizado",
    internal_code_ph: "Indique o Código de imobilizado",
    ready_to_invoice: "Pronto a faturar",
    placeholder: "Escola uma opção",
    no_results: "Nenhum resultado encontrado",
    more_results_available_equipments: "No dashboard apenas são apresentados 5 resultados. No entanto foram encontradas mais correspondências.<br/>Para consultar deverá utilizar o menu de listagem/pesquisa dos equipamentos ",
    more_results_available_equipments_click: "ou carregue aqui.",


    //Billing data
    billing_data: "Dados de transporte e faturação",
    delivery_data: "Dados guia de remessa",
    delivery_note: "Guia de remessa",
    delivery_note_ph: "Indique o num. da guia de remessa",
    delivery_note_date: "Data da guia de remessa",
    invoice_number: "Num. fatura",
    invoice_date: "Data da fatura",
    price: "Preço",
    price_with_vat: "Preço com IVA",
    activation_date: "Data de ativação",
    invoice_period_start: "Inicio de faturação",
    invoice_period_end: "Fim periodo de faturação",
    procedure_number: "Num. procedimento",
    contract_number: "Num. contrato",
    commitment_number: "Num. compromisso",
    procedure_number_ph: "Indique o num. do procedimento",
    contract_number_ph: "Indique o num. do contrato",
    commitment_number_ph: "Indique o num. do contrato",

    AE: "Agrupamento Escolas",
    AE_SCHOOL: "Escola de agrupamento",
    ENA: "Escola não agrupada",

    //alerts
    related_equipment_title: "Equipamento em associação",
    related_equipment_msg: "Este equipamento está associado a um equipamento do tipo <strong>{{profile.name}}</strong> com o {{profile.idLabel}} <strong>{{serialNumber}}</strong>.<br/>" +
        "Para mais informação sobre o equipmento complementar carregue ",
    related_equipment_msg_link: " aqui.",
    selection_related_equipment_msg: "Este equipamento está associado/composto com um equipamento do tipo <strong>{{profile.name}}</strong> com o {{profile.idLabel}} <strong>{{serialNumber}}</strong>.<br/>" +
        "Ao selecionar este equipamento está tambem a selecionar o equipamento complementar.",

    //beneficiary type
    TEACHER: "Docente",
    STUDENT: "Aluno",

    //View
    edit: "Editar",
    options: "Opções / Operações",
    general: "Dados gerais",
    support_requests: "Suporte",
    files: "Ficheiros",
    support_files: "Ficheiros/Documentos de suporte",
    equipment_support_requests: "Pedidos de suporte do equipamento",
    equipments: "Equipamentos",
    non_compliances: "Não conformidades",

    //menu
    request_equipment_support: "Abrir pedido de suporte ao fornecedor",
    request_platform_support: "Abrir pedido de suporte à SGEC",

    show_school: "Ver escola",
    show_beneficiary: "Ver beneficiário",


    MISSING_WARRANTY_START: "Indique uma data válida",
    MISSING_WARRANTY_END: "Indique uma data válida",
    MISSING_SCHOOL_ID: "Indique uma escola ou agrupamento de escolas",
    MISSING_EQUIPMENT_STATUS: "Indique o estado do equipamento",
    MISSING_EQUIPMENT_PROFILE_ID: "Indique o tipo de equipamento",
    MISSING_SERIAL_NUMBER: "Preencha o campo de acordo com a indicação",
    INVALID_SERIAL_NUMBER: "O valor indicado não é válido para o tipo de equipamento escolhido",

    DELETE_EQUIPMENT_CONFIRM: "Confirmação",
    DELETE_EQUIPMENT_CONFIRM_MSG: "Deseja realmente apagar o equipamento {{serialNumber}}?",

    DELETE_SUCCESS: "Equipamento apagado",
    DELETE_SUCCESS_MSG: "O equipamento {{name}} foi apagado com sucesso",


    CREATE_SUCCESS: "Equipamento criado",
    CREATE_SUCCESS_MSG: "O equipamento {{serialNumber}} foi criado com sucesso!",
    UPDATE_SUCCESS: "Equipamento atualizado",
    UPDATE_SUCCESS_MSG: "O equipamento {{serialNumber}} foi atualizado com sucesso!",
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",


    //Historic
    no_historic_data: "Sem dados históricos para apresentar",
    start_date: "Data inicial",
    end_date: "Data final",

    from_start_date: "De data inicial",
    to_start_date: "A data inicial",

    from_end_date: "De data final",
    to_end_date: "A data final",
    historic: "Históricos",
    historic_status: "Alteração de estados",
    historic_school: "Unid. orgânicas",
    historic_beneficiary: "Beneficiários",
    historic_equipment: "Histórico de equipamentos",

    //Billing Type
    ONE_TIME: "Única",
    MONTHLY_SUBSCRIPTION: "Mensal",

    save_changes: "Guardar alterações",
    cancel: "Cancelar",
    no_equipment_found: "Não foram encontrados equipamentos",
    true: "Sim",
    false: "Não",

    //Warnings
    equipment_in_beneficiary_from_different_school: "Equipamento atribuído a beneficiário que não pertence à unidade orgânica",
    equipment_in_beneficiary_from_different_school_msg: "Este equipamento está atualmente atribuído a um beneficiário que já não se encontra na unidade orgânica à qual o equipamento está assignado.",

    equipment_not_allowed_for_student_year: "Aluno em ano escolar não compativel com equipamento",
    equipment_not_allowed_for_student_year_msg: "O aluno ao qual o equipamento está atualmente atribuído não pode ter um equipamento \"{{profile.name}}\" uma vez que este tipo de equipamento apenas pode ser atribuído a alunos dos <strong>{{profile.schoolYears}} ano(s)</strong> e o aluno encontra-se neste momento no <strong>{{beneficiary.schoolYear}} ano.</strong>",

    item_selected_one: "{{count}} equipamento selecionado",
    item_selected_other: "{{count}} equipamento(s) selecionados",

    show_details: "Detalhes da operação associada",


};

export default pt;
