import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import locale from "app/support/locales/locales";
import Loading from "layout/modules/Loading/Loading";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import OpenReplayTracker from "modules/OpenReplay/tracker";
import FormRequestSupportComponent from "app/support/components/form/FormRequestSupportComponent";
import useTranslations from "system/Translations/UseTranslations";

const RequestSupportComponent = props => {

    const {isOpen, loading, onSubmit, onClose, request: baseRequest} = props;
    const {auditProcessId, nonComplianceId} = props;

    const {t} = useTranslations('support', locale);

    const [request, setRequest] = useState({
        ...baseRequest,
        attachments: [],
        currentUrl: window.location.href,
        logSessionId: OpenReplayTracker?.getSessionID(),
        auditProcessId: auditProcessId,
        nonComplianceId: nonComplianceId,
    });

    return <Modal
        show={isOpen}
        size={"xl dark"}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
    >

        <Loading visible={loading}/>

        <Modal.Header closeButton>
            <Modal.Title>{t('request_support')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <FormRequestSupportComponent {...props} request={request} setRequest={setRequest}/>

        </Modal.Body>

        <ModalButtons>
            <Button variant="secondary" onClick={onClose}>
                {t("cancel")}
            </Button>

            <Button variant="primary" onClick={() => onSubmit(request)}>
                {t("create_ticket")}
            </Button>
        </ModalButtons>
    </Modal>


}

export default RequestSupportComponent;
