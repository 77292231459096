import Row from "react-bootstrap/Row";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/dropdown/locales/locales";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import BasicConfigParameters from "layout/modules/FormBuilder/modules/module/components/BasicConfigParameters";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import "layout/modules/FormBuilder/modules/dropdown/assets/configuration.scss";

const ConfigComponent = props => {

    const {configuration, setConfiguration} = props;
    const {t} = useTranslations('FormDropdownModule', locale);

    const handleOptionsChange = useCallback(
        (fnc) => {
            setConfiguration((config) => ({
                ...config,
                options: fnc(Array.isArray(config?.options) ? config.options : []),
            }))

        },
        [setConfiguration]
    );

    return <Row className={"form-builder-dropdown-config"}>
        <BasicConfigParameters {...props}/>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup>
                <Form.Label>{t('placeholder')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(configuration?.placeholder)}
                    autoComplete="chrome-off"
                    placeholder={t('placeholder_ph')}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            placeholder: e.target.value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup>
                <Form.Label>{t('allow_multiple')}</Form.Label>
                <BooleanDropdown
                    value={parseBoolean(configuration?.isMulti, false)}
                    isClearable={false}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            isMulti: value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={12} xl={12} className={"options-configuration"}>
            <FormGroup>
                <Form.Label>{t('options')}</Form.Label>
                <MultiOptionComponent onChange={handleOptionsChange} data={configuration?.options ?? []}>
                    <ListItemComponent t={t}/>
                </MultiOptionComponent>
            </FormGroup>
        </Col>
    </Row>
}


const ListItemComponent = React.forwardRef((props, ref) => {
    const {t, entry = "", onChange, onDelete} = props

    return <Row>
        <Col sm={true} md={true} lg={true}>
            <FormControl
                type="text"
                ref={ref}
                placeholder={t('add_option_placeholder')}
                value={getOrEmpty(entry)}
                onChange={(e) => onChange(e.target.value)}
            />

        </Col>

        {(onDelete) &&
        <Col sm={1} md={1} lg={1} className="align-self-center">
            <div className={"btn btn-rounded-danger ms-auto"} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </div>
        </Col>
        }

    </Row>


});


export default ConfigComponent;
