import en from "app/beneficiaries/beneficiary/locales/en"
import pt from "app/beneficiaries/beneficiary/locales/pt"

const locale = [
    {
        lang: 'en-US',
        name: "English",
        translation: en
    },
    {
        lang: 'pt-PT',
        name: "Português",
        translation: pt
    }

];

export default locale;

