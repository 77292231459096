import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import generateSession from "app/login/utils/generateSession";
import {useApiState} from "system/API/APIStateContext";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import {Navigate} from "react-router";

const SetToken = props => {

    const {token} = useParams();
    const {setAuthentication, authentication} = useApiState();


    const {data: user, error} = useGet({
        path: "/auth/personal",
        lazy: !authentication?.session?.token,
    });

    useEffect(() => {
        setAuthentication(generateSession({
            sessionToken: token,
            sessionValidity: (Date.now() + (24 * 60 * 60)) * 1000,
        }));
    }, [token, setAuthentication]);

    useEffect(() => {

        if (user) {
            setAuthentication(generateSession({
                ...user,
                sessionToken: token,
                sessionValidity: (Date.now() + (24 * 60 * 60)) * 1000,
            }));


        }
    }, [token, user, setAuthentication]);

    if (authentication?.id) {
        return <Navigate to={'/'} state={{loginRedirected: true}}/>
    } else if (error) {
        return <Navigate to={'/'} state={{sessionExpired: true}}/>
    } else {
        return <Loading visible={true}></Loading>
    }

}

export default SetToken;