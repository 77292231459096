import SchoolLevel from "types/SchoolLevel";
import React from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";

const SchoolLevelDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);

    return <EnumDropdown
        {...props}
        t={t}
        options={SchoolLevel}
        placeholder={t('school_level_ph')}
    />

}

export default SchoolLevelDropdown;
