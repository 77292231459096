import React from "react";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import localeEquipments from "app/equipments/equipments/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";
import Button from "react-bootstrap/Button";
import classnames from "classnames";

const SelectedEquipmentsComponent = props => {

    const {requiredEquipments, selectedEquipments, onEquipmentSelect, selectEquipment, errors = {}, viewMode} = props;

    const {t: tEquipments} = useTranslations('equipments', localeEquipments);
    const {t} = useTranslations("EquipmentSelectionRuleAction", locale);


    return <div className={"selected-equipments"}>

        <TableAdvanced hover>
            <thead>
            <tr>
                <th>
                    {t('entry_description')}
                </th>

                <th>
                    {tEquipments('serial_number')}
                </th>

                <th>
                    {tEquipments('profile')}
                </th>

                <th>
                    {t('mandatory')}
                </th>

                {!viewMode &&
                <th/>
                }

            </tr>
            </thead>
            <tbody>

            {requiredEquipments.map((requiredEquipment, p) => {

                const selectedEquipment = selectedEquipments[p];

                return <tr key={p} className={classnames({"in-error": errors[p]})}>

                    <td>
                        {requiredEquipment.description}

                        {errors[p] &&
                        <div className={"error-message"}>
                            {t(errors[p])}
                        </div>
                        }

                    </td>

                    <td className={"fill-empty"}>
                        {selectedEquipment?.serialNumber}
                    </td>

                    <td className={"fill-empty"}>
                        {selectedEquipment?.profile?.name}
                    </td>

                    <td>
                        {t(requiredEquipment.mandatory)}
                    </td>

                    {!viewMode &&
                    <td className={"text-end"}>

                        {!selectedEquipment &&
                        <Button variant={'success'} onClick={() => selectEquipment(p)}>
                            {t('select_equipment')}
                        </Button>
                        }

                        {(selectedEquipment && !selectedEquipment?.preventDelete) &&
                        <Button variant={'danger'} onClick={() => {
                            //remove related and current
                            selectedEquipment.relatedPositions?.forEach(pr => onEquipmentSelect(null, pr));
                            onEquipmentSelect(null, p)
                        }}>
                            {t('remove_equipment')}
                        </Button>
                        }

                    </td>
                    }
                </tr>
            })}

            </tbody>

        </TableAdvanced>
    </div>


}

export default SelectedEquipmentsComponent;
