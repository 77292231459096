import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";
import Fade from "react-bootstrap/Fade";

import 'layout/modules/NotificationArea/assets/css/notification.scss'
import {useApiState} from "system/API/APIStateContext";


const NotificationAreaContext = createContext({});

function useNotifications() {
    const context = React.useContext(NotificationAreaContext)
    if (!context) {
        throw new Error(`useNotifications must be used within a NotificationAreaContext`)
    }
    return context
}


const NotificationAreaProvider = (props) => {

    const {authentication} = useApiState();
    const [visible, setVisible] = useState(false);
    const [notifications, setNotifications] = useState(new Map());
    const [time, setTime] = useState(+new Date());

    const addNotification = useCallback((not) => {
        setNotifications((prev) => new Map(prev.set(not.id, {
            ...not,
            timestamp: +new Date()
        })));
    }, [setNotifications]);

    const handleOnClose = useCallback((id) => {
        setNotifications((prev) => {
            let map = new Map(prev);
            map.delete(id);
            return new Map(map);
        });
    }, [setNotifications]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (visible)
                setTime(+new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, [visible]);


    //Clean notification on user change
    useEffect(() => {
            setNotifications(new Map());
        }, [authentication?.id]);

    useEffect(() => {
        setVisible(notifications.size > 0);
    }, [notifications]);


    const value = useMemo(() => {
        return {
            closeNotification: handleOnClose,
            showNotification: addNotification
        }
    }, [addNotification, handleOnClose]);


    return <NotificationAreaContext.Provider value={value}>

        <div className={visible ? "notification-area" : "notification-area invisible"}>
            {[...notifications.keys()].map(k => {
                let n = notifications.get(k);
                let visible = (n.duration === 0 || n.duration + n.timestamp > time);

                return <Fade key={n.id} in={visible} appear={true} mountOnEnter unmountOnExit timeout={600}
                             onExited={() => handleOnClose(n.id)}>
                    {n.popup}
                </Fade>
            })}

        </div>

        {props.children}
    </NotificationAreaContext.Provider>

}

export {NotificationAreaProvider, useNotifications};
