export const pt = {

    name: "Escolha de beneficiario",
    description: "Permite a seleção manual de um beneficiário.",

    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Deve sempre indicar os tipos de beneficiários que devem ser escolhidos",

    STUDENT: "Aluno",
    TEACHER: "Docente",

    placeholder: "Escolha uma opção",
    beneficiary_type: "Tipo de beneficiário",
    selection_filter: "Filtro de {{type}}",

    ERROR_SELECTING: "Beneficiário inválido",
    INVALID_BENEFICIARY: "O equipamento atual não pode ser associado/atribuido ao beneficiário que escolheu na lista. Por favor verifique novamente o equipamento e o beneficiário ou contacte o serviço de suporte da plataforma.",


};

export default pt;
