import React, {useMemo} from "react";
import InputModule from "layout/modules/FormBuilder/modules/input/InputModule";
import TextAreaModule from "layout/modules/FormBuilder/modules/textArea/TextAreaModule";
import CalendarModule from "layout/modules/FormBuilder/modules/calendar/CalendarModule";
import UploadModule from "layout/modules/FormBuilder/modules/upload/UploadModule";
import HelpModule from "layout/modules/FormBuilder/modules/help/HelpModule";
import FormEditorComponent from "layout/modules/FormBuilder/components/editor/FormEditorComponent";
import DropdownModule from "layout/modules/FormBuilder/modules/dropdown/DropdownModule";

const FormBuilder = props => {

    const {modules: externalModules = [], ...otherProps} = props;

    const modules = useMemo(
        () => [
            InputModule,
            TextAreaModule,
            DropdownModule,
            CalendarModule,
            UploadModule,
            HelpModule,
            ...externalModules
        ],
        [externalModules]
    );


    return <FormEditorComponent {...otherProps} modules={modules}/>


}

export default FormBuilder;
