import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import ChartDataSetType from "app/home/utils/ChartDataSetType";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import NutDropdown, {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import ProcedureDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ProcedureDropdown";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import SupplierDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SupplierDropdown";
import {SchoolType} from "types/SchoolType";
import "app/home/assets/css/chart-filter.scss";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import ContractDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ContractDropdown";

const EquipmentChartFilterComponent = props => {

    const {setFilters, filters} = props;
    const {t} = useTranslation('dashboard');

    return <div className={"chart-data-filter"}>

        <Row>

            <Col md={6}>
                <div className={"label"}>
                    {t('kits')}
                </div>
                <div className={"value"}>
                    <EnumDropdown
                        t={t}
                        value={filters.typologyIds}
                        isClearable={true}
                        isSearchable={false}
                        options={ChartDataSetType}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, typologyIds: value})),
                            [setFilters]
                        )}

                    />

                </div>
            </Col>

            <Col md={6}>
                <div className={"label"}>
                    {t('contract_number')}
                </div>
                <div className={"value"}>
                    <ContractDropdown
                        value={filters.contractNumbers}
                        isClearable={true}
                        isSearchable={true}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, contractNumbers: value})),
                            [setFilters]
                        )}

                    />

                </div>
            </Col>

            <Col md={6}>
                <div className={"label"}>
                    {t('procedure')}
                </div>
                <div className={"value"}>
                    <ProcedureDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.procedureIds)}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, procedureIds: value})),
                            [setFilters]
                        )}
                    />

                </div>

            </Col>

            <Col md={6}>
                <div className={"label"}>
                    {t('school_type')}
                </div>
                <div className={"value"}>
                    <EnumDropdown
                        t={t}
                        options={SchoolType}
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.institutionTypologies)}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, institutionTypologies: value})),
                            [setFilters]
                        )}
                    />

                </div>
            </Col>

            {isNotDefinedNorEmpty(filters?.institutionTypologies)
                ? <SchoolFilterComponent
                    md={6}
                    sm={6}
                    lg={6}
                    value={filters?.schoolIds}
                    isMulti={true}
                    setValue={(value) => setFilters(filter => ({
                        ...filter,
                        schoolIds: value,
                    }))}
                />
                : <Col sm={6} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('school')}</Form.Label>
                        <SchoolDropdown
                            isClearable={true}
                            isMulti={true}
                            value={getOrNull(filters.schoolIds)}
                            typologies={filters.institutionTypologies}
                            onChange={(value) => setFilters(filter => ({...filter, schoolIds: value}))}
                        />
                    </FormGroup>
                </Col>
            }

            <Col md={6}>
                <div className={"label"}>
                    {t('supplier')}
                </div>
                <div className={"value"}>
                    <SupplierDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.equipmentSupplierIds)}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, equipmentSupplierIds: value})),
                            [setFilters]
                        )}
                    />

                </div>
            </Col>

            <Col md={6}>
                <div className={"label"}>
                    {t('nut3')}
                </div>
                <div className={"value"}>
                    <NutDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.nut3)}
                        type={NUTType.NUT3}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, nut3: value})),
                            [setFilters]
                        )}
                    />

                </div>

            </Col>


        </Row>

    </div>
}

export default EquipmentChartFilterComponent;

