export const pt = {

    name: "Upload de ficheiros",
    set_label: "Defina o nome do campo",

    description_help: "Deve definir o campo de ajuda / descrição",

    max_files: "Num. máximo de ficheiros",
    description_info: "Ajuda / Descrição",

    select_accept_types: "Indique o tipo de documentos",
    files: "Ficheiros",

    accept_types: "Tipo de ficheiros aceite",
    PDF: "Doc. pdf",
    IMAGES: "Imagens",
    no_results: "Sem opções",

    MISSING_FILES: "Deve carregar um ficheiro",


};

export default pt;
