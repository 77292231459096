import BeneficiaryType from "types/BeneficiaryType";

const isBeneficiaryNotOnEquipmentSchool = (equipment) =>
    equipment?.beneficiaryId &&
        equipment?.beneficiary?.school?.dre !== equipment?.school?.dre


const isStudentYearNotAllowedOnEquipment = (equipment) => {

    const allowedYears = equipment?.profile?.schoolYears;
    const studentYear = equipment?.beneficiary.schoolYear;

    if (equipment?.beneficiaryType !== BeneficiaryType.STUDENT || !studentYear)
        return false;

    if (!Array.isArray(allowedYears) || allowedYears.length === 0) {
        return false;
    }

    return !allowedYears.includes(studentYear);

}


export {
    isBeneficiaryNotOnEquipmentSchool,
    isStudentYearNotAllowedOnEquipment
}
