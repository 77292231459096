import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faLaptopMedical} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/equipmentSelection/components/config/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/equipmentSelection/components/config/FormEditor";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import ExecutionComponent
    from "app/ruleTemplates/actions/equipmentSelection/components/execution/ExecutionComponent";
import RenderView from "app/ruleTemplates/actions/equipmentSelection/components/view/RenderView";


class EquipmentSelectionRuleAction extends RuleEngineAction {

    static hasViewMode = true;
    static type = RuleActionType.SYNC
    static excludeTypes = [RuleType.EQUIPMENT];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faLaptopMedical, t('name'), t('description'));

    static getName = () => "EquipmentSelectionRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderExecution = (props) => <ExecutionComponent {...props} />
    static renderView = props => <RenderView {...props}/>

    static isAllowed = (config, position) => !ruleHasEquipments(config, position);


}

export default EquipmentSelectionRuleAction;
