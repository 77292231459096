import UserHasPermission from "system/API/Authentication/UserHasPermission";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import useTranslations from "system/Translations/UseTranslations";
import localeEquipments from "app/equipments/equipments/locales/locales";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import {Trans} from "react-i18next";

const EquipmentResultsComponent = props => {

    const {data, query} = props;

    const [profiles, loadingProfiles] = useRemoteEntity(data, '/equipment-profiles/search', 'profile');
    const {t} = useTranslations('equipments', localeEquipments);

    return <div className={"equipment-dashboard-results"}>


        <TableAdvanced hover loading={loadingProfiles}>
            <thead>
            <tr>

                <th id="serialNumber">
                    {t('serial_number')}
                </th>

                <th id="procedure" className={"d-none d-md-table-cell"}>
                    {t('procedure')}
                </th>

                <th id="internalCode">
                    {t('internal_code')}
                </th>


                <th id="profile">
                    {t('profile')}
                </th>

                <th id="status">
                    {t('status')}
                </th>

                <th id="school" className={"d-none d-md-table-cell"}>
                    {t('school')}
                </th>

                <UserHasPermission permission={"READ_BENEFICIARY"}>
                    <th id="procedure" className={"d-none d-md-table-cell"}>
                        {t('beneficiary')}
                    </th>
                </UserHasPermission>

                <th/>

            </tr>
            </thead>
            <tbody>

            {data?.content?.map((equipment, index) => {

                return <tr key={index}>

                    <td className="fill-empty">
                        {equipment.serialNumber}
                    </td>

                    <td className="fill-empty d-none d-md-table-cell">
                        {profiles[equipment.profile.id]?.procedure?.name}
                    </td>

                    <td className="fill-empty">
                        {equipment.internalCode}
                    </td>

                    <td className="fill-empty">
                        <div className={"over-list-entry"}>
                            {profiles[equipment.profile.id]?.typology?.name}
                        </div>
                        {equipment.profile?.name}
                    </td>

                    <td className="fill-empty">
                        {equipment.status?.name}
                    </td>

                    <td className="fill-empty d-none d-md-table-cell">
                        <div className={"over-list-entry"}>
                            {t(equipment.school?.typology)}
                        </div>

                        {equipment.school?.name}
                    </td>

                    <UserHasPermission permission={"READ_BENEFICIARY"}>
                        <td className="fill-empty d-none d-md-table-cell">
                            {equipment.beneficiary && <>
                                <div className={"over-list-entry"}>
                                    {t(equipment.beneficiary?.type)}
                                </div>
                                {equipment?.beneficiary?.vatNumber}
                                {equipment?.beneficiary?.name && <>&nbsp;-&nbsp;</>}
                                {equipment.beneficiary?.name}
                            </>}
                        </td>
                    </UserHasPermission>


                    <td className="table-buttons">

                        <div className="buttons">

                            <TableIconButton
                                path={"/equipments/" + equipment.id}
                                icon={faSearch}
                                className={"table-btn btn-rounded-primary small"}
                            />
                        </div>

                    </td>
                </tr>
            })}
            </tbody>
        </TableAdvanced>

        {data?.totalPages > 1 &&
            <div className={"more-results-available"}>
                <Trans>
                    {t('more_results_available_equipments')}
                    <a href={"/equipments/search/" + query}>
                        {t('more_results_available_equipments_click')}
                    </a>
                </Trans>
            </div>
        }
    </div>


}

export default EquipmentResultsComponent;
