import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import "app/home/assets/css/charts.scss";
import EquipmentChartFilterComponent from "app/home/components/charts/equipment/EquipmentChartFilterComponent";
import React, {useMemo, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import createPersistedState from "system/System/PersistedState/PersistenState";
import EquipmentStatusResolver from "app/home/utils/resolvers/EquipmentStatusResolver";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import useChartDownload from "app/home/utils/useChartDownload";
import {ChartFreshColors} from "app/home/utils/ChartColors";

const useStateChartPersistedState = createPersistedState('equipment-dashboard-chart-filter');

const EquipmentChartComponent = props => {

    const {t} = useTranslation('dashboard');

    const [isFilterVisible, setFilterVisible] = useState(false);
    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const [filters, setFilters] = useStateChartPersistedState({});
    const {handleChartDownload, chartRef} = useChartDownload();

    const {data, loading} = useGet({
        path: "/dashboard/equipment-status",
        localErrorOnly: true,
        queryParams: useMemo(() => ({
            ...filters,
        }), [filters]),
        resolve: EquipmentStatusResolver
    });

    return <Box>
        <BoxTitle>{t('equipment_status')}</BoxTitle>

        <ChartButtonsComponent
            setFilterVisible={setFilterVisible}
            isFilterVisible={isFilterVisible}
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />


        {isFilterVisible &&
            <EquipmentChartFilterComponent filters={filters} setFilters={setFilters}/>
        }

        <Loading visible={loading}/>

        {data?.length > 0 &&
            <>
                {chartMode === ChartMode.CHART &&
                    <ResponsiveContainer height={(data?.length * 24) + 100} width="100%">
                        <BarChart data={data} ref={chartRef} barCategoryGap={0} barGap={0} layout="vertical"
                                  margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                            <YAxis dataKey="status"
                                   stroke={"var(--chart-line-color)"}
                                   tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                   type="category"
                                   width={130}
                                   reversed={true}
                            />

                            <XAxis
                                stroke={"var(--chart-line-color)"}
                                tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                type={"number"}
                                /**padding={{left: 0, right: 10}} *//>


                            <Tooltip
                                cursor={false}
                                contentStyle={{
                                    backgroundColor: undefined,
                                    border: undefined,
                                }}
                                formatter={(value, name, props) => value + " (" + (props.payload?.percentage) + "%)"}/>


                            <Bar dataKey="total" fill="var(--chart-text-color)" barSize={7}
                                 background={{fill: 'var(--chart-bar-background-color)'}}>
                                {data.map((entry, index) => <Cell key={index} radius={3} fill={ChartFreshColors[0]}/>)}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }

                {chartMode === ChartMode.TABLE &&
                    <TableModeComponent data={data}/>
                }
            </>
        }

        {data?.length === 0 &&
            <div className={"no-chart-results"}>
                {t('no_chart_results')}
            </div>

        }

    </Box>

}

export default EquipmentChartComponent;
