import React, {useState} from "react";
import {faPencilAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import ArticleComponent from "./ArticleComponent";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import FormContainer from "app/help/containers/FormContainer";
import IndexContainer from "app/help/containers/IndexContainer";

const ModalContentComponent = (props) => {

    const {article, currentPageArticleExists} = props;
    const articleExists = Object.keys(article).length > 1;
    const [addMode, setAddMode] = useState(false);

    return <div className={classnames("help-content")}>
        <div className={"buttons"}>
            {(!articleExists && !currentPageArticleExists) &&
            <TableIconButton
                className={classnames("btn-rounded-primary", {"active": addMode})}
                icon={articleExists ? faPencilAlt : faPlus}
                onClick={() => setAddMode(!addMode)}
                permission={["WRITE_HELP"]}
            />
            }
        </div>

        {articleExists
            ? <ArticleComponent {...props} />
            : <>
                {addMode
                    ? <FormContainer {...props}/>
                    : <IndexContainer {...props}/>
                }
            </>
        }
    </div>
}

export default ModalContentComponent;
