import {useEffect, useMemo, useState} from "react";
import getAsArray from "system/Arrays/getArray";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";

const useFormFiles = props => {

    const {data, model} = props;
    const [dataWithFiles, setDataWithFiles] = useState(data);

    const fileIds = useMemo(() => {
        let fileIds = [];
        model.forEach((m) => {
            if (m.component === "UploadModule") {
                getAsArray(data[m.id]).forEach((f, i) => {
                    fileIds.push({
                        id: i,
                        fileId: f
                    });
                });
            }
        });

        return {
            content: fileIds
        };

    }, [model, data]);

    const [files, loadingFiles] = useRemoteEntity(fileIds, "/documents/search", 'fileId', "READ_EQUIPMENT");

    useEffect(
        () => {
            if (Object.keys(files).length > 0) {

                model.forEach((m) => {
                    if (m.component === "UploadModule") {
                        setDataWithFiles(dataWithFiles => ({
                            ...dataWithFiles,
                            [m.id]: data[m.id].map(e => ({
                                ...files[e]
                            }))
                        }));
                    }
                })
            }
        },
        // eslint-disable-next-line
        [files]
    );

    return [dataWithFiles, loadingFiles];

}


export default useFormFiles;
