import {ValidateContractBody} from "app/procedures/contractModel/utils/GenerateContractBody";

const ContractModelResolver = (data) => ({
    ...data,
    ...ValidateContractBody(data)
});

const ContractModelLoggerResolver = (data) => ({
    ...data,
});


export default ContractModelResolver;
export {ContractModelLoggerResolver};
