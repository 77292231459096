
const ReportObjectTypes = {
    ReportSchedule: "ReportSchedule",
    Report: "Report",
}

const OperationObjectTypes = {
    OperationSchedule: "OperationSchedule",
    Operation: "Operation",
}

const ContractObjectTypes = {
    Contract: "Contract",
    ContractAnnex: "ContractAnnex",
}



const AuditObjectTypes = {
    ...ReportObjectTypes,
    ...OperationObjectTypes,

    User: "User",
    UserRole: "UserRole",
    Entity: "Entity",
    Template: "Template",
    Configuration: "Configuration",
    HelpTopic: "HelpTopic",
    Observation: "Observation",
    ReportTemplate: "ReportTemplate",
    OperationTemplate: "OperationTemplate",
    LabelValue: "LabelValue",
    Document: "Document",

    Equipment: "Equipment",
    ContractModel: "ContractModel",
    ...ContractObjectTypes,
    Teacher: "Teacher",
    Student: "Student",
    School: "School",
    EquipmentTypology: "EquipmentTypology",
    EquipmentStatus: "EquipmentStatus",
    Procedure: "Procedure",

    EquipmentProfile: "EquipmentProfile",

    Guardian: "Guardian",
    Rule: "Rule",
    RuleSet: "RuleSet",
    SchoolYear: "SchoolYear",
    Supplier: "Supplier",
    Ticket: "Ticket",
    TicketCategory: "TicketCategory",
    TicketSla: "TicketSla",
    EquipmentRefusal: "EquipmentRefusal",

    EquipmentRegistration: "EquipmentRegistration",

}


export {
    AuditObjectTypes,
    ReportObjectTypes,
    OperationObjectTypes,
    ContractObjectTypes,
}
