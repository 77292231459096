import replaceArrayPosition from "system/Arrays/ArrayUtils";
import {useCallback, useEffect, useState} from "react";
import getAsArray from "system/Arrays/getArray";
import useGetRelatedEquipmentsWithDetails
    from "app/ruleTemplates/actions/equipmentSelection/utils/useGetRelatedEquipmentsWithDetails";

const useAdvanceEquipmentSelection = props => {

    const {
        requiredEquipments,
        selectedEquipments: remoteSelectedEquipments,
        autoSelectRelated,
        errors: remoteErrors
    } = props;

    const [errors, setErrors] = useState(remoteErrors ?? {})
    const [currentEquipmentSelectionPosition, setCurrentEquipmentSelectionPosition] = useState(null);
    const [selectedEquipments, setSelectedEquipments] = useState(
        remoteSelectedEquipments ?? new Array(requiredEquipments.length).fill(null));

    const {getRelatedEquipments, loading} = useGetRelatedEquipmentsWithDetails();


    //Update errors
    useEffect(
        () => setErrors(remoteErrors),
        [remoteErrors, setErrors]
    );

    const handleEquipmentSelection = useCallback((equipment, position) => {

            setSelectedEquipments(equipments => replaceArrayPosition(equipments, position, equipment));

            //Clear errors on positions
            setErrors(errors => ({
                ...errors,
                [position]: false
            }));

            setCurrentEquipmentSelectionPosition(null);


            if (autoSelectRelated && (equipment?.relatedEquipmentId || equipment?.isVirtual)) {
                getRelatedEquipments(equipment)
                    .then((relatedEquipments) => {
                        let relatedEquipmentPositions = [];
                        relatedEquipments
                            .forEach((re) => {
                                relatedEquipmentPositions = relatedEquipmentPositions.concat(findAndReplaceCompatibleEquipment(
                                    re, requiredEquipments, equipment, setSelectedEquipments, relatedEquipmentPositions));
                            });

                        setSelectedEquipments(equipments => replaceArrayPosition(equipments, position, {
                            ...equipment,
                            relatedPositions: relatedEquipmentPositions,
                        }));

                        //Clear errors on positions
                        relatedEquipmentPositions
                            .forEach((p) => setErrors(errors => ({
                            ...errors,
                            [p]: false
                        })));


                    }).catch((e) => console.log("Error getRelatedEquipments", e))
            }
        },
        [setSelectedEquipments, setCurrentEquipmentSelectionPosition, setErrors,
            getRelatedEquipments, requiredEquipments, autoSelectRelated]
    );


    return {
        selectedEquipments,
        handleEquipmentSelection,
        currentEquipmentSelectionPosition,
        setCurrentEquipmentSelectionPosition,
        loading,
        errors
    }
}

const findAndReplaceCompatibleEquipment = (equipment, requiredEquipments, mainEquipment, setSelectedEquipments, usedPositions) => {


    const relatedPositions = [];

    if (equipment.id === mainEquipment.id)
        return [];

    for (let p = 0; p < requiredEquipments.length; p++) {

        const re = requiredEquipments[p];
        const profileIds = getAsArray(re.profileIds);
        const typologyId = re.typologyId;

        if ((profileIds.length === 0 || profileIds.includes(equipment.profile.id))
            && typologyId === equipment?.profile.typology.id) {

            if (!usedPositions.includes(p)) {
                relatedPositions.push(p);
                setSelectedEquipments(equipments =>
                    replaceArrayPosition(equipments, p, {
                        ...equipment,
                        preventDelete: true,
                    })
                );
                break;
            }
        }
    }

    return relatedPositions;


}

export default useAdvanceEquipmentSelection;
