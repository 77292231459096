import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faShuffle} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/replaceEquipmentExecution/locales/locales";
import RuleType from "app/ruleTemplates/utils/types/RuleType";


class ReplaceEquipmentExecutionRuleAction extends RuleEngineAction {

    static disableConfiguration = true;
    static hasViewMode = false;
    static type = RuleActionType.SILENT;
    static excludeTypes = [RuleType.SCHOOL, RuleType.BENEFICIARY];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faShuffle, t('name'), t('description'));

    static getName = () => "ReplaceEquipmentExecutionRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = () => null;
    static isAllowed = (config, position) => config.actions.some((e, index) => e.component === "ReplaceEquipmentRuleAction" && index < position);

}

export default ReplaceEquipmentExecutionRuleAction;
