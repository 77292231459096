import React, {useCallback, useMemo} from "react";
import locale from "./locales/locales";
import Select from "react-select";
import CreatableSingle from "layout/modules/Forms/Dropdowns/CreatableSingle/CreatableEditableSelect";
import useTranslations from "system/Translations/UseTranslations";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import {
    convertEnumToOptions,
    getSelectedOptions,
    getValuesArrayFromObjectsArray, handleParameterAsValue
} from "layout/modules/Forms/Dropdowns/utils/utils";



const EnumDropdown = (props, ref) => {

    const {t: te, ready: tr} = useTranslations('enumDropdown', locale);

    const {
        defaultValue,
        value,
        t = te,
        translationReady = tr,
        options: opts,
        isMulti,
        isCreatable,
        isClearable = true,
        onChange = () => void 0,
    } = props


    const isControlled = (value !== undefined);

    const currentValue = useMemo(
        () => isControlled ? value : defaultValue,
        [isControlled, value, defaultValue]
    );

    //Construct options based on object {key: value}
    const options = useMemo(
        () => translationReady ? convertEnumToOptions(opts, t) : [],
        [opts, t, translationReady]
    );

    const selectedOptions = useMemo(
        () => getSelectedOptions(currentValue, options, isMulti, true),
        [options, currentValue, isMulti]
    );

    const handleChange = useCallback(
        (options) => onChange(getValuesArrayFromObjectsArray(options, isMulti), options),
        [onChange, isMulti]
    );

    const handlePlaceHolder = usePlaceHolder(props.placeholder, t('placeholder'));
    const noOptionsMessage = useLabelCallback(props.noOptionsMessage, t('no_results'));
    const loadingMessage = useLabelCallback(props.loadingMessage, t('loading_message'));

    const selectProps = useMemo(
        () => ({
            ref: ref,
            name: props.id,
            id: props.id,
            classNamePrefix: "rs",
            className: "rs",
            options: options,
            defaultValue: !isControlled ? selectedOptions : undefined,
            value: isControlled ? selectedOptions : undefined,
            onChange: handleChange,
            isClearable: isClearable,
            isMulti: isMulti,
            placeholder: handlePlaceHolder,
            noOptionsMessage: noOptionsMessage,
            loadingMessage: loadingMessage,
            formatCreateLabel: handleParameterAsValue
        }),
        [props.id, ref, options, isControlled, selectedOptions, handleChange, isClearable,
            isMulti, handlePlaceHolder, noOptionsMessage, loadingMessage]
    );

    if (!translationReady) {
        return <></>;
    }

    if (isCreatable) {
        return <CreatableSingle {...props} {...selectProps}/>
    } else {
        return <Select {...props} {...selectProps} />
    }

};


export default React.forwardRef(EnumDropdown);
