export const pt = {

    name: "Recuperar estado do equipamento",
    description: "Recupera o estado em que o equipamento esteve antes de um conjunto de estados",

    form_description: "Recupera o estado anterior a: <strong>{{status}}</strong>.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre um ou mais estados.",

    status: "Recuperar estado anteriores a",




};

export default pt;
