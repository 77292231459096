import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import {SchoolType} from "types/SchoolType";
import {useTranslation} from "react-i18next";

const FormSchool = props => {

    const {equipment, setEquipment, errors, readOnly} = props;
    const {t} = useTranslation('equipments');

    return <>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['installationSchool']}>
                <Form.Label>{t('installation_school')}</Form.Label>
                <SchoolDropdown
                    isClearable={true}
                    isDisabled={readOnly}
                    value={getOrNull(equipment?.installationSchoolId)}
                    typologies={[SchoolType.AE_SCHOOL, SchoolType.ENA, SchoolType.EPCCA]}
                    onChange={useCallback((value) => setEquipment(equipment => ({
                        ...equipment,
                        installationSchoolId: value,
                    })), [setEquipment])}
                />
                <Form.Text className="sub-error">
                    {t(errors['installationSchool'])}
                </Form.Text>
            </FormGroup>

        </Col>

    </>
}

export default FormSchool;
