import {useCallback, useState} from "react";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import replaceConfigProps from "layout/modules/FormBuilder/utils/editor/replaceConfigProps";
import getAsArray from "system/Arrays/getArray";

const useFormBuilder = defaultData => {

    const [data, setData] = useState(getAsArray(defaultData))

    const addComponent = useCallback(
        (component, subModule) => {
            setData(data => [...data, {
                component: component.getName(),
                id: generateRandomId(10),
                props: component.getDefaultProps(),
                subModule: subModule,
            }]);
        },
        [setData]
    );

    const delComponent = useCallback(
        (position) => {
            setData(data => data.filter((_, i) => i !== position))
        },
        [setData]
    );

    const setComponentProps = useCallback(
        (position, props) => setData(data => replaceConfigProps(data, position, props)),
        [setData]
    );

    return {
        data,
        setData,
        addComponent,
        delComponent,
        setComponentProps,
    }


}

export default useFormBuilder;
