import Box from "layout/modules/Box/Box";
import React from "react";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import {useTranslation} from "react-i18next";
import {useDateTime} from "system/DateTime/DateTime";

const HistoricStatusComponent = props => {

    const {t} = useTranslation("support");
    const {historic, loading} = props;

    const getDateAndTime = useDateTime("DD-MM-YYYY HH:mm");

    return <Box>

        <TableAdvanced
            TableAdvanced hover
            loading={loading}>
            <thead>
            <tr>
                <th>
                    {t('date')}
                </th>

                <th>
                    {t('user')}
                </th>

                <th>
                    {t('status')}
                </th>
            </tr>
            </thead>
            <tbody>
            {historic?.map((value, key) =>
                <tr key={key}>
                    <td>{getDateAndTime(value.creationTimestamp)}</td>
                    <td className={"fill-empty"}>{value.creationUser?.name}</td>
                    <td>{t(value.ticketStatus)}</td>
                </tr>
            )}

            </tbody>

        </TableAdvanced>


    </Box>
}

export default HistoricStatusComponent;
