import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/setBeneficiary/locales/locales";
import BeneficiaryEquipmentStatus from "app/beneficiaries/beneficiary/utils/BeneficiaryEquipmentStatus";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import localStudents from "app/beneficiaries/students/locales/locales";

const BeneficiaryStatus = {
        [BeneficiaryEquipmentStatus.WITH_EQUIPMENT]: BeneficiaryEquipmentStatus.WITH_EQUIPMENT,
    [BeneficiaryEquipmentStatus.PARTIAL_EQUIPMENT]: BeneficiaryEquipmentStatus.PARTIAL_EQUIPMENT,
}


const FormConfiguration = props => {

    const {setConfiguration, configuration} = props;

    const {t} = useTranslations("SetBeneficiaryRuleAction", locale);
    const {t: tStudents} = useTranslations('students', localStudents);

    return <Row>

        <Col sm={12} lg={12} xl={12}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('new_status')}</Form.Label>
                <EnumDropdown
                    t={tStudents}
                    options={BeneficiaryStatus}
                    id={"equipmentStatus"}
                    value={getOrNull(configuration?.props?.beneficiaryEquipmentStatus)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                beneficiaryEquipmentStatus: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>
    </Row>

}

export default FormConfiguration;
