import React, {useCallback, useEffect, useState} from "react";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import SingleExecutionComponent from "app/ruleTemplates/components/execute/single-mode/SingleExecutionComponent";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/locale/locales";

const ExecuteSingleOperationContainer = React.forwardRef((props, ref) => {

    const {data, reload} = props;
    const showToast = useShowToast();
    const {t} = useTranslations('rule-template', locale);

    const [activeOperation, setActiveOperation] = useState(false);

    const {data: operationData} = useGet({
        path: "/rule-templates/" + activeOperation?.id,
        lazy: !activeOperation
    });


    const handleEnd = useCallback(
        () => {
            showToast({
                title: t('SUCCESS_EXECUTED_SINGLE_MSG'),
                message: operationData.executionFinalMessage,
                icon: faCheck,
            });

            setTimeout(() => {
                setActiveOperation(false);
                reload();
            }, 1500);


        },
        [setActiveOperation, showToast, t, operationData?.executionFinalMessage, reload]
    );

    useEffect(
        () => {
            ref.current = {
                runOperation: (operation) => setActiveOperation(operation),
            }
        },
        [setActiveOperation, ref]
    );


    if (activeOperation) {
        if (operationData && activeOperation.id === operationData?.id) {
            return <SingleExecutionComponent
                onClose={() => setActiveOperation(false)}
                onEnd={handleEnd}
                operation={operationData}
                data={data}

            />
        }

        return <Loading visible={true}/>
    }

    return <></>
});

export default ExecuteSingleOperationContainer;
