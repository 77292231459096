import React from 'react';

import Login from "./modules/BaseLayout/Login/Login"
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'layout/assets/css/fonts.scss';
import 'layout/assets/css/dark-mode.scss';
import 'layout/assets/css/base-login.scss';

const LoginLayout = (props) => {
    return <>
        <Login {...props}/>
    </>
}

export default LoginLayout;
