import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import React, {useCallback, useMemo} from "react";

const SchoolClassDropdown = props => {
    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "recruitmentGroupId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
        schoolId,
        value,
    } = props;

    const defaultSelectedOption = useMemo(() => value ? ({id: value, name: value}) : undefined,[value]);

    return <GenericRemoteClassDropdown
        {...props}
        id={id}
        key={defaultSelectedOption}
        placeholder={usePlaceHolder(placeholder, t('school_class_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage, t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage, t('generic_loading'))}
        remotePath={"/school-classes/search"}
        remoteClassPath={"/school-classes/search"}
        defaultSelectedOption={defaultSelectedOption}
        searchFilter={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: maxItems,
            schoolIds: schoolId
        }), [maxItems,schoolId])}
        searchResponseSolver={useCallback(
            (data) => ({
                    ...data,
                    content: data?.content?.map(e => ({
                        id: e,
                        name: e
                    }))
                }),
            []
        )}


    />
}

export default SchoolClassDropdown;
