import React, {useCallback, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import classnames from "classnames";
import "app/home/assets/css/search.scss"
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import DashboardSearchType, {useDashboardSearchTypeAuthorized} from "app/home/utils/DashboardSearchType";
import Loading from "layout/modules/Loading/Loading";
import EquipmentResultsComponent from "app/home/components/search/EquipmentResultsComponent";
import BeneficiaryResultsComponent from "app/home/components/search/BeneficiaryResultsComponent";
import FormControl from "layout/modules/Forms/Control/FormControl";


const searchChanged = (query, type, searchedQuery, searchedType) => query !== searchedQuery || type !== searchedType;

const SearchComponent = props => {

    const {query: searchedQuery, type: searchedType, onSearch, loading, result} = props;
    const [query, setQuery] = useState("");
    const [type, setType] = useState(DashboardSearchType.EQUIPMENTS);

    const searchTypes = useDashboardSearchTypeAuthorized();

    const {t} = useTranslation("dashboard");

    return <div className={"dashboard-search-area"}>

        <Loading visible={loading}/>
        <div className={"search-area"}>

            <div className={"search-label"}>
                {t('search_label')}
            </div>

            <div className={"search-form"}>

                <div className={"dashboard-search-control"}>
                    <InputGroup>
                        <FormControl
                            type={"text"}
                            placeholder={type === DashboardSearchType.EQUIPMENTS
                                ? t('search_ph_equipments')
                                : t('search_ph_beneficiary')
                            }
                            value={getOrEmpty(query)}
                            onKeyDown={useCallback(
                                (e) => {
                                    if (e.key === 'Enter' && searchChanged(e.target.value, type, searchedQuery, searchedType)) {
                                        onSearch(e.target.value, type);
                                    }
                                },
                                [searchedQuery, onSearch, type, searchedType]
                            )}
                            onBlur={useCallback(
                                (e) => {
                                    if (searchChanged(e.target.value, type, searchedQuery, searchedType)) {
                                        onSearch(e.target.value, type);
                                    }
                                },
                                [searchedQuery, onSearch, type, searchedType]
                            )}
                            onChange={useCallback(
                                (e) => {

                                    if (e.target.value === "") {
                                        setQuery("");
                                        onSearch("");
                                    } else {
                                        setQuery(e.target.value);
                                    }
                                },
                                [setQuery,onSearch]
                            )}


                        />

                        <EnumDropdown
                            t={t}
                            options={searchTypes}
                            value={type}
                            onChange={useCallback(
                                (type) => {
                                    setType(type);
                                    if (searchChanged(query, type, searchedQuery, searchedType)) {
                                        onSearch(query, type);
                                    }
                                }, [query, searchedType, searchedQuery, setType, onSearch]
                            )}
                            isClearable={false}
                        />

                        <div className={"button-search-container"}>
                            <div
                                className={classnames("button-search", {
                                    "active": query?.length > 0
                                })}
                                onClick={() => {
                                    if (searchChanged(query, type, searchedQuery, searchedType)) {
                                        onSearch(query, type);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faSearch}/>
                            </div>
                        </div>


                    </InputGroup>
                </div>

            </div>

        </div>


        {(result?.totalItems > 0) &&
            <div className={"search-result"}>
                {result?.resultType === DashboardSearchType.EQUIPMENTS &&
                    <EquipmentResultsComponent data={result} query={searchedQuery}/>
                }

                {result?.resultType === DashboardSearchType.BENEFICIARIES &&
                    <BeneficiaryResultsComponent data={result} query={searchedQuery}/>
                }

            </div>

        }

        {result?.totalItems === 0 &&
            <div className={"no-results"}>
                <Trans>
                    {t('no_results', {
                        query: searchedQuery,
                        type: t(searchedType)
                    })}
                </Trans>
            </div>
        }


    </div>

}

export default SearchComponent;
