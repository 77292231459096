const studentResolver = (data) => ({
    ...data,
    parishId: data.parish?.id,
    schoolId: data.school?.id,
    guardian: {
        ...data?.guardian,
        parishId: data?.guardian?.parish?.id,
    }
});

export default studentResolver;
