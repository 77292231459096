export const pt = {

    name: "Substituir equipamento",
    description: "Permite a substituição do equipamento atual por um equipamento adquirido/registado pelo beneficiário",


    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Os mapeamentos entre tipologias e o perfil do novo equipamento deve ser definido",

    profile_mapping: "Mapeamento tipologia - perfil  do novo equipamento"
};

export default pt;
