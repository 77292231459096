export const pt = {

    name: "Calendário",
    set_label: "Defina o nome do campo",

    date_ph: "Data no formato (dd-mm-yyyy)",

    mandatory: "Obrigatório",

    only_future_dates: "Apenas datas no futuro",
    only_past_dates: "Apenas datas no passado",

    from_date: "Data minima",
    to_date: "Data máxima",

};

export default pt;
