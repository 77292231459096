const DocumentActions = {
    DOCUMENT_DELETED: "DOCUMENT_DELETED",
    DOCUMENT_VALIDATED: "DOCUMENT_VALIDATED",
};

const AuthenticationActions = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    LOGIN_2FA: "LOGIN_2FA",
    IMPERSONATE_USER: "IMPERSONATE_USER",
    SELF_USER_UPDATE: "SELF_USER_UPDATE",
    SELF_PASSWORD_CHANGE: "SELF_PASSWORD_CHANGE",
    ACTIVATION_CODE_VALIDATED: "ACTIVATION_CODE_VALIDATED",
};

const RoleActions = {
    SYSTEM_ROLE_CREATED: "SYSTEM_ROLE_CREATED",
    SYSTEM_ROLE_EDITED: "SYSTEM_ROLE_EDITED",
    SYSTEM_ROLE_DELETED: "SYSTEM_ROLE_DELETED",
};


const UserActions = {
    USER_READ: "USER_READ",
    USER_CREATED: "USER_CREATED",
    USER_EDITED: "USER_EDITED",
    USER_DELETED: "USER_DELETED",
};

const EntityActions = {
    ENTITY_CREATED: "ENTITY_CREATED",
    ENTITY_EDITED: "ENTITY_EDITED",
    ENTITY_DELETED: "ENTITY_DELETED",
    OWN_ENTITY_EDITED: "OWN_ENTITY_EDITED",
};


const ConfigurationActions = {
    CONFIGURATION_EDITED: "CONFIGURATION_EDITED"
}

const HelpActions = {
    HELP_TOPIC_DELETED: "HELP_TOPIC_DELETED",
    HELP_TOPIC_EDITED: "HELP_TOPIC_EDITED",
}

const ObservationActions = {
    OBSERVATION_CREATED: "OBSERVATION_CREATED",
    OBSERVATION_DELETED: "OBSERVATION_DELETED",
}

const GeneratedReportActions = {
    REPORT_GENERATED: "REPORT_GENERATED",
    REPORT_SCHEDULE_CREATED: "REPORT_SCHEDULE_CREATED",
    REPORT_SCHEDULE_EDITED: "REPORT_SCHEDULE_EDITED",
    REPORT_SCHEDULE_DELETED: "REPORT_SCHEDULE_DELETED",
}

const ExecuteOperationActions = {
    OPERATION_RUN: "OPERATION_RUN",
    REPORT_SCHEDULE_CREATED: "OPERATION_SCHEDULE_CREATED",
    REPORT_SCHEDULE_EDITED: "OPERATION_SCHEDULE_EDITED",
    REPORT_SCHEDULE_DELETED: "OPERATION_SCHEDULE_DELETED",
}

const OperationTemplateActions = {
    REPORT_TEMPLATE_CREATED: "OPERATION_TEMPLATE_CREATED",
    REPORT_TEMPLATE_EDITED: "OPERATION_TEMPLATE_EDITED",
    REPORT_TEMPLATE_DELETED: "OPERATION_TEMPLATE_DELETED",
}


const ReportTemplateActions = {
    REPORT_TEMPLATE_CREATED: "REPORT_TEMPLATE_CREATED",
    REPORT_TEMPLATE_EDITED: "REPORT_TEMPLATE_EDITED",
    REPORT_TEMPLATE_DELETED: "REPORT_TEMPLATE_DELETED",
}

const TeacherActions = {
    TEACHER_CREATED: "TEACHER_CREATED",
    TEACHER_EDITED: "TEACHER_EDITED",
    TEACHER_DELETED: "TEACHER_DELETED",
}

const StudentActions = {
    STUDENT_CREATED: "STUDENT_CREATED",
    STUDENT_EDITED: "STUDENT_EDITED",
    STUDENT_DELETED: "STUDENT_DELETED",
}

const ContractModelActions = {
    CONTRACT_MODEL_CREATED: "CONTRACT_MODEL_CREATED",
    CONTRACT_MODEL_EDITED: "CONTRACT_MODEL_EDITED",
    CONTRACT_MODEL_DELETED: "CONTRACT_MODEL_DELETED",
}

const ContractActions = {
    CONTRACT_REFRESHED: "CONTRACT_REFRESHED",
    CONTRACT_SIGNED: "CONTRACT_SIGNED",
    CONTRACT_SIGNATURES_VALIDATED: "CONTRACT_SIGNATURES_VALIDATED",
    CONTRACT_ANNEX_SIGNATURES_VALIDATED: "CONTRACT_ANNEX_SIGNATURES_VALIDATED",
    CONTRACT_DELETED: "CONTRACT_DELETED",
}

const EquipmentActions = {
    EQUIPMENT_CREATED: "EQUIPMENT_CREATED",
    EQUIPMENT_EDITED: "EQUIPMENT_EDITED",
    EQUIPMENT_DELETED: "EQUIPMENT_DELETED",
}

const EquipmentProfileActions = {
    EQUIPMENT_PROFILE_CREATED: "EQUIPMENT_PROFILE_CREATED",
    EQUIPMENT_PROFILE_EDITED: "EQUIPMENT_PROFILE_EDITED",
    EQUIPMENT_PROFILE_DELETED: "EQUIPMENT_PROFILE_DELETED",
}

const EquipmentTypologyActions = {
    EQUIPMENT_TYPOLOGY_CREATED: "EQUIPMENT_TYPOLOGY_CREATED",
    EQUIPMENT_TYPOLOGY_EDITED: "EQUIPMENT_TYPOLOGY_EDITED",
    EQUIPMENT_TYPOLOGY_DELETED: "EQUIPMENT_TYPOLOGY_DELETED",
}

const EquipmentStatusActions = {
    EQUIPMENT_STATUS_CREATED: "EQUIPMENT_STATUS_CREATED",
    EQUIPMENT_STATUS_EDITED: "EQUIPMENT_STATUS_EDITED",
    EQUIPMENT_STATUS_DELETED: "EQUIPMENT_STATUS_DELETED",
}

const ProcedureActions = {
    PROCEDURE_CREATED: "PROCEDURE_CREATED",
    PROCEDURE_EDITED: "PROCEDURE_EDITED",
    PROCEDURE_DELETED: "PROCEDURE_DELETED",
}

const SchoolActions = {
    SCHOOL_CREATED: "SCHOOL_CREATED",
    SCHOOL_EDITED: "SCHOOL_EDITED",
    SCHOOL_DELETED: "SCHOOL_DELETED",
}


const SupplierActions = {
    SUPPLIER_CREATED: "SUPPLIER_CREATED",
    SUPPLIER_EDITED: "SUPPLIER_EDITED",
    SUPPLIER_DELETED: "SUPPLIER_DELETED"
}


const TicketCategoryActions = {
    TICKET_CATEGORY_CREATED: "TICKET_CATEGORY_CREATED",
    TICKET_CATEGORY_EDITED: "TICKET_CATEGORY_EDITED",
    TICKET_CATEGORY_DELETED: "TICKET_CATEGORY_DELETED",
}

const TicketSlaActions = {
    TICKET_SLA_CREATED: "TICKET_SLA_CREATED",
    TICKET_SLA_EDITED: "TICKET_SLA_EDITED",
    TICKET_SLA_DELETED: "TICKET_SLA_DELETED",
}

const EquipmentRefusalActions = {
    EQUIPMENT_REFUSAL_CREATED: "EQUIPMENT_REFUSAL_CREATED",
    EQUIPMENT_REFUSAL_REVOKED: "EQUIPMENT_REFUSAL_REVOKED",
}

const EquipmentRegistrationActions = {
    EQUIPMENT_REGISTRATION_VALIDATED: "EQUIPMENT_REGISTRATION_VALIDATED",
    EQUIPMENT_REGISTRATION_SUBMIT: "EQUIPMENT_REGISTRATION_SUBMIT"
}


const Actions = {
    ...AuthenticationActions,
    ...RoleActions,
    ...UserActions,
    ...EntityActions,
    ...ConfigurationActions,
    ...HelpActions,
    ...ObservationActions,
    ...ReportTemplateActions,
    ...GeneratedReportActions,
    ...OperationTemplateActions,
    ...ExecuteOperationActions,
    ...DocumentActions,
    ...TeacherActions,
    ...ContractModelActions,
    ...ContractActions,
    ...EquipmentActions,
    ...EquipmentProfileActions,
    ...EquipmentTypologyActions,
    ...EquipmentStatusActions,
    ...ProcedureActions,
    ...SchoolActions,
    ...SupplierActions,
    ...TicketCategoryActions,
    ...TicketSlaActions,
    ...StudentActions,
    ...EquipmentRefusalActions,
    ...EquipmentRegistrationActions,

}


export {
    AuthenticationActions,
    RoleActions,
    UserActions,
    EntityActions,
    ConfigurationActions,
    HelpActions,
    ObservationActions,
    Actions,
    ReportTemplateActions,
    GeneratedReportActions,
    ExecuteOperationActions,
    OperationTemplateActions,
    TeacherActions,
    DocumentActions,
    ContractModelActions,
    ContractActions,
    EquipmentActions,
    EquipmentProfileActions,
    EquipmentTypologyActions,
    EquipmentStatusActions,
    ProcedureActions,
    SchoolActions,
    SupplierActions,
    TicketCategoryActions,
    TicketSlaActions,
    StudentActions,
    EquipmentRefusalActions,
    EquipmentRegistrationActions,
}
