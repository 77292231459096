import locale from "./locales/locales";
import React, {useMemo} from "react";
import ListComponent from "./components/list/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import ListDashboardComponent from "app/support/components/list/ListDashboardComponent";

const List = (props) => {

    const {equipmentId, auditProcessId, nonComplianceId, ids, id, dashboard} = props;

    return <GenericListContainer
        {...props}
        id={"support" + id ?? equipmentId}
        searchPath={"/tickets/search"}
        module="support"
        locale={locale}
        queryParameters={useMemo(() => ({
            sortProperty: 'lastUpdateTimestamp',
            sortDirection: 'DESC',
            currentPage: 0,
            itemsPerPage: 10,
            equipmentId: equipmentId,
            ids: ids,
            auditProcessId: auditProcessId,
            nonComplianceId: nonComplianceId,
        }), [equipmentId, ids, auditProcessId, nonComplianceId])}>

        {dashboard
            ? <ListDashboardComponent/>
            : <ListComponent dashboard={dashboard}/>
        }
    </GenericListContainer>

}

export default List;
