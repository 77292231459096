import {Col, Row} from "react-bootstrap";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import React, {useEffect, useState} from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/locales/locales";
import FormToolbarComponent from "layout/modules/FormBuilder/components/editor/toolbar/FormToolbarComponent";
import useFormBuilder from "layout/modules/FormBuilder/utils/editor/useFormBuilder";
import EditorAreaComponent from "layout/modules/FormBuilder/components/editor/editor/EditorAreaComponent";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import PreviewComponent from "layout/modules/FormBuilder/components/editor/preview/PreviewComponent";
import {objectIsEqual} from "system/Objects/Objects";
import isFunction from "system/Utils/isFunction";

const FormEditorComponent = props => {

    const {data: defaultData, onChange} = props;
    const {t} = useTranslations("form-editor", locale);
    const {data, addComponent, delComponent, setComponentProps, setData} = useFormBuilder(defaultData);
    const [isPreviewVisible, setPreviewVisible] = useState(false);

    useEffect(
        () => {
            if (!objectIsEqual(defaultData, data) && isFunction(onChange)) {
                onChange(data)
            }
        },
        [data, defaultData, onChange]
    );

    return <Row className={"form-builder"}>

        {isPreviewVisible &&
        <PreviewComponent
            {...props}
            data={data}
            onClose={() => setPreviewVisible(false)}
        />
        }

        <Col xl={9}>
            <Box className={"editor"}>
                <BoxTitle>{t('editor')}</BoxTitle>
                <BoxOptions>

                    <ToolTip content={t("preview_form")}>
                        <Button className={classnames("btn btn-rounded-primary small", {"active": !isPreviewVisible})}
                                onClick={() => setPreviewVisible(!isPreviewVisible)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </Button>
                    </ToolTip>

                </BoxOptions>
                <EditorAreaComponent
                    {...props}
                    data={data}
                    onDeleteComponent={delComponent}
                    setData={setData}
                    onEditComponentProps={setComponentProps}/>

            </Box>
        </Col>

        <Col xl={3}>
            <Box className={"tools"}>
                <BoxTitle>{t('toolbar')}</BoxTitle>
                <FormToolbarComponent {...props} onAddComponent={addComponent}/>
            </Box>
        </Col>

    </Row>
}

export default FormEditorComponent;
