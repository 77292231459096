import React, {useCallback, useMemo} from "react";
import HelpHeaderExecuteComponent from "app/ruleTemplates/actions/action/components/help/HelpHeaderExecuteComponent";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import useTranslations from "system/Translations/UseTranslations";
import Loading from "layout/modules/Loading/Loading";
import useNewEquipmentProfile from "app/ruleTemplates/actions/replaceEquipment/utils/useNewEquipmentProfile";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Row from "react-bootstrap/Row";
import FormWarranty from "app/equipments/equipments/components/form/FormWarranty";
import localEquipments from "app/equipments/equipments/locales/locales";

const ExecutionComponent = props => {

    const {operationConfig, setData, config, data, errors} = props;

    const {t: tEquipments} = useTranslations('equipments', localEquipments);

    const equipmentId = useMemo(
        () => operationConfig?.equipmentIds[0],
        [operationConfig?.equipmentIds]
    );

    const newEquipmentProfile = useNewEquipmentProfile(equipmentId, config.typologyProfileMap);

    const handleSerialNumber = useCallback(
        (e) => setData(data => ({
            ...data,
            serialNumber: e.target.value,
        })),
        [setData]
    );

    if (!newEquipmentProfile) {
        return <Loading visible={true}/>;
    }

    return <div className={"rule-equipment-selection"}>

        <HelpHeaderExecuteComponent {...props}/>
        <GenericFormError t={tEquipments} errors={errors}/>

        <Row>
            <Col sm={12} lg={12} xl={12}>
                <FormGroup error={errors['serialNumber']} mandatory={true}>
                    <Form.Label>{newEquipmentProfile?.idLabel}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={tEquipments('serial_number_ph', {name: newEquipmentProfile.idLabel.toLowerCase()})}
                        value={getOrEmpty(data?.serialNumber)}
                        onChange={handleSerialNumber}
                    />

                    <Form.Text className="sub-error">
                        {tEquipments(errors['serialNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <FormWarranty errors={errors} equipment={data} setEquipment={setData}/>
        </Row>


    </div>

}

export default ExecutionComponent;
