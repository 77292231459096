import getAsArray from "system/Arrays/getArray";

const NeedsResolver = (data, t) => {

    if (getAsArray(data).length === 0)
        return data;

    return data.map(e => {
        const entry = {};
        Object.keys(e).forEach(k => {entry[t(k)] = e[k];});
        return entry;
    });

}

export default NeedsResolver;