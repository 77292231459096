import GenericComponent from "./types/GenericComponent";
import UserComponent from "./types/UserComponent";
import ReportTemplateComponent from "app/audit/components/Operation/types/ReportTemplateComponent";
import ReportScheduleComponent from "app/audit/components/Operation/types/ReportScheduleComponent";
import ReportGenerateComponent from "app/audit/components/Operation/types/ReportGenerateComponent";
import OperationTemplateComponent from "app/audit/components/Operation/types/OperationTemplateComponent";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import EquipmentProfileComponent from "app/audit/components/Operation/types/EquipmentProfileComponent";
import ContractModelComponent from "app/audit/components/Operation/types/ContractModelComponent";
import EquipmentComponent from "app/audit/components/Operation/types/EquipmentComponent";
import EquipmentRefusalComponent from "app/audit/components/Operation/types/EquipmentRefusalComponent";

const ShowComponent = (props) => {

    const {operation} = props;

    switch (operation.objectType) {
        case AuditObjectTypes.User:
            return <UserComponent {...props} />
        case AuditObjectTypes.ReportTemplate:
            return <ReportTemplateComponent {...props}/>
        case AuditObjectTypes.OperationTemplate:
            return <OperationTemplateComponent {...props}/>
        case AuditObjectTypes.ReportSchedule:
            return <ReportScheduleComponent {...props}/>
        case AuditObjectTypes.OperationSchedule:
            return <ReportScheduleComponent {...props} isOperation={true}/>
        case AuditObjectTypes.Operation:
            return <ReportGenerateComponent {...props} isOperation={true}/>
        case AuditObjectTypes.ContractModel:
            return <ContractModelComponent {...props}/>
        case AuditObjectTypes.EquipmentProfile:
            return <EquipmentProfileComponent {...props}/>
        case AuditObjectTypes.Report:
            return <ReportGenerateComponent {...props}/>
        case AuditObjectTypes.Equipment:
            return <EquipmentComponent {...props} />
        case AuditObjectTypes.EquipmentRefusal:
            return <EquipmentRefusalComponent {...props}/>
        default:
            return <GenericComponent {...props}/>

    }

}

export default ShowComponent;
