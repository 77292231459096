export const pt = {

    name: "Ecrã informativo",
    description: "Permite criar ecrãs informativos entre ações.",

    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é o conteúdo da página",

    new_status: "Novo estado do equipamento",




};

export default pt;
