import getAsArray from "system/Arrays/getArray";

const EquipmentStatusResolver = (data) => {

    if (getAsArray(data).length === 0)
        return data;

    const total = data.reduce((total, current) => total + current.total,0);
    return data.map((entry) => ({
        status: entry.status,
        total: entry.total,
        percentage: Math.round((entry.total / total) * 100 * 100) / 100
    })).sort((a, b) => b.total - a.total)

}

export default EquipmentStatusResolver;