import {Link} from "react-router-dom";
import {AuditObjectTypes} from "app/audit/utils/AuditObjectTypes";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {useTranslation} from "react-i18next";

const DescriptionComponent = (props) => {

    const {value} = props;
    const {t} = useTranslation('audit');

    const objectType = value.affectedObjectType ? value.affectedObjectType : value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.User:
            return <Link to={"/user/" + objectId} target={"_blank"}>
                {getOrEmpty(value.user?.name)}
            </Link>
        case AuditObjectTypes.UserRole:
            return getOrEmpty(value.role?.name);
        case AuditObjectTypes.EntityProfile:
            return getOrEmpty(value.entityProfile?.name);
        case AuditObjectTypes.Entity:
            return value.entity?.name
                ? <Link to={"/entity/" + objectId} target={"_blank"}>
                    {getOrEmpty(value.entity?.name)}
                </Link>
                : "";
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            return getOrEmpty(value.operation?.name);
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            return getOrEmpty(value.report?.name);
        case AuditObjectTypes.OperationTemplate:
        case AuditObjectTypes.ReportTemplate:
            return getOrEmpty(value.objectRaw?.name);
        case AuditObjectTypes.ContractModel:
            return <Link to={"/contract-model/" + objectId} target={"_blank"}>
                {getOrEmpty(value.contractModel?.name)}
            </Link>
        case AuditObjectTypes.Contract:
        case AuditObjectTypes.ContractAnnex:
            return <Link to={"/contract/" + objectId} target={"_blank"}>
                <>{t('Contract')} {objectId}</>
            </Link>
        case AuditObjectTypes.Procedure:
            return <Link to={"/procedure/" + objectId} target={"_blank"}>
                <>{getOrEmpty(value.objectRaw?.name)}</>
            </Link>
        case AuditObjectTypes.Equipment:
            return value.equipment?.serialNumber
                ? <Link to={"/equipment/" + objectId} target={"_blank"}>
                    {getOrEmpty(value.equipment?.serialNumber)}
                </Link>
                : <>{objectId}</>;
        case AuditObjectTypes.Student:
        case AuditObjectTypes.Teacher:
            return <Link to={value?.affectedObjectType === "Student" ? "/student/" + objectId : "/teacher/" + objectId}
                         target={"_blank"}>
                <>{getOrEmpty(value.beneficiary?.name)}</>
            </Link>
        default:
            return objectId ? objectId : "";

    }


}

export default DescriptionComponent;
