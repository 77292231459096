export const pt = {

    file_management: "Gestão de arquivo geral",

    users: "Todos os utilizadores",
    general_files: "Documentos gerais",


    filename: "Nome",
    date: "Data de criação",
    type: "Tipo",
    expiration_date: "Validade",

    user: "Utilizador",
    close: "Fechar",
    upload_file_label: "Para adicionar um documento largue o ficheiro nesta área ou clique no botão",
    validated: "Validado",
    validate: "Validar",
    invalidate: "Rejeitar",
    true: "Sim",
    false: "Não",

    no_results: "Não existe nenhum ficheiro no arquivo",

    DELETE_FILE_CONFIRM: "Confirmação",
    DELETE_FILE_CONFIRM_MSG: "Deseja realmente apagar o ficheiro {{name}}?",

    VALIDATE_CONFIRM_MSG: "Deseja realmente validar o ficheiro selecionado?",
    INVALIDATE_CONFIRM_MSG: "Deseja realmente rejeitar o ficheiro selecionado?",

    SUCCESS_UPLOAD_FILE: "Ficheiro enviado",
    SUCCESS_UPLOAD_FILE_MSG: "O ficheiro {{name}} foi enviado com sucesso",
    SUCCESS_UPLOAD_PENDING_VALIDATED_FILE_MSG: "O ficheiro {{name}} foi enviado com sucesso e encontra-se pendente de aprovação por parte de um administrador.",

    FAIL_UPLOAD_FILE: "Erro a enviar ficheiro ",
    FAIL_UPLOAD_FILE_MSG: "Ocorreu um erro ao enviar o ficheiro {{name}}. Por favor tente mais tarde.",

    FAIL_DELETE_FILE: "Erro a apagar",
    FAIL_DELETE_FILE_MSG: "Ocorreu um erro ao apagar o ficheiro {{name}}. Por favor tente mais tarde.",

    SUCCESS_DELETE_FILE: "Ficheiro apagado",
    SUCCESS_DELETE_FILE_MSG: "O ficheiro {{name}} foi apagado com sucesso",

    SUCCESS_VALIDATE: "Ficheiro validado",
    SUCCESS_VALIDATE_MSG: "O ficheiro {{name}} foi dado como validado com sucesso",
    SUCCESS_INVALIDATE_MSG: "O ficheiro {{name}} foi dado como REJEITADO com sucesso",

    ERROR_VALIDATE: "Erro ao validar",
    ERROR_VALIDATE_MSG: "Não foi possível validar o ficheiro. Por favor tente mais tarde",

    //Modal
    info_description: "Se pretender, pode acrescentar uma descrição e validade ao documento carregado.",
    file_info: "Informação do ficheiro",
    upload: "Enviar",
    cancel: "Cancelar",

    title: "Descrição",
    title_ph: "Um nome ou descrição opcional",
    file_date_ph: "Escreva ou escolha uma data (dd-mm-aaaa)",


    document_download: "Há um documento pronto que pode ser descarregado através do botão Download.<br/><br/>Alertamos que o ficheiro só pode ser descarregado durante 15 dias.",
    DOCUMENT_NOT_FOUND: "O Documento já se encontra <strong>expirado</strong> e por razões de segurança não é possível realizar o download novamente.<br/>Deve realizar um novo pedido de download do documento.",
    download: "Download",
    preview: "Visualizar",

    //types
    "application/pdf": "Documento",
    "image/jpeg": "Imagem",
    "image/png": "Imagem",
    "application/zip": "Documentos em ZIP",
};

export default pt;
