import React, {useCallback, useRef, useState} from 'react'
import Table from "react-bootstrap/Table"
import cloneWithoutKeys from "system/Objects/Objects";
import Loading from "layout/modules/Loading/Loading";
import useKeyboardNavigation from "system/Acessibility/useKeyboardNavigation";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/TableAdvanced/locales/locales";
import './assets/css/table-advanced.scss'

const TableAdvanced = (props) => {

    const {children, onSortClick, sort, order} = props;
    const [thead, tbody] = [children[0], children[1]];
    const {children: {props: {children: headers}}} = thead.props;

    const {t} = useTranslations('tableAdvanced', locale);

    const bodyRef = useRef(null);

    const [isFocus, setFocus] = useState(false);

    useKeyboardNavigation({
        element: bodyRef?.current,
        enable: isFocus,
        selector: 'tr',
        handleFocus: useCallback(
            (element) => {
                element.tabIndex = -1;
                element.focus();
            },
            []
        )
    });

    return <>
        <Loading visible={props.loading} localOnly={true}/>
        <div className="table-advanced">
            <Table {...cloneWithoutKeys(props, ['onSortClick', 'loading'])}>
                <thead>
                <tr>
                    {(Array.isArray(headers) ? headers : [headers]).map((child, index) => {

                            if (!child?.props) {
                                return null;
                            }

                            //Allow the use of userhaspermissions in tables
                            if (child.props.permission) {
                                let {sorted, id} = child.props.children.props;
                                return sorted ? React.cloneElement(child, {
                                    key: index,
                                    children: React.cloneElement(child.props.children, {
                                        onClick: () => onSortClick(id, (id === sort && order === "asc") ? "desc" : "asc"),
                                        sorted: (id === sort) ? order : "none"
                                    })
                                }) : child;
                            } else {
                                let {sorted, id} = child.props;
                                return sorted ? React.cloneElement(child, {
                                    key: index,
                                    onClick: () => onSortClick(id, (id === sort && order === "asc") ? "desc" : "asc"),
                                    sorted: (id === sort) ? order : "none"
                                }) : child;

                            }
                        }
                    )}
                </tr>
                </thead>
                {React.cloneElement(tbody, {
                    tabIndex: 0,
                    ref: bodyRef,
                    "aria-description": t('aria_description'),
                    onFocus: () => setFocus(true),
                    onBlur: () => setFocus(false)
                })}
            </Table>
        </div>
    </>
};


export default TableAdvanced;

