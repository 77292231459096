import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "layout/modules/Collapse/Collapse";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";

const GenericComponent = (props) => {

    const {operation} = props;

    const {t} = useTranslation('audit');

    if (!operation.objectRaw && !operation.changes )
        return <></>

    return <Collapse title={t('raw_objects')} isOpen={false}>
        <Row>
            {operation.objectRaw &&
            <Col md={6}>
                <Box>
                    <BoxTitle>{t('object')}</BoxTitle>
                    <pre>
                    {JSON.stringify(operation.objectRaw, null, 2)}
                </pre>
                </Box>
            </Col>
            }

            {operation.changes &&
            <Col md={6}>
                <Box>
                    <BoxTitle>{t('changes')}</BoxTitle>
                    <pre>
                    {JSON.stringify(operation.changes, null, 2)}
                </pre>
                </Box>
            </Col>
            }

        </Row>

    </Collapse>
}


export default GenericComponent;
