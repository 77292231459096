import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormComponent from "app/equipments/equipments/components/form/FormComponent";
import React, {useMemo} from "react";
import {getDiffsInErrorFormat} from "app/audit/utils/AuditDiff";
import {equipmentLogResolver} from "app/equipments/equipments/utils/equipmentResolver";
import {useGet} from "system/Rest/Rest";
import equipmentProfileResolver from "app/equipments/equipmentProfile/utils/equipmentProfileResolver";
import Loading from "layout/modules/Loading/Loading";

const EquipmentComponent = props => {

    const {operation} = props;
    const diffs = getDiffsInErrorFormat(operation.changes);

    const {data: profile} = useGet({
        path: "/equipment-profiles/" + operation.objectRaw?.profile,
        resolve: equipmentProfileResolver,
        localErrorOnly: true,
    });

    const equipmentObject = useMemo(() => ({
        ...equipmentLogResolver(operation.objectRaw),
        profile: {
            ...profile
        },
        procedureId: profile?.procedure?.id,
        procedure: {
            ...profile?.procedure
        }

    }), [profile, operation?.objectRaw]);


    return <div className={"versions"}>
        <Row>
            <Col lg={true}>
                {profile
                    ? <FormComponent
                        equipment={equipmentObject}
                        errors={diffs}
                        create={false}
                        compareMode={true}
                        viewMode={true}
                        loading={false}
                    />
                    : <Loading visible={true} localOnly={true}/>
                }
            </Col>

        </Row>

    </div>
}

export default EquipmentComponent;
