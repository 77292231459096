import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/beneficiaryNonCompliance/locales/locales";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";

const FormConfiguration = props => {

    const {setConfiguration, configuration} = props;
    const {t} = useTranslations("GenerateBeneficiaryNonComplianceAction", locale);


    return <Row className={"generate-beneficiary-non-compliance-action"}>

        <Col sm={12} lg={true} xl={true}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('title')}</Form.Label>
                <FormControl
                    type="text"
                    id="title"
                    placeholder={t('title_ph')}
                    value={getOrEmpty(configuration?.props?.title)}
                    onChange={useCallback(
                        e => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                title: e.target.value,
                            }
                        })), [setConfiguration])}
                />

            </FormGroup>
        </Col>


    </Row>

}

export default FormConfiguration;
