import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import NewMessageComponent from "app/support/components/form/NewMessageComponent";
import PanelComponent from "./PanelComponent";
import Box from "layout/modules/Box/Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import MessageComponent from "app/support/components/view/messages/MessageComponent";
import {useApiState} from "system/API/APIStateContext";
import Loading from "layout/modules/Loading/Loading";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {isLeftSide, isTicketLocked} from "app/support/utils/utils";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import "app/support/assets/css/view.scss";
import "app/support/assets/css/status.scss";
import "app/support/assets/css/priority.scss";
import AlertsComponent from "app/support/components/view/AlertsComponent";

const ViewComponent = props => {

    const {ticket, errors = {}, onSubmit, loading, sendingMessage} = props;
    const {t} = useTranslation("support");
    const ref = useRef(null);
    const {authentication} = useApiState();

    const chatRef = useRef();

    const [reply, setReply] = useState({
        attachments: [],
    });

    const handleReplySubmit = useCallback(
        (reply) => {
            onSubmit(reply);
            setReply({
                attachments: [],
            })
        },
        [onSubmit, setReply]
    );

    useEffect(() => {
        setTimeout(() => {
            chatRef.current.scroll({top: chatRef.current.scrollHeight, behavior: 'smooth'});
        }, 500);

    }, [ticket?.conversations?.length]);

    return <>
        <AlertsComponent ticket={ticket}/>
        <div className={"view-ticket"}>
            <Loading visible={loading}/>
            <div className={"ticket"}>
                <Box>
                    <BoxTitle>
                        {t('box_title', {id: ticket?.id, subject: ticket.title})}
                    </BoxTitle>

                    <div className={"ticket-chat"} ref={chatRef}>
                        <MessageComponent
                            leftSide={isLeftSide(ticket, authentication)}
                            attachments={ticket.attachments}
                            message={ticket.message}
                            user={ticket.creationUser?.name ?? ticket.creationUser?.email}
                            entity={ticket.creationUser?.entity}
                            timestamp={ticket.creationTimestamp}
                        />


                        {ticket.conversations.map((c, id) =>
                            <MessageComponent
                                key={id}
                                user={c.creationUser?.name ?? c.creationUser?.email}
                                entity={c.creationUser?.entity}
                                leftSide={isLeftSide(c, authentication)}
                                attachments={c.attachments}
                                message={c.message}
                                timestamp={c.creationTimestamp}
                            />
                        )}

                    </div>


                    {!isTicketLocked(ticket) &&
                    <div className={"new-message"}>
                        <Loading localOnly={true} visible={sendingMessage}/>
                        <NewMessageComponent
                            ref={ref}
                            request={reply}
                            rows={3}
                            setRequest={setReply}
                            id={"message"}
                            errors={errors}
                            showAttachmentLink={true}/>

                        <div className={"attachment-link"} {...generateOnClickAndEnter(() => ref?.current?.click())}>
                            <FontAwesomeIcon icon={faPaperclip}/>
                        </div>

                        <div className={"button-submit"}>
                            <Button variant="primary" onClick={() => handleReplySubmit(reply)}>
                                {t('send_message')}
                            </Button>
                        </div>

                    </div>
                    }
                </Box>


            </div>


            <PanelComponent {...props}/>

        </div>
    </>
}

export default ViewComponent;
