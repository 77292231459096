export const pt = {

    name: "Estado do equipamento",
    description: "Define um novo estado para o equipamento",

    form_description: "Define os equipamento(s) como <strong> {{name}}</strong><strong>.",
    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é o novo estado.",

    new_status: "Novo estado do equipamento",




};

export default pt;
