export const pt = {

    title: "Unidades orgânicas",
    create_title: "Criar unidade",

    //list
    name: "Nome",
    vat: "Nif",
    no_school_found: "Não existem resultados para apresentar",
    group: "Agrupamento",


    //Form
    schools: "Escolas",
    address: "Morada",
    director_name: "Nome do diretor",
    director_name_ph: "Introduza o nome do diretor da unidade orgânica",
    school_data: "Dados gerais",
    name_ph: "Indique o nome do agrupamento",
    vat_ph: "Indique o nif do agrupamento",
    sg_ph: "Escolha o agrupamento",
    typology: "Tipo",
    typology_ph: "Indique a tipologia da unidade orgânica",
    contacts: "Contactos",
    dgeec_code: "Código DGEEC",
    dre: "DRE",
    nut2: "NUT II",
    nut3: "NUT III",
    generic_ph: "Por favor introduza o valor",
    status : {
        label: "Estado",
        ACTIVE: "Ativa",
        INACTIVE: "Inativa",
    },


    //AE
    AE: "Agrupamento de escolas",
    AE_SCHOOL: "Escola de agrupamento",
    ENA: "Escola não agrupada",

    //View
    general: "Dados gerais",
    contracts: "Contratos",
    teachers: "Docentes",

    options: "Opções / Operações",
    edit: "Editar dados",

    //Errors
    MISSING_NAME: "Por favor indique o nome da escola",
    MISSING_VAT_NUMBER: "Por favor indique o nif da escola",
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",
    INVALID_STATUS: "Por favor selecione um estado",
    MISSING_STATUS: "Por favor selecione um estado",
    INVALID_SCHOOL_PARENT_ID: "A escola ou agrupamento não é válido",
    MISSING_DRE: "Deve indicar um valor válido",
    MISSING_DGEEC: "Deve indicar um valor válido",

    DELETE_SCHOOL_CONFIRM: "Apagar escola",
    DELETE_SCHOOL_CONFIRM_MSG: "Deseja realmente apagar a escola \"<strong>{{name}}</strong>\"?",

    //List Success Creation and Update
    UPDATE_SUCCESS: "Escola atualizada",
    UPDATE_SUCCESS_MSG: "A escola \"{{name}}\" foi atualizada com sucesso!",

    //List Success Creation and Update
    CREATE_SUCCESS: "Nova escola criada",
    CREATE_SUCCESS_MSG: "A escola \"{{name}}\" foi criada com sucesso!",

    DELETE_SUCCESS: "Escola Apagada",
    DELETE_SUCCESS_MSG: "A escola \"{{name}}\" foi apagada com sucesso!",

    DELETE_FAILED: "Impossível apagar escola ",

    create: "Criar",
    save_changes: "Guardar",
    cancel: "Cancelar",

    item_selected_one: "{{count}} escola selecionado",
    item_selected_other: "{{count}} escolas selecionadas",

};

export default pt;
