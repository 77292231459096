const teacherResolver = (data) => ({
    ...data,
    parishId: data.parish?.id,
    schoolId: data.school?.id,
    bondStatusId: data.bondStatus?.id,
    recruitmentGroupId: data.recruitmentGroup?.id

});

export default teacherResolver;
