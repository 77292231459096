import {useEffect, useState} from "react";

const useKeyPress = (targetKey, disabled = false) => {
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        const downHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(true);
            }
        };

        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };

        if (!disabled) {
            window.addEventListener('keydown', downHandler);
            window.addEventListener('keyup', upHandler);
        }


        return () => {
            if (!disabled) {
                window.removeEventListener('keydown', downHandler);
                window.removeEventListener('keyup', upHandler);
            }
        };
    }, [targetKey, disabled]);

    return keyPressed;
};

export default useKeyPress;
