import TableMenuEntryOptions from "layout/modules/TableAdvanced/components/TableEntryMenuOptions";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownHeader from "layout/modules/MenuDropdown/components/MenuDropdownHeader";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListUl} from "@fortawesome/free-solid-svg-icons";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

const ListSavedSearches = props => {

    const {t} = useTranslation('searchArea');
    const {savedFilters, onSubmit} = props;

    return  <TableMenuEntryOptions placement={"bottom"} label={<ListIconButton t={t}/>} ariaLabel={t('saved_filters')}>
        <MenuDropdownContent>
            <MenuDropdownHeader>
                {t('saved_filters')}
            </MenuDropdownHeader>

            {Object.values(savedFilters)?.map((filter, idx) =>
                <MenuDropdownOption key={idx + "-" + filter?.name }>
                    <div onClick={() => {
                        onSubmit(filter.value);
                    } }>
                        {filter.name}
                    </div>
                </MenuDropdownOption>
            )}

        </MenuDropdownContent>
    </TableMenuEntryOptions>


}

const ListIconButton = props => {

    const {t} = props;

    return <ToolTip content={t('saved_filters')}>
        <Button className={classnames("btn btn-rounded-primary filter-management")} tabIndex={-1}>
            <FontAwesomeIcon icon={faListUl}/>
        </Button>
    </ToolTip>
}

export default ListSavedSearches;
