export const en = {


    role_ph: "Select a role",
    role_no: "No role found",
    role_loading: "Searching for roles..",

    entity_ph: "Select an Entity",
    entity_loading: "Searching entities",
    entity_no: "No entities found!",

    entity_profile_ph: "Select an Entity Profile",
    entity_profile_loading: "Search entity profiles",
    entity_profile_no: "No entity profiles found!",

    entity_vat_no: "No results. Please type the complete VAT Number",
    entity_vat_loading: "Searching VAT Number...",
    entity_vat_ph: "Please type a VAT Number",

    generic_no: "No Results found.",
    generic_loading: "Searching...",
    generic_ph: "Please Select an option",



};

export default en;
