import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import locale from "layout/modules/FormBuilder/modules/help/locales/locales";
import generateToolBarEntry from "layout/modules/FormBuilder/modules/module/utils/generateToolBarEntry";
import FormModule from 'layout/modules/FormBuilder/modules/module/FormModule'
import EditorComponent from "layout/modules/FormBuilder/modules/help/components/EditorComponent";
import ConfigComponent from "layout/modules/FormBuilder/modules/help/components/ConfigComponent";
import FormComponent from "layout/modules/FormBuilder/modules/help/components/FormComponent";
import "layout/modules/FormBuilder/modules/help/assets/css/help.scss";

class HelpModule extends FormModule {

    static getName = () => "HelpModule";
    static getTranslations = () => locale;
    static getToolBarEntry = (t) => generateToolBarEntry(faQuestionCircle, t('name'));

    static renderEditor = (props) => <EditorComponent id={props.id} {...props.props}/>
    static renderConfig = (props) => <ConfigComponent  {...props} />
    static renderForm = (props) => <FormComponent {...props} />


    static getDefaultProps = () => ({
        title: "",
        text: "",
    });
}

export default HelpModule;
