import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import useTranslationList from "app/users/utils/translations";
import useTimeZoneList from "app/users/utils/timezones";
import useHandleFormSubmit from "system/Forms/FormHandling";
import AdvancedForm from "modules/Forms/NotSaved/AdvancedForm";
import Box from "layout/modules/Box/Box";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import Loading from "layout/modules/Loading/Loading";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";

const GeneralSettingsComponent = (props) => {

    const {user, loading, onSubmit, errors, onEditModeChange} = props
    const {t} = useTranslation('personalArea');

    const [readOnly, setReadOnly] = useState(true);

    const translations = useTranslationList();
    const timeZones = useTimeZoneList();

    const handleOwnUserSubmit = useHandleFormSubmit(onSubmit);

    useEffect(() => {
        if (onEditModeChange) {
            onEditModeChange(!readOnly);
        }
        // eslint-disable-next-line
    }, [readOnly]);


    return <div className={classnames({"view-mode": readOnly})}>
        <AdvancedForm readOnly={readOnly} setReadOnly={setReadOnly} loading={loading} onSubmit={handleOwnUserSubmit}>
            <Box className={"mandatory box-without-title"}>

                <BoxOptions>
                    <div className={classnames("btn btn-rounded-danger small", {"active": !readOnly})}
                         onClick={() => setReadOnly(!readOnly)}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </div>
                </BoxOptions>

                <Loading visible={loading}/>
                <GenericFormError t={t} errors={errors}/>

                <Row>
                    <Col sm={12} lg={true}>
                        <FormGroup error={errors['name']}>
                            <Form.Label>{t('name')}</Form.Label>
                            <FormControl
                                type="text"
                                id="name"
                                disabled={readOnly}
                                placeholder={t('name_ph')}
                                defaultValue={user?.name}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['name'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    {user?.email &&
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['email']}>
                            <Form.Label>{t('email')}</Form.Label>
                            <FormControl
                                type="text"
                                id="email"
                                defaultValue={user?.email}
                                disabled={true}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['email'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                    }
                </Row>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['language']}>
                            <Form.Label>{t('language')}</Form.Label>
                            <Select
                                name="language"
                                id="language"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('language_ph')}
                                noOptionsMessage={useLabelCallback(null, t('language_no'))}
                                defaultValue={translations.find((el) => el.value === user?.language)}
                                key={user?.language}
                                options={translations}
                                isClearable={true}
                                isDisabled={readOnly}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['language'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>

                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['timeZone']}>
                            <Form.Label>{t('timezone')}</Form.Label>
                            <Select
                                name="timeZone"
                                id="timeZone"
                                classNamePrefix="rs"
                                className="rs"
                                placeholder={t('timezone_ph')}
                                noOptionsMessage={useLabelCallback(null, t('timezone_no'))}
                                options={timeZones}
                                defaultValue={timeZones.find((el) => el.value === user?.timeZone)}
                                key={user?.timeZone}
                                isDisabled={readOnly}
                                isClearable={true}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['timeZone'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup error={errors['receiveNotifications']}>
                            <Form.Label>{t('receive_notifications')}</Form.Label>
                            <BooleanDropdown
                                id={"receiveNotifications"}
                                defaultValue={user.receiveNotifications}
                                isDisabled={readOnly}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['receiveNotifications'])}
                            </Form.Text>
                        </FormGroup>

                    </Col>
                </Row>

            </Box>

            {!readOnly &&
            <Row>
                <Col className="text-end">
                    <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                        {t("cancel")}
                    </Button>

                    <Button variant="primary" type="submit">
                        {t("save_changes")}
                    </Button>
                </Col>
            </Row>
            }
        </AdvancedForm>
    </div>
}

export default GeneralSettingsComponent;
