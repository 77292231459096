import React, {useMemo} from "react";
import getRandomString from "system/Randoom/Random";
import {isString} from "system/Utils/isString";

const useCloneChildrenWithAriaAndProps = (children, props) => {

    const id = useMemo(() => getRandomString(5), []);
    const {error, required, ...otherProps} = props;

    return useMemo(
        () => {
            let label = "";

            return React.Children.map(children, c => {

                let extraProps = {};

                    if (React.isValidElement(c)) {
                        //Detect label
                        if (c.props?.children && isString(c.props?.children)) {
                            if (label !== c.props?.children) {
                                label = c.props?.children;
                                extraProps = {
                                    id: "label-" + id,
                                }
                            }

                        } else if (c.props?.placeholder) {
                            extraProps = {
                                "aria-label": label,
                                "aria-errormessage": "error-" + id,
                                "aria-invalid": error,
                                "aria-required": required
                            }
                        }

                        if (c.props?.className?.includes("sub-error")) {
                            extraProps = {
                                id: "error-" + id,
                            }
                        }

                        return React.cloneElement(c, {
                            ...otherProps,
                            ...extraProps
                        });
                    }
                    return c;
                }
            )
        },
        [children, error, required, otherProps,id]
    );


};

export default useCloneChildrenWithAriaAndProps;
