import useTranslations from "system/Translations/UseTranslations";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import locale from "app/ruleTemplates/actions/equipmentSelection/locales/locales";
import EquipmentTypologyDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/TypologyDropdown";
import {generateRandomId} from "@openreplay/tracker/lib/utils";
import EquipmentProfileDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentProfileDropdown";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";

const RequiredEquipmentType = React.forwardRef((props, ref) => {

    const {t} = useTranslations("EquipmentSelectionRuleAction", locale);
    const {entry: equipmentType, errors = {}, readOnly = false, onChange, onDelete, globalError} = props;

    return <Row>
        <Col xs={12} sm={6} lg={6}>
            <FormGroup error={errors['typologyId'] || globalError} mandatory={true}>
                <Form.Label>{t('typology')}</Form.Label>
                <EquipmentTypologyDropdown
                    key={!equipmentType?.typologyId ? generateRandomId(10) : undefined}
                    value={getOrNull(equipmentType?.typologyId)}
                    isClearable={false}
                    isDisabled={readOnly}
                    onChange={(value) => onChange({
                        ...equipmentType,
                        typologyId: value,
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['typology'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={12} sm={6} lg={6}>
            <FormGroup error={errors['profileIds'] || globalError} mandatory={false}>
                <Form.Label>{t('profile')}</Form.Label>
                <EquipmentProfileDropdown
                    isMulti={true}
                    value={getOrNull(equipmentType?.profileIds)}
                    typologyIds={equipmentType?.typologyId}
                    isDisabled={readOnly || !equipmentType?.typologyId}
                    onChange={(value) => onChange({
                        ...equipmentType,
                        profileIds: value,
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['profileIds'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={4} lg={4}>
            <FormGroup error={errors['mandatory'] || globalError} mandatory={true}>
                <Form.Label>{t('mandatory')}</Form.Label>
                <BooleanDropdown
                    value={getOrNull(equipmentType?.mandatory)}
                    isDisabled={readOnly || !equipmentType?.typologyId}
                    onChange={(value) => onChange({
                        ...equipmentType,
                        mandatory: value,
                    })}
                />

                <Form.Text className="sub-error">
                    {t(errors['mandatory'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={true} lg={true}>
            <FormGroup error={errors['description'] || globalError} mandatory={false}>
                <Form.Label>{t('entry_description')}</Form.Label>
                <FormControl
                    type="text"
                    ref={ref}
                    autoComplete="chrome-off"
                    placeholder={t('entry_description_ph')}
                    value={getOrEmpty(equipmentType?.description)}
                    disabled={readOnly || !equipmentType?.typologyId}
                    ignoresubmit={"true"}
                    onChange={(e) => onChange({
                        ...equipmentType,
                        description: e.target.value,
                    })}
                />
                <Form.Text className="sub-error">
                    {t(errors['description'])}
                </Form.Text>
            </FormGroup>
        </Col>


        {(onDelete && !readOnly) &&
        <Col xs={7} sm={2} lg={1} className="align-self-center text-end">
            <div className={"btn btn-rounded-danger ms-auto"} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </div>
        </Col>
        }

    </Row>
});

export default RequiredEquipmentType;
