import Tabs from "layout/modules/Tabs/Tabs";
import Tab from "layout/modules/Tabs/Tab";
import ExecuteDetailComponent from "app/ruleTemplates/components/show/ExecuteDetailComponent";
import getAsArray from "system/Arrays/getArray";
import BeneficiaryTabComponent from "app/ruleTemplates/components/show/beneficiaries/BeneficiaryTabComponent";
import EquipmentTabComponent from "app/ruleTemplates/components/show/equipments/EquipmentTabComponent";
import {getActionFromConfig} from "app/ruleTemplates/utils/config/getActionFromConfig";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/locale/locales";
import RuleExecutionStatus from "app/ruleTemplates/utils/types/RuleExecutionStatus";

const ShowExecutionComponent = props => {

    const {t} = useTranslations('rule-template', locale);

    const {id, execution, tabVariant} = props;
    const {template} = execution;

    const currentStep = execution.status === RuleExecutionStatus.DONE
        ? execution?.currentStep + 1
        : execution?.currentStep;

    return <Tabs id={"operation" + id} defaultActiveKey={"status"} unmountOnExit={true} mountOnEnter={true} variant={tabVariant}>
        <Tab eventKey="status" title={t('execution_detail')}>
            <ExecuteDetailComponent {...props}/>
        </Tab>

        {getAsArray(execution.beneficiaries).length > 0 &&
            <Tab eventKey="beneficiaries" title={t('beneficiaries')}>
                <BeneficiaryTabComponent beneficiaries={execution.beneficiaries}/>
            </Tab>}

        {getAsArray(execution.equipments).length > 0 && <Tab eventKey="equipments" title={t('equipments')}>
            <EquipmentTabComponent {...props} equipments={execution.equipments}/>
        </Tab>}

        {template.actions.map((config, p) => {
            const action = getActionFromConfig(config);

            if (action.hasViewMode && p < currentStep) {
                return <Tab eventKey={action.getName()}
                            title={t(action.getName())}
                            key={action.getName() + "-" + p}>

                    <div className={"rule-view-tab-content"}>
                        {action.renderView({
                            config: config.props, data: execution.props[p], result: execution.stepResults[p],
                        })}
                    </div>
                </Tab>
            }

            return null;
        })}
    </Tabs>
}

export default ShowExecutionComponent;
