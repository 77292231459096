import React, {useCallback, useState} from "react";
import CheckComponent from "layout/modules/Forms/CheckBox/components/CheckComponent";
import RadioComponent from "layout/modules/Forms/CheckBox/components/RadioComponent";

import "./checkbox.scss";

const CheckBox = (props) => {

    const {value, defaultValue, disabled, radio, onChange} = props;
    const [storedValue, setStoredValue] = useState(defaultValue);

    const isControlled = value !== undefined;
    const currentValue = isControlled ? value : storedValue;

    const handleChange = useCallback((e) => {

        e.stopPropagation();

        if (disabled)
            return;

        if (!isControlled)
            setStoredValue(v => !v)

        if (onChange)
            onChange(!currentValue);

    }, [disabled, onChange, isControlled, currentValue]);


    if (radio)
        return <RadioComponent {...props} value={currentValue} onChange={handleChange}/>
    else
        return <CheckComponent {...props} value={currentValue} onChange={handleChange}/>

}

export default CheckBox;
