import React, {Children, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import locale from "locales/locales";
import {useLocation} from "react-router";
import useDebounce from "system/System/useDebounce";
import useTranslations from "system/Translations/UseTranslations";
import {useIsMobile} from "system/Window/Mobile";
import useMultipleMenuAccessibility from "layout/modules/BaseLayout/Menu/utils/useMultipleMenuAccessibility";

const MultipleMenuItemComponent = (props) => {

    const {
        isActive,
        forceClose,
        isSelected,
        setActive,
        className,
        children,
        icon,
        label,
        currentActive
    } = props;

    const {t} = useTranslations('global', locale);

    const [isOver, setOver] = useState(false);
    const setOverDebounced = useDebounce(setOver, 300);
    const ref = useRef();

    const location = useLocation();
    const isMobile = useIsMobile();

    const totalChildren = Children.count(children);

    useMultipleMenuAccessibility({isOver, ref});

    const handleMouseHover = (isOver) => {

        if (isMobile)
            return;

        setOverDebounced(isOver);
        if (isOver && currentActive && !isActive) {
            setOver(true);
        }
    }

    useEffect(() => setOver(false), [location.pathname, setOver]);

    useEffect(() => {
        if (forceClose)
            setOver(false);
    }, [forceClose, setOver]);


    return <>
        <div className={classnames("main-menu-multiple-item", {active: isActive})}>
            <div
                tabIndex={-1}
                aria-haspopup="true"
                role={"menuitem"}
                className={classnames("main-menu-item", className, {
                    "selected": isSelected,
                    "hover": isOver,
                })}

                onMouseOut={() => {
                    handleMouseHover(false)
                }}

                onBlur={() => {
                    handleMouseHover(false)
                }}

                onMouseOver={() => {
                    handleMouseHover(true);
                    if (!isMobile)
                        setActive(true);
                }}

                onFocus={
                    () => {
                        handleMouseHover(true)
                    }
                }

                onClick={() => {
                    if (isMobile) {
                        setActive(true)
                    }
                }}>

                <FontAwesomeIcon icon={icon}/>
                <div className={"label"}>
                    {label}
                </div>
                <div className={"item-go"}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </div>

            </div>

            <div className={classnames("menu-lateral", {'visible': isOver, "force-close": forceClose})}>
                <div className={classnames("menu-lateral-container", {
                    "small": totalChildren < 3
                })}

                     onFocus={() => handleMouseHover(true)}
                     onMouseEnter={() => handleMouseHover(true)}
                     onMouseLeave={() => handleMouseHover(false)}
                     onBlur={() => handleMouseHover(false)}
                >

                    <div className={"menu-lateral-title"}>{label}</div>
                    <div className={"menu-lateral-items"} ref={ref}>{children}</div>

                    <div className={"sub-menu-main-item item-back"} onClick={() => setActive(false)}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <div className={"label"}>
                            {t('go_back')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

}

export default MultipleMenuItemComponent;
