import useTranslations from "system/Translations/UseTranslations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useMemo} from "react";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import "layout/modules/FormBuilder/assets/css/toolbar-entry.scss";
import getAsArray from "system/Arrays/getArray";

const FormToolbarEntryComponent = props => {

    const {onAddComponent, module} = props;
    const {t} = useTranslations(module.getName(), module.getTranslations())

    const moduleMenuData = useMemo(
        () => module.getToolBarEntry(t),
        [t, module]
    );

    return getAsArray(moduleMenuData).map((entry, id) =>
        <div
            key={module.getName() + "-" + id}
            className={"form-toolbar-entry"}
            {...generateOnClickAndEnter(() => onAddComponent(module, entry.subModule))}>
            <div className={"icon"}>
                <FontAwesomeIcon icon={entry.icon}/>
            </div>
            <div className={"label"}>
                {entry.label}
            </div>
        </div>
    );
}

export default FormToolbarEntryComponent;
