import Form from "react-bootstrap/Form";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import React, {useCallback, useMemo} from "react";
import Col from "react-bootstrap/Col";
import {getOrNull} from "system/Objects/ObjectParameters";
import Select from "react-select";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/dropdown/locales/locales";
import {getSelectedOptions, getValuesArrayFromObjectsArray} from "layout/modules/Forms/Dropdowns/utils/utils";

const EditorComponent = props => {

    const {id, setData, data, configuration, readOnly, errorMessage} = props;

    const {t} = useTranslations('FormDropdownModule', locale);
    const value = data[id];

    const noOptionsMessage = useLabelCallback(props.noOptionsMessage, t('no_results'));
    const loadingMessage = useLabelCallback(props.loadingMessage, t('loading_message'));

    const selectOptions = useMemo(
        () => configuration?.options.map((option) => ({
            value: option,
            label: option,
        })),
        [configuration?.options]
    );

    const selectedOptions = useMemo(
        () => getSelectedOptions(value, selectOptions, configuration?.isMulti, true),
        [selectOptions, value, configuration?.isMulti]
    );



    return <Col lg={6} sm={12}>
        <FormGroup mandatory={configuration.mandatory} error={errorMessage}>
            <Form.Label>{configuration.label}</Form.Label>
            <Select
                noOptionsMessage={noOptionsMessage}
                loadingMessage={loadingMessage}
                isDisabled={readOnly}
                isMulti={configuration?.isMulti}
                value={getOrNull(selectedOptions)}
                options={selectOptions}
                classNamePrefix={"rs"}
                className={"rs"}
                placeholder={configuration?.placeholder}
                onChange={useCallback(
                    (value) => setData(data => ({
                        ...data,
                        [id]: getValuesArrayFromObjectsArray(value, configuration?.isMulti),
                    })),
                    [setData, id, configuration?.isMulti]
                )}
            />

            <Form.Text className="sub-error">
                {t(errorMessage)}
            </Form.Text>

        </FormGroup>
    </Col>

}

export default EditorComponent;
