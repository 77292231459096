import React from 'react'
import locale from './locales/locales'
import useTranslations from "system/Translations/UseTranslations";
import Title from "layout/modules/BaseLayout/ContentArea/components/Title";
import AlertsContainer from "app/home/containers/AlertsContainer";
import "./assets/css/dashboard.scss"
import SearchContainer from "app/home/containers/SearchContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NotificationComponent from "app/home/components/NotificationComponent";
import SupportComponent from "app/home/components/SupportComponent";
import EquipmentChartComponent from "app/home/components/charts/equipment/EquipmentChartComponent";
import BeneficiaryChartComponent from "app/home/components/charts/beneficiary/BeneficiaryChartComponent";
import ContractChartComponent from "app/home/components/charts/contracts/ContractChartComponent";
import {faChartPie, faComment} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NeedsChartComponent from "app/home/components/charts/needs/NeedsChartComponent";
import NonComplianceChartComponent from "app/home/components/charts/nonCompliances/NonComplianceChartComponent";
import AuditChartComponent from "app/home/components/charts/audit/AuditChartComponent";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {Tab, Tabs} from "react-bootstrap";

const Home = (props) => {

    const {t} = useTranslations('dashboard', locale);


    return <div /** className={"d-none"} */>
        <Title title={t('title_dashboard')} breadcrumbs={[{href: "/" + module, label: t('title_dashboard')}]}/>

        <div className={"dashboard"}>
            <AlertsContainer/>

            <UserHasPermission any={true} permission={['READ_EQUIPMENT','READ_BENEFICIARY']}>
                <SearchContainer {...props}/>
            </UserHasPermission>

            <Tabs id="tab-dashboard" unmountOnExit={false} mountOnEnter={true}>
                <Tab eventKey="notifications" title={<FontAwesomeIcon icon={faComment}/>}>
                    <Row>
                        <UserHasPermission permission={'USE_TICKETS'}>
                            <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={8} className={"dashboard-grid"}>
                                <SupportComponent {...props}/>
                            </Col>
                        </UserHasPermission>

                        <Col sm={12} xs={12} md={12} lg={12} xl={true} xxl={true} className={"dashboard-grid"}>
                            <NotificationComponent {...props}/>
                        </Col>
                    </Row>

                </Tab>
                <Tab eventKey="statistics" title={<FontAwesomeIcon icon={faChartPie}/>}>
                    <Row>



                        <UserHasPermission permission={["READ_EQUIPMENT", "READ_BENEFICIARY"]}>
                            <Col xxl={8} lg={12} md={12} sm={12}>
                                <BeneficiaryChartComponent/>
                            </Col>
                        </UserHasPermission>

                        <UserHasPermission permission={["READ_CONTRACT", "READ_BENEFICIARY"]}>
                            <Col xxl={4} lg={12} md={12} sm={12}>
                                <ContractChartComponent/>
                            </Col>
                        </UserHasPermission>

                        <UserHasPermission permission={"READ_EQUIPMENT"}>
                            <Col xs={12} md={12} sm={12}>
                                <EquipmentChartComponent/>
                            </Col>
                        </UserHasPermission>

                        <UserHasPermission permission={["READ_EQUIPMENT", "READ_BENEFICIARY"]}>
                            <Col xs={12} md={6} sm={12}>
                                <NeedsChartComponent/>
                            </Col>
                        </UserHasPermission>

                        <UserHasPermission permission={"READ_NON_COMPLIANCE"}>
                            <Col xs={12} md={true} sm={12}>
                                <NonComplianceChartComponent/>
                            </Col>
                        </UserHasPermission>


                        <UserHasPermission permission={"READ_AUDIT_PROCESS"}>
                            <Col xs={12} md={12} sm={12}>
                                <AuditChartComponent/>
                            </Col>
                        </UserHasPermission>


                    </Row>


                </Tab>
            </Tabs>
        </div>

    </div>
}

export default Home;
