import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faHeadset} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/supportTicket/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/supportTicket/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/supportTicket/components/FormEditor";
import RenderView from "app/ruleTemplates/actions/supportTicket/components/RenderView";


class SupportTicketRuleAction extends RuleEngineAction {

    static hasViewMode = true;
    static type = RuleActionType.SILENT
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faHeadset, t('name'), t('description'));

    static getName = () => "SupportTicketAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>
    static renderView = props => <RenderView {...props}/>

    static isAllowed = (config, position) => true;

}

export default SupportTicketRuleAction;
