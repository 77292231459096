import {useMemo} from "react";
import {useGet} from "system/Rest/Rest";
import equipmentProfileResolver from "app/equipments/equipmentProfile/utils/equipmentProfileResolver";
import BeneficiaryType from "types/BeneficiaryType";
import studentResolver from "app/beneficiaries/students/utils/StudentResolver";
import teacherResolver from "app/beneficiaries/teachers/utils/TeacherResolver";

const useEquipmentWithDetails = (equipment, ignoreBeneficiary = false) => {


    const {data: profile, loading: loadingProfile} = useGet({
        path: "/equipment-profiles/" + equipment?.profile?.id,
        lazy: !equipment?.profile?.id,
        resolve: equipmentProfileResolver,
    });


    const {data: beneficiary, loading: loadingBeneficiary} = useGet({
        lazy: !equipment?.beneficiaryId || ignoreBeneficiary,
        path: useMemo(
            () => equipment?.beneficiary?.type === BeneficiaryType.STUDENT
                ? "students/" + equipment?.beneficiaryId
                : "teachers/" + equipment?.beneficiaryId,
            [equipment?.beneficiaryId, equipment?.beneficiary?.type]
        ),
        resolve: equipment?.beneficiary?.type === BeneficiaryType.STUDENT
            ? studentResolver
            : teacherResolver
    });

    const equipmentWithDetails = useMemo(
        () => ({
            ...equipment,
            profile: {
                ...equipment?.profile,
                ...profile
            },
            beneficiary: {
                ...equipment?.beneficiary,
                ...beneficiary,
            },
            procedureId: profile?.procedure?.id,
            procedure: {
                ...profile?.procedure
            }
        }),
        [equipment, profile, beneficiary]
    );

    const isBeneficiaryReady = (equipment?.beneficiaryId && !!beneficiary) || !equipment?.beneficiaryId || ignoreBeneficiary;

    return {
        isReady: !!profile && isBeneficiaryReady ,
        loadingDetails: loadingProfile || loadingBeneficiary,
        equipmentWithDetails: equipmentWithDetails,
    }


}

export default useEquipmentWithDetails;
