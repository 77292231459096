import {useClickedOutsideEffect} from "system/Window/HandleClickOutside";
import {useCallback} from "react";

const useHandleMouseGestures = props => {

    const {isVisible, menuRef, setVisible, onChange, disabled, target} = props;

    useClickedOutsideEffect(target, useCallback((e) => {
            if (isVisible) {
                if (!menuRef.current.contains(e.target)) {
                    setVisible(false);
                    if (onChange) onChange(!isVisible);
                }

            }
        }, [setVisible, isVisible, onChange, menuRef])
    );


    return useCallback(
        (e) => {
            if (onChange) onChange(!isVisible);
            setVisible(!disabled && !isVisible);

            e.stopPropagation()
        },
        [onChange, setVisible, disabled, isVisible]
    );
}

export default useHandleMouseGestures;
