import FormRender from "layout/modules/FormBuilder/components/editor/execute/FormRender";
import formModules from "app/ruleTemplates/actions/forms/config";
import React, {useCallback} from "react";
import useFormFiles from "app/ruleTemplates/actions/forms/utils/useFormFiles";
import Loading from "layout/modules/Loading/Loading";
import {useMutate} from "system/Rest/Rest";
import Box from "layout/modules/Box/Box";

const RenderView = props => {

    const {config: {formModel}, data} = props;

    const [dataWithFiles, loadingFiles] = useFormFiles({
        model: formModel,
        data: data,
    });

    const {mutate: downloadFile} = useMutate({
        verb: "GET",
        path: useCallback(({id}) => `/documents/${id}`, []),
        localErrorOnly: true,
    });

    return <Box>
        <div className={"form-render-view view-mode"}>
            <Loading visible={loadingFiles}/>
            <FormRender
                modules={formModules}
                data={formModel}
                formData={dataWithFiles}
                readOnly={true}
                getFileContent={useCallback(
                    (id) => downloadFile(undefined, {pathParams: {id: id}}),
                    [downloadFile]
                )}
            />

        </div>
    </Box>
}

export default RenderView;
