import Select from "react-select";
import React, {useCallback, useMemo} from "react";
import locale from "layout/modules/Forms/Dropdowns/EnumDropdown/locales/locales";

import useTranslations from "system/Translations/UseTranslations";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import {getNut, normalizeNutKey} from "layout/modules/Forms/Dropdowns/NutDropdown/utils";
import {getSelectedOptions, getValuesArrayFromObjectsArray} from "layout/modules/Forms/Dropdowns/utils/utils";
import NutDatabase from "layout/modules/Forms/Dropdowns/NutDropdown/NutDatabase";

const NUTType = {
    NUT2: "NUT2",
    NUT3: "NUT3"
};

const NutDropdown = React.forwardRef((props, ref) => {

    const {t} = useTranslations('enumDropdown', locale);
    const {id = "nut", type = NUTType.NUT2, nut2, value, defaultValue, isMulti, onChange} = props;

    const isControlled = (value !== undefined);

    const currentValue = useMemo(
        () => isControlled ? normalizeNutKey(value) : normalizeNutKey(defaultValue),
        [isControlled, value, defaultValue]
    );

    const options = useMemo(
        () => {

            if (type === NUTType.NUT2) {
                return NutDatabase
                    .sort((a, b) => Intl.Collator().compare(a.label, b.label))
                    .map(entry => ({
                        label: entry.label,
                        value: normalizeNutKey(entry.value)
                    }));
            } else {
                return getNut(nut2);

            }
        },
        [nut2, type]
    );

    const selectedOptions = useMemo(
        () => getSelectedOptions(currentValue, options, isMulti),
        [options, currentValue, isMulti]
    );


    return <Select
        {...props}
        ref={ref}
        name={id}
        classNamePrefix="rs"
        className="rs"
        options={options}
        defaultValue={!isControlled ? selectedOptions : undefined}
        value={isControlled ? selectedOptions : undefined}
        isMulti={isMulti}
        placeholder={usePlaceHolder(props.placeholder, t('placeholder'))}
        noOptionsMessage={useLabelCallback(props.noOptionsMessage, t('no_results'))}
        loadingMessage={useLabelCallback(props.loadingMessage, t('loading_message'))}
        onChange={useCallback(
            (options) => {
                if (onChange)
                    onChange(getValuesArrayFromObjectsArray(options, isMulti), options)
            },
            [onChange, isMulti]
        )}

    />
});


export default NutDropdown;
export {NUTType};
