import Row from "react-bootstrap/Row";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/calendar/locales/locales";
import React, {useCallback} from "react";
import BasicConfigParameters from "layout/modules/FormBuilder/modules/module/components/BasicConfigParameters";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";

const ConfigComponent = props => {

    const {configuration, setConfiguration} = props;
    const {t} = useTranslations('CalendarModule', locale);

    return <Row>
        <BasicConfigParameters {...props}/>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('only_past_dates')}</Form.Label>
                <BooleanDropdown
                    value={parseBoolean(configuration?.onlyPastDates, false)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            onlyPastDates: value,
                            onlyFutureDates: value ? false : value?.onlyFutureDates
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('only_future_dates')}</Form.Label>
                <BooleanDropdown
                    value={parseBoolean(configuration?.onlyFutureDates, false)}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            onlyFutureDates: value,
                            onlyPastDates: value ? false : value?.onlyPastDates
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={false}>
                <Form.Label>{t('from_date')}</Form.Label>
                <DatePicker
                    onlyPastDates={configuration.onlyPastDates}
                    onlyFutureDates={configuration.onlyFutureDates}
                    toDate={configuration.toDate}
                    timezone={"UTC"}
                    placeholder={t("date_ph")}
                    value={getOrEmpty(configuration.fromDate)}
                    onChange={useCallback((value) => setConfiguration(config => ({
                            ...config,
                            fromDate: value,
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={false}>
                <Form.Label>{t('to_date')}</Form.Label>
                <DatePicker
                    onlyPastDates={configuration.onlyPastDates}
                    onlyFutureDates={configuration.onlyFutureDates}
                    fromDate={configuration.fromDate}
                    placeholder={t("date_ph")}
                    timezone={"UTC"}
                    value={getOrEmpty(configuration.toDate)}
                    onChange={useCallback((value) => setConfiguration(config => ({
                            ...config,
                            toDate: value,
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

    </Row>
}

export default ConfigComponent;
