import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import classnames from "classnames";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {filterDate} from "layout/modules/Forms/DatePicker/utils/DateValidators";
import {validateDateString} from "layout/modules/Forms/DatePicker/utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faTimes} from "@fortawesome/free-solid-svg-icons";

const DateTextComponent = props => {

    const {open, setOpen, value, onChange, disabled, fromDate, toDate, ...filteredProps} = props;

    const [inputValue, setInputValue] = useState("");
    const refInput = useRef(null);

    useEffect(
        () => setInputValue(value),
        [value]
    );

    const handleClear = useCallback(e => {
        e.stopPropagation();
        setInputValue("");
        if (onChange)
            onChange("");
    }, [onChange]);

    const handleDownClick = useCallback(e => {
        if (!disabled) {
            e.stopPropagation();
            refInput.current.focus()
            setOpen(open => !open)
        }
    }, [disabled, refInput, setOpen]);


    return <div className="date-picker-input">
        <InputGroup>
            {(!value && disabled) && <div className={"empty-value"}/>}
            <FormControl
                {...filteredProps}
                ref={refInput}
                autoComplete="off"
                ignoresubmit={"true"}
                className={classnames({"focus": open})}
                value={getOrEmpty(inputValue)}
                disabled={disabled}
                onClick={useCallback(e => {
                    e.stopPropagation();
                    setOpen(open => !open);
                }, [setOpen])}
                onChange={useCallback(e => {
                    if (filterDate(e.target.value) !== false) {
                        setInputValue(filterDate(e.target.value));
                        if (validateDateString(e.target.value)) {
                            onChange(e.target.value);
                            return;
                        }
                    }
                    onChange("");
                }, [onChange])}
            />

            {!disabled &&
            <>
                <div className="date-picker-clear">
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{visibility: value ? 'visible' : 'hidden'}}
                        onClick={handleClear}
                    />
                </div>
                <div className="date-picker-separator"/>
                <InputGroup.Text>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        onClick={handleDownClick}/>
                </InputGroup.Text>
            </>
            }
        </InputGroup>
    </div>

}

export default DateTextComponent;
