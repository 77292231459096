import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import "app/home/assets/css/charts.scss";
import React, {useCallback, useMemo, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import createPersistedState from "system/System/PersistedState/PersistenState";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import useChartDownload from "app/home/utils/useChartDownload";
import NeedsChartFilterComponent from "app/home/components/charts/needs/NeedsChartFilterComponent";
import {ChartColors, ChartFreshColors} from "app/home/utils/ChartColors";
import NeedsResolver from "app/home/utils/resolvers/NeedsResolver";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/UserType";
import ChartLegendComponent from "app/home/components/charts/ChartLegendComponent";

const useStateChartPersistedState = createPersistedState('needs-dashboard-chart-filter');

const NeedsChartComponent = props => {

    const {t} = useTranslation('dashboard');

    const [isFilterVisible, setFilterVisible] = useState(false);
    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const [filters, setFilters] = useStateChartPersistedState({});
    const {handleChartDownload, chartRef} = useChartDownload();

    const {authentication} = useApiState();
    const canFilter = authentication.type === UserType.NORMAL;

    const {data, loading} = useGet({
        path: "/dashboard/equipment-needs",
        localErrorOnly: true,
        queryParams: useMemo(() => ({
            ...filters,
        }), [filters]),
        resolve: useCallback(data => NeedsResolver(data, t), [t])
    });

    const handleLabel = useCallback(
        (value, name, props) => {
            if (name === t('totalEquipments')) {
                const needed = props.payload[t('equipmentNeeds')];
                if (needed < 0) {
                    return value + " (" + t('less_equipments', {value: needed * -1}) + ")";
                } else {
                    return value + " (" + t('more_equipments', {value: needed}) + ")";
                }
            }
            const totalBeneficiaries = props.payload[t('totalBeneficiaries')];
            if (totalBeneficiaries === 0) {
                return value;
            }
            return value + " (" + (Math.round((value / totalBeneficiaries) * 100 * 100)) / 100 + "%)"
        },
        [t]
    );

    return <Box>
        <BoxTitle>{t('equipment_needs')}</BoxTitle>

        <ChartButtonsComponent
            setFilterVisible={canFilter ? setFilterVisible : undefined}
            isFilterVisible={isFilterVisible}
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />


        {(isFilterVisible && canFilter) &&
            <NeedsChartFilterComponent filters={filters} setFilters={setFilters}/>
        }

        <Loading visible={loading}/>


        {data?.length > 0 &&
            <>
                {chartMode === ChartMode.CHART &&
                    <ResponsiveContainer height={350} width="100%">
                        <BarChart data={data} ref={chartRef} barCategoryGap={10} barGap={0}
                                  margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                            <XAxis dataKey={t('equipmentTypology')}
                                   stroke={"var(--chart-line-color)"}
                                   tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                   padding={{left: 0, right: 10}}/>


                            <YAxis
                                stroke={"var(--chart-line-color)"}
                                tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                padding={{left: 0, right: 10}}/>

                            <Legend content={ChartLegendComponent}/>

                            <Tooltip
                                cursor={false}
                                formatter={handleLabel}
                                contentStyle={{
                                    backgroundColor: undefined, border: undefined,
                                }}
                            />
                            <CartesianGrid stroke="var(--chart-grid-line)" strokeDasharray="3 1" vertical={false}/>
                            <Bar dataKey={t('totalEquipments')} stackId={1} fill={ChartFreshColors[0]} barSize={10}/>
                            <Bar dataKey={t('totalBeneficiariesWithEquipment')} stackId={2} fill={ChartColors[0]} barSize={10}/>
                            <Bar dataKey={t('totalBeneficiariesWithoutEquipment')} stackId={2} fill={ChartColors[1]} barSize={10}/>

                        </BarChart>
                    </ResponsiveContainer>
                }

                {chartMode === ChartMode.TABLE &&
                    <TableModeComponent data={data}/>
                }
            </>
        }

        {data?.length === 0 &&
            <div className={"no-chart-results"}>
                {t('no_chart_results')}
            </div>

        }

    </Box>

}

export default NeedsChartComponent;
