import SearchComponent from "app/home/components/search/SearchComponent";
import {useCallback, useState} from "react";
import {useMutate} from "system/Rest/Rest";
import DashboardSearchType from "app/home/utils/DashboardSearchType";

const SearchContainer = (props) => {

    const [query, setQuery] = useState("");
    const [type, setType] = useState("");

    const [result, setResult] = useState(false);

    const {mutate: searchEquipments, loading: searchingEquipments} = useMutate({
        verb: "GET",
        path: "/equipments/search",
    });

    const {mutate: searchBeneficiaries, loading: searchingBeneficiaries} = useMutate({
        verb: "GET",
        path: "/beneficiaries/search",
    });


    return <SearchComponent
        {...props}
        query={query}
        type={type}
        result={result}
        loading={searchingEquipments || searchingBeneficiaries}
        onSearch={useCallback(
            (query, type) => {
                setResult(false);
                setQuery(query);
                setType(type);

                const searchFunction = type === DashboardSearchType.EQUIPMENTS
                    ? searchEquipments
                    : searchBeneficiaries

                if (!query) {
                    return;
                }

                searchFunction(null,{
                    queryParams: {
                        universalSearch: query,
                        itemsPerPage: 5
                    }
                }).then(result => {
                    setResult({
                        ...result,
                        resultType: type,
                    })

                }).catch(e => console.log("error",e));
            },
            [setQuery, setType, searchEquipments, searchBeneficiaries, setResult]
        )}


    />

}

export default SearchContainer;
