export const pt = {

    help_collapse: "Caixa de ajuda",
    title: "Titulo da ajuda",
    title_ph: "Indique um titulo curto",
    message: "Mensagem de ajuda",


};

export default pt;
