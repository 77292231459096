import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faCircleDot} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/equipmentStatus/locales/locales";
import React from "react";
import FormConfiguration from "app/ruleTemplates/actions/equipmentStatus/components/FormConfiguration";
import FormEditor from "app/ruleTemplates/actions/equipmentStatus/components/FormEditor";
import ruleHasEquipments from "app/ruleTemplates/utils/config/ruleHasEquipments";


class EquipmentStatusRuleAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [];

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faCircleDot, t('name'), t('description'));

    static getName = () => "EquipmentStatusRuleAction";
    static getDefaultProps = () => ({});

    static renderEditor = (props) => <FormEditor {...props}/>
    static renderConfig = (props) => <FormConfiguration {...props}/>

    static isAllowed = (config, position) => ruleHasEquipments(config, position);

}

export default EquipmentStatusRuleAction;
