class RuleEngineAction {

    static hasViewMode = false;

    constructor() {
        if (this.constructor === RuleEngineAction) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    static getTranslations() {
        throw new Error("Method 'getTranslations()' must be implemented.");
    }

    static getAddOption() {
        throw new Error("Method 'getToolBarEntry()' must be implemented.");
    }

    static getName() {
        throw new Error("Method 'getName()' must be implemented.");
    }

    static getDefaultProps() {
        throw new Error("Method 'getName()' must be implemented.");
    }

    static renderEditor() {
        throw new Error("Method 'renderEditor()' must be implemented.");
    }

    static renderConfig() {
        throw new Error("Method 'renderConfig()' must be implemented.");
    }

    static renderExecution() {
        return <></>;
    }

    static renderView() {
        return <></>;
    }

    static isAllowed(config, position) {
        return true;
    }

}

export default RuleEngineAction;
