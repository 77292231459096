export const pt = {

    title: "Lista de alunos",
    title_old: "Antigos alunos com equipamentos",
    create_title: "Criar aluno",
    show_student: "Visualizar aluno",
    show_beneficiary: "Visualizar beneficiário",


    //list
    no_student_found: "Não foram encontrados alunos",
    name: "Nome",
    vat: "NIF",
    school: "Escola",
    dgeec_code: "Código DGEEC",
    type: "Tipo",

    TEACHER: "Docente",
    STUDENT: "Estudante",

    //Filters
    school_or_group: "Unidade orgânica",
    select_entity_required: "Escolha primeiro uma escola",
    equipment_status: "Equipamento",
    typology: "Tipo de Unid. Orgânica",


    //Equipment Status
    NO_EQUIPMENT: "Não atribuído",
    WITH_EQUIPMENT: "Atribuído",
    PARTIAL_EQUIPMENT: "Atribuído parcialmente",
    REFUSED: "Rejeitado",


    //form
    generic_ph: "Por favor preencha o campo",
    student_data: "Dados gerais",
    address: "Morada",
    rank: "Escalão",
    rank_ph: "Indique o escalão do aluno",
    RANK_A: "Escalão A",
    RANK_B: "Escalão B",
    RANK_C: "Escalão C",
    NO_RANK: "Sem escalão",
    guardian_data: "Encarregado de educação",
    school_level: "Nível de ensino",
    school_year: "Ano escolar",
    school_class: "Turma",
    class_data: "Informação da turma",
    in_digital_manual_pilot: "Em piloto de manuais digitais",
    enrollment_status: "Matrículado",
    placeholder: "Escola uma opção",
    no_results: "Nenhum resultado encontrado",

    create: "Criar",
    cancel: "Cancelar",
    save_changes: "Guardar Alterações",


    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    CREATE_SUCCESS: "Aluno criado",
    CREATE_SUCCESS_MSG: "O aluno {{name}} foi criado com sucesso!",

    UPDATE_SUCCESS: "Aluno atualizado",
    UPDATE_SUCCESS_MSG: "O aluno {{name}} foi atualizado com sucesso!",

    MISSING_SCHOOL_LEVEL: "Indique uma opção de nível de ensino",
    MISSING_SCHOOL_YEAR: "Indique o ano escolar em que o aluno se encontra",

    //Viwew
    general: "Dados gerais",
    guardian: "Enc. Educação",

    //School year and level
    BASIC: "Básico",
    SECONDARY: "Secundário",
    Y1: "1º",
    Y2: "2º",
    Y3: "3º",
    Y4: "4º",
    Y5: "5º",
    Y6: "6º",
    Y7: "7º",
    Y8: "8º",
    Y9: "9º",
    Y10: "10º",
    Y11: "11º",
    Y12: "12º",


};

export default pt;
