import {Button} from "react-bootstrap";
import {faGear, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from "react";
import classnames from "classnames";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import ConfigurationComponent
    from "layout/modules/FormBuilder/components/editor/configurations/ConfigurationComponent";
import {useDrag, useDrop} from "react-dnd";
import {useTranslation} from "react-i18next";
import {shouldMove} from "layout/modules/FormBuilder/utils/editor/dragUtils";
import "layout/modules/FormBuilder/assets/css/editor-entry.scss";

const EditorEntryComponent = props => {

    const {id, position, moveEntry, entry, module, onDelete, onEditComponentProps} = props;
    const {t} = useTranslation("form-editor");
    const [isConfigVisible, setConfigVisible] = useState(false);

    const ref = useRef(null)

    const [{handlerId}, drop] = useDrop({
        accept: 'CARD',
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover: (item, monitor) => {
            if (!ref.current || item?.id === id) {
                return;
            }

            if (shouldMove(ref, position, item, monitor)) {
                moveEntry(item?.position, position)
            }
        },
    });


    const [{isDragging}, drag] = useDrag({
        type: 'CARD',
        item: () => ({
            position,
            id
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drag(drop(ref));


    return <div className={classnames("editor-entry", {'in-drag': isDragging})} ref={ref} data-handler-id={handlerId}>

        {isConfigVisible &&
        <ConfigurationComponent
            onClose={() => setConfigVisible(false)}
            configuration={entry.props}
            onEditComponentProps={onEditComponentProps}
            module={module}
        />
        }

        <div className={"button-bar"}>
            <ToolTip content={t('configure_component')}>
                <Button
                    type={'button'}
                    className={classnames("btn btn-rounded-primary small", {'active': isConfigVisible})}
                    onClick={() => setConfigVisible(true)}
                >
                    <FontAwesomeIcon icon={faGear}/>
                </Button>
            </ToolTip>

            <ToolTip content={t('remove_component')}>
                <Button type={'button'} className={classnames("btn btn-rounded-danger small")} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </ToolTip>

        </div>

        {module.renderEditor(entry)}

    </div>
}

export default EditorEntryComponent;
