import Row from "react-bootstrap/Row";
import useTranslations from "system/Translations/UseTranslations";
import locale from "layout/modules/FormBuilder/modules/input/locales/locales";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import React, {useCallback} from "react";
import Col from "react-bootstrap/Col";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import BasicConfigParameters from "layout/modules/FormBuilder/modules/module/components/BasicConfigParameters";

const ConfigComponent = props => {

    const {configuration, setConfiguration} = props;
    const {t} = useTranslations('FormInputModule', locale);

    return <Row>
        <BasicConfigParameters {...props}/>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup>
                <Form.Label>{t('placeholder')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(configuration?.placeholder)}
                    autoComplete="chrome-off"
                    placeholder={t('placeholder_ph')}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            placeholder: e.target.value,
                        })),
                        [setConfiguration]
                    )}

                />
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={false}>
                <Form.Label>{t('validation_expression')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(configuration?.validationExpression)}
                    autoComplete="chrome-off"
                    placeholder={t('validation_ph')}
                    onChange={useCallback(
                        (e) => setConfiguration(config => ({
                            ...config,
                            validationExpression: e.target.value,
                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

    </Row>
}

export default ConfigComponent;
