import {config} from '@fortawesome/fontawesome-svg-core';

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const appConfig = (function () {

    const defaultConfig = {
        defaultCountry: "PT",
        defaultLng: "pt_PT",
        name: "SGEC",
        nameComplete: "Portal escola digital",

        supportEmail: "geral@bleen.pt",
        api: {
            base: window.location.origin + "/api/",
        },
        openReplay: {
            url: "https://openreplay.ed.bleen.pt/ingest",
            allowInsecure: false,
            projectKey: process.env.REACT_APP_OPEN_REPLAY_PK

        }
    };

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return {
            ...defaultConfig,
            api: {
                ...defaultConfig.api,
                base: "https://ed.bleen.pt/api/",
                //base: "http://localhost:8080/api/",
                //base: "https://c8f0-78-137-210-243.ngrok-free.app/api/"
            },
            openReplay: {
                ...defaultConfig.openReplay,
                disabled: true,
                allowInsecure: true,
                projectKey: "uXIzipmvre4YGCXyTWmQ",
            }
        }
    }
    return defaultConfig;
})
();


export default appConfig;
