const DocumentType = {
    ENTITY: "ENTITY",
    USER: "USER",
    EQUIPMENT_PROFILE: "EQUIPMENT_PROFILE",
    CONTRACT: "CONTRACT",
    CONTRACT_ANNEX: "CONTRACT_ANNEX",
    EQUIPMENT: "EQUIPMENT",
    EQUIPMENT_REGISTRATION: "EQUIPMENT_REGISTRATION",
    TICKET: "TICKET",
    TICKET_CONVERSATION: "TICKET_CONVERSATION",
    RULE_EXECUTION: "RULE_EXECUTION",
    NON_COMPLIANCE: "NON_COMPLIANCE",
};

export default DocumentType;
