const shouldMove = (ref, position, item, monitor) => {

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current?.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    if (item?.position < position && hoverClientY < hoverMiddleY) {
        return false
    }
    // Dragging upwards
    if (item?.position >= position && hoverClientY > hoverMiddleY) {
        return false
    }

    return true;
}

export {shouldMove}
