import React, {useCallback, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/actions/notification/locales/locales";
import localeValidation from "app/ruleTemplates/actions/validation/locales/locales";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import ValidationEntityType, {generateDestinationEntityList} from "app/ruleTemplates/actions/validation/utils/ValidationEntityType";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import FormControl from "layout/modules/Forms/Control/FormControl";


const FormConfiguration = props => {

    const {rule, position, setConfiguration, configuration} = props;

    const {t} = useTranslations("NotificationAction", locale);
    const {t: tValidation} = useTranslations("ValidationRuleAction", localeValidation);

    const options = useMemo(() => generateDestinationEntityList(rule, position), [rule, position]);

    return <Row className={"rule-action-support-config-form"}>

        <Col sm={12} lg={true} xl={true}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('destination_entity_type')}</Form.Label>
                <EnumDropdown
                    t={tValidation}
                    value={getOrNull(configuration?.props?.destinationEntityType)}
                    options={options}
                    onChange={useCallback(
                        (value) => setConfiguration(config => ({
                            ...config,
                            props: {
                                ...config.props,
                                destinationEntityType: value,
                            }

                        })),
                        [setConfiguration]
                    )}
                />
            </FormGroup>
        </Col>

        {configuration?.props?.destinationEntityType === ValidationEntityType.DEFINED_ENTITY &&
        <Col sm={12} lg={6} xl={6}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('destination_entity')}</Form.Label>
                <EntityDropdown
                    value={getOrNull(configuration?.props?.entityId)}
                    onChange={(value) => setConfiguration(config => ({
                        ...config,
                        props: {
                            ...config.props,
                            entityId: value,
                        }

                    }))}
                />
            </FormGroup>
        </Col>
        }


        <Col sm={12} md={12}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('title')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('title_ph')}
                    value={getOrEmpty(configuration?.props?.title)}
                    onChange={(e) => setConfiguration(config => ({
                        ...config,
                        props: {
                            ...config.props,
                            title: e.target.value,
                        }

                    }))}

                />
            </FormGroup>
        </Col>

        <Col sm={12} md={12}>
            <FormGroup mandatory={true}>
                <Form.Label>{t('model')}</Form.Label>
                <FormControl
                    type="textarea"
                    as="textarea"
                    rows={3}
                    value={getOrEmpty(configuration?.props?.body)}
                    onChange={(e) => setConfiguration(config => ({
                        ...config,
                        props: {
                            ...config.props,
                            body: e.target.value,
                        }

                    }))}

                />
            </FormGroup>
        </Col>


    </Row>

}

export default FormConfiguration;
