import {useMemo} from "react";
import {isTicketLocked} from "app/support/utils/utils";
import {useApiState} from "system/API/APIStateContext";
import {useUserHasPermission} from "system/API/Authentication/UserHasPermission";

const useCanChangeTicketStatus = ticket => {

    const {authentication} = useApiState();
    const userHasPermission = useUserHasPermission();

    return useMemo(
        () => {
            if (isTicketLocked(ticket))
                return false;
            if (userHasPermission({permission: 'MANAGE_TICKETS'}))
                return true;
            return userHasPermission({permission: 'SET_TICKET_STATUS'})
                && ticket.destinationEntity.id === authentication.entity.id;
        },
        [ticket, userHasPermission, authentication.entity.id]
    );
}

export default useCanChangeTicketStatus;
