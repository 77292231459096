export const pt = {

    title: "Suporte / Tickets",
    view_title: "Pedido de suporte {{id}}",
    view: "Visualizar mensagens",
    control_title: "Pedido de suporte associado",
    box_title: "{{id}} | {{subject}}",
    dashboard_title: "Pedidos de suporte recentes",
    request_support: "Novo pedido de suporte",
    view_btn: "Ver e responder",
    create: "Novo pedido",
    destination: "Destino",
    destination_user: "Utilizador de destino",

    subject: "Assunto",
    last_update: "Ult. atualização",
    creation_date: "Data criação",
    status: "Estado",
    user_and_entity: "Utilizador e entidade",
    id: "Pedido",
    url: "Endereço do pedido",
    no_tickets_found: "Não existem pedidos de suporte para apresentar",
    view_all: "Ver todos",
    new_request: "Novo pedido",
    priority: "Prioridade",
    open_by: "Aberto por",
    placeholder: "Escola uma opção",
    equipment_ticket: " - Eq. {{serialNumber}}",

    item_selected_one: "{{count}} ticket selecionado",
    item_selected_other: "{{count}} tickets selecionados",

    //alerts
    ticket_is_transferred: "Transferência de pedido",
    ticket_is_transferred_msg: "Este pedido teve origem num outro pedido que foi entretanto transferido para a entidade <strong>{{entityName}}</strong>. Caso queira consultar o original carregue ",
    ticket_was_transferred_msg: "Este pedido foi transferido para uma nova entidade. Para visualizar o novo ticket carregue ",
    ticket_is_transferred_msg_link: "aqui.",

    transfer_ticket_to_operator: "Transferir para operador",
    transfer_ticket_to_entity: "Transferir para entidade",


    ticket_is_associated_with_equipment: "Suporte ao equipamento {{serialNumber}}",
    ticket_is_associated_with_equipment_msg: "Este pedido de suporte foi feito a partir do equipamento {{serialNumber}}.<br/>" +
        "Para mais informações sobre o equipamento carregue ",
    ticket_is_associated_with_equipment_msg_link: "aqui.",


    ticket_is_associated_with_audit: "Tarefa de processo de auditoria",
    ticket_is_associated_with_audit_msg: "Este pedido de suporte está relacionado com uma tarefa de auditoria com o numero #{{auditProcessId}}.",
    ticket_is_associated_with_audit_msg_link: "Para visualizar o pedido carregue aqui",


    ticket_is_associated_with_non_compliance: "Não conformidade associada",
    ticket_is_associated_with_non_compliance_msg: "Este pedido está associado a uma não conformidade com o id #{{nonComplianceId}}",
    ticket_is_associated_with_non_compliance_link: "Para visualizar o pedido carregue aqui",

    //Operations
    transfer_ticket: "Transferir ticket",
    observations: "Observações",
    close_and_discard: "Cancelar",
    transfer: "Transferir",

    //Ticket status
    OPEN: "Novo",
    PENDING: "Pendente",
    RESOLVED: "Resolvido",
    REPLIED: "Respondido",
    REOPEN: "Reaberto",
    IN_PROGRESS: "Em progresso",
    TRANSFERRED: "Transferido",


    //Ticket category management
    title_categories_list: "Listar categorias de tickets",
    view_category: "Editar/Visualizar categoria",
    create_category: "Criar categoria",
    category_name: "Nome da categoria",
    category_name_ph: "Indique o nome da categoria",
    user_types: "Tipo de utilizadores autorizados",
    DELETE_CATEGORY_CONFIRM: "Confirmação",
    DELETE_CATEGORY_CONFIRM_MSG: "Deseja realmente apagar a categoria {{name}}?",
    DELETE_SUCCESS: "Categoria apagada",
    DELETE_SUCCESS_MSG: "A categoria \"{{name}}\" foi apagada com sucesso!",

    category_data: "Dados da categoria",
    ticket_statuses: "Estados permitidos",
    limit_user_type: "Limitar a utilizadores do tipo",
    save_changes: "Guardar alterações",
    status_on_origin_reply: "Estado do ticket após resposta da origem",
    status_on_destination_reply: "Estado do ticket após resposta do destino",

    ACTIVE: "Ativa",
    INACTIVE: "Inativa",
    INVALID_NAME: "O nome escolhido não é válido",
    MISSING_NAME: "Deve indicar um nome válido",
    MISSING_TICKET_STATUSES: "Deve indicar os estados permitidos",
    CREATE_SUCCESS: "Categoria criada",
    CREATE_SUCCESS_MSG: "A categoria {{name}} foi criada com sucesso!",
    UPDATE_SUCCESS: "Categoria atualizada",
    UPDATE_SUCCESS_MSG: "A categoria {{name}} foi atualizada com sucesso!",
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",

    //ticket priority
    LOW: "Baixa",
    NORMAL: "Normal",
    HIGH: "Alta",
    CRITICAL: "Crítica",

    //Filter
    status_ph: "Escolha um estado",
    select_ph: "Escolha uma opção",
    from_date: "Data inicial",
    to_date: "Data final",
    date_ph: "Data no formato (dd-mm-yyyy)",
    open_by_entity: "Aberto pela entidade",
    open_by_user: "Aberto pelo utilizador",
    destination_entity: "Entidade de destino",
    is_read: "Lido",


    //Historic
    historic: "Histórico",
    close: "Fechar",
    historic_status: "Alterações de estado",
    historic_agents: "Alterações de operador",
    agent: "Operador",
    user_responsible: "Utilizador (que realizou mudança)",
    date: "Data",

    //ERRORS & MEssages
    MISSING_MESSAGE: "Deve preencher com a mensagem que deseja enviar como resposta",
    MISSING_TITLE: "Deve indicar um assunto/titulo para o pedido",

    ERROR_COMMENT: "Erro no formulário",
    ERROR_COMMENT_MSG: "Por favor verifique os campos assinalados",

    SUCCESS_COMMENT: "Sucesso",
    SUCCESS_COMMENT_MSG: "O seu comentário foi registado com sucesso",

    SUCCESS_TRANSFER: "Pedido transferido",
    SUCCESS_TRANSFER_MSG: "O pedido foi transferido com sucesso",
    SUCCESS_TRANSFER_BULK_MSG: "Foram transferidos {{count}} pedidos com sucesso",

    //Form Create
    category: "Categoria",
    select_category: "Escolha uma categoria",
    subject_ph: "Indique o motivo do pedido de suporte",
    description: "Descrição do pedido de suporte",
    create_ticket: "Registar pedido",
    message: "Mensagem",
    cancel: "Fechar",
    send_message: "Enviar",
    no_results: "Não foram encontrados resultados",


    //Error ticket
    INVALID_CATEGORY: "A categoria indicada não é válida",
    MISSING_CATEGORY: "Indique a categoria do pedido",
    MISSING_SUBJECT: "Indique o assunto principal do pedido",
    MISSING_DESCRIPTION: "Indique uma descrição para o pedido de suporte",
    USER_HAS_NO_EMAIL: "Não é possível realizar o pedido de suporte uma vez que não tem email definido na sua conta da Ordem dos Médicos Veterinários",

    SUCCESS_REQUEST: "Pedido registado",
    SUCCESS_REQUEST_MSG: "O seu pedido ficou registado e será respondido com a maior brevidade possível",

    n_responses_one: "{{count}} Resposta",
    n_responses_other: "{{count}} Respostas",
    upload_ticket_files: "Para adicionar um documento largue o ficheiro nesta área ou clique no botão escolher",
    user: "Utilizador",
    entity: "Entidade",

    //right menu
    details: "Detalhes",

    SUCCESS_SET_STATUS: "Estado alterado",
    SUCCESS_SET_STATUS_MSG: "O estado do ticket #{{id}} foi alterado para <strong>{{status}}</strong>.",

    SUCCESS_SET_PRIORITY: "Prioridade alterada",
    SUCCESS_SET_PRIORITY_MSG: "A prioridade do ticket #{{id}} foi alterada para <strong>{{priority}}</strong>.",

};

export default pt;
