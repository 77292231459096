import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useEffect, useMemo, useState} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import {parseBoolean} from "system/Utils/parseBoolean";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import SupplierDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SupplierDropdown";
import ProcedureDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ProcedureDropdown";
import BeneficiaryDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/BeneficiaryDropdown";
import {SchoolType} from "types/SchoolType";
import EquipmentStatusDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentStatusDropdown";
import EquipmentProfileDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentProfileDropdown";
import EquipmentTypologyDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/TypologyDropdown";
import isFunction from "system/Utils/isFunction";
import FormControl from "layout/modules/Forms/Control/FormControl";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";
import ContractDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ContractDropdown";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";

const ListFilterComponent = (props) => {

    const {searchFilter, t, hideAttributions, onFilterChange, readOnly, lockedFilters = {}} = props;
    const [filterState, setFilterState] = useState(searchFilter);

    const schoolFilter = useMemo(() => [SchoolType.AE, SchoolType.AE_SCHOOL, SchoolType.ENA, SchoolType.EPCCA], []);

    useEffect(() => {

        if (!isFunction(onFilterChange)) {
            setFilterState({
                ...searchFilter
            });
        }

    }, [searchFilter, onFilterChange]);


    useEffect(() => {
            if (isFunction(onFilterChange) && filterState !== searchFilter) {
                onFilterChange(filterState);
            }
        }, // eslint-disable-next-line
        [filterState, onFilterChange]);


    if (!filterState) {
        return <></>;
    }

    return <>
        <Row>

            {isNotDefinedNorEmpty(lockedFilters['deliveryNoteNumber']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('delivery_note')}</Form.Label>
                    <FormControl
                        type="text"
                        id={"deliveryNoteNumber"}
                        disabled={readOnly}
                        placeholder={t('delivery_note_ph')}
                        value={getOrEmpty(filterState?.deliveryNoteNumber)}
                        onChange={(e) => {
                            setFilterState({
                                ...filterState, deliveryNoteNumber: e.target.value,
                            })
                        }}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['procedureNumber']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('procedure_number')}</Form.Label>
                    <FormControl
                        type="text"
                        id={"procedureNumber"}
                        disabled={readOnly}
                        placeholder={t('procedure_number_ph')}
                        value={getOrEmpty(filterState?.procedureNumber)}
                        onChange={(e) => {
                            setFilterState({
                                ...filterState, procedureNumber: e.target.value,
                            })
                        }}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['contractNumber']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('contract_number')}</Form.Label>
                    <ContractDropdown
                        id={"contractNumber"}
                        isDisabled={readOnly}
                        placeholder={t('contract_number_ph')}
                        value={getOrEmpty(filterState?.contractNumber)}
                        onChange={(value) => {
                            setFilterState({
                                ...filterState, contractNumber: value,
                            })
                        }}
                    />

                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['commitmentNumber']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('commitment_number')}</Form.Label>
                    <FormControl
                        type="text"
                        id={"commitmentNumber"}
                        disabled={readOnly}
                        placeholder={t('commitment_number_ph')}
                        value={getOrEmpty(filterState?.commitmentNumber)}
                        onChange={(e) => {
                            setFilterState({
                                ...filterState, commitmentNumber: e.target.value,
                            })
                        }}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['procedureIds']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('procedure')}</Form.Label>
                    <ProcedureDropdown
                        id={'procedureIds'}
                        isDisabled={readOnly}
                        isMulti={true}
                        value={getOrNull(filterState?.procedureIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, procedureIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['statusIds']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('status')}</Form.Label>
                    <EquipmentStatusDropdown
                        id={'statusIds'}
                        isMulti={true}
                        isDisabled={readOnly}
                        value={getOrNull(filterState?.statusIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, statusIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['typologyIds']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('typology')}</Form.Label>
                    <EquipmentTypologyDropdown
                        id={'typologyIds'}
                        isMulti={true}
                        isDisabled={readOnly}
                        value={getOrNull(filterState?.typologyIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, typologyIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['profileIds']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('profile')}</Form.Label>
                    <EquipmentProfileDropdown
                        id={'profileIds'}
                        isDisabled={readOnly}
                        isMulti={true}
                        value={getOrNull(filterState?.profileIds)}
                        typologyIds={getOrNull(filterState?.typologyIds)}
                        procedureIds={getOrNull(filterState?.procedureIds)}
                        equipmentSupplierIds={getOrNull(filterState?.equipmentSupplierIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, profileIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['assigned']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('assigned')}</Form.Label>
                    <BooleanDropdown
                        id={'assigned'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.assigned)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, assigned: value, beneficiaryIds: undefined,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {(isNotDefinedNorEmpty(lockedFilters['beneficiaryIds']) && filterState?.assigned) &&
                <UserHasPermission permission={"READ_BENEFICIARY"}>
                    {!hideAttributions && <Col sm={12} lg={6}>
                        <FormGroup>
                            <Form.Label>{t('beneficiary')}</Form.Label>
                            <BeneficiaryDropdown
                                id={'beneficiaryIds'}
                                isDisabled={readOnly}
                                isMulti={true}
                                value={getOrNull(filterState?.beneficiaryIds)}
                                onChange={(value) => setFilterState(state => ({
                                    ...state, beneficiaryIds: value,
                                }))}
                            />
                        </FormGroup>
                    </Col>}
                </UserHasPermission>}

            {(isNotDefinedNorEmpty(lockedFilters['schoolIds']) && !hideAttributions) &&
                <SchoolFilterComponent
                    value={filterState?.schoolIds}
                    typologies={schoolFilter}
                    isDisabled={readOnly}
                    id={'schoolIds'}
                    isMulti={true}
                    setValue={(value) => setFilterState(state => ({
                        ...state, schoolIds: value,
                    }))}
                />
            }

            {isNotDefinedNorEmpty(lockedFilters['inWarranty']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('in_warranty')}</Form.Label>
                    <BooleanDropdown
                        id={'inWarranty'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.inWarranty)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, inWarranty: value,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['equipmentSupplierIds']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('equipment_supplier')}</Form.Label>
                    <SupplierDropdown
                        id={'equipmentSupplierIds'}
                        isDisabled={readOnly}
                        isMulti={true}
                        value={getOrNull(filterState?.equipmentSupplierIds)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, equipmentSupplierIds: value,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['withMaintenanceSupplier']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('with_maintenance_supplier')}</Form.Label>
                    <BooleanDropdown
                        id={'withMaintenanceSupplier'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.withMaintenanceSupplier)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, withMaintenanceSupplier: value, maintenanceSupplierIds: undefined,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {(isNotDefinedNorEmpty(lockedFilters['maintenanceSupplierIds']) && filterState?.withMaintenanceSupplier) &&
                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('maintenance_supplier')}</Form.Label>
                        <SupplierDropdown
                            id={'maintenanceSupplierIds'}
                            isDisabled={readOnly}
                            isMulti={true}
                            value={getOrNull(filterState?.maintenanceSupplierIds)}
                            onChange={(value) => setFilterState(state => ({
                                ...state, maintenanceSupplierIds: value,
                            }))}
                        />
                    </FormGroup>
                </Col>}


            {isNotDefinedNorEmpty(lockedFilters['readyToInvoice']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('ready_to_invoice')}</Form.Label>
                    <BooleanDropdown
                        id={'readyToInvoice'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.readyToInvoice)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, readyToInvoice: value,
                        }))}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['availableForStudent']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('available_for_student')}</Form.Label>
                    <BooleanDropdown
                        id={'availableForStudent'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.availableForStudent)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, availableForStudent: value,
                        }))}
                    />
                </FormGroup>
            </Col>}


            {isNotDefinedNorEmpty(lockedFilters['availableForTeacher']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('available_for_teacher')}</Form.Label>
                    <BooleanDropdown
                        id={'availableForTeacher'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.availableForTeacher)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, availableForTeacher: value,
                        }))}
                    />
                </FormGroup>
            </Col>}

            {isNotDefinedNorEmpty(lockedFilters['availableForSchool']) && <Col sm={12} lg={6}>
                <FormGroup>
                    <Form.Label>{t('available_for_school')}</Form.Label>
                    <BooleanDropdown
                        id={'availableForSchool'}
                        isDisabled={readOnly}
                        isClearable={true}
                        value={parseBoolean(filterState?.availableForSchool)}
                        onChange={(value) => setFilterState(state => ({
                            ...state, availableForSchool: value,
                        }))}
                    />
                </FormGroup>
            </Col>}

        </Row>

    </>
}

export default ListFilterComponent;
