import {Trans, useTranslation} from "react-i18next";
import {faChalkboardUser, faSchool} from "@fortawesome/free-solid-svg-icons";
import AdvanceInfoMessage from "layout/modules/InfoMessages/AdvanceInfoMessage";
import React from "react";
import {
    isBeneficiaryNotOnEquipmentSchool,
    isStudentYearNotAllowedOnEquipment
} from "app/equipments/equipments/utils/equipmentBeneficiaryValidations";
import useTranslations from "system/Translations/UseTranslations";
import localStudents from "app/beneficiaries/students/locales/locales";

const NonComplianceAlertsComponent = props => {

    const {equipment} = props;
    const {t} = useTranslation('equipments');
    const {t: tStudents} = useTranslations('students', localStudents);

    return <>

        {isBeneficiaryNotOnEquipmentSchool(equipment) &&
        <AdvanceInfoMessage
            icon={faSchool}
            type={"danger"}
            iconType={"danger"}
            title={t("equipment_in_beneficiary_from_different_school")}>

            <Trans>
                {t('equipment_in_beneficiary_from_different_school_msg', equipment)}
            </Trans>
        </AdvanceInfoMessage>
        }

        {isStudentYearNotAllowedOnEquipment(equipment) &&
        <AdvanceInfoMessage
            icon={faChalkboardUser}
            type={"danger"}
            iconType={"danger"}
            title={t("equipment_not_allowed_for_student_year")}>

            <Trans>
                {t('equipment_not_allowed_for_student_year_msg', {
                    ...equipment,
                    beneficiary: {
                        ...equipment.beneficiary,
                        schoolYear: tStudents(equipment?.beneficiary?.schoolYear)
                    },
                    profile: {
                        ...equipment.profile,
                        schoolYears: equipment?.profile?.schoolYears?.map(year => tStudents(year))
                    }
                })}
            </Trans>
        </AdvanceInfoMessage>
        }

    </>


}

export default NonComplianceAlertsComponent;
