import pt from "./pt";
import en from "./en";

const locale = [
    {
        lang: 'en-US',
        name: "English",
        translation: en
    },
    {
        lang: 'pt-PT',
        name: "Português",
        translation: pt
    }

];

export default locale;

