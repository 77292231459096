import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import "app/files/assets/css/upload.scss"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {acceptTypeImages, acceptTypesPdf} from "layout/modules/Forms/DragDropFile/utils/acceptTypes";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import FormControl from "layout/modules/Forms/Control/FormControl";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import ModalButtons from "layout/modules/Modal/Buttons/ModalButtons";
import DragDropFile from "layout/modules/Forms/DragDropFile/DragDropFile";
import {readFileContentToByteString} from "system/Files/ReadFileContentToByteString";


const UploadComponent = (props) => {

    const {t} = useTranslation("file");

    const {onUpload, loading} = props;
    const [currentFile, setCurrentFile] = useState(null);

    return <>
        <Loading visible={loading}/>

        {currentFile &&
        <Modal
            show={true}
            onHide={loading ? () => void 0 : () => setCurrentFile(null)}
            size={"xl"}
            className={"file-modal dark"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('file_info')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {t('info_description')}

                <Box>
                    <BoxTitle>
                        {currentFile.filename}
                    </BoxTitle>
                    <Row>
                        <Col xs={12} sm={6} xl={6}>
                            <FormGroup>
                                <Form.Label>{t('title')}</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder={t('title_ph')}
                                    onChange={(e) => {
                                        setCurrentFile((cf) => ({
                                            ...cf,
                                            title: e.target.value,
                                        }));
                                    }}
                                />
                            </FormGroup>

                        </Col>

                        <Col xs={12} sm={6} xl={6}>
                            <FormGroup>
                                <Form.Label>{t('expiration_date')}</Form.Label>
                                <DatePicker
                                    name={"expirationDate"}
                                    placeholder={t("file_date_ph")}
                                    onlyFutureDates={true}
                                    onChange={(date) => setCurrentFile(cf => ({
                                        ...cf,
                                        expirationDate: date,
                                    }))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Box>


            </Modal.Body>

            <ModalButtons>
                <Button variant="secondary" onClick={() => setCurrentFile(null)}>
                    {t("cancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        onUpload({...currentFile});
                        setCurrentFile(null);
                    }}>
                    {t("upload")}
                </Button>
            </ModalButtons>
        </Modal>
        }


        <Box className={"upload-box"}>
            <DragDropFile
                acceptTypes={{
                    ...acceptTypesPdf,
                    ...acceptTypeImages,
                }}
                text={t('upload_file_label')}
                maxFiles={1}
                onFileUploaded={useCallback(
                    (nf) => {
                        nf.forEach((file) => {
                            readFileContentToByteString(file, (content) => {
                                setCurrentFile({
                                    filename: file.name,
                                    type: file.type,
                                    contents: btoa(content)
                                });
                            });
                        })
                    },
                    [setCurrentFile]
                )}
            />
        </Box>
    </>;
}

export default UploadComponent;
