import React, {useMemo} from 'react'
import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";

const ContractModelDropdown = (props) => {

    const {type} = props;
    return <SimpleListDropdown
        {...props}
        filterExtraProps={useMemo(() => ({
            type: type
        }), [type])}
        remotePath={"/contract-models/"}
    />

}

export default ContractModelDropdown;
