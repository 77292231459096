import React from 'react'
import SimpleListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SimpleListDropdown";

const EquipmentTypologyDropdown = (props) => {

    return <SimpleListDropdown
        {...props}
        remotePath={"/equipment-typologies/search"}
    />

}

export default EquipmentTypologyDropdown;
