import SchoolLevel from "types/SchoolLevel";
import React, {useMemo} from "react";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import StudentSchoolYear, {StudentSchoolYearBasic, StudentSchoolYearSecondary} from "types/StudentSchoolYear";

const StudentSchoolYearDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);
    const {schoolLevel, ...otherProps} = props;

    const data = useMemo(
        () => {
            switch (schoolLevel) {
                case SchoolLevel.BASIC:
                    return StudentSchoolYearBasic;
                case SchoolLevel.SECONDARY:
                    return StudentSchoolYearSecondary;
                default:
                    return StudentSchoolYear;
            }
        },
        [schoolLevel]
    );

    return <EnumDropdown
        {...otherProps}
        t={t}
        options={data}
        placeholder={t('school_year_ph')}
    />

}

export default StudentSchoolYearDropdown;
