import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "system/Objects/ObjectParameters";
import EquipmentProfileDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentProfileDropdown";

const EquipmentProfileComponent = props => {

    const {value, setValue, parameter, error, readOnly} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EquipmentProfileDropdown
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EquipmentProfileComponent;
