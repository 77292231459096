import useTranslations from "system/Translations/UseTranslations";
import locale from "app/ruleTemplates/locale/locales";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Loading from "layout/modules/Loading/Loading";

const ModalExecutionComponent = props => {

    const {done, loading, onClose, operation, children, availableButtons} = props;
    const {t} = useTranslations('rule-template', locale);

    if (done) {
        return <Loading visible={true}/>
    }

    return <Modal show={true} onHide={onClose} backdrop={false} size={"xl"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{operation.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loading visible={loading}/>
            {children}
        </Modal.Body>
        <Modal.Footer>

            <Button variant={'secondary'} onClick={onClose}>
                {t('close')}
            </Button>

            {availableButtons && availableButtons}

        </Modal.Footer>
    </Modal>
}
export default ModalExecutionComponent;
