import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import EntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/EntityDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";

const EntityComponent = props => {

    const {value, setValue, parameter, error, readOnly, types} = props;

    return <Col sm={12} lg={6}>
        <FormGroup error={error} mandatory={parameter?.mandatory}>
            <Form.Label>{parameter?.label}</Form.Label>
            <EntityDropdown
                types={types}
                isDisabled={readOnly}
                isClearable={!parameter?.mandatory}
                isMulti={parameter?.multipleValues}
                value={getOrNull(value)}
                remotePath={"/entities/search-all"}
                onChange={setValue}
            />
            <Form.Text className="sub-error">
                {error}
            </Form.Text>
        </FormGroup>
    </Col>

}

export default EntityComponent;
