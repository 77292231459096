const acceptTypeImages = {
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpeg', '.jpg']
}

const acceptTypesPdf = {
    'application/pdf': ['.pdf']
}

const acceptTypeDocx = {
    'application/*': ['.docx']
}

const acceptTypeExcel = {
    'application/vnd.ms-excel': ['.xls', '.xlsx']
}


export {
    acceptTypeImages,
    acceptTypesPdf,
    acceptTypeDocx,
    acceptTypeExcel
}
