import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import FormWarranty from "app/equipments/equipments/components/form/FormWarranty";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import localEquipments from "app/equipments/equipments/locales/locales";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import locale from "app/ruleTemplates/actions/replaceEquipment/locales/locales";

const ViewComponent = props => {

    const {data} = props;

    const {t} = useTranslations("ReplaceEquipmentRuleAction", locale);
    const {t: tEquipments} = useTranslations('equipments', localEquipments);

    return <div className={"rule-equipment-selection view-mode"}>

        <Box>
            <BoxTitle>
                {t('name')}
            </BoxTitle>
            <Row>
                <Col sm={12} lg={12} xl={12}>
                    <FormGroup>
                        <Form.Label>{tEquipments('serial_number')}</Form.Label>
                        <FormControl
                            type="text"
                            disabled={true}
                            value={getOrEmpty(data?.serialNumber)}
                        />

                    </FormGroup>
                </Col>

                <FormWarranty equipment={data} readOnly={true} errors={{}}/>
            </Row>

        </Box>

    </div>
}

export default ViewComponent;
