import React from "react";
import {Status} from "types/Status";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";


const StatusDropdown = (props) => {

    return <EnumDropdown
        {...props}
        name={props.id ? props.id : "status"}
        id={props.id ? props.id : "status"}
        options={Status}
    >
    </EnumDropdown>
}


export default StatusDropdown;
