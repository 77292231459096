import {useGet} from "system/Rest/Rest";
import {useMemo} from "react";
import RuleType from "app/ruleTemplates/utils/types/RuleType";
import canEquipmentExecuteOperation from "app/ruleTemplates/utils/execution/canEquipmentExecuteOperation";

const useRuleTemplateOperations = (type, object) => {

    const {data: operations} = useGet({
        path: "/rule-templates/type/" + type + "/" + object.id,
    });


    const allowedOperations = useMemo(
        () => operations?.filter(operation => isOperationAllowed(operation, type, object))
            .sort((a, b) => Intl.Collator().compare(a.name, b.name)) ?? [],
        [operations, object, type]
    );

    return {
        operations: allowedOperations,
    }
}

const isOperationAllowed = (operation, type, object) => {
    switch (type) {
        case RuleType.EQUIPMENT:
            return canEquipmentExecuteOperation(operation, object);
        default:
            return true;
    }
}

export default useRuleTemplateOperations;
