export const pt = {

    title: "Notificações",
    dashboard_title: "Notificações recentes",
    notifications: "Notificações",
    view_all: "Ver todas",


    NEW_TICKET_CONVERSATION: "Resposta a pedido de suporte",
    NEW_TICKET_CONVERSATION_MSG: "Foi recebida uma nova mensagem de resposta ao pedido de suporte <strong>{{documentId}}</strong>",

    NEW_TICKET: "Novo pedido de suporte",
    NEW_TICKET_MSG: "Foi recebido um novo pedido de suporte com o ID <strong>#{{documentId}}</strong>",


    REPORT: "Resultado de execução de relatório",
    REPORT_MSG: "Foi disponibilizado o resultado da execução do relatório <strong>#{{documentId}}</strong> no seu arquivo pessoal com o nome <strong>{{additionalInfo.filename}}</strong>.",

    REPORT_FAILED: "Relatório falhou",
    REPORT_FAILED_MSG: "Não foi possível executar o relatório agendado <strong>{{documentId}}</strong>. Por favor tente mais tarde ou contacte o serviço de suporte.",

    NEW_CONTRACT_AVAILABLE: "Novo contrato/acordo",
    NEW_CONTRACT_AVAILABLE_MSG: "Foi carregado um novo contrato/acordo que requer a sua assinatura.",

    NEW_CONTRACT_ANNEX_AVAILABLE: "Anexo/listagem de contrato/acordo",
    NEW_CONTRACT_ANNEX_AVAILABLE_MSG: "Um anexo/listagem de contrato/acordo foi alterdo e requer nova assinatura.",

    NEW_CONTRACT_ANNEX_SIGNATURE: "Assinatura de anexo",
    NEW_CONTRACT_ANNEX_SIGNATURE_MSG: "Foi assinado um anexo/listagem de um contrato/acordo que necessita de validação. ",


    NEW_CONTRACT_SIGNATURE: "Assinatura de contrato/acordo",
    NEW_CONTRACT_SIGNATURE_MSG: "Foi assinado um contrato/acordo que necessita de validação. ",

    NEW_DOCUMENT_TO_VALIDATE: "Documento para validação",
    NEW_DOCUMENT_TO_VALIDATE_MSG: "Foi carregado um novo documento na plataforma que requer validação.",

    EQUIPMENT_REGISTRATION_TO_VALIDATE: "Equipamentos importados",
    EQUIPMENT_REGISTRATION_TO_VALIDATE_MSG: "Foram importados equipamentos para a plataforma que necessitam de validação.",

    EQUIPMENT_REGISTRATION_NOT_VALIDATED: "Importação rejeitada",
    EQUIPMENT_REGISTRATION_NOT_VALIDATED_MSG: "A importação <strong>#{{documentId}}</strong> foi rejeitada com o seguinte motivo: <strong>{{additionalInfo.comments}}</strong>.",

    EQUIPMENT_REGISTRATION_VALIDATED: "Importação aprovada",
    EQUIPMENT_REGISTRATION_VALIDATED_MSG: "A importação <strong>#{{documentId}}</strong> foi aprovada com sucesso",

    OPERATION: "{{additionalInfo.title}}",
    OPERATION_MSG: "{{additionalInfo.body}}",

    OPERATION_VALIDATION_PENDING: "Validação pendente",
    OPERATION_VALIDATION_PENDING_MSG: "Tem um pedido de validação de execução de uma operação do tipo <strong>\"{{additionalInfo.ruleName}}\"</strong> que deve ser validado ou rejeitado.",


    NEW_DOCUMENT_ZIP_FILE: "Ficheiro zip ",
    NEW_DOCUMENT_ZIP_FILE_MSG: "Foi gerado um ficheiro temporário com o nome  <i>\"{{additionalInfo.filename}}\"</i> que poderá ser descarregado na sua zona pessoal de documentos.",


    no_results: "Não existem notificações para apresentar"
};

export default pt;
