import React, {useCallback} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import {ReportParamType} from "app/reporting/utils/ReportParamType";

const ParamComponent = React.forwardRef((props, ref) => {

    const {t, entry: param, errors = {}, readOnly = false, onChange, onDelete, globalError, isOperation} = props;

    const handleCondition = useCallback(
        (e) => onChange({
            ...param,
            condition: e.target.value,
        }),
        [param, onChange]
    );

    const handleVariableName = useCallback(
        (e) => onChange({
            ...param,
            variableName: e.target.value,
        }),
        [param, onChange]
    );

    const handleMandatory = useCallback(
        (value) => onChange({
            ...param,
            mandatory: value,
        }),
        [param, onChange]
    );

    const handleReference = useCallback(
        (e) => onChange({
            ...param,
            reference: e.target.value,
        }),
        [param, onChange]
    );


    return <Row>
        <Col xs={12} sm={6} lg={isOperation ? 2 : 4}>
            <FormGroup error={errors['type'] || globalError} mandatory={true}>
                <Form.Label>{t('param_type')}</Form.Label>
                <EnumDropdown
                    t={t}
                    value={getOrNull(param?.type)}
                    options={ReportParamType}
                    isClearable={false}
                    placeholder={t('generic_select_ph')}
                    isSearchable={true}
                    isDisabled={readOnly}
                    onChange={useCallback(
                        (value) => onChange({
                            ...param,
                            type: value,
                        }),
                        [param, onChange]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['type'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={6} lg={isOperation ? 3 : 4}>
            <FormGroup error={errors['label'] || globalError} mandatory={true}>
                <Form.Label>{t('label')}</Form.Label>
                <FormControl
                    type="text"
                    ref={ref}
                    autoComplete="chrome-off"
                    placeholder={t('label_ph')}
                    value={getOrEmpty(param?.label)}
                    disabled={readOnly || !param?.type}
                    ignoresubmit={"true"}
                    onChange={useCallback(
                        (e) => onChange({
                            ...param,
                            label: e.target.value,
                        }),
                        [param, onChange]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['label'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={12} sm={6} lg={2}>
            <FormGroup error={errors['mandatory'] || globalError} mandatory={true}>
                <Form.Label>{t('mandatory')}</Form.Label>
                <BooleanDropdown
                    t={t}
                    value={parseBoolean(param?.mandatory, false)}
                    isClearable={false}
                    placeholder={t('generic_select_ph')}
                    isSearchable={false}
                    isDisabled={readOnly || !param?.type}
                    onChange={handleMandatory}
                />
                <Form.Text className="sub-error">
                    {t(errors['mandatory'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col xs={12} sm={6} lg={2}>
            <FormGroup error={errors['multipleValues'] || globalError} mandatory={true}>
                <Form.Label>{t('multiple_values')}</Form.Label>
                <BooleanDropdown
                    t={t}
                    value={parseBoolean(param?.multipleValues, false)}
                    isClearable={false}
                    placeholder={t('generic_select_ph')}
                    isSearchable={false}
                    isDisabled={readOnly || !param?.type}
                    onChange={useCallback(
                        (value) => onChange({
                            ...param,
                            multipleValues: value,
                        }),
                        [param, onChange]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['multipleValues'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col xs={true} sm={true} lg={true}>
            {isOperation
                ? <FormGroup error={errors['variableName'] || globalError} mandatory={true}>
                    <Form.Label>{t('variable_name')}</Form.Label>
                    <FormControl
                        type="text"
                        autoComplete="chrome-off"
                        placeholder={t('variable_name_ph')}
                        value={getOrEmpty(param?.variableName)}
                        disabled={readOnly || !param?.type}
                        ignoresubmit={"true"}
                        onChange={handleVariableName}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['variableName'])}
                        {t(globalError)}
                    </Form.Text>
                </FormGroup>

                : <FormGroup error={errors['condition'] || globalError} mandatory={true}>
                    <Form.Label>{t('query_condition')}</Form.Label>
                    <FormControl
                        type="text"
                        autoComplete="chrome-off"
                        placeholder={t('condition_ph')}
                        value={getOrEmpty(param?.condition)}
                        disabled={readOnly || !param?.type}
                        ignoresubmit={"true"}
                        onChange={handleCondition}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['condition'])}
                        {t(globalError)}
                    </Form.Text>
                </FormGroup>
            }
        </Col>

        {!isOperation &&
            <Col xs={12} sm={6} lg={4}>
                <FormGroup error={errors['reference'] || globalError}>
                    <Form.Label>{t('reference')}</Form.Label>
                    <FormControl
                        type="text"
                        autoComplete="chrome-off"
                        placeholder={t('reference_ph')}
                        value={getOrEmpty(param?.reference)}
                        disabled={readOnly || !param?.type}
                        ignoresubmit={"true"}
                        onChange={handleReference}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['reference'])}
                        {t(globalError)}
                    </Form.Text>
                </FormGroup>
            </Col>
        }

        {(onDelete && !readOnly) &&
            <Col xs={2} sm={1} lg={1} className="align-self-center">
                <div className={"btn btn-rounded-danger float-end"} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash}/>
                </div>
            </Col>
        }

    </Row>

});

export default ParamComponent;
