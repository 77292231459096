import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import "app/home/assets/css/charts.scss";
import React, {useCallback, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import useChartDownload from "app/home/utils/useChartDownload";
import {ChartColors, ChartFreshColors} from "app/home/utils/ChartColors";
import GenericResolver from "app/home/utils/resolvers/GenericResolver";
import ChartLegendComponent from "app/home/components/charts/ChartLegendComponent";


const NonComplianceChartComponent = props => {

    const {t} = useTranslation('dashboard');

    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const {handleChartDownload, chartRef} = useChartDownload();

    const {data, loading} = useGet({
        path: "/dashboard/non-compliances",
        localErrorOnly: true,
        resolve: useCallback(data => GenericResolver(data, t), [t])
    });

    const handleLabel = useCallback(
        (value, name, props) => {
            if (name === t('total')) {
                return value
            } else {
                return value + " (" + (Math.round((value / props.payload[t('total')]) * 100 * 100)) / 100 + "%)"
            }


        },
        [t]
    );


    return <Box>
        <BoxTitle>{t('non_compliance')}</BoxTitle>

        <ChartButtonsComponent
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />


        <Loading visible={loading}/>


        {data?.length > 0 &&
            <>
                {chartMode === ChartMode.CHART &&
                    <ResponsiveContainer height={350} width="100%">
                        <BarChart data={data} ref={chartRef} barCategoryGap={10} barGap={0}
                                  margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                            <XAxis dataKey={t('type')}
                                   stroke={"var(--chart-line-color)"}
                                   tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                   padding={{left: 0, right: 10}}/>

                            <YAxis
                                stroke={"var(--chart-line-color)"}
                                tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                                padding={{left: 0, right: 10}}/>

                            <Legend content={ChartLegendComponent}/>
                            <CartesianGrid stroke="var(--chart-grid-line)" strokeDasharray="3 1" vertical={false}/>

                            <Tooltip
                                cursor={false}
                                formatter={handleLabel}
                                contentStyle={{
                                    backgroundColor: undefined, border: undefined,
                                }}
                            />

                            <Bar dataKey={t('total')} stackId={1} fill={ChartFreshColors[0]} barSize={10}/>
                            <Bar dataKey={t('closed')} stackId={2} fill={ChartColors[0]} barSize={10}/>
                            <Bar dataKey={t('open')} stackId={2} fill={ChartColors[1]} barSize={10}/>

                        </BarChart>
                    </ResponsiveContainer>
                }

                {chartMode === ChartMode.TABLE &&
                    <TableModeComponent data={data}/>
                }
            </>
        }

        {data?.length === 0 &&
            <div className={"no-chart-results"}>
                {t('no_chart_results')}
            </div>

        }

    </Box>

}

export default NonComplianceChartComponent;
