import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Validations/AcceptOrReject/locales/locales";
import {Trans} from "react-i18next";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import RemoteListDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/RemoteListDropdown";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import Button from "react-bootstrap/Button";
import "modules/Validations/AcceptOrReject/assets/accept-or-reject.scss";

const AcceptOrReject = React.forwardRef((props, ref) => {

    const {
        acceptMessage,
        rejectMessage,
        rejectHasReason,
        rejectReasonsEndpoint,
        onReject,
        onAccept,
    } = props;

    const {t} = useTranslations("acceptOrReject", locale)

    const [isRejectVisible, setRejectVisible] = useState(false);
    const [isAcceptVisible, setAcceptVisible] = useState(false);

    const [rejectReason, setRejectReason] = useState("");
    const [data, setData] = useState(null);

    useEffect(
        () => {
            ref.current = {
                showAccept: (data) => {
                    setAcceptVisible(true);
                    setData(data);
                },
                showReject: (data) => {
                    setRejectVisible(true);
                    setData(data);
                },
                isVisible: isRejectVisible || isAcceptVisible,
            }
        },
        [setAcceptVisible, setRejectVisible, isRejectVisible, isAcceptVisible, ref, setData]
    );


    if (isAcceptVisible) {
        return <PopupConfirm
            title={t('accept_header')}
            message={acceptMessage}
            classConfirm={"success"}
            visible={true}
            onCancel={() => {
                setAcceptVisible(false);
            }}
            onConfirm={() => {
                onAccept(data);
                setAcceptVisible(false);
            }}
        />
    }

    if (isRejectVisible)
        return <Modal
            show={true}
            onHide={() => setRejectVisible(false)}
            className={"modal-accept-or-reject"}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >

            <Modal.Header closeButton>
                <Modal.Title>{t('reject_header')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col lg={12} sm={12}>
                        <Trans>{rejectMessage}</Trans>
                    </Col>

                    {rejectHasReason &&
                    <Col lg={12} sm={12}>
                        <Form className={"reject-form"}>
                            <Row>
                                <Col lg={12} sm={12}>
                                    <FormGroup mandatory={true}>
                                        <Form.Label>{t('reject_reason')}</Form.Label>
                                        {rejectReasonsEndpoint
                                            ? <RemoteListDropdown
                                                url={rejectReasonsEndpoint}
                                                value={getOrEmpty(rejectReason)}
                                                isCreatable={true}
                                                onChange={setRejectReason}
                                            />
                                            : <Form.Control
                                                type="textarea"
                                                as="textarea"
                                                rows={8}
                                                value={getOrEmpty(rejectReason)}
                                                onChange={(e) => setRejectReason(e.target.value)}
                                            />
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    }
                </Row>

                <div className={"accept-reject-buttons"}>

                    <Button variant="secondary" onClick={() => setRejectVisible(false)}>
                        {t("cancel")}
                    </Button>

                    <Button
                        variant="danger"
                        onClick={() => {
                            onReject(rejectReason, data);
                            setRejectVisible(false)
                        }}
                        disabled={rejectHasReason && !rejectReason}>
                        {t("reject")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
});

export default AcceptOrReject;
