import locale from "app/users/locales/locales";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import {auditUserResolver} from "app/users/utils/UserResolver";
import FormComponent from "app/users/components/form/FormComponent";
import {getDiffsInErrorFormat, getOriginalRawObject} from "app/audit/utils/AuditDiff";
import useTranslations from "system/Translations/UseTranslations";

const UserComponent = (props) => {

    const {operation} = props;

    useTranslations("user", locale);
    const diffs = getDiffsInErrorFormat(operation.changes);

    return <>

        <div className={"versions"}>
            <Row>
                <Col lg={true}>
                    <FormComponent
                        {...props}
                        errors={diffs}
                        create={false}
                        loading={false}
                        compare={true}
                        viewMode={true}
                        readOnly={true}
                        user={operation.objectRaw ? auditUserResolver(operation.objectRaw) : operation.user}
                    />

                </Col>

                {operation.changes &&
                <Col lg={6}>
                    <FormComponent
                        {...props}
                        errors={diffs}
                        create={false}
                        loading={false}
                        compare={true}
                        viewMode={true}
                        readOnly={true}
                        user={auditUserResolver(getOriginalRawObject(operation.objectRaw, operation.changes))}
                    />
                </Col>
                }
            </Row>


        </div>
    </>
}

export default UserComponent;
