import useTranslations from "system/Translations/UseTranslations";
import localeBeneficiary from "app/beneficiaries/beneficiary/locales/locales";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import BeneficiaryType from "types/BeneficiaryType";
import {Trans} from "react-i18next";
import {schoolLabelHandler} from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";

const BeneficiaryResultsComponent = props => {

    const {data, query} = props;

    const {t} = useTranslations('beneficiary', localeBeneficiary);

    return <div className={"beneficiary-dashboard-results"}>


        <TableAdvanced hover>
            <thead>
            <tr>

                <th id="vatNumber">
                    {t('vat_number')}
                </th>

                <th id="name">
                    {t('name')}
                </th>

                <th id="type">
                    {t('type')}
                </th>

                <th id="equipment_status" className={" d-none d-sm-table-cell"}>
                    {t('equipment_status')}
                </th>

                <th id="school">
                    {t('school')}
                </th>

                <th/>

            </tr>
            </thead>
            <tbody>

            {data?.content?.map((beneficiary, index) => {

                return <tr key={index}>


                    <td className="fill-empty">
                        {beneficiary.vatNumber}
                    </td>

                    <td className="fill-empty">
                        {beneficiary.name}
                    </td>

                    <td className={"fill-empty"}>
                        {t(beneficiary.type)}
                    </td>

                    <td className={"fill-empty  d-none d-sm-table-cell"}>
                        {t(beneficiary.equipmentStatus)}
                    </td>

                    <td className={"fill-empty  d-none d-sm-table-cell"}>
                        {beneficiary.school?.name &&
                            <>{schoolLabelHandler(beneficiary.school)}</>
                        }
                    </td>

                    <td className="table-buttons">
                        <div className={"buttons"}>
                            <ToolTip content={t('show_beneficiary')}>
                                <TableIconButton
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                    path={beneficiary.type === BeneficiaryType.STUDENT
                                        ? "/student/" + beneficiary?.id
                                        : "/teacher/" + beneficiary?.id
                                    }
                                />
                            </ToolTip>
                        </div>
                    </td>

                </tr>
            })}
            </tbody>
        </TableAdvanced>

        {data?.totalPages > 0 &&
            <div className={"more-results-available"}>

                <Trans>
                {t('more_results_available_beneficiaries')}
                <a href={"/student/search/" + query}>
                    {t('more_results_available_beneficiaries_link_students')}
                </a>
                <a href={"/teacher/search/" + query}>
                    {t('more_results_available_beneficiaries_link_teachers')}
                </a>
                </Trans>
            </div>
        }

    </div>
}

export default BeneficiaryResultsComponent;
