import classnames from "classnames";
import React from "react";
import {useNavigate} from "react-router";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import DateTime from "system/DateTime/DateTime";
import {parseToCss} from "system/Utils/css";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault} from "system/Objects/ObjectParameters";
import {isTicketSelectDisabled} from "app/support/utils/utils";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const ListEntryComponent = props => {

    const {data, t, setSelectedItems, selectedItems, simpleMode, isDashboard} = props;
    const navigate = useNavigate();

    return <>
        {data?.content?.map((ticket, index) => {


            return <tr
                key={index}
                className={classnames("ticket-item", {"new": !ticket.readTimestamp})}
                {...generateOnClickAndEnter(() => {
                    navigate("/support/" + ticket.id);
                }, -1)}
            >

                {!simpleMode &&
                <UserHasPermission permission={["MANAGE_TICKETS"]}>
                    <td className={"table-checkbox"}  onClick={(e) => e.stopPropagation()}>
                        <CheckBox
                            value={getOrDefault(selectedItems?.find(e => e.id === ticket.id), false)}
                            disabled={isTicketSelectDisabled(ticket, selectedItems)}
                            onChange={(value) => setSelectedItems((items) => {
                                if (value) {
                                    return [...selectedItems, ticket];
                                } else {
                                    return [...items].filter(item => item.id !== ticket.id)
                                }
                            })}
                        />
                    </td>
                </UserHasPermission>
                }

                <td className={"td-sorted"} onClick={(e) => e.stopPropagation()}>
                    #{ticket.id}
                </td>

                <td className={"subject"}>
                    <div className={"sub-line"}>
                        {t(ticket.category?.name)}
                        {ticket?.equipment?.id &&
                        <>{t('equipment_ticket', ticket?.equipment)}</>
                        }
                    </div>
                    <div className={"subject fill-empty"}>
                        {ticket.title}
                    </div>
                </td>


                {!isDashboard &&
                <td>
                    {ticket?.creationEntity?.name}
                    <div className={"sub-line"}>
                        {ticket?.creationUser?.name}
                    </div>
                </td>
                }

                {!isDashboard &&
                <td>
                    {ticket?.destinationEntity?.name}
                    <div className={"sub-line"}>
                        {ticket?.destinationUser?.name}
                    </div>
                </td>
                }


                <td className={classnames("td-sorted", {
                    "d-none d-md-table-cell": !isDashboard
                })}>
                    <DateTime format="DD-MM-YYYY">
                        {ticket.lastUpdateTimestamp}
                    </DateTime>

                    <div className={"sub-line"}>
                        <DateTime format="HH:mm">
                            {ticket.lastUpdateTimestamp}
                        </DateTime>
                    </div>
                </td>

                {!isDashboard &&
                <td className={classnames("td-sorted", "fill-empty")}>
                    <div
                        className={classnames("label-entry ticket-status", parseToCss(ticket.status))}>
                        {t(ticket.status)}
                    </div>
                </td>
                }

                {!isDashboard &&
                <td className={classnames("td-sorted", "fill-empty")}>
                    <div
                        className={classnames("label-entry ticket-priority", parseToCss(ticket.priority))}>
                        {t(ticket.priority)}
                    </div>
                </td>
                }
            </tr>
        })
        }
    </>

}

export default ListEntryComponent;
