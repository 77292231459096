import {faUpload} from "@fortawesome/free-solid-svg-icons";
import locale from "layout/modules/FormBuilder/modules/upload/locales/locales";
import generateToolBarEntry from "layout/modules/FormBuilder/modules/module/utils/generateToolBarEntry";
import FormModule from 'layout/modules/FormBuilder/modules/module/FormModule'
import EditorComponent from "layout/modules/FormBuilder/modules/upload/components/EditorComponent";
import ConfigComponent from "layout/modules/FormBuilder/modules/upload/components/ConfigComponent";
import FormComponent from "layout/modules/FormBuilder/modules/upload/components/FormComponent";
import UploadSupportedTypes from "layout/modules/FormBuilder/modules/upload/utils/UploadSupportedTypes";

class UploadModule extends FormModule {

    static getName = () => "UploadModule";
    static getTranslations = () => locale;
    static getToolBarEntry = (t) => generateToolBarEntry(faUpload, t('name'));

    static renderEditor = (props) => <EditorComponent id={props.id} {...props.props}/>
    static renderConfig = (props) => <ConfigComponent  {...props} />
    static renderForm = (props) => <FormComponent {...props} />


    static getDefaultProps = () => ({
        label: null,
        mandatory: false,
        maxFiles: 1,
        acceptTypes: [UploadSupportedTypes.IMAGES, UploadSupportedTypes.PDF],

    });
}

export default UploadModule;
