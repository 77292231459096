import EditorEntryComponent from "layout/modules/FormBuilder/components/editor/editor/EditorEntryComponent";
import getModuleFromEntry from "layout/modules/FormBuilder/utils/editor/getModuleFromEntry";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useCallback} from "react";
import update from "immutability-helper";


const EditorAreaComponent = props => {

    const {data, modules, onDeleteComponent, onEditComponentProps, setData} = props;

    const moveEntry = useCallback((dragIndex, hoverIndex) => {
        setData((prevData) =>
            update(prevData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevData[dragIndex]],
                ],
            }),
        );
    }, [setData]);

    return <div className={"editor-area-component"}>
        <DndProvider backend={HTML5Backend}>

            {data.map((entry, position) => <EditorEntryComponent
                key={entry.id}
                id={entry.id}
                entry={entry}
                position={position}
                moveEntry={moveEntry}
                onDelete={() => onDeleteComponent(position)}
                onEditComponentProps={(data) => onEditComponentProps(position, data)}
                module={getModuleFromEntry(modules, entry)}
            />)}
        </DndProvider>
    </div>
}

export default EditorAreaComponent;
