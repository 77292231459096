import React from "react";
import locale from "app/users/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import {useRemoteEntity} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import DateTime from "system/DateTime/DateTime";
import ListFilterComponent from "app/users/components/list/ListFilterComponent";

const ListTableComponent = (props) => {

    const {
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        onFilterSubmit,
        loading,
        data,
        hideAdd = false,
        entityId,
        className
    } = props;

    const {t} = useTranslations('user', locale);

    const [roles, loadingRoles] = useRemoteEntity(data, '/roles/search', 'roleId');

    return <>

        <TableHeader>
            <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}
                        onSubmit={(data) => onFilterSubmit(data)}>
                <SearchFilter>
                    <ListFilterComponent searchFilter={searchFilter} t={t} disableEntity={entityId}/>
                </SearchFilter>
            </SearchArea>

            <TableHeaderButtonGroup>
                <TableIconAdd
                    path={{
                        pathname: "/user/create",
                        state: {
                            entityId: entityId
                        }
                    }}
                    permission={["WRITE_USER"]}
                    visible={!hideAdd}

                />
            </TableHeaderButtonGroup>

        </TableHeader>

        <SearchDataPagination data={data} onPageChange={onPageChange}/>

        <TableAdvanced hover
                       sort={data?.sortBy}
                       order={data?.sortOrder?.toLowerCase()}
                       onSortClick={onPageSort}
                       loading={(loadingRoles || loading || !data)}
                       className={className}

        >
            <thead>
            <tr>

                <th id="name" sorted>
                    {t('name')}
                </th>

                <th id="email">
                    {t('email')}
                </th>


                {!entityId &&
                <UserHasPermission permission={"READ_ENTITY"}>
                    <th id="entity.name" sorted className="d-none d-xl-table-cell">
                        {t('entity')}
                    </th>
                </UserHasPermission>
                }

                <th id="status" sorted className="d-none d-sm-table-cell">
                    {t('status').label}
                </th>

                <th id="role.name" sorted className="d-none d-lg-table-cell">
                    {t('role_and_type')}
                </th>

                <th id="lastLoginTimestamp" sorted className="d-none d-md-table-cell">
                    {t('lastLogin')}
                </th>

                <th/>

            </tr>
            </thead>
            <tbody>

            {data?.content?.map((value, index) => {
                let role = roles[value.roleId];
                return <tr key={index}>

                    <td className="td-sorted">
                        {value.name}
                    </td>

                    <td>
                        {value.email}
                    </td>

                    {!entityId &&
                    <UserHasPermission permission={"READ_ENTITY"}>
                        <td className="td-sorted d-none d-xl-table-cell fill-empty">
                            {value.entity?.name}
                        </td>
                    </UserHasPermission>
                    }

                    <td className="td-sorted d-none d-sm-table-cell">
                        {t('status')[value.status]}
                    </td>

                    <td className="td-sorted d-none d-lg-table-cell fill-empty">
                        <div className={"over-list-entry"}>
                            {t(value.type)}
                        </div>
                        <div>{role?.name}</div>
                    </td>

                    <td className="td-sorted d-none d-md-table-cell fill-empty">
                        <DateTime format="DD-MM-YYYY | HH:mm:ss">
                            {value.lastLoginTimestamp}
                        </DateTime>
                    </td>

                    <td className="table-buttons">
                        {React.cloneElement(props.children, {value: value, role: role})}
                    </td>
                </tr>
            })}
            </tbody>
        </TableAdvanced>
        {data?.content?.length === 0 &&
        <div className={"no-table-results"}>
            {t('no_users_found')}
        </div>
        }
    </>

}

export default ListTableComponent;
