import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import BooleanDropdown from "layout/modules/Forms/Dropdowns/BooleanDropdown/BooleanDropdown";
import Form from "react-bootstrap/Form";
import {parseBoolean} from "system/Utils/parseBoolean";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";
import FormOneTimeBilling from "app/equipments/equipments/components/form/billing/FormOneTimeBilling";
import EquipmentBillingType from "types/EquipmentBillingType";
import FormMonthlyBilling from "app/equipments/equipments/components/form/billing/FormMonthlyBilling";
import UserHasPermission, {UserHasNoPermission} from "system/API/Authentication/UserHasPermission";

const FormBillingData = props => {

    const {t} = useTranslation('equipments');
    const {equipment, setEquipment, errors, readOnly} = props;
    const billingDetails = equipment?.billingDetails ?? {};

    return <Box>

        <UserHasPermission permission={['READ_FINANCIAL_DATA']}>
            <BoxTitle>{t('billing_data')}</BoxTitle>
        </UserHasPermission>

        <UserHasNoPermission permission={['READ_FINANCIAL_DATA']}>
            <BoxTitle>{t('delivery_data')}</BoxTitle>
        </UserHasNoPermission>

        <Row>

            <Col sm={12} lg={6} xl={6}>

                <FormGroup error={errors['procedureNumber']}>
                    <Form.Label>{t('procedure_number')}</Form.Label>
                    <FormControl
                        type="text"
                        value={getOrEmpty(billingDetails?.procedureNumber)}
                        disabled={readOnly}
                        onChange={useCallback((e) => setEquipment(profile => ({
                            ...profile,
                            billingDetails: {
                                ...profile.billingDetails,
                                procedureNumber: e.target.value,
                            }
                        })), [setEquipment])}

                    />

                    <Form.Text className="sub-error">
                        {t(errors['procedureNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} lg={6} xl={6}>
                <FormGroup error={errors['contractNumber']}>
                    <Form.Label>{t('contract_number')}</Form.Label>
                    <FormControl
                        type="text"
                        value={getOrEmpty(billingDetails?.contractNumber)}
                        disabled={readOnly}
                        onChange={useCallback((e) => setEquipment(profile => ({
                            ...profile,
                            billingDetails: {
                                ...profile.billingDetails,
                                contractNumber: e.target.value,
                            }
                        })), [setEquipment])}

                    />

                    <Form.Text className="sub-error">
                        {t(errors['contractNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} lg={6} xl={6}>
                <FormGroup error={errors['commitmentNumber']}>
                    <Form.Label>{t('commitment_number')}</Form.Label>
                    <FormControl
                        type="text"
                        value={getOrEmpty(billingDetails?.commitmentNumber)}
                        disabled={readOnly}
                        onChange={useCallback((e) => setEquipment(profile => ({
                            ...profile,
                            billingDetails: {
                                ...profile.billingDetails,
                                commitmentNumber: e.target.value,
                            }
                        })), [setEquipment])}

                    />

                    <Form.Text className="sub-error">
                        {t(errors['commitmentNumber'])}
                    </Form.Text>
                </FormGroup>

            </Col>

            <Col sm={12} lg={6} xl={6}>

                <FormGroup error={errors['deliveryNoteNumber']}>
                    <Form.Label>{t('delivery_note')}</Form.Label>
                    <FormControl
                        type="text"
                        value={getOrEmpty(billingDetails?.deliveryNoteNumber)}
                        disabled={readOnly}
                        onChange={useCallback((e) => setEquipment(profile => ({
                            ...profile,
                            billingDetails: {
                                ...profile.billingDetails,
                                deliveryNoteNumber: e.target.value,
                            }
                        })), [setEquipment])}

                    />

                    <Form.Text className="sub-error">
                        {t(errors['deliveryNoteNumber'])}
                    </Form.Text>
                </FormGroup>
            </Col>


            <Col sm={12} lg={6} xl={6}>
                <FormGroup error={errors['deliveryNoteDate']}>
                    <Form.Label>{t('delivery_note_date')}</Form.Label>
                    <DatePicker
                        placeholder={t('date_ph')}
                        timezone={"UTC"}
                        disabled={readOnly}
                        value={getOrEmpty(billingDetails?.deliveryNoteDate)}
                        onChange={useCallback(
                            date => setEquipment(equipment => ({
                                ...equipment,
                                billingDetails: {
                                    ...equipment.billingDetails,
                                    deliveryNoteDate: date,
                                }
                            })), [setEquipment]
                        )}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['deliveryNoteDate'])}
                    </Form.Text>
                </FormGroup>
            </Col>


            <UserHasPermission permission={['READ_FINANCIAL_DATA']}>
                <Col sm={12} lg={6} xl={6}>
                    <FormGroup error={errors['readyToInvoice']}>
                        <Form.Label>{t('ready_to_invoice')}</Form.Label>
                        <BooleanDropdown
                            isDisabled={readOnly}
                            value={parseBoolean(billingDetails?.readyToInvoice, false)}
                            onChange={useCallback((value) => setEquipment(profile => ({
                                ...profile,
                                billingDetails: {
                                    ...profile.billingDetails,
                                    readyToInvoice: value,
                                }
                            })), [setEquipment])}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['readyToInvoice'])}
                        </Form.Text>
                    </FormGroup>
                </Col>


                {equipment.profile?.billingType === EquipmentBillingType.ONE_TIME &&
                <FormOneTimeBilling {...props} />
                }

                {equipment.profile?.billingType === EquipmentBillingType.MONTHLY_SUBSCRIPTION &&
                <FormMonthlyBilling {...props} />
                }

            </UserHasPermission>


        </Row>

    </Box>
}

export default FormBillingData;
