import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import cloneWithoutKeys, {removeEmptyParameters} from "system/Objects/Objects";

const SelectionModeMainCheckBox = props => {

    const {t} = useTranslation('equipments');
    const {
        data,
        selectionMode,
        selectedItems,
        setSelectedItems,
        isInExcludeMode,
        setInExcludeMode,
        allowExcludeMode,
        searchFilter,
        setExcludeFilter
    } = props;


    const cleanedFilter = allowExcludeMode
        ? cloneWithoutKeys(removeEmptyParameters(searchFilter), ['currentPage'])
        : undefined;


    useEffect(
        () => {

            if (allowExcludeMode && selectionMode) {
                setInExcludeMode(false);
                setExcludeFilter(null);
                setSelectedItems([]);
            }
        },
        // eslint-disable-next-line
        [selectionMode, allowExcludeMode, JSON.stringify(cleanedFilter)],
    );

    const handleChange = useCallback(
        (value) => {
            if (!isInExcludeMode && !allowExcludeMode) {
                setSelectedItems([])
            } else if (allowExcludeMode && data?.totalItems > 0) {
                if (!isInExcludeMode && value) {
                    setInExcludeMode(true);
                    setExcludeFilter(cleanedFilter);
                } else {
                    setInExcludeMode(false);
                    setSelectedItems([]);
                }

            }
        },
        // eslint-disable-next-line
        [isInExcludeMode, allowExcludeMode, setInExcludeMode, data?.totalItems, setExcludeFilter, setSelectedItems]
    );

    if (!selectionMode)
        return <></>

    const totalSelectableItems = data?.totalItems;
    const totalItems = isInExcludeMode
        ? totalSelectableItems - selectedItems.length
        : selectedItems.length;


    const square = isInExcludeMode
        ? selectedItems.length > 0
        : !(data?.content?.totalItems > selectedItems.length);


    return <CheckBox
        label={t('item_selected', {count: totalItems})}
        value={totalItems > 0}
        disabled={allowExcludeMode ? false : totalItems === 0}
        square={square}
        onChange={handleChange}
    />
}


export default SelectionModeMainCheckBox;
