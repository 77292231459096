import React from "react";
import ExecuteOperationComponent from "app/ruleTemplates/components/execute/ExecuteOperationComponent";
import ModalExecutionComponent from "app/ruleTemplates/components/execute/single-mode/ModalExecutionComponent";

const SingleExecutionComponent = props => {

    return <ExecuteOperationComponent {...props}>
        <ModalExecutionComponent/>
    </ExecuteOperationComponent>


}

export default SingleExecutionComponent;
