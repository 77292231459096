export const pt = {

    name: "Gerar e assinar contrato/acordo",
    description: "Gera um contrato/acordo do tipo beneficiário para os equipamentos associados. A ação só avança após assinatura do documento",

    invalid_configuration: "Configuração incompleta",
    invalid_configuration_msg: "Por favor verifique a configuração da ação. Deve indicar sempre qual é o modelo do contrato.",

    contract_model: "Modelo do contrato/acordo",

    contract_info_title: "Assinatura de contrato",
    contract_info_message: "Vai ser gerado um contrato do tipo <strong>\"{{name}}\"</strong> que deverá ser assinado e submetido na plataforma." +
        "<br/>Após executar a operação poderá descarregar o contrato gerado no menu procedimentos ou no ecrã do beneficiário.",

    var_ph: "Preencha com {{var}}",

    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",
    MISSING_VALUE: "Deve preencher o campo",


};

export default pt;
