import {useUserHasPermission} from "../../../system/API/Authentication/UserHasPermission";
import {useMemo} from "react";

const DashboardSearchType = {
    EQUIPMENTS: "EQUIPMENTS",
    BENEFICIARIES: "BENEFICIARIES",
}

const useDashboardSearchTypeAuthorized = props => {

    const userHasPermission = useUserHasPermission();

    return useMemo(() => {

        const searchTypes = {};

        if (userHasPermission("READ_BENEFICIARY")) {
            searchTypes[DashboardSearchType.BENEFICIARIES] = DashboardSearchType.BENEFICIARIES;
        }

        if (userHasPermission("READ_EQUIPMENT")) {
            searchTypes[DashboardSearchType.EQUIPMENTS] = DashboardSearchType.EQUIPMENTS;
        }

        return searchTypes;

    }, [userHasPermission]);

}



export {useDashboardSearchTypeAuthorized};
export default DashboardSearchType;
