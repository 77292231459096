import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EnumDropdown from "layout/modules/Forms/Dropdowns/EnumDropdown/EnumDropdown";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import NutDropdown, {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import SchoolDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import {SchoolType} from "types/SchoolType";
import "app/home/assets/css/chart-filter.scss";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import {cloneWithoutKey} from "system/Objects/Objects";
import {getAggregatedInstitutionTypologiesValue} from "app/home/utils/AggregatedInstitutionTypologies";

const NeedsChartFilterComponent = props => {

    const {setFilters, filters} = props;
    const {t} = useTranslation('dashboard');

    return <div className={"chart-data-filter"}>

        <Row>

            <Col md={6} xxl={12}>
                <div className={"label"}>
                    {t('school_type')}
                </div>
                <div className={"value"}>
                    <EnumDropdown
                        t={t}
                        options={cloneWithoutKey(SchoolType, [SchoolType.AE_SCHOOL])}
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.institutionTypologiesRaw)}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({
                                ...filter,
                                institutionTypologies: getAggregatedInstitutionTypologiesValue(value),
                                institutionTypologiesRaw: value,

                            })),
                            [setFilters]
                        )}
                    />

                </div>
            </Col>

            <Col sm={6} lg={6} xxl={12}>
                <FormGroup>
                    <Form.Label>{t('school')}</Form.Label>
                    <SchoolDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.schoolIds)}
                        typologies={filters.institutionTypologiesRaw ?? [SchoolType.AE, SchoolType.ENA, SchoolType.EPCCA]}
                        onChange={(value) => setFilters(filter => ({...filter, schoolIds: value}))}
                    />
                </FormGroup>
            </Col>

            <Col md={6} xxl={12}>
                <div className={"label"}>
                    {t('nut3')}
                </div>
                <div className={"value"}>
                    <NutDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.nut3)}
                        type={NUTType.NUT3}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, nut3: value})),
                            [setFilters]
                        )}
                    />

                </div>

            </Col>

        </Row>

    </div>
}

export default NeedsChartFilterComponent;

