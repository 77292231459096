import InfoMessage from "./InfoMessage";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next"

import "./assets/css/alert-messages.scss"

const AdvanceInfoMessage = (props) => {

    const {title, message, icon, type, iconType, children} = props;

    return <InfoMessage type={type}>
        <div className={"advanced-alert-box " + iconType}>
            <div className={"icon"}>
                <div className={"ball"}>
                    <div className={"icon-container"}>
                        <FontAwesomeIcon icon={icon}/>
                    </div>
                </div>
            </div>

            <div className={"message-area"}>
                <div className={"title"}>{title}</div>
                <div className={"message"}>
                    {children
                        ? children
                        : <Trans>{message}</Trans>
                    }
                </div>
            </div>
        </div>
    </InfoMessage>
}

export default AdvanceInfoMessage;
