import {faBars, faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useMemo, useState} from "react";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";

import "layout/modules/Tabs/assets/css/tab.scss";
import getAsArray from "system/Arrays/getArray";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";

const MobileTabs = (props) => {

    const {defaultActiveKey, children, onSelect} = props;
    const [isOpen, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(defaultActiveKey
        ? defaultActiveKey
        : children[0]?.props?.eventKey);

    const tabs = useMemo(
        () => getAsArray(children).filter(e => e !== undefined).reduce((tabs, tab) => {
            if (tab?.props?.eventKey) {
                return {
                    ...tabs,
                    [tab.props?.eventKey]: {
                        ...tab?.props
                    }
                }
            } else return tabs;
        }, {}),
        [children]
    );

    const handleTabChange = useCallback(
        (id) => {
            setActiveTab(id);
            if (onSelect)
                onSelect(id);
        },
        [onSelect, setActiveTab]
    );

    return <>
        {isOpen &&
        <div className={"tab-overlay"}/>
        }
        <MenuDropdown placement={"bottom-start"} className={"tab-dropdown-menu"} onChange={setOpen}>
            <div className={"mobile-tab-button"}>
                <div className={"icon icon-closed"}>
                    <FontAwesomeIcon icon={faBars}/>
                </div>
                <div className={"icon icon-opened"}>
                    <FontAwesomeIcon icon={faMinus}/>
                </div>
                <div className={"label"}>
                    {tabs[activeTab]?.title}
                </div>
            </div>

            <MenuDropdownContent>
                {Object.entries(tabs).map((value) =>
                    <MenuDropdownOption key={value[0]} visible={!value[1].disabled}>
                        <div {...generateOnClickAndEnter(() => handleTabChange(value[0]), -1)}>
                            {value[1]?.title}
                        </div>
                    </MenuDropdownOption>
                )}
            </MenuDropdownContent>
        </MenuDropdown>

        <div className={"tab-content"}>
            {tabs[activeTab]?.children}
        </div>

    </>

}

export default MobileTabs;
