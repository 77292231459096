import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import React, {useCallback, useEffect, useState} from "react"
import {Trans} from "react-i18next"
import locale from "./locales/locales"
import 'layout/modules/Popups/assets/css/popupConfirm.scss'
import ConfirmationComponent from "./components/ConfirmationComponent";
import useTranslations from "system/Translations/UseTranslations";


const PopupConfirm = (props) => {

    const {t} = useTranslations('_popup', locale);

    const {
        title, message, labelConfirm, classConfirm = "primary",
        labelCancel, classCancel = "secondary", visible = false,
        confirmationString, onCancel, onConfirm, backdrop = true
    } = props;

    //Popup stuff
    const [show, setShow] = useState(visible);
    const [isConfirmed, setConfirmed] = useState(!confirmationString);

    const handleCancel = useCallback(
        () => {
            if (onCancel)
                onCancel();

            setShow(false);
        },
        [setShow, onCancel]
    );

    const handleConfirm = useCallback(
        () => {

            if (onConfirm)
                onConfirm();

            setShow(false);

        },
        [setShow, onConfirm]
    );

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    if (!show) {
        return <></>;
    }

    return <Modal show={show} onHide={handleCancel} centered backdrop={backdrop} className={"confirm"}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Trans>{message}</Trans>

            {confirmationString &&
            <ConfirmationComponent
                {...props}
                setShow={setShow}
                onConfirm={setConfirmed}
            />
            }

            {props.children}

        </Modal.Body>
        <Modal.Footer>
            <Button variant={classCancel} onClick={handleCancel}>
                {labelCancel ? labelCancel : t('cancel')}
            </Button>
            <Button variant={classConfirm} onClick={handleConfirm} disabled={!isConfirmed}>
                {labelConfirm ? labelConfirm : t('continue')}
            </Button>
        </Modal.Footer>
    </Modal>

}

export default PopupConfirm;

