import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import GenericFormError from "modules/Forms/Errors/FormGenericError";
import Loading from "layout/modules/Loading/Loading";
import FormHandleNotSaved from "modules/Forms/NotSaved/FormHandleNotSaved";
import Form from "react-bootstrap/Form";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import {getOrNull} from "system/Objects/ObjectParameters";
import ProcedureDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/ProcedureDropdown";
import EquipmentProfileDropdown
    from "modules/Forms/Dropdowns/ClassDropdown/components/equipment/EquipmentProfileDropdown";
import FormEquipmentData from "app/equipments/equipments/components/form/FormEquipmentData";
import FormWarranty from "app/equipments/equipments/components/form/FormWarranty";
import FormBillingData from "app/equipments/equipments/components/form/billing/FormBillingData";
import EquipmentOptionsComponent from "app/equipments/equipments/components/show/EquipmentOptionsComponent";

const FormComponent = props => {

    const {
        equipment: remoteEquipment,
        loading = true,
        onSubmit,
        create = false,
        onEditMode,
        errors = {},
        compareMode,
        viewMode,
    } = props;

    const {t} = useTranslation('equipments');

    const [equipment, setEquipment] = useState(remoteEquipment);
    const [readOnly, setReadOnly] = useState(viewMode || compareMode);


    useEffect(() => {
        if (equipment === null)
            setEquipment(remoteEquipment);
    }, [remoteEquipment, equipment, setEquipment]);

    //Update EditMode
    useEffect(() => {
        if (onEditMode)
            onEditMode(!readOnly);
    }, [onEditMode, readOnly])


    return <div className={classnames("limited-width", {
        "view-mode": (readOnly && viewMode),
        "compare-mode": compareMode,
    })}>

        <Loading visible={loading}/>

        {!compareMode &&
        <>
            <GenericFormError t={t} errors={errors}/>
            <FormHandleNotSaved
                remote={remoteEquipment}
                local={equipment}
                loading={loading}
                readOnly={readOnly}
                setLocal={setEquipment}
                setReadOnly={setReadOnly}
                onSubmit={onSubmit}
            />
        </>
        }

        <Form autoComplete="chrome-off">
            <Box>
                <BoxTitle>
                    {t('equipment_data')}
                </BoxTitle>

                <BoxOptions visible={!create && !compareMode}>
                    <EquipmentOptionsComponent {...props} setReadOnly={setReadOnly} />
                </BoxOptions>

                <Row>

                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['procedureId']} mandatory={true}>
                            <Form.Label>{t('procedure')}</Form.Label>
                            <ProcedureDropdown
                                value={getOrNull(equipment?.procedureId)}
                                isDisabled={readOnly}
                                onChange={useCallback(
                                    (value, procedure) => setEquipment({
                                        procedureId: value,
                                        procedure: procedure,
                                    }), [setEquipment]
                                )}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['procedure'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>


                    <Col sm={12} lg={6} xl={6}>
                        <FormGroup error={errors['profileId']} mandatory={true}>
                            <Form.Label>{t('profile')}</Form.Label>
                            <EquipmentProfileDropdown
                                value={getOrNull(equipment?.profileId)}
                                isDisabled={!equipment?.procedureId || readOnly}
                                procedureIds={getOrNull(equipment?.procedureId)}
                                onChange={useCallback(
                                    (value, profile) => setEquipment(equipment => ({
                                        ...equipment,
                                        profileId: value,
                                        profile: profile
                                    })), [setEquipment]
                                )}
                            />
                            <Form.Text className="sub-error">
                                {t(errors['profileId'])}
                            </Form.Text>
                        </FormGroup>
                    </Col>
                </Row>


                {(equipment?.profileId || compareMode) &&
                <Row>

                    <FormEquipmentData setEquipment={setEquipment} equipment={equipment} errors={errors}
                                       readOnly={readOnly}/>

                    <FormWarranty setEquipment={setEquipment} equipment={equipment} errors={errors}
                                  readOnly={readOnly}/>

                </Row>
                }

            </Box>

            {(equipment?.profileId || compareMode) &&
            <FormBillingData setEquipment={setEquipment} equipment={equipment} errors={errors}
                             readOnly={readOnly}/>
            }

            {!readOnly &&
            <div>
                <Row>
                    <Col className={"text-end"}>
                        {!create &&
                        <Button variant="secondary" className={"cancel"} onClick={() => setReadOnly(true)}>
                            {t("cancel")}
                        </Button>
                        }
                        <Button variant="primary" className="submit" disabled={readOnly}
                                onClick={() => onSubmit(equipment)}>
                            {create ? t("create") : t("save_changes")}
                        </Button>
                    </Col>
                </Row>
            </div>
            }
        </Form>
    </div>
}

export default FormComponent;
