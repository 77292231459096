import "./assets/lwv.scss";
import classnames from "classnames";

const LabelWithValue = props => {

    const {label, value, children, className} = props;

    return <div className={classnames("label-with-value",className)}>

        <div className={"label"}>
            {label}
        </div>

        <div className={"value fill-empty"}>
            {value ? value : children}
        </div>

    </div>
}

export default LabelWithValue
