import RuleEngineAction from "app/ruleTemplates/actions/action/RuleEngineAction";
import RuleActionType from "app/ruleTemplates/actions/action/utils/RuleActionType";
import generateAddOption from "app/ruleTemplates/actions/action/utils/generateAddOption";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import locale from "app/ruleTemplates/actions/beneficiaryNonCoplianceClose/locales/locales";
import RuleType from "app/ruleTemplates/utils/types/RuleType";


class CloseBeneficiaryNonComplianceAction extends RuleEngineAction {

    static type = RuleActionType.SILENT
    static excludeTypes = [RuleType.SCHOOL];
    static disableConfiguration = true;

    static getTranslations = () => locale;
    static getAddOption = (t) => generateAddOption(faUserCheck, t('name'), t('description'));

    static getName = () => "CloseBeneficiaryNonComplianceAction";
    static getDefaultProps = () => ({});
    static renderEditor = () => null;
    static isAllowed = (config, position) => true;

}

export default CloseBeneficiaryNonComplianceAction;
