import {Table} from "react-bootstrap";
import React, {useCallback, useMemo, useState} from "react";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import ToolTip from "layout/modules/ToolTip/ToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCircleInfo, faLink} from "@fortawesome/free-solid-svg-icons";
import MenuDropdownOption from "layout/modules/MenuDropdown/components/MenuDropdownOption";
import MenuDropdownContent from "layout/modules/MenuDropdown/components/MenuDropdownContent";
import MenuDropdown from "layout/modules/MenuDropdown/MenuDropdown";
import {ReportOutputFormat} from "app/reporting/utils/ReportOutputFormat";
import Loading from "layout/modules/Loading/Loading";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Pagination from "layout/modules/TableAdvanced/components/Pagination";
import Button from "react-bootstrap/Button";
import {geLinkUrl, getOperationId, isLink, isOperation} from "app/reporting/utils/result/parseLink";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import ShowOperationContainer from "app/ruleTemplates/containers/ShowOperationContainer";
import HiddenHeaders, {FILE_ID, getFileIDs} from "app/reporting/utils/HiddenHeaders";
import getAsArray from "system/Arrays/getArray";

const ResultComponent = props => {

    const {t, result, downloading, onDownload, onDownloadFiles, disableExport} = props;
    const itemsPerPage = 20;
    const totalItems = result?.rows?.length;

    const [currentPage, setCurrentPage] = useState(0);

    const rows = useMemo(
        () => result?.rows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
        [result, currentPage]
    );

    const [showOperation, setShowOperation] = useState(false);

    const hiddenPositions = useMemo(
        () => getAsArray(result?.headers?.map((h) => HiddenHeaders.includes(h))),
        [result]
    );

    const hasFiles = useMemo(
        () => !!result?.headers?.find((h) => h === FILE_ID),
        [result]
    );

    const handleFileDownload = useCallback(
        () => onDownloadFiles(getFileIDs(result)),
        [result, onDownloadFiles]
    );

    return <>

        {showOperation &&
            <ShowOperationContainer id={showOperation} onClose={() => setShowOperation(false)}/>
        }

        <Loading visible={downloading}/>
        <Box>
            <BoxTitle>
                {t('report_result', {count: totalItems})}
            </BoxTitle>

            {(!disableExport && totalItems > 0) &&
                <BoxOptions>
                    <MenuDropdown>
                        <ToolTip content={t('download_menu')}>
                            <Button variant={"info"} type={"button"}
                                    className={"btn-rounded-primary with-space with-icon"}>
                                {t('export_options')}
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </Button>
                        </ToolTip>
                        <MenuDropdownContent>
                            {Object.keys(ReportOutputFormat).map(param =>
                                <MenuDropdownOption key={param}>
                                    <div onClick={() => onDownload(param)}>
                                        {t('export_to', {type: t(param)})}
                                    </div>
                                </MenuDropdownOption>
                            )}

                            {hasFiles &&
                                <MenuDropdownOption key={'DOWNLOAD_FILES'}>
                                    <div onClick={handleFileDownload}>
                                        {t('download_files')}
                                    </div>
                                </MenuDropdownOption>
                            }
                        </MenuDropdownContent>
                    </MenuDropdown>
                </BoxOptions>
            }

            <Pagination pages={Math.ceil(totalItems / itemsPerPage)}
                        currentPage={currentPage + 1}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPageChange={(item) => setCurrentPage(item - 1)}/>

            <Table responsive>
                <thead>
                <tr>
                    {result?.headers?.filter((h,i) => !hiddenPositions[i])
                        .map((header, id) => <th key={id}>{header}</th>)
                    }
                </tr>

                </thead>

                <tbody>
                {rows?.map((row, id) =>
                    <tr key={id}>
                        {row?.filter((h,i) => !hiddenPositions[i])
                            .map((cell, id) => {

                            if (isLink(cell)) {
                                return <td key={id}>
                                    <TableIconButton
                                        path={geLinkUrl(cell)}
                                        icon={faLink}
                                        className={"table-btn btn-rounded-primary small"}
                                    />
                                </td>
                            } else if (isOperation(cell)) {
                                return <td key={id}>
                                    <TableIconButton
                                        onClick={() => setShowOperation(getOperationId(cell))}
                                        icon={faCircleInfo}
                                        className={"table-btn btn-rounded-success small"}
                                    />
                                </td>
                            }
                            return <td key={id} className={"fill-empty"}>
                                {cell}
                            </td>

                        })}
                    </tr>
                )}

                <tr>

                </tr>
                </tbody>

            </Table>

        </Box>
    </>


}


export default ResultComponent;
