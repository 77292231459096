import React, {useEffect} from "react"
import {useMutate} from "system/Rest/Rest"
import {useApiState} from "system/API/APIStateContext";
import Loading from "layout/modules/Loading/Loading";

const Logout = (props) => {

    const {setAuthentication} = useApiState();

    const {mutate: logout} = useMutate({
        verb: "DELETE",
        path: "/auth/logout",
        localErrorOnly: true,
    });


    useEffect(() => {
        logout({})
            .then(() => {
                setAuthentication({});
            })
            .catch(() => {
                setAuthentication({})
            });

    }, [setAuthentication, logout]);

    return <Loading visible={true}/>;
}

export default Logout;
