import React from "react";
import {useTranslation} from "react-i18next"
import Container from "react-bootstrap/Container"
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import ListFilterComponent from "app/beneficiaries/students/components/list/ListFilterComponent";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {getOrDefault} from "system/Objects/ObjectParameters";
import classnames from "classnames";
import SelectionModeHeaderCheckbox
    from "app/beneficiaries/beneficiary/components/list/selection-mode/SelectionModeHeaderCheckbox";
import SelectionModeEntryCheckbox
    from "app/beneficiaries/beneficiary/components/list/selection-mode/SelectionModeEntryCheckbox";
import {isNotDefinedNorEmpty} from "system/Utils/isDefined";

const ListComponent = (props) => {

    const {t} = useTranslation('student');

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        loading,
        onFilterSubmit,
        selectionMode,
        maxSelection,
        selectedItems,
        lockedFilters
    } = props;

    const isSelectionDisabled = (selectedItems?.length >= maxSelection)

    return <Container fluid>

        <Box>
            <TableHeader>
                <SearchArea
                    onSearch={onSearch}
                    disableSaving={selectionMode}
                    value={searchFilter.universalSearch}
                    onSubmit={(data) => onFilterSubmit(data)}
                >
                    <SearchFilter>
                        <ListFilterComponent t={t} searchFilter={searchFilter} lockedFilters={lockedFilters}/>
                    </SearchFilter>
                </SearchArea>

                {!selectionMode &&
                <TableHeaderButtonGroup>
                    <TableIconAdd path="/student/create" permission={["WRITE_BENEFICIARY"]}/>
                </TableHeaderButtonGroup>
                }

            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}>
                {selectionMode &&
                <SelectionModeHeaderCheckbox {...props} t={t}/>
                }

            </SearchDataPagination>

            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data)}>
                <thead>
                <tr>

                    {selectionMode &&
                    <th/>
                    }

                    <th id="vatNumber" className={"d-none d-sm-table-cell"}>
                        {t('vat')}
                    </th>

                    <th id="name" sorted>
                        {t('name')}
                    </th>

                    <th id="school" className={"d-none d-sm-table-cell"}>
                        {t('school')}
                    </th>

                    <th id="equipmentStatus" className={"d-none d-sm-table-cell"}>
                        {t('equipment_status')}
                    </th>

                    {!isNotDefinedNorEmpty(searchFilter?.ranks) &&
                    <th>
                        {t('rank')}
                    </th>
                    }

                    {!isNotDefinedNorEmpty(searchFilter?.schoolLevel) &&
                    <th>
                        {t('school_level')}
                    </th>
                    }

                    {!isNotDefinedNorEmpty(searchFilter?.schoolYears) &&
                    <th>
                        {t('school_year')}
                    </th>
                    }

                    {!isNotDefinedNorEmpty(searchFilter?.schoolClass) &&
                    <th>
                        {t('school_class')}
                    </th>
                    }

                    {!selectionMode &&
                    <th></th>
                    }

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((student, index) => {

                    const selectValue = getOrDefault(selectedItems?.find(e => e.id === student.id));
                    const disableLine = isSelectionDisabled && !selectValue;

                    return <tr key={index} className={classnames({"disabled-line": disableLine})}>

                        {selectionMode &&
                        <SelectionModeEntryCheckbox
                            {...props}
                            t={t}
                            isDisabled={disableLine}
                            beneficiary={student}
                        />
                        }

                        <td className="fill-empty d-none d-sm-table-cell">
                            {student.vatNumber}
                        </td>

                        <td className="td-sorted fill-empty">
                            {student.name}
                        </td>


                        <td className="fill-empty d-none d-sm-table-cell">
                            {student.school?.dre}
                            &nbsp;-&nbsp;
                            {student.school?.name}
                        </td>

                        <td className="fill-empty d-none d-sm-table-cell">
                            {t(student.equipmentStatus)}
                        </td>

                        {!isNotDefinedNorEmpty(searchFilter?.ranks) &&
                        <td className="fill-empty">
                            {t(student.rank)}
                        </td>
                        }


                        {!isNotDefinedNorEmpty(searchFilter?.schoolLevel) &&
                        <td>
                            {t(student.schoolLevel)}
                        </td>
                        }


                        {!isNotDefinedNorEmpty(searchFilter?.schoolYears) &&
                        <td>
                            {t(student.schoolYear)}
                        </td>
                        }

                        {!isNotDefinedNorEmpty(searchFilter?.schoolClass) &&
                        <td>
                            {student.schoolClass}
                        </td>
                        }

                        {!selectionMode &&
                        <td className="table-buttons">
                            <div className={"buttons"}>
                                <TableIconButton
                                    path={"/student/" + student.id}
                                    icon={faSearch}
                                    permission={["READ_BENEFICIARY"]}
                                    className={"table-btn btn-rounded-primary small"}
                                />

                            </div>
                        </td>
                        }

                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_student_found')}
            </div>
            }
        </Box>
    </Container>

}

export default ListComponent;
