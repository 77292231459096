import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next"
import Container from "react-bootstrap/Container"
import {faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import ListFilterComponent from "./ListFilterComponent";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "layout/modules/SearchArea/SearchArea";
import SearchFilter from "layout/modules/SearchArea/components/SearchFilter";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";
import {useApiState} from "system/API/APIStateContext";
import {UserType} from "types/UserType";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";
import {getOrDefault} from "system/Objects/ObjectParameters";
import classnames from "classnames";

const ListComponent = (props) => {

    const {t} = useTranslation('school');
    const {authentication} = useApiState();

    const {
        data,
        searchFilter,
        onSearch,
        onPageChange,
        onPageSort,
        loading,
        onFilterSubmit,
        onDelete,
        parentIds,
        selectionMode,
        maxSelection,
        selectedItems,
        setSelectedItems,
        lockedFilters
    } = props;

    const isSelectionDisabled = (selectedItems?.length >= maxSelection)

    const [deletedInfo, setDeletedInfo] = useState({});

    return <Container fluid>

        <PopupConfirm
            title={t('DELETE_SCHOOL_CONFIRM')}
            message={t('DELETE_SCHOOL_CONFIRM_MSG', {name: deletedInfo.name})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={() => {
                setDeletedInfo({})
            }}
            onConfirm={useCallback(() => {
                setDeletedInfo({})
                onDelete(deletedInfo.id, {name: deletedInfo.name});
            }, [setDeletedInfo, deletedInfo.id, deletedInfo.name, onDelete])}
            confirmationString={deletedInfo.name}
        />

        <Box className={classnames("school-list", {"selection-disabled": isSelectionDisabled})}>
            <TableHeader>
                <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}
                            onSubmit={(data) => onFilterSubmit(data)} disableSaving={!!parentIds}>
                    {authentication?.type === UserType.NORMAL &&
                    <SearchFilter>
                        <ListFilterComponent t={t} searchFilter={searchFilter} parentIds={parentIds} lockedFilters={lockedFilters}/>
                    </SearchFilter>
                    }
                </SearchArea>
                {!selectionMode &&
                <TableHeaderButtonGroup>
                    <TableIconAdd path="/school/create" permission={["WRITE_SCHOOL"]}/>
                </TableHeaderButtonGroup>
                }
            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}>
                {selectionMode &&
                <CheckBox
                    label={t('item_selected', {count: selectedItems.length})}
                    value={selectedItems.length > 0}
                    disabled={selectedItems.length === 0}
                    square={!(data?.content?.totalItems > selectedItems.length)}
                    onChange={() => setSelectedItems([])}
                />
                }

            </SearchDataPagination>

            <TableAdvanced hover
                           sort={data?.sortBy}
                           order={data?.sortOrder?.toLowerCase()}
                           onSortClick={onPageSort}
                           loading={(loading || !data)}>
                <thead>
                <tr>

                    {selectionMode &&
                    <th/>
                    }

                    <th id="dgeecCode" className={"d-none d-sm-table-cell"} sorted>
                        {t('dgeec_code')}
                    </th>

                    <th id="dre" className={"d-none d-sm-table-cell"} sorted>
                        {t('dre')}
                    </th>


                    <th id="name" sorted>
                        {t('name')}
                    </th>

                    <th id="typology" className={"d-none d-sm-table-cell"}>
                        {t('typology')}
                    </th>

                    {!parentIds &&
                    <th id="group" className={"d-none d-sm-table-cell"}>
                        {t('group')}
                    </th>
                    }

                    <th></th>

                </tr>
                </thead>
                <tbody>

                {data?.content?.map((school, index) => {

                    const selectValue = getOrDefault(selectedItems?.find(e => e.id === school.id));
                    const disableLine = isSelectionDisabled && !selectValue;

                    return <tr key={index} className={classnames({"disabled-line": disableLine})}>

                        {selectionMode &&
                        <td className={"table-checkbox"}>
                            <CheckBox
                                disabled={disableLine}
                                value={getOrDefault(selectedItems.find(e => e.id === school.id), false)}
                                onChange={(value) => setSelectedItems((items) => {
                                    if (value) {
                                        return [...selectedItems, school];
                                    } else {
                                        return [...items].filter(item => item.id !== school.id)
                                    }
                                })}
                            />
                        </td>
                        }

                        <td className={"td-sorted d-none d-sm-table-cell fill-empty"}>
                            {school.dgeecCode}
                        </td>

                        <td className={"td-sorted d-none d-sm-table-cell fill-empty"}>
                            {school.dre}
                        </td>

                        <td className="td-sorted">
                            {school.name}
                        </td>

                        <td className={"d-none d-sm-table-cell"}>
                            {t(school.typology)}
                        </td>

                        {!parentIds &&
                        <td className={"d-none d-sm-table-cell fill-empty"}>
                            {school.parent?.name}
                        </td>
                        }

                        <td className="table-buttons">
                            {!selectionMode &&
                            <div className={"buttons"}>
                                <TableIconButton
                                    path={"/school/" + school.id}
                                    icon={faSearch}
                                    permission={["READ_SCHOOL"]}
                                    className={"table-btn btn-rounded-primary small"}
                                />

                                <TableIconButton
                                    icon={faTrash}
                                    permission={"DELETE_SCHOOL"}
                                    className={"table-btn btn-rounded-danger small"}
                                    onClick={() => {
                                        setDeletedInfo({
                                            id: school.id,
                                            name: school.name
                                        })
                                    }}

                                />
                            </div>
                            }
                        </td>

                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_school_found')}
            </div>
            }
        </Box>
    </Container>

}

export default ListComponent;
