import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {useTranslation} from "react-i18next";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import React, {useCallback, useMemo, useState} from "react";
import ChartMode from "app/home/utils/ChartMode";
import TableModeComponent from "app/home/components/charts/TableModeComponent";
import {useGet} from "system/Rest/Rest";
import Loading from "layout/modules/Loading/Loading";
import createPersistedState from "system/System/PersistedState/PersistenState";
import ChartButtonsComponent from "app/home/components/charts/ChartButtonsComponent";
import BeneficiaryChartFilterComponent from "app/home/components/charts/beneficiary/BeneficiaryChartFilterComponent";
import BeneficiaryStatusResolver from "app/home/utils/resolvers/BeneficiaryStatusResolver";
import "app/home/assets/css/charts.scss";
import useChartDownload from "app/home/utils/useChartDownload";
import {UserType} from "types/UserType";
import {useApiState} from "system/API/APIStateContext";
import ChartLegendComponent from "app/home/components/charts/ChartLegendComponent";

const useStateChartPersistedState = createPersistedState('beneficiary-dashboard-chart-filter');

const BeneficiaryChartComponent = props => {

    const {t} = useTranslation('dashboard');

    const [isFilterVisible, setFilterVisible] = useState(false);
    const [chartMode, setChartMode] = useState(ChartMode.CHART);
    const [filters, setFilters] = useStateChartPersistedState({});
    const {handleChartDownload, chartRef} = useChartDownload();
    const {authentication} = useApiState();

    const canFilter = authentication.type === UserType.NORMAL;

    const {data, loading} = useGet({
        path: "/dashboard/beneficiary-status", localErrorOnly: true, queryParams: useMemo(() => ({
            ...filters,
        }), [filters]), resolve: useCallback(data => BeneficiaryStatusResolver(data, t), [t])
    });

    return <Box>
        <BoxTitle>{t('beneficiary_status')}</BoxTitle>

        <ChartButtonsComponent
            setFilterVisible={canFilter ? setFilterVisible : undefined}
            isFilterVisible={isFilterVisible}
            chartMode={chartMode}
            setChartMode={setChartMode}
            data={data}
            handleChartDownload={handleChartDownload}
        />



        {(isFilterVisible && canFilter) && <BeneficiaryChartFilterComponent filters={filters} setFilters={setFilters}/>}

        <Loading visible={loading}/>

        {data?.length > 0 && <>
            {chartMode === ChartMode.CHART &&
                <ResponsiveContainer height={350} width="100%">
                    <BarChart data={data} ref={chartRef} barCategoryGap={0} barGap={0}
                              margin={{top: 0, right: 0, bottom: 0, left: 0}}>

                        <XAxis dataKey="label"
                               stroke={"var(--chart-line-color)"}
                               tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                               allowDataOverflow
                               padding={{left: 0, right: 10}}/>

                        <CartesianGrid stroke="var(--chart-grid-line)" strokeDasharray="3 1" vertical={false} />

                        <YAxis
                            stroke={"var(--chart-line-color)"}
                            tick={{fontSize: 13, fill: "var(--chart-text-color)"}}
                            padding={{left: 0, right: 10}}/>

                        <Legend content={ChartLegendComponent}/>

                        <Tooltip
                            cursor={false}
                            formatter={(value, name, props) => value + " (" + (Math.round((value / props.payload?.total) * 100 * 100)) / 100 + "%)"}
                            contentStyle={{
                                backgroundColor: undefined, border: undefined,
                            }}
                        />

                        <Bar dataKey={t('WITH_EQUIPMENT')} stackId={1} fill="var(--color-1)" barSize={10} />
                        <Bar dataKey={t('PARTIAL_EQUIPMENT')} stackId={1} fill="var(--color-7)" barSize={10} />
                        <Bar dataKey={t('NO_EQUIPMENT')} stackId={1} fill="var(--color-14)" barSize={10} />
                        <Bar dataKey={t('REFUSED')} stackId={1} fill="var(--color-100)" barSize={10}/>

                    </BarChart>
                </ResponsiveContainer>}

            {chartMode === ChartMode.TABLE && <TableModeComponent data={data}/>}
        </>}

        {data?.length === 0 && <div className={"no-chart-results"}>
            {t('no_chart_results')}
        </div>

        }

    </Box>

}

export default BeneficiaryChartComponent;
