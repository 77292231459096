import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React, {useCallback, useEffect, useMemo} from "react";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import SchoolDropdown, {
    schoolLabelHandlerWithType
} from "modules/Forms/Dropdowns/ClassDropdown/components/SchoolDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Filters/schoolFilter/locales/locales";
import {SchoolType} from "types/SchoolType";
import getAsArray from "system/Arrays/getArray";
import Select from "react-select";
import useHistoryState from "system/Routing/useHistoryState";
import arrayEquals from "system/Arrays/equals";


const generateInternalValue = (selectedIds, mainSelection, subSelection) => {

    const output = [];
    selectedIds.forEach(id => {

        if (mainSelection?.value.includes(id)) {
            output.push(mainSelection.data.find((e) => e.id === id))
        } else if (subSelection?.value.includes(id)) {
            output.push(subSelection.data.find((e) => e.id === id))
        }
    })

    return output;

}

const SchoolFilterComponent = props => {

    const {
        value,
        setValue,
        typologies = [SchoolType.AE_SCHOOL, SchoolType.AE, SchoolType.EPCCA, SchoolType.ENA],
        isDisabled,
        id = 'schoolId',
        isMulti,
        sm, lg, md, xxl
    } = props;

    const {t} = useTranslations("schoolFilter", locale);
    const {t: tDropdown} = useTranslations('classDropdown', locale);

    const [mainSelections, setMainSelections] = useHistoryState("school-ms-filter-" + id, {
        value: null, data: null,
    });
    const [subSelections, setSubSelections] = useHistoryState("school-ss-filter-" + id, {
        value: null, data: null,
    });

    const [internalValue, setInternalValue] = useHistoryState("school-iv-filter-" + id, null);
    const internalOptions = useMemo(() => internalValue?.map(e => ({
        label: schoolLabelHandlerWithType(e, tDropdown), value: e.id
    })), [internalValue, tDropdown]);

    const typologiesForUO = useMemo(() => typologies?.filter(t => t !== SchoolType.AE_SCHOOL), [typologies]);
    const aeSchoolIsPresentOnFilter = useMemo(() => getAsArray(typologies).length === 0 || typologies?.includes(SchoolType.AE_SCHOOL), [typologies]);
    const selectedAEs = useMemo(() => getAsArray(mainSelections.data).filter(s => s.typology === SchoolType.AE), [mainSelections.data]);

    const handleSubSelection = useCallback((value, data) => {
        setSubSelections({
            data: data, value: value
        });
    }, [setSubSelections]);

    //Update selected valeus
    useEffect(() => {
            let selectedEntities = [].concat(getAsArray(mainSelections.value));
            for (const aeSchool of getAsArray(subSelections.data)) {
                if (mainSelections.value?.includes(aeSchool.parent.id)) {
                    selectedEntities = selectedEntities.filter(e => e !== aeSchool.parent.id);
                    selectedEntities.push(aeSchool.id);
                }
            }

            setInternalValue(generateInternalValue(selectedEntities, mainSelections, subSelections));

            if (!arrayEquals(selectedEntities, value))
                setValue(selectedEntities);

        }, // eslint-disable-next-line
        [mainSelections, subSelections]);

    //Catch filter external remove
    useEffect(() => {

        internalValue?.forEach((iv) => {

            if (!value || (!value.includes(iv.id) && !value.includes(iv.id.toString()))) {

                if (iv.parent?.id && subSelections?.data?.filter(i => i.parent?.id === iv.parent.id)?.length === 1) {
                    setMainSelections(mainSelections => ({
                        data: mainSelections.data?.filter(i => i.id !== iv.parent.id),
                        value: mainSelections.value?.filter(i => i !== iv.parent.id)
                    }));
                }

                setMainSelections(mainSelections => ({
                    data: mainSelections.data?.filter(i => i.id !== iv.id),
                    value: mainSelections.value?.filter(i => i !== iv.id)
                }));

                setSubSelections(subSelections => ({
                    data: subSelections.data?.filter(i => i.id !== iv.id),
                    value: subSelections.value?.filter(i => i !== iv.id)
                }));
            }
        });

        // eslint-disable-next-line
    }, [value]);


    return <>

        <Col sm={sm ?? 12} lg={lg ?? 6} md={md ?? 6} xxl={xxl ?? 6}>
            <FormGroup filter-hidden={"true"}>
                <Form.Label>{t('uo')}</Form.Label>
                <SchoolDropdown
                    typologies={typologiesForUO}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    value={getOrNull(mainSelections?.value)}
                    onChange={useCallback((value, data) => {
                        setMainSelections({
                            data: data, value: value,
                        });
                    }, [setMainSelections])}
                />
            </FormGroup>
        </Col>

        {(selectedAEs.length > 0 && aeSchoolIsPresentOnFilter) &&
            <Col sm={sm ?? 12} lg={lg ?? 6} md={md ?? 6} xxl={xxl ?? 6}>
            <FormGroup filter-hidden={"true"}>
                <Form.Label>{t('schools_from_uo')}</Form.Label>
                <SchoolDropdown
                    typologies={[SchoolType.AE_SCHOOL]}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    parentIds={selectedAEs.map(s => s.id)}
                    value={getOrNull(subSelections.value)}
                    onChange={handleSubSelection}
                />
            </FormGroup>
        </Col>}

        <Col sm={sm ?? 12} lg={lg ?? 6} className={"d-none"}>
            <FormGroup>
                <Form.Label>{t('uo_schools')}</Form.Label>
                <Select
                    id={id}
                    name={id}
                    classNamePrefix={"rs"}
                    className={"rs"}
                    isMulti={true}
                    key={internalOptions}
                    value={internalOptions ?? []}
                    options={internalOptions ?? []}
                />
            </FormGroup>
        </Col>
    </>

}

export default SchoolFilterComponent;