import Loading from "layout/modules/Loading/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfoMessage from "layout/modules/InfoMessages/InfoMessage";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Button from "react-bootstrap/Button";
import React, {useCallback, useEffect, useState} from "react";
import locale from "app/login/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import AuthCode from "react-auth-code-input";
import {Trans} from "react-i18next";
import BasePageLayout from "app/login/components/BasePageLayout";
import "app/login/assets/css/two-factor.scss"
import {Form} from "react-bootstrap";
import useHandleFormSubmit from "system/Forms/FormHandling";
import {Link} from "react-router-dom";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import HelpContainer from "app/login/containers/HelpContainer";

const TwoFactorAuthComponent = props => {

    const {loading, error, onAuthenticate, codeValidity, onCodeExpired} = props;
    const {t} = useTranslations('login', locale);
    const [code, setCode] = useState("");

    const isInvalid = code?.length < 6 || !code;

    const [remainValidity, setRemainValidity] = useState({minutes: 5, seconds: 59});

    const handleLoginSubmit = useHandleFormSubmit(useCallback(
        () => onAuthenticate(code),
        [code, onAuthenticate]
    ));

    useEffect(() => {
        const interval = setInterval(() => {
            const remainingTime = (codeValidity - Date.now()) / 1000;

            if (remainingTime > 0) {
                setRemainValidity({
                    minutes: (remainingTime - (remainingTime % 60)) / 60,
                    seconds: Math.round(remainingTime % 60),
                });
            } else {
                onCodeExpired();
            }

        }, 1000);

        return () => clearInterval(interval);
    }, [setRemainValidity, codeValidity, onCodeExpired]);


    return <BasePageLayout>
        <Loading visible={loading}/>

        <div className={"two-fa-area"}>
            <Row className={["justify-content-md-center"]}>
                <Col md="auto" className="login-title">
                    {t('title_2fa')}
                </Col>
            </Row>

            <Row className={["justify-content-md-center", "login-side-bars"]}>
                <Col className={["login-form-area"]}>

                    <InfoMessage type={"info"}>
                        <Trans>{t('description_2fa', remainValidity)}</Trans>
                    </InfoMessage>

                    <Form autoComplete="off" onSubmit={(data) => handleLoginSubmit(data)}>

                        <FormGroup error={error}>
                            <div className={"code-area"}>
                                <AuthCode
                                    allowedCharacters='numeric'
                                    length={6}
                                    inputClassName='form-control'
                                    onChange={setCode}
                                />
                            </div>
                            <Form.Text className="sub-label">

                            </Form.Text>
                        </FormGroup>


                        <Row className="justify-content-md-center">
                            <Col className="text-center">
                                <Button variant="primary" type="submit" disabled={isInvalid}>
                                    {t('submit_2fa')}
                                </Button>
                            </Col>
                        </Row>

                        <div className={"login-links"}>

                            <div>
                                <Link {...generateOnClickAndEnter(onCodeExpired)}>
                                    {t('go_to_login')}
                                </Link>
                            </div>
                            <HelpContainer/>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    </BasePageLayout>;
}

export default TwoFactorAuthComponent;
