import en from "layout/modules/FormBuilder/modules/help/locales/en"
import pt from "layout/modules/FormBuilder/modules/help/locales/pt"

const locale = [
    {
        lang: 'en-US',
        name: "English",
        translation: en
    },
    {
        lang: 'pt-PT',
        name: "Português",
        translation: pt
    }

];

export default locale;

