export const pt = {

    name: "Caixa de ajuda",
    title: "Titulo",
    text: "Corpo",

    title_default: "Titulo de exemplo",
    text_default: "Corpo de exemplo"


};

export default pt;
