import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import GlobalEntityDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/GlobalEntityDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import React, {useCallback} from "react";
import UserDropdown from "modules/Forms/Dropdowns/ClassDropdown/components/UserDropdown";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import {useTranslation} from "react-i18next";

const DestinationComponent = props => {

    const {errors, setRequest, request, disableEntitySelection,excludeSelf} = props;
    const {t} = useTranslation("support");


    return <UserHasPermission permission={['SET_TICKET_DESTINATION']}>
        <Col sm={12} md={12}>
            <FormGroup mandatory={true} error={errors['destinationEntityId']}>
                <Form.Label>{t('destination')}</Form.Label>
                <GlobalEntityDropdown
                    excludeSelf={excludeSelf}
                    isDisabled={disableEntitySelection}
                    value={getOrNull(request?.destinationEntityId)}
                    onlyRootEntities={true}
                    onChange={useCallback((value) => setRequest(r => ({
                        ...r,
                        destinationEntityId: value,
                        destinationUserId: null,
                    })), [setRequest])}
                />
                <Form.Text className="sub-error">
                    {t(errors['destinationEntityId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} md={12}>
            <FormGroup mandatory={false} error={errors['destinationUserId']}>
                <Form.Label>{t('destination_user')}</Form.Label>
                <UserDropdown
                    entityId={getOrNull(request?.destinationEntityId)}
                    isDisabled={!request?.destinationEntityId}
                    value={getOrNull(request?.destinationUserId)}
                    onChange={useCallback((value) => setRequest(r => ({
                        ...r,
                        destinationUserId: value,
                    })), [setRequest])}
                />
                <Form.Text className="sub-error">
                    {t(errors['destinationUserId'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </UserHasPermission>

}

export default DestinationComponent;
