import SupportMenuButtonsComponet from "app/support/components/menu/SupportMenuButtonsComponet";
import React, {useMemo} from "react";
import Poll from "system/Rest/Poll";

const SupportButtons = React.forwardRef((props, ref) => {

    return <Poll
        path="/tickets/search"
        localErrorOnly={true}
        interval={30 * 1000}
        queryParams={useMemo(() => ({
            isRead: false,
            itemsPerPage: 1,
        }),[])}>
        {(data) => <SupportMenuButtonsComponet {...props} data={data} ref={ref}/>}
    </Poll>
});

export default SupportButtons;
