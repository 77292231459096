import "layout/modules/TableAdvanced/assets/css/header-button-group.scss";

const TableHeaderButtonGroup = (props) => {

    return <div className={"table-advanced-button-group"}>
        {props.children}
    </div>
}

export default TableHeaderButtonGroup;
