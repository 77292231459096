import React from "react";

const MenuDropdownOption = (props) => {

    const {visible = true} = props;

    if (visible) {
        return <div className={"menu-drop-down-option"}>
            {React.cloneElement(props.children, {
                "role": "menu-item",
                tabIndex: -1,
            })}
        </div>
    } else {
        return <></>
    }

}

export default MenuDropdownOption;
