const getInitials = (nameRaw) => {

    if (!nameRaw)
        return "--";



    const namesArray = nameRaw.trim().split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

const camelCaseToSnake = camelCaseString => camelCaseString?.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();


export {getInitials, camelCaseToSnake};
