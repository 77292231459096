import useTranslations from "system/Translations/UseTranslations";
import locale from "modules/Forms/Dropdowns/ClassDropdown/locales/locales";
import GenericRemoteClassDropdown from "modules/Forms/Dropdowns/ClassDropdown/GenericRemoteClassDropdown";
import usePlaceHolder from "modules/Forms/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Forms/Dropdowns/ClassDropdown/utils/useLabelCallback";
import React, {useMemo} from "react";
import UserHasPermission from "system/API/Authentication/UserHasPermission";

const SupplierDropdown = props => {

    const {t} = useTranslations('classDropdown', locale);

    const {
        id = "supplierId",
        placeholder,
        noOptionsMessage,
        loadingMessage,
        maxItems = 50,
    } = props;

    return  <UserHasPermission permission={["READ_SUPPLIER"]}>
        <GenericRemoteClassDropdown
        {...props}
        id={id}
        placeholder={usePlaceHolder(placeholder,t('supplier_ph'))}
        noOptionsMessage={useLabelCallback(noOptionsMessage,t('generic_no'))}
        loadingMessage={useLabelCallback(loadingMessage,t('generic_loading'))}
        remotePath={"/suppliers/search"}
        remoteClassPath={"/suppliers/search"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: maxItems,
        }), [maxItems])}/>

    </UserHasPermission>

}

export default SupplierDropdown;
