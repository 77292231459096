import {useCallback, useEffect} from "react";
import {handleEnterDownAndSpace, handleEscapePress} from "system/Acessibility/handleKeyboard";
import useKeyboardNavigation from "system/Acessibility/useKeyboardNavigation";

const useMenuDropdownAccessibility = props => {

    const {isVisible, onChange, setVisible, disabled, menuRef, target} = props;

    const escapePressed = useKeyboardNavigation({
        element: menuRef?.current?.firstChild,
        enable: isVisible && !disabled,
        selector: '.menu-drop-down-option',
        handleFocus: useCallback(
            (element) => {
                if (element.firstChild) {
                    element.firstChild.focus();
                }
            },
            []
        )
    });

    useEffect(
        () => {
            if (isVisible) {
                if (onChange) onChange(false);
                setVisible(false);

                if (target.current?.firstChild)
                    target.current.firstChild.focus();
            }
        },
        // eslint-disable-next-line
        [escapePressed]
    )


    return useCallback(
        (e) => {

            handleEnterDownAndSpace(e, () => {
                if (!isVisible) {
                    if (onChange) onChange(true);
                    setVisible(!disabled);
                }
            });

            handleEscapePress(e, () => {
                if (onChange) onChange(false);
                setVisible(false);
            })

        },
        [onChange, setVisible, isVisible, disabled]
    );


}

export default useMenuDropdownAccessibility;
