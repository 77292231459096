import {AuditObjectTypes} from "./AuditObjectTypes";
import {useEffect, useState} from "react";
import {useGetRemoteEntities} from "modules/GenericRemoteClassFetcher/GenericSubClassFetcher";

const useLogDocument = (data) => {

    const [finalData, setFinalData] = useState([]);
    const [objectIds, setObjectIds] = useState({});

    const [users, loadingUsers] = useGetRemoteEntities("/users/search", objectIds.users, "READ_USER");
    const [roles, loadingRoles] = useGetRemoteEntities("/roles/search", objectIds.roles, "READ_ROLE");
    const [entityProfiles, loadingEntityProfiles] = useGetRemoteEntities("/entity-profiles/search", objectIds.entityProfiles);
    const [entities, loadingEntities] = useGetRemoteEntities("/entities/search", objectIds.entities, "READ_ENTITY");
    const [reports, loadingReports] = useGetRemoteEntities("/reports/template", objectIds.reports, "GENERATE_REPORT");
    const [operations, loadingOperations] = useGetRemoteEntities("/operations/template", objectIds.operations, "RUN_OPERATION");
    const [contractModels, loadingContractModels] = useGetRemoteEntities("/contract-models/", objectIds.contractModels, "MANAGE_CONTRACT_MODELS");
    const [equipments, loadingEquipments] = useGetRemoteEntities("/equipments/search", objectIds.equipments, "READ_EQUIPMENT");
    const [beneficiaries, loadingBeneficiaries] = useGetRemoteEntities("/beneficiaries/search", objectIds.beneficiaries, "READ_BENEFICIARY");

    //Iterate data and set remote objects
    useEffect(
        () => setFinalData(
            data?.content?.map((value) => getRemoteObject(value, {
                users: users,
                roles: roles,
                entityProfiles: entityProfiles,
                entities: entities,
                reports: reports,
                operations: operations,
                contractModels: contractModels,
                equipments: equipments,
                beneficiaries: beneficiaries,
            }))
        ),
        [users, roles, entityProfiles, entities, reports, operations, equipments, contractModels, beneficiaries, data?.content]
    )

    //Get Objects IDs and fetch objects from server
    useEffect(
        () => {
            const objectIds = {
                roles: [],
                users: [],
                entities: [],

                configurations: [],
                helpTopics: [],
                observations: [],

                reports: [],
                operations: [],

                contractModels: [],
                equipments: [],

                beneficiaries: [],

                unknown: []
            };

            if (!data)
                return;

            // eslint-disable-next-line
            data?.content?.forEach((value) => {
                getEntryId(value, objectIds)
            });
            setObjectIds(objectIds);
        },
        [data, setObjectIds]
    );


    return [finalData,
        loadingUsers || loadingRoles ||
        loadingEntityProfiles || loadingEntities ||
        loadingReports || loadingOperations || loadingContractModels ||
        loadingEquipments || loadingBeneficiaries
    ];

}

// Filter remote objects from log entry
const getRemoteObject = (value, objects) => {

    const objectType = value.affectedObjectType ?? value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.User:
            return addKeyFromRemoteObject(value, objects.users, 'user', objectId);
        case AuditObjectTypes.UserRole:
            return addKeyFromRemoteObject(value, objects.roles, 'role', objectId);
        case AuditObjectTypes.Entity:
            return addKeyFromRemoteObject(value, objects.entities, 'entity', objectId);
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            return addKeyFromRemoteObject(value, objects.reports, 'report', objectId);
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            return addKeyFromRemoteObject(value, objects.operations, 'operation', objectId);
        case AuditObjectTypes.ContractModel:
            return addKeyFromRemoteObject(value, objects.contractModels, 'contractModel', objectId);
        case AuditObjectTypes.Equipment:
            return addKeyFromRemoteObject(value, objects.equipments, 'equipment', objectId);
        case AuditObjectTypes.Student:
        case AuditObjectTypes.Teacher:
            return addKeyFromRemoteObject(value, objects.beneficiaries, 'beneficiary', objectId);
        case AuditObjectTypes.Template:
        case AuditObjectTypes.Configuration:
        case AuditObjectTypes.HelpTopic:
        case AuditObjectTypes.Observation:
        default:
            return {
                ...value,
            }
    }

}

const getEntryId = (value, objectIds) => {

    const objectType = value.affectedObjectType ??  value.objectType;
    const objectId = value.affectedObjectType ? value.affectedObjectId : value.objectId;

    switch (objectType) {
        case AuditObjectTypes.User:
            objectIds.users.push(objectId)
            break;
        case AuditObjectTypes.Entity:
            objectIds.entities.push(objectId)
            break;
        case AuditObjectTypes.UserRole:
            objectIds.roles.push(objectId)
            break;
        case AuditObjectTypes.Template:
            objectIds.templates.push(objectId)
            break;
        case AuditObjectTypes.Configuration:
            objectIds.configurations.push(objectId)
            break;
        case AuditObjectTypes.HelpTopic:
            objectIds.helpTopics.push(objectId)
            break;
        case AuditObjectTypes.Observation:
            objectIds.observations.push(objectId)
            break;
        case AuditObjectTypes.Report:
        case AuditObjectTypes.ReportSchedule:
            objectIds.reports.push(objectId)
            break;
        case AuditObjectTypes.ContractModel:
            objectIds.contractModels.push(objectId)
            break;
        case AuditObjectTypes.Equipment:
            objectIds.equipments.push(objectId)
            break;
        case AuditObjectTypes.Operation:
        case AuditObjectTypes.OperationSchedule:
            objectIds.operations.push(objectId)
            break;
        case AuditObjectTypes.Student:
        case AuditObjectTypes.Teacher:
            objectIds.beneficiaries.push(objectId);
            break;
        default:
            objectIds.unknown.push(objectId)
    }
}

const addKeyFromRemoteObject = (value, list, key, id) => {
    return {
        ...value,
        [key]: {
            ...list?.find(o => o.id === parseInt(id))
        }
    }
}


export default useLogDocument;
