import React, {useMemo} from "react";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import {useTranslation} from "react-i18next";


const excludedFields = ['label']

const TableModeComponent = props => {

    const {data} = props;
    const {t} = useTranslation('dashboard');

    const keys = useMemo(() => Object.keys(data?.length > 0 ? data[0] : [])
        .filter(l => !excludedFields.includes(l)), [data]);

    return <div className={"chart-table-mode"}>

        <TableAdvanced hover responsive>
            <thead>
            <tr>
                {keys.map((key) => (
                    <th key={key}>{t(key)}</th>
                ))}
            </tr>
            </thead>
            <tbody>

            {data?.map((entry, index) => {
                return <tr key={index}>
                    {keys.map((key) => (
                        <td className={"fill-empty"} key={key + "-" + index}>{t(entry[key])}</td>
                    ))}
                </tr>
            })}
            </tbody>
        </TableAdvanced>
    </div>

}

export default TableModeComponent;