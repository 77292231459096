import React from 'react';
import locale from "locales/locales"
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router";
import classnames from "classnames";
import useTranslations from "system/Translations/UseTranslations";
import {useLocation} from "react-router-dom";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import 'layout/modules/BaseLayout/ContentArea/css/header.scss'
import 'layout/modules/BaseLayout/ContentArea/css/header-responsive.scss'


const Header = (props) => {

    const {title} = props;
    const {t} = useTranslations('global', locale);

    const navigate = useNavigate();
    const location = useLocation();

    const isDashboard = location.pathname.length <= 1;

    if (isDashboard)
        return <></>


    return <div className={"fixed-header"}>
        <div className={classnames("content-header")}>

            <div className={"content-header-title"}>
                <div className={"page-name"} role="heading" aria-level="1">
                    {title}
                </div>


                {(!isDashboard && window.history.length > 1)
                    ? <div {...generateOnClickAndEnter(() => navigate(-1))} tabIndex={-1} className="go-back">
                        <div className={"go-back-icon"}>
                            <FontAwesomeIcon icon={faAngleLeft}/>
                        </div>
                        <span className="go-back-label">
                    {t('go_back')}
                    </span>
                    </div>
                    : <div className="go-back">&nbsp;</div>
                }

            </div>

        </div>
    </div>

}


export default Header;
