import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import NutDropdown, {NUTType} from "layout/modules/Forms/Dropdowns/NutDropdown/NutDropdown";
import {getOrNull} from "system/Objects/ObjectParameters";
import "app/home/assets/css/chart-filter.scss";
import SchoolFilterComponent from "modules/Filters/schoolFilter/SchoolFilterComponent";

const BeneficiaryChartFilterComponent = props => {

    const {setFilters, filters} = props;
    const {t} = useTranslation('dashboard');

    return <div className={"chart-data-filter"}>

        <Row>

            <Col md={6}>
                <div className={"label"}>
                    {t('nut3')}
                </div>
                <div className={"value"}>
                    <NutDropdown
                        isClearable={true}
                        isMulti={true}
                        value={getOrNull(filters.nut3)}
                        type={NUTType.NUT3}
                        onChange={useCallback(
                            (value) => setFilters(filter => ({...filter, nut3: value})),
                            [setFilters]
                        )}
                    />

                </div>

            </Col>

            <SchoolFilterComponent
                md={6}
                sm={6}
                lg={6}
                value={filters?.schoolIds}
                isMulti={true}
                setValue={(value) => setFilters(filter => ({
                    ...filter,
                    schoolIds: value,
                }))}
            />

        </Row>

    </div>
}

export default BeneficiaryChartFilterComponent;

