import React, {useMemo} from "react";
import locale from "app/procedures/contracts/locales/locales"
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import ListComponent from "app/procedures/contracts/components/list/ListComponent";

const List = (props) => {

    const {beneficiaryIds, entityIds, ids} = props;

    return <GenericListContainer
        {...props}
        module="contract"
        id={"contract-list"}
        searchPath={"/contracts/"}
        locale={locale}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 5,
            beneficiaryIds: beneficiaryIds,
            entityIds: entityIds,
            ids,
        }),[beneficiaryIds,entityIds, ids])}>
        <ListComponent {...props}/>
    </GenericListContainer>

}

export default List;
