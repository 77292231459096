const getAsArray = (value) => {
    if (value === null || value === undefined)
        return [];
    else if (!Array.isArray(value))
        return [value];
    else
        return value;
}

const getMergedArrays = (a,b) => {
    return getAsArray(a).push(...getAsArray(b));
}

export {getMergedArrays};
export default getAsArray;
