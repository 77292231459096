import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import FormGroup from "layout/modules/Forms/Group/FormGroup";
import Form from "react-bootstrap/Form";
import FormControl from "layout/modules/Forms/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import DatePicker from "layout/modules/Forms/DatePicker/DatePicker";


const FormBillingData = props => {

    const {t} = useTranslation('equipments');
    const {equipment, setEquipment, errors, readOnly} = props;
    const billingDetails = equipment?.billingDetails ?? {};

    return <>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['invoiceNumber']}>
                <Form.Label>{t('invoice_number')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(billingDetails?.invoiceNumber)}
                    disabled={readOnly}
                    onChange={useCallback((e) => setEquipment(profile => ({
                        ...profile,
                        billingDetails: {
                            ...profile.billingDetails,
                            invoiceNumber: e.target.value,
                        }
                    })), [setEquipment])}

                />

                <Form.Text className="sub-error">
                    {t(errors['invoiceNumber'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['invoiceDate']}>
                <Form.Label>{t('invoice_date')}</Form.Label>
                <DatePicker
                    placeholder={t('date_ph')}
                    timezone={"UTC"}
                    disabled={readOnly}
                    value={getOrEmpty(billingDetails?.invoiceDate)}
                    onChange={useCallback(
                        date => setEquipment(equipment => ({
                            ...equipment,
                            billingDetails: {
                                ...equipment.billingDetails,
                                invoiceDate: date,
                            }
                        })), [setEquipment]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['invoiceDate'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['price']}>
                <Form.Label>{t('price')}</Form.Label>
                <FormControl
                    type="number"
                    value={getOrEmpty(billingDetails?.price)}
                    disabled={readOnly}
                    onChange={useCallback((e) => setEquipment(profile => ({
                        ...profile,
                        billingDetails: {
                            ...profile.billingDetails,
                            price: e.target.value,
                        }
                    })), [setEquipment])}

                />

                <Form.Text className="sub-error">
                    {t(errors['price'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col sm={12} lg={6} xl={6}>
            <FormGroup error={errors['priceWithVat']}>
                <Form.Label>{t('price_with_vat')}</Form.Label>
                <FormControl
                    type="text"
                    value={getOrEmpty(billingDetails?.priceWithVat)}
                    disabled={readOnly}
                    onChange={useCallback((e) => setEquipment(profile => ({
                        ...profile,
                        billingDetails: {
                            ...profile.billingDetails,
                            priceWithVat: e.target.value,
                        }
                    })), [setEquipment])}
                />

                <Form.Text className="sub-error">
                    {t(errors['priceWithVat'])}
                </Form.Text>
            </FormGroup>
        </Col>

    </>

}
export default FormBillingData;
